import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
  mangoUtils,
  AuthGuard
} from '@app/_services';
import { SharedComponentsService } from '@app/shared/components';
import { Table } from 'primeng/table';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
declare let numeral: any;
import $ from "jquery";
import { forkJoin } from "rxjs";
import { Message, SelectItem } from "primeng/api";
import { environment } from "@environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { HtmlEditorService, ImageService, LinkService, RichTextEditorComponent, ToolbarService, actionSuccess } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
  selector: 'app-batch-billing',
  templateUrl: './batch-billing.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class BatchBillingComponent implements OnInit, AfterViewInit{
  public batchBillingForm: UntypedFormGroup;
  @ViewChild("searchValue") searchValue;
  @ViewChild("defaultMemo") defaultMemo: ElementRef;
  @ViewChild("parentRowMemo") parentRowMemo: ElementRef;
  @ViewChild("dtchild") dtchild: Table;
  @ViewChild("dtchildex") dtchildex: Table;

  saveData:Array<any> = [];  
  searchTextStr: any = "";
  @ViewChild("searchValueTime") searchValueTime;
  searchTextTimeStr: any ="";
  filteredTimeItemsSize = -1;
  filteredItemsSize = -1;
  public AllStaffsTypes: any = [];
  public filteredStaffSingle: any[];
  public activityList: any = [];
  public companyLocations: SelectItem[] = [];
  public activitiesTypes: any;
  public engagementsTypes: any = [];
  public selEngagementType: any = {};
  public mangoCompanyData: any = {};
  public isFormValid: boolean = false;
  public NextInvoiceNumber: number = 0;
  public clientProfile: any;
  public _TotalStaffCost: number = 0;
  public invoiceCols: any[];
  public timeSlipsChildCols: any[];
  public standardFooterMessage: any = '';
  engagementLevelTimeTax: 0;
  engagementLevelExpTax: 0;
  @ViewChild('clientInvoiceListdt') dt: Table;

  public parentLevelOne: any = [];
  public tempValues: any = [];
  public tempParentLevelOne: any = [];
  public selectedItems: any = [];
  public headerTitle: string = "";
  successCounter = 0;
  processedCounter = 0;
  clientNames = [];
  public parentLevelOneOrizinal: any = [];
  parentTotalBillableExpenses: any = 0;
  parentTotalBillableServices: any = 0;
  companyNextInvoiceNumber: any = 0;
  parentTotalDiscount: any = 0;
  parentBillableAmount: any = 0;
  parentWUWDAmount: any = 0;
  parentInvoiceAmount: any = 0;
  groupList: SelectItem[];
  clientGroupList: SelectItem[];
  companyId;
  finalizeActions: SelectItem[];
  invoiceTemplateTypes: SelectItem[];
  isIncluedeBillThrough: boolean = true;
  includeInactiveClients: boolean = false;
  sidePanelDisplay: boolean = false;
  public historyDataSource: any = [];
  public customerBalance = 0;
  public customerName = '';
  public clientPaymentsHistory: any = [];
  public clientInfo = null;
  timeexp;
  selectedOnly = false;
  private eventTimeSheetSubscription;
  private eventTimeExpenseSubscription;
  scratchPadEnabled: boolean = false;
  public editableData: any = null;
  public rteObj: RichTextEditorComponent;
  public tools: ToolbarModule = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      'SuperScript',
      'SubScript',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      'CreateTable',
      'CreateLink',
      'Image',
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      'FullScreen',
      '|',
      'Undo',
      'Redo'
    ]
  };

  cellFocused: any = null;
  showFilterSideBar: boolean = false;
  showRecordsSideBar: boolean = false;
  recordsTable: any;
  public salesTax: any = {
    Labor: 0,
    Expense: 0,
    taxableAmtService: 0,
    taxableAmtExpense: 0,
    serviceTax: 0,
    expenseTax: 0
  };
  public invoiceTax = 0;
  public billingMethodList: any = [
    { value: null, label: 'All' },
    { value: 'Hourly', label: 'Hourly' },
    { value: 'Fixed Fee', label: 'Fixed Fee' }
  ];

  cols: any[];
  _selectedColumns: any = [];

  viewRecordOptions: any = [];
  selectedViewRecord: any;

  months = [
    { label: 'January', value: 0 },
    { label: 'February', value: 1 },
    { label: 'March', value: 2 },
    { label: 'April', value: 3 },
    { label: 'May', value: 4 },
    { label: 'June', value: 5 },
    { label: 'July', value: 6 },
    { label: 'August', value: 7 },
    { label: 'September', value: 8 },
    { label: 'October', value: 9 },
    { label: 'November', value: 10 },
    { label: 'December', value: 11 }
  ];

  quarters = [
    { label: '1st Quarter', value: 1 },
    { label: '2nd Quarter', value: 2 },
    { label: '3rd Quarter', value: 3 },
    { label: '4th Quarter', value: 4 }
  ];
  currentMonth = null;
  currentYear = null;
  currentQuarter = null;
  showDefaultMemo = false;
  years = [];
  public descCount: number = 0;
  invoiceMemoParent = null;
  showParentMemo = false;

  isPreviewVisible: boolean = false;
  loginCompanyId: any;
  billingHeaderPreview = null;
  public dialogTab1Display: boolean = false;
  public dialogTab2Display: boolean = false;
  public dialogTab3Display: boolean = false;
  public dialogTab4Display: boolean = false;
  public dialogTab5Display: boolean = false;
  public pdfUrl: any = '';

  createTimeRecordsArr = [];
  createBillingDetailsArr = [];
  updateBillingHeaderArr = [];
  public clientList: any = [];
  intervalid: any;

  emailList: any;
  msgs1: Message[];
  clientBalances: any = [];
  companySetting: any;

  constructor(
    private _fb: UntypedFormBuilder,
    private translate: TranslateService,
    private http: HttpClient,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    public mangoUtils: mangoUtils,
    public auth: AuthGuard,
    public sharedSrvc: SharedComponentsService,
    private router: Router,
    private currencyPipe: CurrencyPipe
  ) {
    this.loginCompanyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.getMonthAndYearDropdownOptions();
    this.translate.reloadLang(this.translate.currentLang).subscribe(data => {
      this.initializeColumns();
      this.msgs1 = [
        {
          severity: 'info',
          summary: 'Info',
          detail: this.translate.instant('default_memo_warning')
        }
      ];
      this.breadcrumbService.setItems([
        { label: this.translate.instant('Billing-&-Invoicing') },
        { label: this.translate.instant('Batch_Billing'), icon: 'ic-red' }
      ]);

      this.invoiceTemplateTypes = [
        { value: null, label: this.translate.instant('Select_template') },
        { value: '2', label: this.translate.instant('client.narrative') },
        {
          value: '9',
          label: this.translate.instant('client.narrative_summary')
        },
        {
          value: '10',
          label: this.translate.instant('client.narrative_detail')
        },
        {
          value: '4',
          label: this.translate.instant('client.narrative_mini_statement')
        },
        // { value: '14', label: this.translate.instant('Flexible Invoice') },
        { value: '0', label: this.translate.instant('client.detail') },
        { value: '6', label: this.translate.instant('client.detail_no_rate') },
        { value: '7', label: this.translate.instant('client.detail_no_rate_time') },
        {
          value: '11',
          label: this.translate.instant('client.detail_no_rate_time_amount')
        },
        {
          value: '12',
          label: this.translate.instant('client.narrative_simple_no_detail')
        },
        {
          value: '13',
          label: this.translate.instant('client.narrative_simple_with_detail')
        },
        {
          value: '14',
          label: this.translate.instant('client.narrative_simple_no_remit')
        },
        {
          value: "15",
          label: this.translate.instant("client.invoice_detail_summary"),
        },
        {
          value: "16",
          label: this.translate.instant("client.invoice_narrative_summary_by_activity"),
        },
      ];

      this.finalizeActions = [
        { value: null, label: this.translate.instant('select_action') },
        { label: 'Print', value: 'Print' },
        { label: 'Email', value: 'Email' },
        { label: 'Finalize Only', value: 'Finalize Only' }
      ];
    });

    this.viewRecordOptions = [
      { value: null, label: 'Get All records' },
      { value: 20, label: 'Get Last 20 records' },
      { value: 50, label: 'Get Last 50 records' },
      { value: 80, label: 'Get Last 80 records' }
    ];

    this.selectedViewRecord = 20; // default

    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.timeexp = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);

    this.engagementsTypes = [];
    this.selEngagementType = [];
    this.getAllStaffList();
  }

  closeInvoiceMemo(){
    const parentRow = this.parentLevelOne.find(item=> !item.isChildItem && item.ClientID == this.invoiceMemoParent['ClientID']);
    if(parentRow) {
      parentRow['customDescription'] = this.invoiceMemoParent['customDescription'];
    }
    setTimeout(() => {
      this.showParentMemo = false;
      this.invoiceMemoParent = null;
    }, 50);
  }

  showDefaultMemoDialog() {
    this.showDefaultMemo = true;
    setTimeout(() => {
      this.defaultMemo.nativeElement.focus();
    }, 100);
  }

  openInvoiceMemo(rowData){
    const defaultDescription = this.batchBillingForm.controls["DescriptionShort"].value;
    if(defaultDescription && defaultDescription.length > 0){
      Swal.fire({
        title: this.translate.instant('warning'),
        html: this.translate.instant('batch_description_overriden_warning'),
        icon: 'warning',
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('ok'),
        timer: 3000
      }).then(result => {
        setTimeout(() => {
          this.parentRowMemo.nativeElement.focus();
        }, 100);
      });
    }
    this.showParentMemo = true;
    // this.invoiceMemoParent = this.getSpecificInvoiceMemo(rowData['ClientID']);;
    this.invoiceMemoParent = rowData;
    setTimeout(() => {
      this.parentRowMemo.nativeElement.focus();
    }, 100)
    this.saveData.push(rowData);
  }

  getSpecificInvoiceMemo(clientId) {
    //get custom description depending on the selected children
    const parentRow = this.selectedItems.find(item=> !item.isChildItem && item.isSelected && item['ClientID'] == clientId)
    if(!parentRow) return null;
    let exceedChecker = false;
    const dataItems = this.selectedItems.filter((item) => item.isChildItem && item['ClientID'] == parentRow.ClientID && item.isSelected); // get children of the same client id
    if (dataItems.length > 0) {
      const customDescription = [];
      for (let childIndex = 0; childIndex < dataItems.length; ++childIndex) {
        if (dataItems[childIndex].isChildItem) {
          dataItems[childIndex]['EngagementInvoiceDescription'] =
            dataItems[childIndex]['EngagementInvoiceDescription'] ||
            dataItems[childIndex]['EngagementName'];
          customDescription.push(dataItems[childIndex]['EngagementInvoiceDescription']);
        }
      }
      parentRow['customDescription'] = customDescription.join(' | ');
      parentRow['customDescription'] = this.mangoUtils.replaceCaretTemplateWithMonthAndYear(
        this.replaceShortcuts(parentRow.customDescription),
        this.currentMonth,
        this.currentYear,
        this.currentQuarter
      );
      if (parentRow['customDescription'].length > 250) {
        exceedChecker = true;
        this.checkIfMemoExceeds(parentRow['customDescription'], this);
      }
    }
    return parentRow;
  }

  getMonthAndYearDropdownOptions() {
    const currentDate = new Date();
    this.currentMonth = currentDate.getMonth();
    this.currentQuarter = moment().get('quarter');
    this.currentYear = currentDate.getFullYear();

    for (let i = this.currentYear + 2; i > this.currentYear - 5; i--) {
      this.years.push({ label: i.toString(), value: i });
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(e) {
    if (this.isPreviewVisible) {
      e.preventDefault();
      this.closeDialog();
    }
    if (!this.isPreviewVisible) {
      return '';
    }
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      //restore original order
      const arr = val.map(col => col.field)
      this._selectedColumns = this.cols.filter(col => arr.includes(col.field));
  }

  onChangeSelectedCols(event) {
    let columnsToSave = '';
    event.value.map((cols, index) => {
      if (index > 0) columnsToSave += `, ${cols.field}`;
      else columnsToSave += cols.field;
    });
    const objToSave = { OpenTimeCols: `{${columnsToSave}}` };
    this.mangoAPISrvc
      .updateUserSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID), objToSave)
      .subscribe(
        data => {},
        err => {}
      );
  }

  onShowScratchpad(data) {
    this.editableData = data;
    this.scratchPadEnabled = true;
  }

  onScratchpadChange() {
    this.editableData['IsColumnChanges'] = true;
  }

  onScratchpadClose() {
    this.scratchPadEnabled = false;
    this.editableData = null;
  }

  fetchClients() {
    const list = this.encrDecSrvc.clientList;
    if (this.clientList.length == 0 || this.clientList.length !== list.length) {
      this.clientList = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        this.clientList.push(item);
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  initializeColumns() {
    const allCols = [
      {
        field: 'Ddate',
        header: this.translate.instant('date'),
        rowClass: 'width-7p p-text-center'
      },
      {
        field: 'StaffName',
        header: this.translate.instant('user-title'),
        rowClass: 'width-10p p-text-left',
        canFilter: true
      },
      {
        field: 'StaffNumber',
        header: this.translate.instant('User Initials'),
        rowClass: 'width-7p p-text-left',
        canFilter: true
      },
      {
        field: 'scdescr',
        header: this.translate.instant('activity'),
        rowClass: 'width-10p p-text-left'
      },
      {
        field: 'ServiceCode',
        header: this.translate.instant('Code'),
        rowClass: 'width-5p p-text-left'
      },
      {
        field: 'Memo',
        header: this.translate.instant('description'),
        rowClass: 'width-15p p-text-left'
      }
    ];
    let selectedCols = [];
    const defaultCols = [
      {
        field: 'Ddate',
        header: this.translate.instant('date'),
        rowClass: 'width-7p p-text-center'
      },
      {
        field: 'StaffName',
        header: this.translate.instant('user-title'),
        rowClass: 'width-10p p-text-left',
        canFilter: true
      },
      {
        field: 'scdescr',
        header: this.translate.instant('activity'),
        rowClass: 'width-10p p-text-left'
      },
      {
        field: 'Memo',
        header: this.translate.instant('description'),
        rowClass: 'width-15p p-text-left'
      }
    ];
    this.cols = [...allCols];
    this.mangoAPISrvc
      .getUsersSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID))
      .subscribe(
        (data: any) => {
          if (data.OpenTimeCols?.length > 0) {
            selectedCols = allCols.filter(col => data.OpenTimeCols.includes(col.field));
          } else {
            selectedCols = [...defaultCols];
          }
          this._selectedColumns = selectedCols;
        },
        err => {
          selectedCols = [...defaultCols];
        }
      );
  }
  @HostListener('mouseup', ['$event'])
  @HostListener('mousemove', ['$event'])
  refreshUserState(event: MouseEvent) {
    if (!this.sharedSrvc.invoiceInactivitySub.closed)
      this.sharedSrvc.invoiceInactivitySub.next(null);
  }

  ngOnInit(): void {
    this.initializeForm();
    this.getCompanyLocations();
    this.getLastInvoiceNumber();
    this.getEngagements();
    this.getGroups();
    this.getProjects();
    this.companySetting = this.encrDecSrvc.getObject(AppConstants.systemLocking);

    this.eventTimeSheetSubscription = this.sharedSrvc.timeEntryDialogData.subscribe(data => {
      this.fetchDataSource();
    });

    this.eventTimeExpenseSubscription = this.sharedSrvc.expenseDialogData.subscribe(data => {
      this.fetchDataSource();
    });
    this.getActivityGroups();

    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
  }

  ngOnDestroy(): void {
    this.eventTimeSheetSubscription.unsubscribe();
    this.eventTimeExpenseSubscription.unsubscribe();
  }

  showSidePanel(item) {
    this.customerName = item.ClientName;
    this.getClientInformation(item.ClientID);
    this.getCustomerBalance(item.ClientID);
    this.getInvoiceHistoryData(item.ClientID);
    this.getClientPaymentHistory(item.ClientID);
    this.sidePanelDisplay = !this.sidePanelDisplay;
  }

  getCustomerBalance(clientId) {
    this.mangoAPISrvc.getClientCustomerBalance(clientId).subscribe(data => {
      if (data && data[0]) {
        this.customerBalance = data[0].CustomerBalance;
      }
    });
  }

  getClientPaymentHistory(clientId) {
    const limit = this.selectedViewRecord;
    this.mangoAPISrvc.getClientPayments(clientId, limit).subscribe((data: any) => {
      if (data && data.length > 0) {
        this.clientPaymentsHistory = data;
      }
    });
  }

  getClientInformation(clientId) {
    this.mangoAPISrvc.getClientFullinformation(clientId).subscribe(data => {
      this.clientInfo = data;
    });
  }

  resetClientInformation() {
    this.customerName = '';
    this.clientInfo = {};
    this.customerBalance = 0;
    this.historyDataSource = [];
    this.clientPaymentsHistory = [];
  }

  getInvoiceHistoryData(clientId) {
    if (clientId) {
      const limit = this.selectedViewRecord;
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getInvoiceHistory2Limit(clientId, limit).subscribe((item: any) => {
        this.mangoAPISrvc.showLoader(false);
        this.historyDataSource = item;
      });
    }
  }

  getGroups() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getGroups().subscribe(
      (data: any) => {
        this.groupList = data.map(group => {
          return {
            label: group.GroupDescription,
            value: group.CustomerGroupCategoryID
          };
        });

        this.clientGroupList = data.map(group => {
          return {
            label: group.GroupDescription,
            value: group.CustomerGroupCategoryID
          };
        });
        this.groupList.unshift({ label: 'All', value: null });
        this.mangoAPISrvc.showLoader(false);
      },
      error => {
        this.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getInvoiceTemplate(template) {
    if (!template) return '';

    return this.invoiceTemplateTypes?.filter(type => type.value === template)[0]?.label;
  }

  getActivityGroups() {
    const parent = this;
    if (this.activityList.length > 0) {
      return false;
    }

    let tempList = this.encrDecSrvc.activitiesList;
    tempList = tempList.sort(parent.mangoUtils.compareValues('ServiceCode', 'asc'));

    this.activityList = tempList.map(data => {
      return {
        label: data.ServiceCode + ' - ' + data.Description,
        value: data.ServiceCodeID,
        fullobj: data
      };
    });
  }

  isLocked(ddate) {
    if (!(this.auth.isAllowAccess(32) || this.auth.isSuperAdmin)) {
      if (this.timeexp.isLockTime && parseInt(this.timeexp.LockTimeDays) > 0) {
        const dDate = moment(ddate).format('MM/DD/YYYY');
        const dateNow = moment().format('MM/DD/YYYY');
        return moment(dateNow).diff(moment(dDate), 'days') > this.timeexp.LockTimeDays;
      }
    }
    return false;
  }

  replaceCaret(value, rowData, type?) {
    if (type) {
      const value = this.mangoUtils.replaceCaretTemplate(this.batchBillingForm.value["DescriptionShort"]);
      this.batchBillingForm.controls["DescriptionShort"].setValue(value);
      return;
    }
    rowData['Memo'] = this.mangoUtils.replaceCaretTemplate(rowData['Memo']);
  }

  replaceCaret2(type){
    if(type=="DescriptionShort"){
      const value = this.mangoUtils.replaceCaretTemplateWithMonthAndYear(this.batchBillingForm.value["DescriptionShort"], this.currentMonth, this.currentYear, this.currentQuarter);
      this.batchBillingForm.controls["DescriptionShort"].setValue(value, { emitEvent: false });
    } else if(type=="customDescription"){
      this.invoiceMemoParent['customDescription'] = this.mangoUtils.replaceCaretTemplateWithMonthAndYear(this.invoiceMemoParent['customDescription'], this.currentMonth, this.currentYear, this.currentQuarter);
    } else if(type=="EngagementInvoiceDescription"){
      this.recordsTable['EngagementInvoiceDescription'] = this.mangoUtils.replaceCaretTemplateWithMonthAndYear(this.recordsTable['EngagementInvoiceDescription'], this.currentMonth, this.currentYear, this.currentQuarter);
    }
  }
  /**/
  fetchDataSource() {
    const formData = this.batchBillingForm.value;

    let query = moment(formData.InvoiceStartDate).format('MM-DD-YYYY') + '/';
    query += formData['staffId'] ? formData['staffId'] + '/' : 'null/';
    query += this.selEngagementType.length > 0 ? this.selEngagementType + '/' : 'null/';
    query += formData['billingPartnerId'] ? formData['billingPartnerId'] + '/' : 'null/';
    query += formData['selectedGroup'] ? formData['selectedGroup'] + '/' : 'null/';
    query += formData['selectedBillingMethod']
      ? formData['selectedBillingMethod'] === 'Hourly'
        ? 'H' + '/'
        : 'F' + '/'
      : 'null/';
    query += formData['companyLocationsId'] ? formData['companyLocationsId'] + '/' : 'null/';
    query +=
      formData['selectedGroupArray'] && formData['selectedGroupArray'].length > 0
        ? `[${formData['selectedGroupArray'].join('-')}]/`
        : 'null/';
    query += this.includeInactiveClients;
    this.getClientInvoiceDatasource(query);
  }

  onEngagementDescriptionChange() {
    this.recordsTable['isDescriptionChanged'] = true;
  }

  getClientInvoiceDatasource(query) {
    const _this = this;
    _this.parentLevelOneOrizinal = [];
    _this.selectedItems = [];
    _this.tempParentLevelOne = [];
    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc.SlipMasterbyCompanyIDLevel1(query).subscribe(function (parentOne: any) {
      parentOne = parentOne.map(function (obj) {
        obj['billableexpenses'] = obj['billableexpenses'] ? numeral(obj['billableexpenses']).value() : 0.00;
        obj['totaltime'] = obj['totaltime'] ? numeral(obj['totaltime']).value() : 0;
        obj['billableservices'] = obj['billableservices'] ? numeral(obj['billableservices']).value() : 0.00;
        obj['nonbillableamount'] = obj['nonbillableamount'] ? numeral(obj['nonbillableamount']).value() : 0.00;
        obj['parentDiscount'] = 0.00;
        obj['parentBillableAmount'] = 0.00;
        obj['parentWUWDAmount'] = 0.00;
        obj['parentInvoiceAmount'] = 0.00;
        obj['levelTwoRecordsSize'] = 0;
        obj['levelThreeRecordsSize'] = 0;
        obj['isSelected'] = false;
        obj['isChildItem'] = false;
        obj["allTimeSlips"] = [];
        obj["allSelectedTimeSlips"] = [];
        obj["allExpenseSlips"] = [];
        obj["allSelectedExpenseSlips"] = [];
        obj['uuid'] = _this.mangoUtils.generateUUID();
        obj['SearchClientName'] = obj['ClientName'];
        obj['BillingMethod'] = '';
        obj['customDescription'] = '';
        obj['isDisableDiscount'] = false;
        obj['FlatFeeAmount'] = 0.00;
        obj['id'] = `${obj['ClientID']}`
        const compLocation = _this.companyLocations.find(itm => itm.value === obj['CompanyMangoLocationID']);
        obj['CompanyLocation'] = compLocation ? compLocation.label : '';
        return obj;
      });
      _this.tempParentLevelOne = [...parentOne];
      _this.parentLevelOneOrizinal = [...parentOne];
      _this.mangoAPISrvc.showLoader(true);
      const headers = new HttpHeaders()
        .set("content-type", "application/json")
        .set("Authorization", _this.encrDecSrvc.getObject("token"));
      const urlOne = _this.http.get(
        `${environment.API_URL}/company/batchbilling/SlipMasterParentbyCompanyIDLevel2/${query}`,
        { headers: headers, withCredentials: true }
      );
      const urlTwo = _this.http.get(
        `${environment.API_URL}/company/batchbilling/SlipMasterbyCompanyIDDetailRecordsLevel3/${query}`,
        { headers: headers, withCredentials: true }
      );
      forkJoin([urlOne, urlTwo]).subscribe(
        childs => {
          const levelTwo = childs[0];
          const levelThree = childs[1];
          const form = _this.batchBillingForm.value;
          for (let i = 0; i < _this.tempParentLevelOne.length; i++) {
            const levelOneItem = _this.tempParentLevelOne[i];
            const levelTwoRecords = levelTwo[levelOneItem['ClientID']] ? levelTwo[levelOneItem['ClientID']] : [];
            // adding level 2 items into level 1
            if (levelTwoRecords.length > 0) {
              const parentItemPos = _this.tempParentLevelOne.indexOf(levelOneItem);
              for (let secondItem = 0; secondItem < levelTwoRecords.length; ++secondItem) {
                if (parentItemPos > -1) {
                  const secondDataItem = levelTwoRecords[secondItem];
                  secondDataItem['billableexpenses'] = secondDataItem['billableexpenses'] ? numeral(secondDataItem['billableexpenses']).value() : 0.00;
                  secondDataItem['totaltime'] = secondDataItem['totaltime'] ? numeral(secondDataItem['totaltime']).value() : 0;
                  secondDataItem['billableservices'] = secondDataItem['billableservices'] ? numeral(secondDataItem['billableservices']).value() : 0.00;
                  secondDataItem['nonbillableamount'] = secondDataItem['nonbillableamount'] ? numeral(secondDataItem['nonbillableamount']).value() : 0.00;
                  secondDataItem['ClientName'] = secondDataItem['ClientName'];
                  secondDataItem['SearchClientName'] = levelOneItem['ClientName'];
                  secondDataItem['BillingMethod'] = secondDataItem['BillingMethod'];
                  secondDataItem['FlatFeeAmount'] = secondDataItem['FlatFeeAmount'];
                  secondDataItem['isSkipFlatAmount'] = (secondDataItem['billableservices'] == 0) ? true : false;
                  secondDataItem['isDiscountChanged'] = false;
                  secondDataItem['parentDiscount'] = 0.00;
                  secondDataItem['parentBillableAmount'] = 0.00;
                  secondDataItem['parentWUWDAmount'] = 0.00;
                  secondDataItem['parentInvoiceAmount'] = 0.00;
                  secondDataItem['levelTwoRecordsSize'] = 0;
                  secondDataItem['levelThreeRecordsSize'] = 0;
                  secondDataItem['customDescription'] = '';
                  secondDataItem['isSelected'] = false;
                  secondDataItem['isChildItem'] = true;
                  secondDataItem["IsExpenseRollUp"] = _this.mangoCompanyData['AutoRollupExpenses'];
                  secondDataItem['id'] = `${secondDataItem['ClientID']}-${secondDataItem['ProjectMasterID']}`
                  secondDataItem['isDisableDiscount'] = (secondDataItem['billableexpenses'] > 0 && secondDataItem['billableservices'] == 0) ? true : false;
                  secondDataItem['uuid'] = _this.mangoUtils.generateUUID();
                  const allRecords = levelThree[secondDataItem['ProjectMasterID']] ? levelThree[secondDataItem['ProjectMasterID']] : [];

                  allRecords.map(
                    function( obj ){
                      obj[ 'displayDate' ] = (
                        moment.utc( new Date( obj.Ddate ) )
                        .format( "MM/DD/YYYY" )
                      );
                    }
                  );

                  secondDataItem["allTimeSlips"] = allRecords.filter((item) => item['IsTimeRecord'] == 'T');
                  secondDataItem["allSelectedTimeSlips"] = [];
                  secondDataItem["allExpenseSlips"] = allRecords.filter((item) => item['IsTimeRecord'] == 'X');
                  secondDataItem["allSelectedExpenseSlips"] = [];

                  secondDataItem['grandExpenseTotalAmt'] = secondDataItem["allExpenseSlips"].reduce(function (a, b) { return a + +numeral(b['StandardAmount']).value(); }, 0);

                  secondDataItem['grandExpenseHrs'] = secondDataItem["allTimeSlips"].reduce(function (a, b) { return a + +numeral(b['TotalTime']).value(); }, 0);
                  secondDataItem['grandBillableamount'] = secondDataItem["allTimeSlips"].reduce(function (a, b) { return a + +numeral(b['billableamount']).value(); }, 0);
                  secondDataItem['grandNonbillableamount'] = secondDataItem["allTimeSlips"].reduce(function (a, b) { return a + +numeral(b['nonbillableamount']).value(); }, 0);
                  secondDataItem['grandBilledamount'] = secondDataItem["allTimeSlips"].reduce(function (a, b) { return a + +(numeral(b['BilledAmount']).value() || numeral(b['billableamount']).value()); }, 0);
                  secondDataItem['grandWUWDamount'] = 0;
                  // secondDataItem['amount'] = secondDataItem['grandBillableamount'];
                  // secondDataItem["amount"] =
                  //  secondDataItem["BillingMethod"] == "Fixed Fee"
                  //    ? (secondDataItem["FlatFeeAmount"] ? secondDataItem["FlatFeeAmount"] : 0)
                  //    : secondDataItem["grandBillableamount"];
                  secondDataItem["amount"] =
                    secondDataItem["BillingMethod"] == "Fixed Fee"
                      ? secondDataItem["FlatFeeAmount"]
                        ? secondDataItem["FlatFeeAmount"]
                        : 0
                      : secondDataItem["grandBilledamount"];
                  _this.distributeInvoiceAmount(secondDataItem, false, true)
                  secondDataItem['isAutoUpdate'] = true;
                  _this.parentLevelOneOrizinal.push(secondDataItem);
                }
              }

            }
          }
          const updatedArray = [];
          for (let parentIndex = 0; parentIndex < _this.tempParentLevelOne.length; ++parentIndex) {
            const parentItem = _this.tempParentLevelOne[parentIndex];
            updatedArray.push(parentItem);
            const dataItems = _this.parentLevelOneOrizinal.filter((item) => item['ClientID'] == parentItem.ClientID);
            if (dataItems.length > 0) {
              for (let childIndex = 0; childIndex < dataItems.length; ++childIndex) {
                if (dataItems[childIndex].isChildItem) {
                  dataItems[childIndex].allTimeSlips = dataItems[childIndex].allTimeSlips.map((timeSlip) => {
                    return {
                      ...timeSlip,
                      Ddate: new Date(timeSlip.Ddate)
                    }
                  });
                  dataItems[childIndex].allExpenseSlips = dataItems[childIndex].allExpenseSlips.map((timeSlip) => {
                    return {
                      ...timeSlip,
                      Ddate: new Date(timeSlip.Ddate),
                      //BilledAmount: timeSlip.StandardAmount
                    }
                  });

                  updatedArray.push(dataItems[childIndex]);
                }
              }
            }
          }
          _this.parentLevelOne = [...updatedArray];
          _this.mangoAPISrvc.getEmailNamesByCompanyID(_this.loginCompanyId).subscribe((custContacts: any) => {
            _this.parentLevelOne = _this.parentLevelOne.map((item) => {
              if(item.FinalizeAction == 'Email') {
                const custContactsPerClient = custContacts?.filter((contact) => contact.ClientID == item.ClientID)
                if(custContactsPerClient?.length == 0) {
                  return {
                    ...item,
                    FinalizeAction: 'Print'
                  }
                } else
                  return { ...item }
              } else
                return { ...item }
            })
          })
          // _this.parentLevelOne.map((item, index) => {
          //   _this.mangoAPISrvc.getEmailNames(item['ClientID']).subscribe((emailList: any) => {
          //     const finalizeAction = _this.parentLevelOne[index]['FinalizeAction'];
          //     if(finalizeAction == 'Email' && emailList.length == 0) {
          //       _this.parentLevelOne[index]['FinalizeAction'] = 'Print'
          //     }
          //   })
          // })
          // _this.footerTotalsForParent();
          _this.calculateParentAndChildFooters();
          _this.mangoAPISrvc.showLoader(false);
        },
        err => console.error(err)
      );
    }, (error) => {
      _this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.fetchErrorMsg);
      _this.mangoAPISrvc.showLoader(false);
    })
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = '';
    this.filteredItemsSize = -1;
    this.encrDecSrvc.addObject('batchBilling_' + AppConstants.SearchString, '');
  }

  clearSearchFilterTime() {
    this.searchValueTime.nativeElement.value = this.searchTextTimeStr = '';
    this.filteredTimeItemsSize = -1;
    this.calcEngagementTimeSalesTax(this.dtchild?._value);
  }

  ngAfterViewInit() {
    this.searchValue.nativeElement.focus();
  }

  onFilterTime(obj) {
    this.filteredTimeItemsSize = obj.filteredValue.length;
    if (obj.filteredValue.length === 0 && this.searchValue?.nativeElement?.value == '')
      this.calcEngagementTimeSalesTax(this.dtchild?._value);
    else this.calcEngagementTimeSalesTax(obj?.filteredValue);
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue?.filter(item => item?.isChildItem != true)?.length;
    this.encrDecSrvc.addObject(
      'batchBilling_' + AppConstants.SearchString,
      obj.filters.global?.value
    );
  }

  previewInvoice(rowData) {
    rowData['isPreviewMode'] = true;
    const childItems = this.selectedItems.filter((item) => (item.isChildItem && item['ClientID'] == rowData.ClientID));
    if(childItems.length == 0){
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), this.translate.instant("cant-preview-recurring-header-row"))
    }else {
      this.createTimeRecordsArr = [];
      this.createBillingDetailsArr = [];
      this.updateBillingHeaderArr = [];
      this.processedCounter = 0;
      this.successCounter = 0;
      this.processOneByOne(rowData);
    }
  }

  closeDialog() {
    this.isPreviewVisible = false;
    if (!this.billingHeaderPreview) return;
    this.mangoAPISrvc
      .reverseInvoice(this.billingHeaderPreview['BillingHeaderID'], true)
      .subscribe(data => {});
  }

  newPreviewInvoices(billingHeader) {
    let pdfUrl;
    const parent = this;
    parent.dialogTab1Display = false;
    parent.dialogTab2Display = false;
    parent.dialogTab3Display = false;
    parent.dialogTab4Display = false;
    parent.dialogTab5Display = false;
    parent.pdfUrl = "";
    billingHeader['IsPrintPreview'] = true;
    this.mangoAPISrvc.updateBillingHeader({IsPrintPreview: true}, billingHeader['BillingHeaderID'])
      .subscribe(data =>{
      switch (billingHeader['InvoiceTemplate']) {
        case '2':
        case '4':
          pdfUrl = new URL(`${encodeURIComponent('/home/tim/InvoiceNarrativeBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);
          parent.headerTitle = parent.translate.instant("Narrative-Invoices");
          parent.isPreviewVisible = true;
          parent.pdfUrl = pdfUrl.href;

          setTimeout(() => {
            parent.mangoAPISrvc.showLoader(false);
            parent.dialogTab1Display = true;
          }, 300);          
          break;
        case '0':
        case '1':
        case '11':
        case '6':
        case '7':
          pdfUrl = new URL(`${encodeURIComponent('/home/tim/InvoiceDetailBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);
          parent.headerTitle = parent.translate.instant("Detail-Invoices");
          parent.isPreviewVisible = true;
          parent.pdfUrl = pdfUrl.href;

          setTimeout(() => {
            parent.mangoAPISrvc.showLoader(false);
            parent.dialogTab1Display = true;
          }, 300);
          break;
        case '12':
        case '13':
          pdfUrl = new URL(`${encodeURIComponent('/home/tim/InvoiceSimpleBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);
          parent.headerTitle = parent.translate.instant("Simple-Narrative-Invoices");
          parent.isPreviewVisible = true;
          parent.pdfUrl = pdfUrl.href;

          setTimeout(() => {
            parent.mangoAPISrvc.showLoader(false);
            parent.dialogTab1Display = true;
          }, 300);
          break;
        case '14':
          pdfUrl = new URL(`${encodeURIComponent('/home/tim/NarrativeSimpleNoRemitBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);
          parent.headerTitle = parent.translate.instant("Narrative-Summary-Invoices");
          parent.isPreviewVisible = true;
          parent.pdfUrl = pdfUrl.href;

          setTimeout(() => {
            parent.mangoAPISrvc.showLoader(false);
            parent.dialogTab1Display = true;
          }, 300);
          break;
        case '9':
        case '10':
          pdfUrl = new URL(`${encodeURIComponent('/home/tim/InvoiceNarrativeSummaryBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);
          parent.headerTitle = parent.translate.instant("Narrative-Summary-Invoices");
          parent.isPreviewVisible = true;
          parent.pdfUrl = pdfUrl.href;

          setTimeout(() => {
            parent.mangoAPISrvc.showLoader(false);
            parent.dialogTab1Display = true;
          }, 300);
          break;
      }
    });
  }

  processInvoiceList() {
    const parent = this;
    ///let selectedActivitiesSize = parent.selEngagementType.length;
    Swal.fire({
      title: this.translate.instant('confirmation'),
      html: this.translate.instant('billing.process_invoice_list'),
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: this.translate.instant('yes_continue'),
      cancelButtonText: this.translate.instant('no_cancel')
    }).then(result => {
      if (result.value) {
        this.createTimeRecordsArr = [];
        this.createBillingDetailsArr = [];
        this.updateBillingHeaderArr = [];
        const formObj = this.batchBillingForm.value;
        this.syncSelectedItems();
        const selectedParentItem = this.selectedItems.filter((item) => (!item.isChildItem));
        this.processedCounter = 0;
        this.successCounter = 0;
        for (let i = 0; i < selectedParentItem.length; i++) {
          const selectedLevelOneObj = selectedParentItem[i];
          const childItems = parent.selectedItems.filter((item) => (item.isChildItem && item['ClientID'] == selectedLevelOneObj.ClientID));
          selectedLevelOneObj['ProjectMasterIDArray'] = childItems.map(function (element) { return element.ProjectMasterID; });
          // childItems.map(function (element) {
          //   element['EngagementInvoiceDescription'] = element['EngagementInvoiceDescription'] || element['EngagementName'];
          //   if (element['EngagementInvoiceDescription']) {
          //     if (selectedLevelOneObj['customDescription']) {
          //       selectedLevelOneObj['customDescription'] = selectedLevelOneObj['customDescription'] + " | " + element['EngagementInvoiceDescription'];
          //     } else {
          //       selectedLevelOneObj['customDescription'] = element['EngagementInvoiceDescription'];
          //     }
          //   }
          // });
          // selectedLevelOneObj['customDescription'] += ' (thru ' + moment(formObj.InvoiceStartDate).format('MM-DD-YYYY') + ')';

          (function (index, selectedLevelOneObj, size) {
            setTimeout(() => {
              parent.processOneByOne(selectedLevelOneObj);
            }, i * 1000);
          })(i, selectedLevelOneObj, selectedParentItem.length - 1);
        }

        const interval = setInterval(() => {
          if(parent.processedCounter < selectedParentItem.length && this.updateBillingHeaderArr.length < selectedParentItem.length) return;
          clearInterval(interval);
          this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);

          //update last invoice number
          const lastInvoiceObj = {};
          lastInvoiceObj['NextInvoiceNumber'] = this.NextInvoiceNumber;
          parent.mangoAPISrvc.updateLastInvoiceNumber(lastInvoiceObj).subscribe(
            data => {},
            err => {
              parent.mangoAPISrvc.showLoader(false);
              parent.mangoAPISrvc.notify(
                'error',
                this.translate.instant('error'),
                AppConstants.updateErrorMsg
              );
            }
          );

          //batch create BD
          if(this.createBillingDetailsArr.length > 0){
            while (this.createBillingDetailsArr.length > 0) {
              this.batchCreateBillingDetails(this.createBillingDetailsArr, null)
            }
            // const intervalDetails = setInterval(() => this.batchCreateBillingDetails(this.createBillingDetailsArr, intervalDetails), 900);
          }

          //batch update BH
          setTimeout(() => {
            this.mangoAPISrvc
              .batchUpdateBillingHeader(this.updateBillingHeaderArr)
              .subscribe(response => {
                this.updateBillingHeaderArr = [];
                parent.mangoAPISrvc.showLoader(false);
                let staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
                let data = {};
                data['Action'] = 'Batch Invoice';
                data['Description'] = 'Process Batch Invoice' + ' - ' + 'StaffID: ' + staffID;
                data['Table'] = 'CompanyMango';
                let isManaging = this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
                if (!isManaging) {
                  this.mangoAPISrvc.addUserLogs(data).subscribe(
                    res => {},
                    err => {}
                  );
                }
                let message = `<div>${parent.successCounter} out of ${
                  selectedParentItem.length
                } ${parent.translate.instant('Invoices_created_successfully')}</div>`;
                if (
                  selectedParentItem.length - parent.successCounter > 0 &&
                  parent.clientNames.length > 0
                ) {
                  message += `<br>`;
                  message += `<div><strong>${
                    selectedParentItem.length - parent.successCounter
                  } Failed</strong> (Default invoices descriptions are too long.  250 character limit):</div>`;
                  message += `<div>${parent.clientNames.join(', ')}</div>`;
                }
                Swal.fire({
                  icon: 'success',
                  title: parent.translate.instant('Information'),
                  html: message,
                  showConfirmButton: true,
                  showDenyButton: true,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  backdrop: false,
                  confirmButtonText: parent.translate.instant('ok'),
                  denyButtonText: parent.translate.instant('Go_To_Invoice_Review')
                }).then(result => {
                  if (result.isConfirmed) {
                    parent.fetchDataSource();
                  } else if (result.isDenied) {
                    parent.router.navigate(['/billing-invoicing/invoiceReview']);
                  }
                });
              });
          }, 3000);
        }, 500);
      }
    });
  }

  /*
    initialize the form
  */
  initializeForm() {
    const invoiceDate = this.encrDecSrvc.getObject(AppConstants.batchLastInvoiceDate)  != null &&  this.encrDecSrvc.getObject(AppConstants.batchLastInvoiceDate) != "" ? new Date(this.encrDecSrvc.getObject(AppConstants.batchLastInvoiceDate)) : new Date();
    const invoiceStart = this.encrDecSrvc.getObject(AppConstants.batchLastDateThru)  != null &&  this.encrDecSrvc.getObject(AppConstants.batchLastDateThru) != "" ? new Date(this.encrDecSrvc.getObject(AppConstants.batchLastDateThru)) : new Date();


    this.batchBillingForm = this._fb.group({
      InvoiceStartDate: [invoiceStart, [<any>Validators.required]],
      DescriptionShort: [''],
      staffId: [''],
      billingPartnerId: [''],
      invoiceTemplate: [''],
      selectedGroup: [''],
      selectedGroupArray: [''],
      FinalizeAction: [''],
      companyLocationsId: [''],
      InvoiceDate: [invoiceDate, [<any>Validators.required]],
      selectedBillingMethod: null
    });
    const parent = this;

    this.batchBillingForm.valueChanges.subscribe(data => {
      this.validateForm();
      data.DescriptionShort = parent.mangoUtils.replaceCaretTemplate(
        parent.replaceShortcuts(data.DescriptionShort)
      );
      parent.batchBillingForm.controls['DescriptionShort'].setValue(data.DescriptionShort, {
        emitEvent: false
      });
    });
  }

  calculateStandardAmount(data) {
    if (data['BillingRate']) {
      if (data['Billable']) {
        data['billableamount'] = (
          parseFloat(data['TotalTime']) * parseFloat(data['BillingRate'])
        ).toString();
        data['StandardAmount'] = data['billableamount'];
      } else {
        data['nonbillableamount'] = (
          parseFloat(data['TotalTime']) * parseFloat(data['BillingRate'])
        ).toString();
        data['StandardAmount'] = data['nonbillableamount'];
      }
    }

    return data;
  }

  SelectEngagements(opt) {
    const activity = this.engagementsTypes.filter((item) => {
      return item.value == opt.value;
    })[0];
  }

  getAllStaffList() {
    const parent = this;
    if (parent.AllStaffsTypes.length > 0) {
      return false;
    }
    parent.AllStaffsTypes = [{ label: 'All', value: null }];
    let item = [];
    if (parent.auth.isAllowAccess(14)) {
      item = parent.encrDecSrvc.getObject(AppConstants.allStaffList);
    } else {
      item = parent.encrDecSrvc.getObject(AppConstants.staffList);
      item = item?.filter(
        staff => staff?.StaffID == parent.encrDecSrvc.getObject(AppConstants.staffID)
      );
    }
    for (let i = 0; i < item.length; ++i) {
      if (item[i].Inactive) continue;

      const obj = {};
      obj['label'] = item[i]['StaffName'];
      obj['value'] = item[i]['StaffID'];
      obj['StaffID'] = item[i]['StaffID'];
      obj['StaffName'] = item[i]['StaffName'];
      parent.AllStaffsTypes.push(obj);
    }
  }

  getEngagements() {
    let parent = this;
    parent.mangoAPISrvc.getEngagementTypes(this.companyId).subscribe(
      function (data: any) {
        let list = data.filter(invoiceGroup => invoiceGroup.Inactive == false);
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          parent.engagementsTypes.push({
            label: item.Description,
            value: item.EngagementTypeID,
            EngagementTypeID: item.EngagementTypeID
          });
        }
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );
      }
    );
  }

  getLastInvoiceNumber() {
    const parent = this;
    parent.mangoAPISrvc.getInvoiceOption().subscribe((item: any) => {
      parent.mangoCompanyData = item;
      parent.fetchDataSource();
      parent.NextInvoiceNumber = item.NextInvoiceNumber;
      parent.companyNextInvoiceNumber = item.NextInvoiceNumber;
      if (parent.standardFooterMessage == '') {
        parent.standardFooterMessage = item.StandardFooterMessage;
      }
    });
  }

  charCountDescriptionShort() {
    const desValue = this.batchBillingForm.controls["DescriptionShort"].value;
    if (desValue && desValue.length != 0) {
      this.descCount = desValue.length;
    } else {
      this.descCount = 0;
    }
  }

  replaceShortcuts(value?, type?) {
    if (!value && type == 'DescriptionShort') {
      value = this.batchBillingForm.controls['DescriptionShort'].value;
    } else if (!value && type == 'customDescription') {
      value = this.invoiceMemoParent['customDescription'];
    } else if (!value && type == 'EngagementInvoiceDescription') {
      value = this.recordsTable['EngagementInvoiceDescription'];
    }
    if (!value) {
      return;
    }

    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut['ShortCutCode']) {
          label = shortcut['Phrase'];
        }
      }
      valueArr[i] = label;
    }
    if (value && type == 'DescriptionShort') {
      this.batchBillingForm.controls['DescriptionShort'].setValue(valueArr.join(' '), {
        emitEvent: false
      });
    } else if (value && type == 'customDescription') {
      this.invoiceMemoParent['customDescription'] = valueArr.join(' ');
    } else if (value && type == 'EngagementInvoiceDescription') {
      this.recordsTable['EngagementInvoiceDescription'] = valueArr.join(' ');
    }
    return valueArr.join(' ');
  }

  replaceShortcuts2(value, desc, type?) {
    desc['IsColumnChanges'] = true;

    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut['ShortCutCode']) {
          label = shortcut['Phrase'];
        }
      }
      valueArr[i] = label;
    }
    if (type === 'Memo') desc['Memo'] = valueArr.join(' ');
    else {
      desc['description'] = valueArr.join(' ');
      // this.validateLineItems();
    }
  }

  convertShortcuts(value) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut['ShortCutCode']) {
          label = shortcut['Phrase'];
        }
      }
      valueArr[i] = label;
    }
    return valueArr.join(' ');
  }
  /*
    Verifing the form
  */
  validateForm() {
    let isInValidData = false;
    Object.keys(this.batchBillingForm.controls).forEach(key => {
      if (this.batchBillingForm.get(key).invalid) {
        isInValidData = true;
      }
    });
    if (!isInValidData) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  processExpenses(levelTwoObj, headerObj, billingheaderId) {
    const parent = this;
    const datasetList: any = [];
    if (levelTwoObj.allSelectedExpenseSlips.length > 0) {
      for (let expenseIndex = 0; expenseIndex < levelTwoObj.allSelectedExpenseSlips.length; expenseIndex++) {
        const expensesItem = levelTwoObj.allSelectedExpenseSlips[expenseIndex];
        expensesItem["StandardAmount"] = expensesItem["StandardAmount"]
          ? expensesItem["StandardAmount"].toString()
          : "0";
        expensesItem["BillingRate"] = expensesItem["BillingRate"]
            ? expensesItem["BillingRate"].toString()
            : "0";
        expensesItem['BilledAmount'] = expensesItem['BilledAmount'] ? numeral(expensesItem['BilledAmount']).value() : 0;
        expensesItem["SelectedAmount"] = numeral(expensesItem['StandardAmount']).value();
        expensesItem['BillingHeaderID'] = billingheaderId;
        expensesItem['InvoiceDate'] = headerObj['InvoiceDate'];
        expensesItem['InvoiceNumber'] = headerObj['InvoiceNumber'];
        expensesItem['Billed'] = true;
        expensesItem['IsPrintExpense'] = !levelTwoObj['IsExpenseRollUp'];
        datasetList.push(expensesItem);
        expensesItem.StandardAmount = expensesItem.StandardAmount ? expensesItem.StandardAmount : 0;
        // need to check if user changed taxable at the expense entry level
        if (
          parent.mangoCompanyData.ActivateExpenseRates &&
          (levelTwoObj['isTaxable'] || levelTwoObj['isTaxable'] == null) &&
          expensesItem.ectaxable == true &&
          expensesItem.IsTaxable == true
        ) {
          parent.salesTax.taxableAmtExpense += numeral(expensesItem.BilledAmount).value();
          parent.salesTax.expenseTax =
            parent.salesTax.taxableAmtExpense * (parent.salesTax.Expense / 100);
        }
      }

      if(parent.mangoCompanyData.ActivateExpenseRates && (levelTwoObj['isTaxable'] || levelTwoObj['isTaxable'] == null) && numeral(parent.salesTax.expenseTax).value() !== 0) {
        const temp = parent.updateBillingHeaderArr.find(item=> item['BillingHeaderID'] == billingheaderId);
        parent.invoiceTax = ((numeral(temp?.data?.TotalTax).value() || 0) + parent.salesTax.expenseTax);
        const obj = {};
        obj["TotalTaxExpenses"] = parent.salesTax.expenseTax
          ? numeral(parent.salesTax.expenseTax).value()
          : 0;
        obj['SalesTaxAmountExpenses'] = parent.salesTax.taxableAmtExpense
          ? numeral(parent.salesTax.taxableAmtExpense).value()
          : 0;

        obj['InvoiceBalance'] =
          headerObj['InvoiceAmount'] +
          obj['TotalTaxExpenses'] +
          (numeral(temp?.data?.TotalTax).value() || 0);
        parent.pushToUpdateArray(obj, billingheaderId);
      }
      if (datasetList.length > 0) {
        if (headerObj['isPreviewMode']) {
          this.createTimeRecordsArr = [
            ...this.createTimeRecordsArr,
            ...JSON.parse(JSON.stringify(datasetList))
          ];
          // this.createTimeRecordsArr.forEach(item=> delete item['SlipMasterID']);
        } else {
          let forkJoin = null;
          forkJoin = parent.updateTimeSlipsRecords(datasetList);
          forkJoin.subscribe(data => {
            // parent.mangoAPISrvc.updateSalesTaxInBillingHeader(obj, billingheaderId).subscribe(data => {
            //   parent.mangoAPISrvc.showLoader(false);
            // }, (error) => {
            //   parent.mangoAPISrvc.showLoader(false);
            // });
          });
        }
      }
    }
  }

  pushToUpdateArray(object, id){
    const temp = this.updateBillingHeaderArr.find(item=> item['BillingHeaderID'] == id);
    if(temp){
      const toMerge = JSON.parse(JSON.stringify(temp['data']));
      temp['data'] = {...toMerge, ...object}
    }else{
      this.updateBillingHeaderArr.push({
        data: object,
        BillingHeaderID: id
      });
    }
  }

  updateTimeSlipsRecords(selectedItemList) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject('token'));
    selectedItemList.forEach((selectedItem, key) => {
      const urlOne = parent.http.put(
        `${environment.API_URL}/company/timeSheet/${selectedItem['SlipMasterID']}`,
        selectedItem,
        { headers: headers, withCredentials: true }
      );
      observableBatch.push(urlOne);
    });

    return forkJoin(observableBatch);
  }

  createTimeSlipsRecords(selectedItemList) {
    const observableBatch = [];
    selectedItemList.forEach((selectedItem) => {
      // delete selectedItem['SlipMasterID'];
      observableBatch.push(this.mangoAPISrvc.createTimeSheet(selectedItem));
    });
    return forkJoin(observableBatch);
  }

  footerTotalsForParent() {
    const filteredDataSet = this.parentLevelOne.filter((item) => item['isChildItem'] == false);
    this.parentTotalBillableExpenses = filteredDataSet.reduce(function (a, b) { return a + +b.billableexpenses; }, 0);
    this.parentTotalBillableServices = filteredDataSet.reduce(function (a, b) { return a + +b.billableservices; }, 0);
    this.parentTotalDiscount = filteredDataSet.reduce(function (a, b) { return a + +b.parentDiscount; }, 0);
    this.parentBillableAmount = filteredDataSet.reduce(function (a, b) { return a + +b.parentBillableAmount; }, 0);
    this.parentWUWDAmount = filteredDataSet.reduce(function (a, b) { return a + +b.parentWUWDAmount; }, 0);
    this.parentInvoiceAmount = filteredDataSet.reduce(function (a, b) { return a + +b.parentInvoiceAmount; }, 0);
  }

  onSelectedOnlyChange() {
    if (this.selectedOnly) {
      this.tempValues = this.parentLevelOne;
      this.parentLevelOne = this.selectedItems;
    }else{
      this.parentLevelOne = this.tempValues.map(item =>{
        const find = this.parentLevelOne.find(item2 => item['id'] == item2['id']);
        if(find){
          item = find;
        }
        return item;
      });
    }
  }

  checkIfMemoExceeds(value, _this) {
    if (value && value.length > 250) {
      Swal.fire({
        title: _this.translate.instant('warning'),
        html: _this.translate.instant('invoice_memo_exceeds_limit'),
        icon: 'warning',
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: _this.translate.instant('ok'),
        timer: 3000
      });
    }
  }

  markAsChanged() {
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
  }
  /*
   Check and Uncheck row
  */
  levelOneCheckUncheckRow(itemData: any, isParentSelection) {
    // main check/uncheck  selection
    // this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    if (isParentSelection) {
      const isChecked = itemData.checked;
      for (let i = 0; i < this.parentLevelOne.length; ++i) {
        const itemData = this.parentLevelOne[i];
        itemData.isSelected = isChecked;
        itemData.allTimeSlips.map(item => {
          item['isSelected'] = isChecked;
        });
        itemData.allExpenseSlips.map(item => {
          item['isSelected'] = isChecked;
        });
        itemData.allSelectedTimeSlips = isChecked ? [...itemData.allTimeSlips] : [];
        itemData.allSelectedExpenseSlips = isChecked ? [...itemData.allExpenseSlips] : [];
      }
    } // individual row check box selection
    else {
      itemData.isSelected = !itemData.isSelected;
      itemData.allTimeSlips.map(item => {
        item['isSelected'] = itemData.isSelected;
      });
      itemData.allExpenseSlips.map(item => {
        item['isSelected'] = itemData.isSelected;
      });
      itemData.allSelectedTimeSlips = itemData.isSelected ? [...itemData.allTimeSlips] : [];
      itemData.allSelectedExpenseSlips = itemData.isSelected ? [...itemData.allExpenseSlips] : [];
      // if parent element selected/unselected
      if (!itemData.isChildItem) {
        if (itemData.isSelected) {
          // checkIfMemoExceeds(itemData.customDescription, this);
          const childItem = this.parentLevelOne.filter((item) => (item.isChildItem && item['ClientID'] == itemData.ClientID));
          const parentItem = this.selectedItems.filter((item) => (!item.isChildItem && item['ClientID'] == itemData.ClientID));
          // selecting level 3 items
          for (let i = 0; i < childItem.length; ++i) {
            const item = childItem[i];
            item.isSelected = true;
            item.allSelectedTimeSlips = [...item.allTimeSlips];
            item.allSelectedExpenseSlips = [...item.allExpenseSlips];
          }
          if (parentItem.length == 0) {
            itemData.isSelected = true;
            const itm = {}
            this.selectedItems = [...this.selectedItems, ...itemData];
          }
          this.selectedItems = [...this.selectedItems, ...childItem];
        } else {
          const childItems = this.selectedItems.filter((item) => (item.isChildItem && item['ClientID'] == itemData.ClientID));
          for (let i = 0; i < childItems.length; ++i) {
            const childrow = childItems[i];
            childrow.allSelectedTimeSlips = [];
            childrow.allSelectedExpenseSlips = [];
            childrow.isSelected = false;
            this.selectedItems.splice(this.selectedItems.indexOf(childrow), 1).slice(0); // remove parent row from parent selection
            this.selectedItems = this.selectedItems.concat(
              this.selectedItems.splice(this.selectedItems.indexOf(childrow), 1).slice(0)
            ); // trick to update the view
          }
        }
      } else {
        const childItems = this.selectedItems.filter((item) => (item.isChildItem && item['ClientID'] == itemData.ClientID));
        const parentItem = this.selectedItems.filter((item) => (!item.isChildItem && item['ClientID'] == itemData.ClientID));
        // if parent is unselected selected we need to do unselect all child items
        if (childItems.length == 0 && parentItem.length == 1) {
          parentItem.allSelectedTimeSlips = [];
          parentItem.allSelectedExpenseSlips = [];
          parentItem[0].isSelected = false;
          this.selectedItems.splice(this.selectedItems.indexOf(parentItem[0]), 1).slice(0); // remove parent row from parent selection
          this.selectedItems = this.selectedItems.concat(
            this.selectedItems.splice(this.selectedItems.indexOf(parentItem[0]), 1).slice(0)
          ); // trick to update the view
        }
        // if child item is selected automatically its parent should select
        else if (childItems.length > 0 && parentItem.length == 0) {
          const parentNewItem = this.parentLevelOne.filter((item) => (!item.isChildItem && item['ClientID'] == itemData.ClientID));
          parentNewItem[0].isSelected = true;
          // checkIfMemoExceeds(parentNewItem[0].customDescription, this);
          parentNewItem[0].allSelectedTimeSlips = [...parentNewItem[0].allTimeSlips];
          parentNewItem[0].allSelectedExpenseSlips = [...parentNewItem[0].allExpenseSlips];
          this.selectedItems = [...this.selectedItems, ...parentNewItem];
        }
      }
    }
    //get custom description depending on the selected children
    const parentSelectedItems = this.selectedItems.filter(item=> (isParentSelection && !item.isChildItem && item.isSelected) || (!isParentSelection && !item.isChildItem && item.isSelected && item['ClientID'] == itemData['ClientID']))
    let exceedChecker = false;
    for (let parentIndex = 0; parentIndex < parentSelectedItems.length; ++parentIndex) {
      const parentItem = parentSelectedItems[parentIndex]; // parent
      const dataItems = this.selectedItems.filter((item) => item.isChildItem && item['ClientID'] == parentItem.ClientID); // get children of the same client id
      if (dataItems.length > 0) {
        const customDescription = [];
        for (let childIndex = 0; childIndex < dataItems.length; ++childIndex) {
          if (dataItems[childIndex].isChildItem) {
            dataItems[childIndex]['EngagementInvoiceDescription'] =
              dataItems[childIndex]['EngagementInvoiceDescription'] ||
              dataItems[childIndex]['EngagementName'];
            customDescription.push(dataItems[childIndex]['EngagementInvoiceDescription']);
          }
        }
        parentItem['customDescription'] = customDescription.join(' | ');
        parentItem['customDescription'] = this.mangoUtils.replaceCaretTemplateWithMonthAndYear(
          this.replaceShortcuts(parentItem.customDescription),
          this.currentMonth,
          this.currentYear,
          this.currentQuarter
        );
        if (!isParentSelection && itemData['ClientID'] != parentItem['ClientID']) continue;
        if (parentItem['customDescription']?.length > 250 && !exceedChecker) {
          exceedChecker = true;
          this.checkIfMemoExceeds(parentItem['customDescription'], this);
        }
      }
    }

    // remove duplicates
    this.selectedItems = Array.from(new Set(this.selectedItems));
    this.levelThreeCalculations();
    this.levelTwoCalculations();
  }

  singleUncheck(event: any, data: any, parentRow: any) {
    event.preventDefault();
    event.stopPropagation();
    event.cancelBubble = true;
    this.checkHeaderCheckbox(parentRow);
    this.saveData.push(data);
  }

  checkHeaderCheckbox(parentRow: any) {
    const parentNewItem = this.parentLevelOne.filter((item) => (!item.isChildItem && item['ClientID'] == parentRow.ClientID));
    if (!parentRow.allSelectedExpenseSlips && !parentRow.allSelectedTimeSlips) {
      parentRow.isSelected = false; // invert
      this.selectedItems.splice(this.selectedItems.indexOf(parentRow), 1).slice(0); // remove parent row from parent selection
      this.selectedItems = [].concat(
        this.selectedItems.splice(this.selectedItems.indexOf(parentRow), 1).slice(0)
      ); // trick to update the view
    } else if (
      parentRow.allSelectedExpenseSlips &&
      parentRow.allSelectedExpenseSlips.length == 0 &&
      parentRow.allSelectedTimeSlips &&
      parentRow.allSelectedTimeSlips.length == 0
    ) {
      parentRow.isSelected = false; // invert
      this.selectedItems.splice(this.selectedItems.indexOf(parentRow), 1).slice(0); // remove parent row from parent selection
      this.selectedItems = this.selectedItems.concat(this.selectedItems.splice(this.selectedItems.indexOf(parentRow), 1).slice(0)); // trick to update the view
      const childItems = this.selectedItems.filter((item) => (item.isSelected && item.isChildItem && item['ClientID'] == parentNewItem[0].ClientID));
      if (childItems.length == 0) {
        parentNewItem[0].isSelected = false;
        this.selectedItems.splice(this.selectedItems.indexOf(parentNewItem[0]), 1).slice(0); // remove parent row from parent selection
        this.selectedItems = this.selectedItems.concat(
          this.selectedItems.splice(this.selectedItems.indexOf(parentNewItem[0]), 1).slice(0)
        ); // trick to update the view
      }
    } else if (
      (parentRow.allSelectedExpenseSlips && parentRow.allSelectedExpenseSlips.length > 0) ||
      (parentRow.allSelectedTimeSlips && parentRow.allSelectedTimeSlips.length > 0)
    ) {
      if (this.selectedItems.indexOf(parentRow) === -1) {
        parentRow.isSelected = true; // invert
        this.selectedItems = this.selectedItems.concat(parentRow);
        parentNewItem[0].isSelected = true;
        this.selectedItems = this.selectedItems.concat(parentNewItem[0]);
      }
    }
    this.selectedItems = Array.from(new Set(this.selectedItems));
    this.levelThreeCalculations();
    this.levelTwoCalculations();
  }

  /*
   Saving Billing Header information for the NEW manual invoice created. Step 1
   Have to get the BillingHeaderID to be used for Detail records and to update other records.
 */
  saveBillingHeaderInfo(selectedLevelOneObj) {
    const parent = this;
    const formObj = this.batchBillingForm.value;

    const headerObj = {}
    const isPreviewMode = selectedLevelOneObj['isPreviewMode'];
    if(!isPreviewMode) this.NextInvoiceNumber += 1;

    headerObj['ClientID'] = selectedLevelOneObj.ClientID;
    headerObj['ClientName'] = selectedLevelOneObj.ClientName;
    headerObj['CompanyID'] = parseInt(parent.loginCompanyId);
    headerObj['QBReference'] = null;
    headerObj['InvoiceNumber'] = isPreviewMode ? null : this.NextInvoiceNumber;
    headerObj['isPreviewMode'] = isPreviewMode;
    headerObj['InvoiceDate'] = formObj.InvoiceDate;
    headerObj['DescriptionShort'] = formObj.DescriptionShort || selectedLevelOneObj['customDescription'];
    headerObj['DescriptionShort'] = this.mangoUtils.replaceCaretTemplateWithMonthAndYear(headerObj['DescriptionShort'], this.currentMonth, this.currentYear, this.currentQuarter) //replace text
    headerObj['DescriptionShort'] = headerObj['DescriptionShort'] && headerObj['DescriptionShort'].length > 250?
      headerObj['DescriptionShort'].substring(0,250) : headerObj['DescriptionShort']
    headerObj['TotalServices'] = selectedLevelOneObj.billableservices;
    headerObj['InvoiceAmount'] = selectedLevelOneObj.parentInvoiceAmount;
    headerObj['InvoiceBalance'] = headerObj['InvoiceAmount'];

    if (parent.isIncluedeBillThrough) {
      headerObj['PeriodTo'] = formObj.InvoiceStartDate ? formObj.InvoiceStartDate : null;
    }

    headerObj['TotalWUWD'] = selectedLevelOneObj.parentWUWDAmount;
    headerObj['TotalExpenses'] = selectedLevelOneObj.billableexpenses;
    headerObj['TotalTax'] = 0; //this needs to be caalculated fix later
    // headerObj['SaleslTaxAmount'] = 0  //numeral(this.invoiceTax).value();

    headerObj['BillNote'] = this.standardFooterMessage;
    //headerObj['BillNoteTop'] = formObj.topInvoiceNote;

    headerObj['TotalPayments'] = 0;

    headerObj['InvoiceType'] = 'Batch Billing';
    headerObj['ProjectMasterIDArray'] = selectedLevelOneObj.ProjectMasterIDArray;
    headerObj['GraceDays'] = this.clientProfile['GraceDays'];

    headerObj['OriginatingPartnerID'] = this.clientProfile['OriginatingPartnerID'];
    headerObj['BillingPartnerID'] = this.clientProfile['BillingPartnerID'];
    headerObj['StaffAssignedID'] = this.clientProfile['StaffAssignedID'];
    headerObj['GroupDescriptionID'] = this.clientProfile['GroupDescriptionID'];
    headerObj['GroupDescriptionIDArray'] = this.clientProfile['GroupDescriptionIDArray'];
    headerObj['ClientTypeID'] = this.clientProfile['ClientTypeID'];
    headerObj['FinalizeAction'] = selectedLevelOneObj['FinalizeAction']
      ? selectedLevelOneObj['FinalizeAction']
      : 'Print';
    headerObj['InvoicePosted'] =
      (headerObj['FinalizeAction'] == 'Finalize Only' &&
        this.mangoCompanyData['SkipInvoiceReview'] &&
        !isPreviewMode) ||
      false;
    headerObj['InvoiceTemplate'] = selectedLevelOneObj['DefaultInvoiceTemplate']
      ? selectedLevelOneObj['DefaultInvoiceTemplate']
      : '2';
    headerObj['BillNote'] = headerObj['BillNote']
      ? this.mangoUtils.replaceCaretTemplate(headerObj['BillNote'], headerObj)
      : '';
    headerObj['CreatedbyStaffID'] = this.encrDecSrvc.getObject(AppConstants.staffID);
    headerObj['PreviousBalance'] = selectedLevelOneObj['PreviousBalance'] || 0;
    headerObj['SalesTaxAmountExpenses'] = 0;
    headerObj['TotalTaxExpenses'] = 0;

    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.createBillingHeader(headerObj).subscribe(
      (rateData: any) => {
        let billingHeaderId = rateData.data['BillingHeaderID'];
        headerObj['BillingHeaderID'] = billingHeaderId;
        if (!isPreviewMode) {
          headerObj['InvoiceNumber'] = rateData.data['InvoiceNumber'];
        }
        /* BillingDetail must be created for each Client Invoice Selected
        The BillingDetail records is associated with the BillingHeader and billingHeaderID
      */
        //
        // loop level 2
        let childItems = parent.selectedItems.filter(
          item => item.isChildItem && item['ClientID'] == selectedLevelOneObj.ClientID
        );
        for (let i = 0; i < childItems.length; ++i) {
          let childItem = childItems[i];
          (function (index, childItem, size) {
            parent.createBillingDetailRecord(billingHeaderId, headerObj, childItem);
            // for last record of Level Two
            if (index == size) {
              parent.successCounter++;
              parent.processedCounter++;
              setTimeout(() => {
                headerObj['TotalStaffCost'] = Number(JSON.stringify(parent._TotalStaffCost));
                headerObj['BillingHeaderID'] = billingHeaderId;
                parent.pushToUpdateArray(
                  { TotalStaffCost: parent._TotalStaffCost },
                  billingHeaderId
                );
                if (isPreviewMode) {
                  parent.createTimeRecordsArr.forEach(item => delete item['SlipMasterID']);
                  setTimeout(() => {
                    forkJoin([
                      parent.mangoAPISrvc.batchUpdateBillingHeader(parent.updateBillingHeaderArr),
                      parent.mangoAPISrvc.batchCreateBillingDetails({
                        list: parent.createBillingDetailsArr
                      }),
                      parent.mangoAPISrvc.batchCreateTimeRecords({
                        list: parent.createTimeRecordsArr
                      })
                    ]).subscribe(res => {
                      parent.createBillingDetailsArr = [];
                      parent.createTimeRecordsArr = [];
                      parent.updateBillingHeaderArr = [];
                      parent.billingHeaderPreview = headerObj;
                      parent.newPreviewInvoices(headerObj);
                      selectedLevelOneObj['isPreviewMode'] = false;
                    });
                  }, 3000);
                }
                parent._TotalStaffCost = 0;
                // parent.mangoAPISrvc.updateBillingHeader(headerObj, headerObj['BillingHeaderID']).subscribe(rateData => {
                //   parent._TotalStaffCost = 0;
                // }, err => {
                //   parent.mangoAPISrvc.showLoader(false);
                //   parent.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg)
                // });
              }, index * 300);
            }
          })(i, childItem, childItems.length - 1);
        }
        // setTimeout(() => {
        //   if(isPreviewMode){
        //     this.billingHeaderPreview = headerObj;
        //     parent.newPreviewInvoices(headerObj);
        //     selectedLevelOneObj['isPreviewMode'] = false;
        //   }
        // }, 1000);
      },
      err => {
        this.clientNames.push(selectedLevelOneObj['ClientName']);
        this.processedCounter++;
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('error', this.translate.instant('error'), err);
      }
    );
  }

  updateExpenseRollUp(event, parentRecord) {
    const selectedLineItem = this.selectedItems.find((item) => item["ProjectMasterID"] == parentRecord.ProjectMasterID);
    parentRecord['IsExpenseRollUp'] = event.checked;
    selectedLineItem['IsExpenseRollUp'] = event.checked;
    this.saveData.push(parentRecord);
  }

  /*
  Process Invoice Service Line items in table
  Creating billing details record
*/
  createBillingDetailRecord(billingheaderId, headerObj, levelTwoObj) {
    const hasDuplicates = () => {
      const compareArrays = (a, b) => a == b || (a.length === b.length && a.every((element, index) => element === b[index]));

      return this.createBillingDetailsArr.some(x => 
        x.BillingHeaderID === detailObj['BillingHeaderID'] &&
        x.ClientID === detailObj['ClientID'] &&
        x.CompanyID === detailObj['CompanyID'] &&
        x.WorkCodeID === detailObj['WorkCodeID'] &&
        x.ServiceCodeID === detailObj['ServiceCodeID'] &&
        x.IsExpenseRollUp === detailObj['IsExpenseRollUp'] &&
        x.Description === detailObj['Description'] &&
        x.EngagementTypeID === detailObj['EngagementTypeID'] &&
        x.OriginatingPartnerID === detailObj['OriginatingPartnerID'] &&
        x.BillingPartnerID === detailObj['BillingPartnerID'] &&
        x.StaffAssignedID === detailObj['StaffAssignedID'] &&
        x.GroupDescriptionID === detailObj['GroupDescriptionID'] &&
        compareArrays(x.GroupDescriptionIDArray, detailObj['GroupDescriptionIDArray']) &&
        x.ClientTypeID === detailObj['ClientTypeID'] &&
        x.InvoiceDate === detailObj['InvoiceDate'] &&
        x.InvoiceNumber === detailObj['InvoiceNumber'] &&
        x.StaffID === detailObj['StaffID'] &&
        x.ProjectID === detailObj['ProjectID'] &&
        x.Amount === detailObj['Amount'] &&
        x.WriteUpDownTime === detailObj['WriteUpDownTime'] &&
        x.InvoiceAmount === detailObj['InvoiceAmount'] &&
        x.WriteUpDownExpenses === detailObj['WriteUpDownExpenses'] &&
        x.ExpenseAmount === detailObj['ExpenseAmount']
      );
    };

    var detailObj = {};
    const formObj = this.batchBillingForm.value;
    detailObj['BillingHeaderID'] = billingheaderId;
    detailObj['ClientID'] = headerObj.ClientID;
    detailObj['CompanyID'] = headerObj['CompanyID'];
    detailObj['WorkCodeID'] = null;
    detailObj['ServiceCodeID'] = null;
    detailObj['IsExpenseRollUp'] = levelTwoObj['IsExpenseRollUp'] || false;
    if (this.selEngagementType.length == 1 && formObj['DescriptionShort']) {
      detailObj['Description'] = formObj['DescriptionShort'];
      if (this.isIncluedeBillThrough) {
        detailObj['Description'] =
          formObj['DescriptionShort'] +
          ' (thru ' +
          moment(formObj.InvoiceStartDate).format('MM-DD-YYYY') +
          ')';
      }
    } else if (this.isIncluedeBillThrough) {
      detailObj['Description'] =
        (levelTwoObj['EngagementInvoiceDescription']
          ? levelTwoObj['EngagementInvoiceDescription']
          : levelTwoObj['EngagementName']) +
        ' (thru ' +
        moment(formObj.InvoiceStartDate).format('MM-DD-YYYY') +
        ')';
    } else {
      detailObj['Description'] = levelTwoObj['EngagementInvoiceDescription']
        ? levelTwoObj['EngagementInvoiceDescription']
        : levelTwoObj['EngagementName'];
    }
    detailObj['Description'] = detailObj['Description']
      ? this.mangoUtils.replaceCaretTemplateWithMonthAndYear(
          detailObj['Description'],
          this.currentMonth,
          this.currentYear,
          this.currentQuarter
        ) //replace text
      : null;

    detailObj['EngagementTypeID'] = levelTwoObj['EngagementTypeID'];

    detailObj['OriginatingPartnerID'] = headerObj['OriginatingPartnerID'];
    detailObj['BillingPartnerID'] = headerObj['BillingPartnerID'];
    detailObj['StaffAssignedID'] = headerObj['StaffAssignedID'];
    detailObj['GroupDescriptionID'] = headerObj['GroupDescriptionID'];
    detailObj['GroupDescriptionIDArray'] =
      headerObj['GroupDescriptionIDArray'] && headerObj['GroupDescriptionIDArray'].length > 0
        ? headerObj['GroupDescriptionIDArray']
        : null;
    detailObj['ClientTypeID'] = headerObj['ClientTypeID'];

    //   // Engagementtype.invoicedescription   Audit = invoice
    detailObj['InvoiceDate'] = headerObj.InvoiceDate;
    detailObj['InvoiceNumber'] = headerObj.InvoiceNumber;
    //detailObj['SalesTaxAmount'] = obj.invoiceTax;
    detailObj['StaffID'] = null;

    detailObj['ProjectID'] = levelTwoObj['ProjectMasterID'];
    detailObj['Discount'] = numeral(levelTwoObj['parentDiscount'] || 0).value()
    detailObj['Amount'] = levelTwoObj['billableservices'];
    detailObj['WriteUpDownTime'] = levelTwoObj['parentWUWDAmount']
    detailObj['InvoiceAmount'] = headerObj['InvoiceAmount'];

    detailObj['WriteUpDownExpenses'] = levelTwoObj.allSelectedExpenseSlips.reduce((a, b) => { return a + +numeral(b.WriteUpDown).value(); }, 0);
    detailObj['ExpenseAmount'] = levelTwoObj.allSelectedExpenseSlips.reduce((a, b) => { return a + +numeral(b.BilledAmount).value(); }, 0);
    if (levelTwoObj.allSelectedTimeSlips.length > 0) {
      detailObj['ExpenseAmount'] = levelTwoObj.allSelectedTimeSlips.reduce((a, b) => { 
        return a + numeral(b.WriteUpDown).value(); 
      }, 0);
      detailObj['IsExpenseRollUp'] = true;
    }
    if (!hasDuplicates()) this.createBillingDetailsArr.push(detailObj);
    const parent = this;
    if (levelTwoObj['billableservices'] == 0 && levelTwoObj['parentInvoiceAmount'] == 0) {
      if (levelTwoObj.allSelectedExpenseSlips.length > 0) {
        const temp = parent.updateBillingHeaderArr.find(item=> item['BillingHeaderID'] == billingheaderId);
        parent.invoiceTax = 0;
        parent.salesTax.serviceTax = 0;
        parent.salesTax.expenseTax = 0;
        parent.salesTax.taxableAmtExpense =
          numeral(temp?.data?.SalesTaxAmountExpenses).value() || 0;
        parent.salesTax.taxableAmtService = numeral(temp?.data?.SalesTaxAmount).value() || 0;
        parent.processExpenses(levelTwoObj, headerObj, billingheaderId);
      }
    } else {
      // Create 1 BillingDetail record based off of ShortDescription and InvoiceAmount
      this.mangoAPISrvc.showLoader(true);
      if (levelTwoObj.allSelectedTimeSlips.length > 0) {
        parent.processTimeSlipes(
          levelTwoObj,
          headerObj,
          billingheaderId,
          headerObj['TotalServices']
        );
      } else if (
        (levelTwoObj?.isCalcTaxNoTime == null || levelTwoObj?.isCalcTaxNoTime) &&
        (levelTwoObj['isTaxable'] == null || levelTwoObj['isTaxable'])
      ) {
        parent.invoiceTax = 0;
        parent.salesTax.serviceTax = 0;
        parent.salesTax.taxableAmtService = 0;
        parent.salesTax.expenseTax = 0;
        parent.salesTax.taxableAmtExpense = 0;
        const temp = parent.updateBillingHeaderArr.find(item=> item['BillingHeaderID'] == billingheaderId);
        const obj = {}
        parent.salesTax.taxableAmtService = obj['taxableAmtService'] = (numeral(detailObj['Amount']).value() || 0) + (numeral(temp?.data?.SalesTaxAmount).value() || 0);
        parent.salesTax.serviceTax = obj['serviceTax'] = obj['taxableAmtService'] * (parent.salesTax.Labor / 100);
        obj["TotalTax"] = obj['serviceTax']
          ? numeral(obj['serviceTax']).value()
          : 0;
        obj["SalesTaxAmount"] = obj['taxableAmtService']
          ? numeral(obj['taxableAmtService']).value()
          : 0;
        obj['InvoiceBalance'] =
          headerObj['InvoiceAmount'] +
          obj['TotalTax'] +
          (numeral(temp?.data?.TotalTaxExpenses).value() || 0);
        parent.pushToUpdateArray(obj, billingheaderId);
      }
      if (levelTwoObj.allSelectedExpenseSlips.length > 0) {
        parent.processExpenses(levelTwoObj, headerObj, billingheaderId);
      }
      // this.mangoAPISrvc.createBillingDetailsRecord(detailObj).subscribe(item => {
      //   detailObj['InvoiceAmount'] = headerObj['InvoiceAmount'];
      //   if (levelTwoObj.allSelectedTimeSlips.length > 0) {
      //     parent.processTimeSlipes(levelTwoObj, headerObj, billingheaderId, headerObj['TotalServices']);
      //   }
      //   if (levelTwoObj.allSelectedExpenseSlips.length > 0) {
      //     parent.processExpenses(levelTwoObj, headerObj, billingheaderId);
      //   }

      // }, err => {
      //   parent.mangoAPISrvc.showLoader(false);
      //   parent.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.createErrorMsg)
      // });
    }
  }

  batchCreateBillingDetails(bulkData, interval) {
    let observableBatch = bulkData.splice(0, 100);
    // if (observableBatch.length < 100) {
    //   clearInterval(interval);
    // }

    this.mangoAPISrvc.batchCreateBillingDetails({ list: observableBatch }).subscribe(
      data => {
        observableBatch?.forEach(lineItem => {
          this.mangoAPISrvc
            .sendBudgetAlert({
              ClientID: lineItem.ClientID,
              ProjectMasterID: lineItem['ProjectID'],
              CompanyID: this.loginCompanyId,
              Ddate: moment(new Date(lineItem.InvoiceDate)).format('YYYY-MM-DD')
            })
            .subscribe(
              data => {},
              err => {
                console.log(err);
              }
            );
        });

        if (bulkData.length > 0) {
          return false;
        }

        this.createBillingDetailsArr = [];
      },
      err => {
        this.createBillingDetailsArr = [];
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  batchUpdateBillingHeader() {
    this.mangoAPISrvc.batchUpdateBillingHeader(this.updateBillingHeaderArr).subscribe(res => {
      this.updateBillingHeaderArr = [];
    });
  }

  processOneByOne(selectedLevelOneObj) {
    const parent = this;
    parent.clientProfile = {};
    parent.mangoAPISrvc.showLoader(true);

    const clientdata = (this.clientProfile = this.clientList.filter(
      c => c.ClientID === selectedLevelOneObj['ClientID']
    )[0]);
    // this.mangoAPISrvc.getClientFullinformation(clientId).subscribe(clientdata => {
    if (!clientdata) {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Client not found!');
      this.processedCounter++;
      return;
    }
    parent.clientProfile = clientdata;
    parent.setLaborRates();
    parent.mangoAPISrvc
      .getPreviousBalance(
        selectedLevelOneObj.ClientID,
        moment(parent.batchBillingForm.value.InvoiceDate).format('YYYY-MM-DD HH:mm:ss'),
        0
      )
      .subscribe(
        (result: any) => {
          selectedLevelOneObj['PreviousBalance'] = result['PreviousBalance'] || 0;
          parent.saveBillingHeaderInfo(selectedLevelOneObj);
        },
        err => {
          selectedLevelOneObj['PreviousBalance'] = 0;
          parent.saveBillingHeaderInfo(selectedLevelOneObj);
        }
      );
  }
  /*
     Handle Processing Time Records
  */
  processTimeSlipes(levelTwoObj, headerObj, billingheaderId, TotalServices) {
    const parent = this;
    const temp = parent.updateBillingHeaderArr.find(item=> item['BillingHeaderID'] == billingheaderId);
    parent.invoiceTax = 0;
    parent.salesTax.serviceTax = 0;
    parent.salesTax.taxableAmtService = numeral(temp?.data?.SalesTaxAmount).value() || 0;
    parent.salesTax.expenseTax = 0;
    parent.salesTax.taxableAmtExpense = (numeral(temp?.data?.SalesTaxAmountExpenses).value() || 0);
    const timeSlipsDS = [];
    const timeRecordsObj = levelTwoObj.allSelectedTimeSlips;
    const totalBillableAmount = levelTwoObj.parentBillableAmount;
    const totalNonBillableAmount = levelTwoObj.nonbillableamount;
    const writeupDownAmount = levelTwoObj.parentWUWDAmount;
    const AmountToAllocate = (levelTwoObj.discount * -1) + writeupDownAmount;
    let Total_AmtToAllocate = AmountToAllocate;

    if (timeRecordsObj.length > 0) {
      // level Three
      for (let timeRecordIndex = 0; timeRecordIndex < timeRecordsObj.length; timeRecordIndex++) {
        const eachTimeSlip = timeRecordsObj[timeRecordIndex];
        const StandardAmt = numeral(eachTimeSlip['StandardAmount']).value();

        if (eachTimeSlip['Billable']) {
          // eachTimeSlip['WriteUpDown'] = AmountToAllocate * (StandardAmt / totalBillableAmount);
          // eachTimeSlip['BilledAmount'] = writeupDownAmount * (StandardAmt / totalBillableAmount) + StandardAmt;
          if (timeRecordsObj == eachTimeSlip.length - 1) {
            // eachTimeSlip['WriteUpDown'] = Total_AmtToAllocate;
          } else {
            Total_AmtToAllocate -= eachTimeSlip['WriteUpDown'];
          }
        } else {
          // eachTimeSlip['WriteUpDown'] = StandardAmt * -1;
          // eachTimeSlip['BilledAmount'] = 0;
        }
        eachTimeSlip['BillingHeaderID'] = billingheaderId;
        eachTimeSlip['Billed'] = true;
        eachTimeSlip['BilledAmountLessDiscount'] = eachTimeSlip['BilledAmountLessDiscount']
          ? eachTimeSlip['BilledAmountLessDiscount']
          : eachTimeSlip['BilledAmount'];
        eachTimeSlip['InvoiceDate'] = headerObj.InvoiceDate;
        eachTimeSlip['InvoiceNumber'] = headerObj['InvoiceNumber'];
        eachTimeSlip['SelectedAmount'] = eachTimeSlip['BilledAmount'];
        eachTimeSlip['BillingRate'] = eachTimeSlip['BillingRate']
          ? eachTimeSlip['BillingRate'].toString()
          : '0';
        eachTimeSlip['StandardAmount'] = eachTimeSlip['StandardAmount']
          ? eachTimeSlip['StandardAmount'].toString()
          : '0';
        // eachTimeSlip['SelectedAmount'] = (StandardAmt - eachTimeSlip['WriteUpDown']);
        parent._TotalStaffCost += eachTimeSlip['StaffCost']
          ? numeral(eachTimeSlip['StaffCost']).value()
          : 0.0;
        timeSlipsDS.push(eachTimeSlip);

        // calculate sales tax
        if (
          parent.mangoCompanyData.ActivateLaborRates == true &&
          (levelTwoObj['isTaxable'] == null || levelTwoObj['isTaxable']) &&
          parent.clientProfile.SalesTaxLevel != 'None'
        ) {
          eachTimeSlip.BilledAmount = eachTimeSlip.BilledAmount ? eachTimeSlip.BilledAmount : 0;
          if (eachTimeSlip.sctaxable == true) {
            parent.salesTax.taxableAmtService += numeral(eachTimeSlip.BilledAmount).value();
            parent.salesTax.serviceTax =
              parent.salesTax.taxableAmtService * (parent.salesTax.Labor / 100);
          }
        }
      }
      parent.invoiceTax = parent.salesTax.serviceTax + parent.salesTax.expenseTax;
    }
    // update Time Records from Billable Time table - one shot in DB
    if (timeSlipsDS.length > 0) {
      const obj = {};
      if(parent.mangoCompanyData.ActivateLaborRates && (levelTwoObj['isTaxable'] == null || levelTwoObj['isTaxable']) && numeral(parent.salesTax.serviceTax).value() !== 0) {
        const temp = parent.updateBillingHeaderArr.find(item=> item['BillingHeaderID'] == billingheaderId);
        obj["TotalTax"] = parent.salesTax.serviceTax
          ? numeral(parent.salesTax.serviceTax).value()
          : 0;
        obj['SalesTaxAmount'] = parent.salesTax.taxableAmtService
          ? numeral(parent.salesTax.taxableAmtService).value()
          : 0;
        obj['InvoiceBalance'] =
          headerObj['InvoiceAmount'] +
          obj['TotalTax'] +
          (numeral(temp?.data?.TotalTaxExpenses).value() || 0);
        parent.pushToUpdateArray(obj, billingheaderId);
      }
      if (headerObj['isPreviewMode']) {
        this.createTimeRecordsArr = [
          ...this.createTimeRecordsArr,
          ...JSON.parse(JSON.stringify(timeSlipsDS))
        ];
        // forkJoin = parent.createTimeSlipsRecords(timeSlipsDS);
      } else {
        let forkJoin = null;
        forkJoin = parent.updateTimeSlipsRecords(timeSlipsDS).subscribe(data => {
          // parent.mangoAPISrvc.updateSalesTaxInBillingHeader(obj, billingheaderId).subscribe(data => {
          // }, (error) => {
          //   parent.mangoAPISrvc.showLoader(false);
          // });
        });
      }
    }
  }

  batchCreateTimeRecords(bulkData, interval) {
    const observableBatch = bulkData.splice(0, 100);

    if (observableBatch.length < 100) {
      clearInterval(interval);
    }

    this.mangoAPISrvc.batchCreateTimeRecords({ list: observableBatch }).subscribe(
      data => {
        if (bulkData.length > 0) {
          return false;
        }

        this.createTimeRecordsArr = [];
      },
      err => {
        this.createTimeRecordsArr = [];
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

 async calculateWPWD(data) {
    if (await this.getRecordChanges(data.ProjectMasterID) > 0) {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        html: this.translate.instant('billing.invoice_amount_changed'),
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant("I_know_continue"),
        cancelButtonText: this.translate.instant("no_cancel"),
      }).then(async (result) => {
        if (result.value) {
          if (data['BillingMethod'] != 'Fixed Fee') {
            data['parentInvoiceAmount'] = data['parentInvoiceAmount']
              ? numeral(data['parentInvoiceAmount']).value()
              : 0.0;
            data['parentBillableAmount'] = data['parentBillableAmount']
              ? numeral(data['parentBillableAmount']).value()
              : 0.0;
            data['parentWUWDAmount'] = data['parentInvoiceAmount'] - data['parentBillableAmount'];
          }
          if (data['BillingMethod'] == 'Fixed Fee' || data.billableservices == 0) {
            data['isSkipFlatAmount'] = true;
          }
          data['InvoiceAmountChanged'] = true;
          await this.levelTwoCalculations(data['uuid']);

          await this.resetIsBilledFlag(data.ProjectMasterID)
          data["PreviousInvoiceAmt"] = 0;
        } else {
          data.parentInvoiceAmount = data['PreviousInvoiceAmt'];
          data['PreviousInvoiceAmt'] = 0;
        }
      });
    } else {
      if (data['BillingMethod'] != 'Fixed Fee') {
        data['parentInvoiceAmount'] = data['parentInvoiceAmount']
          ? numeral(data['parentInvoiceAmount']).value()
          : 0.0;
        data['parentBillableAmount'] = data['parentBillableAmount']
          ? numeral(data['parentBillableAmount']).value()
          : 0.0;
        data['parentWUWDAmount'] = data['parentInvoiceAmount'] - data['parentBillableAmount'];
      }
      if (data['BillingMethod'] == 'Fixed Fee' || data.billableservices == 0) {
        data['isSkipFlatAmount'] = true;
      }
      data['InvoiceAmountChanged'] = true;
      await this.levelTwoCalculations(data['uuid']);
    }
    this.saveData.push(data);
  }

  async levelTwoCalculations(uuidToProcess = null) {
    const parent = this;
    // handinging selected items
    // get all selected parent Items
    this.selectedItems = [];
    this.selectedItems = this.parentLevelOne.filter((item) => item.isSelected);
    const selectedParentItem = this.selectedItems.filter((item) => (!item.isChildItem));
    // selected level 1 loop
    for (let parentIndex = 0; parentIndex < selectedParentItem.length; ++parentIndex) {
      const parentItem = selectedParentItem[parentIndex];
      const selectedLlevelTwoItems = this.selectedItems.filter((item) => (item.isChildItem && item['ClientID'] == parentItem.ClientID));
      // selected level two loop
      for (var childIndex = 0; childIndex < selectedLlevelTwoItems.length; ++childIndex) {
        const itemData = selectedLlevelTwoItems[childIndex];
        if(uuidToProcess && itemData['uuid'] !== uuidToProcess) {
          continue
        }
        itemData['billableexpenses'] = itemData['billableexpenses'] ? numeral(itemData['billableexpenses']).value() : 0.00;
        itemData['billableservices'] = itemData['billableservices'] ? numeral(itemData['billableservices']).value() : 0.00;
        itemData['parentDiscount'] = itemData['parentDiscount'] ? numeral(itemData['parentDiscount']).value() : 0.00;
        itemData['parentWUWDAmount'] = itemData['parentWUWDAmount'] ? numeral(itemData['parentWUWDAmount']).value() : 0.00;
        itemData['FlatFeeAmount'] = itemData['FlatFeeAmount'] ? numeral(itemData['FlatFeeAmount']).value() : 0.00;
        //itemData.isSkipFlatAmount = (itemData['billableservices'] == 0) ? true : false;
        if (!itemData['InvoiceAmountChanged']) {
          itemData['parentInvoiceAmount'] = itemData.allSelectedTimeSlips.reduce((a, b) => {
            return (
              a +
              +numeral(
                itemData['isDiscountChanged']
                  ? b.BilledAmountLessDiscount || b.BilledAmount
                  : b.BilledAmount
              ).value()
            );
          }, 0);
          itemData['parentInvoiceAmount'] += itemData.allSelectedExpenseSlips.reduce((a, b) => {
            return a + +numeral(b.StandardAmount).value();
          }, 0);
        }

        if ((itemData['isDiscountChanged'] && !itemData['InvoiceAmountChanged']))
          itemData['parentInvoiceAmount'] -= itemData['parentDiscount'];

        itemData['parentBillableAmount'] = ((itemData['billableexpenses'] + itemData['billableservices']));
        itemData['parentWUWDAmount'] = itemData['parentInvoiceAmount'] - itemData['parentBillableAmount'];

        if (!itemData.isDiscountChanged && itemData['BillingMethod'] == 'Fixed Fee') {
          if (!itemData.isSkipFlatAmount) {
            itemData['parentInvoiceAmount'] = itemData['parentInvoiceAmount']
              ? itemData['parentInvoiceAmount']
              : itemData['FlatFeeAmount'];
          } else if (itemData['billableservices'] == 0 && itemData['billableexpenses'] > 0) {
            itemData['parentBillableAmount'] = itemData['billableexpenses'];
            itemData['parentWUWDAmount'] = 0;
            itemData['parentInvoiceAmount'] =
              itemData['parentBillableAmount'] + itemData['parentWUWDAmount'];
          }
          itemData['parentWUWDAmount'] = itemData['parentWUWDAmount']
            ? itemData['parentWUWDAmount']
            : itemData['parentInvoiceAmount'] - itemData['parentBillableAmount'];
        } else {
          itemData['parentInvoiceAmount'] = itemData['parentInvoiceAmount']
            ? itemData['parentInvoiceAmount']
            : itemData['parentBillableAmount'] + itemData['parentWUWDAmount'];
        }

        if (itemData['InvoiceAmountChanged'] || itemData['isDiscountChanged']) {
          itemData['amount'] = itemData['parentInvoiceAmount'];
          await parent.distributeInvoiceAmount(
            {
              ...itemData,
              amount:
                itemData["InvoiceAmountChanged"]
                  ? itemData["amount"]
                  : parent.mangoUtils.addFloat(itemData["amount"], itemData["parentDiscount"]),
            },
            true
          );
          itemData['InvoiceAmountChanged'] = false;
          itemData['isDiscountChanged'] = false;
          await parent.calculateParentAndChildFooters(true);
          await parent.calculateClientInvoiceAmt();
        }

        if (itemData['parentInvoiceAmount'] < 0 && itemData['parentDiscount'] != 0) {
          itemData['parentDiscount'] = 0;
          await parent.levelTwoCalculations();
          parent.mangoAPISrvc.notify('error', this.translate.instant('error'), this.translate.instant("billing,A_negative_Invoice_Amount_is_not_allowed"));
        }
      }
      // unselected level 1
      const unselectedLevelTwoItems = this.parentLevelOne.filter((item) => (!item.isSelected && item.isChildItem && item['ClientID'] == parentItem.ClientID));
      for (let unselectedChildIndex = 0; unselectedChildIndex < unselectedLevelTwoItems.length; ++unselectedChildIndex) {
        const itemData = unselectedLevelTwoItems[unselectedChildIndex];
        itemData['parentBillableAmount'] = 0.00;
        itemData['parentInvoiceAmount'] = 0.00;
        itemData['parentDiscount'] = 0.00;
        itemData['parentWUWDAmount'] = 0.00;
        itemData['isSkipFlatAmount'] = (itemData['billableservices'] == 0) ? true : false;
        itemData['isDiscountChanged'] = false;
      }
      // selected level one
      parentItem['billableexpenses'] = selectedLlevelTwoItems.reduce(function (a, b) {
        return a + +b.billableexpenses;
      }, 0);
      parentItem['billableservices'] = selectedLlevelTwoItems.reduce(function (a, b) {
        return a + +b.billableservices;
      }, 0);
      parentItem['parentBillableAmount'] = selectedLlevelTwoItems.reduce(function (a, b) {
        return a + +b.parentBillableAmount;
      }, 0);
      parentItem['parentInvoiceAmount'] = selectedLlevelTwoItems.reduce(function (a, b) {
        return a + +b.parentInvoiceAmount;
      }, 0);
      parentItem['parentDiscount'] = selectedLlevelTwoItems.reduce(function (a, b) {
        return a + +b.parentDiscount;
      }, 0);
      parentItem['parentWUWDAmount'] = selectedLlevelTwoItems.reduce(function (a, b) {
        return a + +b.parentWUWDAmount;
      }, 0);
    }
    // handling unselectes setItems
    const unselectedItems = this.parentLevelOne.filter((item) => (!item.isChildItem && !item.isSelected));
    for (let levelOneIndex = 0; levelOneIndex < unselectedItems.length; ++levelOneIndex) {
      const parentItem = unselectedItems[levelOneIndex];
      const unselectedlevelTwoItems = this.parentLevelOne.filter((item) => (item.isChildItem && item['ClientID'] == parentItem.ClientID));
      // level two loop
      for (var childIndex = 0; childIndex < unselectedlevelTwoItems.length; ++childIndex) {
        const itemData = unselectedlevelTwoItems[childIndex];
        itemData['parentBillableAmount'] = 0.00;
        itemData['parentInvoiceAmount'] = 0.00;
        itemData['parentDiscount'] = 0.00;
        itemData['parentWUWDAmount'] = 0.00;
        itemData['isSkipFlatAmount'] = (itemData['billableservices'] == 0) ? true : false;
        itemData['isDiscountChanged'] = false;
      }
      // level one
      parentItem['billableexpenses'] = unselectedlevelTwoItems.reduce(function (a, b) {
        return a + +b.billableexpenses;
      }, 0);
      parentItem['billableservices'] = unselectedlevelTwoItems.reduce(function (a, b) {
        return a + +b.billableservices;
      }, 0);
      parentItem['parentBillableAmount'] = unselectedlevelTwoItems.reduce(function (a, b) {
        return a + +b.parentBillableAmount;
      }, 0);
      parentItem['parentInvoiceAmount'] = unselectedlevelTwoItems.reduce(function (a, b) {
        return a + +b.parentInvoiceAmount;
      }, 0);
      parentItem['parentDiscount'] = unselectedlevelTwoItems.reduce(function (a, b) {
        return a + +b.parentDiscount;
      }, 0);
      parentItem['parentWUWDAmount'] = unselectedlevelTwoItems.reduce(function (a, b) {
        return a + +b.parentWUWDAmount;
      }, 0);
    }
    this.calculateParentAndChildFooters();
    // this.footerTotalsForParent();
  }

  levelThreeCalculations() {
    const selectedLevelItem = this.selectedItems.filter((item) => (item.isChildItem));
    for (let i = 0; i < selectedLevelItem.length; ++i) {
      const levelTwoObj = selectedLevelItem[i];
      levelTwoObj['billableexpenses'] = levelTwoObj.allSelectedExpenseSlips.reduce(function (a, b) { return a + +numeral(b['StandardAmount']).value(); }, 0);
      levelTwoObj['billableservices'] = levelTwoObj.allSelectedTimeSlips.reduce(function (a, b) { return a + +numeral(b['billableamount']).value(); }, 0);
    }
  }

  changefinalizeActions(data) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getEmailNames(data['ClientID']).subscribe((emailsList: any) => {
      this.mangoAPISrvc.showLoader(false);
      if (emailsList.length == 0) {
        Swal.fire({
          icon: 'error',
          title: this.translate.instant('Information'),
          text: this.translate.instant('client_email_required_message'),
          showConfirmButton: true
        });
        data['FinalizeAction'] = 'Print';
      }
    });
  }

  
  handleDropStaffSelectClick(event, data, formtype) {

    if (formtype == "Staff") {
      data["StaffName"] = event.label;
      data["StaffID"] = event.value;
    } else if (formtype == "Activity") {
      data["Description"] = event.originalEvent.target.textContent;
      data["ServiceCodeID"] = event.value;
    } else if (formtype === "FinalizeAction") {
      // data["FinalizeAction"] = event.value;
      if (event.value == 'Email') {
        this.changefinalizeActions(data);
      } else {
        data['FinalizeAction'] = event.value;
      }
    } else if (formtype === 'InvoiceTemplate') {
      data['DefaultInvoiceTemplate'] = event.value;
    }

    data["IsColumnChanges"] = true;

    this.saveData.push(data);
  }

  filterData(query, staffListItems: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    const filtered: any[] = [];
    for (let i = 0; i < staffListItems.length; i++) {
      const staffItem = staffListItems[i];
      if (staffItem.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(staffItem);
      }
    }
    return filtered;
  }

  filterStaffItems(event, data) {
    this.filteredStaffSingle = this.filterData(event.query, this.AllStaffsTypes);
  }

  changeUSMoney(evt: any, data: any) {
    let enteredValue;
    if (evt['target']) {
      enteredValue = evt.target.value;
    } else {
      enteredValue = evt['value'] ? evt['value'] : evt['value'];
    }
    if (enteredValue == '' && enteredValue != 0) {
      enteredValue = data.amount;
    }
    const myNumeral = numeral(enteredValue);
    if (myNumeral.value() === null) {
      if (data) {
        evt['target']['value'] = data.amount = '$0.00';
      } else {
        evt.setValue('0.00');
      }
    } else {
      if (data) {
        evt['target']['value'] = data.amount = '$' + numeral(enteredValue).format('0,0.00');
      } else {
        if (myNumeral.value() < 0) {
          enteredValue = 0;
        }
        evt.setValue(numeral(enteredValue).format('0,0.00'));
      }
    }
  }

  removeOffset(date: Date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = date.getTime() - offset * 60 * 1000;
    return new Date(time);
  }

  saveTimeEntry(evt, data) {
    const klassObj = this;
    data.StandardAmount = data.StandardAmount
      ? numeral(data.StandardAmount).value()
      : 0.0;
    const obj = {
      Ddate: (
        moment( new Date( data.Ddate ) )
        .format( "YYYY-MM-DD" )
      ),

      TimeStart: this.removeOffset(new Date(data.TimeStart)),
      TimeStop: data.TimeStop ? this.removeOffset(new Date(data.TimeStop)) : data.TimeStop,
      Approved: data.Approved,
      Billable: data.Billable,
      Billed: data.Billed ? data.Billed : false,
      BillingRate: numeral(data.BillingRate).value()?.toString(),
      ClientID: data.ClientID,
      ClientName: data.ClientName,
      ElaspedTime: data.TotalTime,
      Description: data.Description,
      Memo: data.Memo,
      nonbillableamount: data.nonbillableamount,
      ServiceCode: data.ServiceCode,
      ServiceCodeID: data.ServiceCodeID,
      StandardAmount: data.StandardAmount.toString(),
      TotalTime: data.TotalTime,
      StaffID: data.StaffID,
      StaffName: data.StaffName,
      StaffDeptID: data.StaffDeptID,
      StaffPayRate: data.StaffPayRate,
      StaffCost: Number(data.TotalTime) * Number(data.StaffPayRate),
      WorkCodeID: data.WorkCodeID,
      OriginatingPartnerID: data.OriginatingPartnerID,
      BillingPartnerID: data.BillingPartnerID,
      GroupDescriptionID: data.GroupDescriptionID,
      GroupDescriptionIDArray: data.GroupDescriptionIDArray,
      ClientTypeID: data.ClientTypeID,
      ProjectMasterID: data.ProjectMasterID,
      PrivateMemo: data.PrivateMemo,
      EngagementTypeID: data.EngagementTypeID,
      IsTimeRecord: 'T',
      WorkLocation: data.WorkLocation,
      isFlatFee: data.isFlatFee
    };
    if (!obj.TimeStop) {
      delete obj.TimeStop;
    }
    this.encrDecSrvc.addObject(AppConstants.savedWorkLocation, data.WorkLocation);
    klassObj.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateTimeSheet(data.SlipMasterID, obj).subscribe(
      function (resp) {
        klassObj.mangoAPISrvc
          .sendBudgetAlert({
            ClientID: obj['ClientID'],
            ProjectMasterID: obj['ProjectMasterID'],
            CompanyID: klassObj.loginCompanyId,
            Ddate: moment(obj.Ddate).format('YYYY-MM-DD')
          })
          .subscribe(
            data => {},
            err => {
              console.log(err);
            }
          );
        klassObj.mangoAPISrvc.notify(
          'success',
          klassObj.translate.instant('updated'),
          AppConstants.updateMsg
        );
        data['IsColumnChanges'] = false;
        // this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
        klassObj.mangoAPISrvc.showLoader(false);
      },
      err => {
        data['IsColumnChanges'] = false;
        klassObj.mangoAPISrvc.showLoader(false);
        klassObj.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
      }
    );
    // data['IsColumnChanges'] = false
  }

  saveTimeExpense(evt, data) {
    const _this = this;

    data.Ddate = (
      moment( new Date( data.Ddate ) )
      .format( "YYYY-MM-DD" )
    );

    data["ExpenseCodeID"] = data.ExpenseCodeID
      ? data.ExpenseCodeID
      : null;
    data["ExpenseCode"] = data.ExpenseCode
      ? data.ExpenseCode
      : null;
    data["Cost"] = data["Cost"] ? data["Cost"] : 0;
    data["Units"] = data["Units"] ? data["Units"] : 0;
    data["Markup"] = data["Markup"] ? data["Markup"] : 0;
    data["Tax"] = data["Tax"] ? data["Tax"] : 0;
    data["Reimbursed"] = data["Reimbursed"]
      ? data["Reimbursed"]
      : false;
    data["Reimburseable"] = data["Reimburseable"]
      ? data["Reimburseable"]
      : false;
    data["IsTimeRecord"] = "X";
    data["Description"] = data["Description"]
      ? data["Description"]
      : null;
    data["BillingRate"] = data["BillingRate"]
      ? data["BillingRate"]
      : null;
    data["TotalTime"] = data["TotalTime"]
      ? data["TotalTime"]
      : null;
    data["StaffPayRate"] = data["StaffPayRate"]
      ? data["StaffPayRate"]
      : 0;
    data["ElaspedTime"] = data["ElaspedTime"]
      ? data["ElaspedTime"]
      : null;
    data["WriteupDown"] = data["WriteupDown"]
      ? data["WriteupDown"]
      : 0;

    data['SelectedAmount'] = data['SelectedAmount'] ? data['SelectedAmount'] : 0;
    data['Billed'] = data['Billed'] ? data['Billed'] : false;

    data.StaffID = data.StaffID;
    _this.mangoAPISrvc.showLoader(true);

    _this.mangoAPISrvc.updateTimeSheet(data.SlipMasterID, data).subscribe(function (resp) {
      _this.mangoAPISrvc
        .sendBudgetAlert({
          ClientID: data['ClientID'],
          ProjectMasterID: data['ProjectMasterID'],
          CompanyID: _this.loginCompanyId,
          Ddate: moment(data.Ddate).format('YYYY-MM-DD')
        })
        .subscribe(
          data => {},
          err => {
            console.log(err);
          }
        );
      data['IsColumnChanges'] = false;
      _this.mangoAPISrvc.showLoader(false);
      _this.mangoAPISrvc.notify(
        'success',
        this.translate.instant('Success_notify'),
        AppConstants.updateMsg
      );
    }),
      error => {
        data['IsColumnChanges'] = false;
        _this.mangoAPISrvc.showLoader(false);
        _this.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
      };
  }

  openTimeEntryDailog(data?: any, isTimeRecord?: any, parentItems?: any) {
    data['isEditFlow'] = true;
    data['isDisable'] = true;
    data['ClientName'] = parentItems.ClientName;

    if (isTimeRecord) {
      data['ServiceCode'] = data['Description'];
      this.sharedSrvc.openTimeEntryDialog(data);
    } else {
      this.sharedSrvc.openExpenseDialog(data);
    }
  }

  onEditInit(event, table) {
    this.cellFocused = { ...event, table };
  }

  onEditComplete() {
    this.cellFocused = null;
  }

  onEditCancel() {
    this.cellFocused = null;
  }

  onTotalTimeChange(evt, data) {
    let newValue = evt;
    if (evt == null) {
      newValue = 0;
      return false;
    } else if (evt == '.') {
      return false;
    }

    data['TotalTime'] = newValue ? numeral(newValue).value() : data['TotalTime'];
    data['IsColumnChanges'] = true;
    data = this.calculateStandardAmount(data);
    this.calculateBilledAmtWuwd(data);
    this.calculateClientInvoiceAmt();
    this.calculateParentAndChildFooters();
    this.saveData.push(data);
  }

  onBillRateChange(evt, data) {
    data['BillingRate'] = evt.target.value
      ? evt.target.value.replace('$', '').replace(',', '')
      : data['BillingRate'];
    data['IsColumnChanges'] = true;
    data = this.calculateStandardAmount(data);
    this.calculateBilledAmtWuwd(data);
    this.calculateClientInvoiceAmt();
    this.calculateParentAndChildFooters();
    this.saveData.push(data);
  }

  async calculateParentAndChildFooters(isChildOnly?: boolean) {
    this.parentLevelOne = this.parentLevelOne.map((obj) => {
      return {
        ...obj,
        // billableservices: obj["allTimeSlips"].reduce((a, b) => a + +numeral(b['billableamount']).value(), 0),

        grandExpenseHrs: obj['allSelectedTimeSlips'].reduce(
          (a, b) => a + +numeral(b['TotalTime']).value(),
          0
        ),
        grandBillableamount: obj['allSelectedTimeSlips'].reduce(
          (a, b) => a + +numeral(b['billableamount']).value(),
          0
        ),
        grandNonbillableamount: obj['allSelectedTimeSlips'].reduce(
          (a, b) => a + +numeral(b['nonbillableamount']).value(),
          0
        ),
        grandBilledamount: obj['allSelectedTimeSlips'].reduce(
          (a, b) => a + +numeral(b['BilledAmount']).value(),
          0
        ),
        grandWUWDamount: obj['allSelectedTimeSlips'].reduce(
          (a, b) => a + +numeral(b['WriteUpDown']).value(),
          0
        ),
        grandExpenseTotalAmt: obj['allSelectedExpenseSlips'].reduce(
          (a, b) => a + +numeral(b['StandardAmount']).value(),
          0
        ),
        grandExpenseWUWDamount: obj['allSelectedExpenseSlips'].reduce(
          (a, b) => a + +numeral(b['WriteUpDown']).value(),
          0
        ),
        grandExpenseBilledamount: obj['allSelectedExpenseSlips'].reduce(
          (a, b) => a + +numeral(b['BilledAmount']).value(),
          0
        )
        // parentInvoiceAmount: obj["allTimeSlips"].reduce((a, b) => a + +numeral(b['BilledAmount']).value(), 0),
        // parentWUWDAmount: obj["allTimeSlips"].reduce((a, b) => a + +numeral(b['WriteUpDown']).value(), 0),
      };
    });
    this.syncSelectedItems();
    if (this.recordsTable) {
      this.recordsTable = this.parentLevelOne.filter(
        record =>
          record.ClientID === this.recordsTable.ClientID &&
          record.ProjectMasterID === this.recordsTable.ProjectMasterID
      )[0];

      if (!this.recordsTable) this.showRecordsSideBar = false;
    }

    if (isChildOnly) return;

    this.parentTotalBillableExpenses = this.parentLevelOne.reduce(function (a, b) {
      return !b.isChildItem ? a + +b.billableexpenses : a + 0;
    }, 0);
    this.parentTotalBillableServices = this.parentLevelOne.reduce(function (a, b) {
      return !b.isChildItem ? a + +b.billableservices : a + 0;
    }, 0);
    this.parentTotalDiscount = this.parentLevelOne.reduce(function (a, b) {
      return !b.isChildItem ? a + +b.parentDiscount : a + 0;
    }, 0);
    this.parentBillableAmount = this.parentLevelOne.reduce(function (a, b) {
      return !b.isChildItem ? a + +b.parentBillableAmount : a + 0;
    }, 0);
    this.parentWUWDAmount = this.parentLevelOne.reduce(function (a, b) {
      return !b.isChildItem ? a + +b.parentWUWDAmount : a + 0;
    }, 0);
    this.parentInvoiceAmount = this.parentLevelOne.reduce(function (a, b) {
      return !b.isChildItem ? a + +b.parentInvoiceAmount : a + 0;
    }, 0);
  }

  roundOffDecimals(money) {
    return numeral(this.currencyPipe.transform(money, 'USD')).value();
  }

  async distributeInvoiceAmount(data, isFixed?: boolean, isInitial?: boolean, isDisregardExpense?: boolean) {
    let unpaidAmount =
      typeof data.amount === 'number'
        ? data.amount
        : numeral(data.amount.replace('$', '').replace(',', '')).value();

    const parent = this;

    let totalBillable = 0;
    let timeSlipsArray = [];
    let expenseSlipsArray = [];

    if (isInitial) {
      timeSlipsArray = data.allTimeSlips;
    } else {
      timeSlipsArray = data.allSelectedTimeSlips;
    }

    if (isInitial) {
      expenseSlipsArray = data.allExpenseSlips;
    } else {
      expenseSlipsArray = data.allSelectedExpenseSlips;
    }

    expenseSlipsArray.forEach(slip => {
      const slipStandardAmt = numeral(slip.StandardAmount).value();
      slip.BilledAmount = slip.StandardAmount;
      slip.WriteUpDown = parent.mangoUtils.subtractFloat(slip.BilledAmount, slipStandardAmt);
      slip.InitialBilledAmt = slip.BilledAmount;
    });

    totalBillable = timeSlipsArray.reduce((a, b) => {
      return b.Billable && ((numeral(b.BilledAmount || 0).value() == 0 && isInitial) || !isInitial)
        ? a + +numeral(b['StandardAmount']).value()
        : a + 0;
    }, 0);

    if (!isInitial && !isDisregardExpense) {
      unpaidAmount -= data.allSelectedExpenseSlips.reduce((a, b) => {
        return a + +numeral(b['StandardAmount']).value();
      }, 0);
    } else {
      unpaidAmount = this.mangoUtils.subtractFloat(
        unpaidAmount,
        timeSlipsArray.reduce((a, b) => {
          return b.Billable && numeral(b.BilledAmount).value() > 0 && isInitial
            ? a + +numeral(b['BilledAmount']).value()
            : a + 0;
        }, 0)
      );
    }

    const isDistribute: boolean = totalBillable < unpaidAmount;
    let totalBilledAmt = 0;
    let lastBillableSlipID = null;
    timeSlipsArray.forEach(slip => {
      const slipStandardAmt = numeral(slip.StandardAmount).value();
      if (numeral(slip.BilledAmount).value() > 0 && isInitial) {
        slip.WriteUpDown = parent.mangoUtils.subtractFloat(slip.BilledAmount, slipStandardAmt);
        slip.IsBilled = true;
        return;
      }
      if (slip.Billable) {
        if (data.BillingMethod === 'Fixed Fee' || isFixed || isDistribute) {
          const invoiceAMount =
            isFixed || (data.BillingMethod === 'Hourly' && isDistribute)
              ? unpaidAmount
              : numeral(data.FlatFeeAmount).value();
          slip.BilledAmount = parent.roundOffDecimals(
            (slipStandardAmt / totalBillable) * invoiceAMount
          );
          slip.WriteUpDown = parent.roundOffDecimals(
            parent.mangoUtils.subtractFloat(slip.BilledAmount, slipStandardAmt)
          );

          totalBilledAmt = parent.roundOffDecimals(
            parent.mangoUtils.addFloat(slip.BilledAmount, totalBilledAmt)
          );
          lastBillableSlipID = slip.SlipMasterID;
        } else {
          if (unpaidAmount >= slipStandardAmt) {
            slip.BilledAmount = slipStandardAmt;
            slip.WriteUpDown = 0.0;
          } else {
            if (unpaidAmount <= 0) {
              slip.BilledAmount = 0;
            } else slip.BilledAmount = unpaidAmount;

            slip.WriteUpDown = parent.mangoUtils.subtractFloat(slip.BilledAmount, slipStandardAmt);
          }
          unpaidAmount = parent.mangoUtils.subtractFloat(unpaidAmount, slip.BilledAmount);
        }
      } else {
        slip.WriteUpDown = slipStandardAmt * -1;
        slip.BilledAmount = 0;
      }
      slip['InitialBilledAmt'] = slip.BilledAmount;
    });

    if (data.BillingMethod === 'Fixed Fee' || isFixed || isDistribute) {
      if (totalBilledAmt > unpaidAmount) {
        const diff = parent.mangoUtils.subtractFloat(totalBilledAmt, unpaidAmount);
        timeSlipsArray.forEach(slip => {
          if (slip.SlipMasterID === lastBillableSlipID) {
            slip.BilledAmount = parent.roundOffDecimals(
              parent.mangoUtils.subtractFloat(slip.BilledAmount, diff)
            );
            slip.WriteUpDown = parent.roundOffDecimals(
              parent.mangoUtils.subtractFloat(slip.BilledAmount, slip.StandardAmount)
            );
            return;
          }
        });
      } else if (totalBilledAmt < unpaidAmount) {
        const diff = parent.mangoUtils.subtractFloat(unpaidAmount, totalBilledAmt);
        timeSlipsArray.forEach(slip => {
          if (slip.SlipMasterID === lastBillableSlipID) {
            slip.BilledAmount = parent.roundOffDecimals(
              parent.mangoUtils.addFloat(slip.BilledAmount, diff)
            );
            slip.WriteUpDown = parent.roundOffDecimals(
              parent.mangoUtils.subtractFloat(slip.BilledAmount, slip.StandardAmount)
            );
            return;
          }
        });
      }
    }

    return;
  }

  distributeAmountLessDiscount(
    data,
    isFixed?: boolean,
    isInitial?: boolean,
    isDisregardExpense?: boolean
  ) {
    let unpaidAmount =
      typeof data.amount === 'number'
        ? data.amount
        : numeral(data.amount.replace('$', '').replace(',', '')).value();

    const parent = this;

    let totalBillable = 0;
    let timeSlipsArray = [];

    if (isInitial) {
      timeSlipsArray = data.allTimeSlips;
    } else {
      timeSlipsArray = data.allSelectedTimeSlips;
    }

    totalBillable = timeSlipsArray.reduce((a, b) => {
      return b.Billable && ((numeral(b.BilledAmount || 0).value() == 0 && isInitial) || !isInitial)
        ? a + +numeral(b['StandardAmount']).value()
        : a + 0;
    }, 0);

    if (!isInitial && !isDisregardExpense) {
      unpaidAmount -= data.allSelectedExpenseSlips.reduce((a, b) => {
        return a + +numeral(b['StandardAmount']).value();
      }, 0);
    } else {
      unpaidAmount = this.mangoUtils.subtractFloat(
        unpaidAmount,
        timeSlipsArray.reduce((a, b) => {
          return b.Billable && numeral(b.BilledAmount).value() > 0 && isInitial
            ? a + +numeral(b['BilledAmount']).value()
            : a + 0;
        }, 0)
      );
    }

    const isDistribute: boolean = totalBillable < unpaidAmount;
    let totalBilledAmt = 0;
    let lastBillableSlipID = null;
    timeSlipsArray.forEach(slip => {
      const slipStandardAmt = numeral(slip.StandardAmount).value();
      if (numeral(slip.BilledAmountLessDiscount).value() > 0 && isInitial) {
        return;
      }
      if (slip.Billable) {
        if (data.BillingMethod === 'Fixed Fee' || isFixed || isDistribute) {
          const invoiceAMount =
            isFixed || (data.BillingMethod === 'Hourly' && isDistribute)
              ? unpaidAmount
              : numeral(data.FlatFeeAmount).value();
          slip.BilledAmountLessDiscount = parent.roundOffDecimals(
            (slipStandardAmt / totalBillable) * invoiceAMount
          );

          totalBilledAmt = parent.roundOffDecimals(
            parent.mangoUtils.addFloat(slip.BilledAmountLessDiscount, totalBilledAmt)
          );
          lastBillableSlipID = slip.SlipMasterID;
        } else {
          if (unpaidAmount >= slipStandardAmt) {
            slip.BilledAmountLessDiscount = slipStandardAmt;
          } else {
            if (unpaidAmount <= 0) {
              slip.BilledAmountLessDiscount = 0;
            } else slip.BilledAmountLessDiscount = unpaidAmount;
          }
          unpaidAmount = parent.mangoUtils.subtractFloat(
            unpaidAmount,
            slip.BilledAmountLessDiscount
          );
        }
      }
      slip['InitialBilledAmtLessDiscount'] = slip.BilledAmountLessDiscount;
    });

    if (data.BillingMethod === 'Fixed Fee' || isFixed || isDistribute) {
      if (totalBilledAmt > unpaidAmount) {
        const diff = parent.mangoUtils.subtractFloat(totalBilledAmt, unpaidAmount);
        timeSlipsArray.forEach(slip => {
          if (slip.SlipMasterID === lastBillableSlipID) {
            slip.BilledAmountLessDiscount = parent.roundOffDecimals(
              parent.mangoUtils.subtractFloat(slip.BilledAmountLessDiscount, diff)
            );
            return;
          }
        });
      } else if (totalBilledAmt < unpaidAmount) {
        const diff = parent.mangoUtils.subtractFloat(unpaidAmount, totalBilledAmt);
        timeSlipsArray.forEach(slip => {
          if (slip.SlipMasterID === lastBillableSlipID) {
            slip.BilledAmountLessDiscount = parent.roundOffDecimals(
              parent.mangoUtils.addFloat(slip.BilledAmountLessDiscount, diff)
            );
            return;
          }
        });
      }
    }

    return;
  }

  setWriteUpDownColor(data) {
    let colorStr = '#1f0c0c';
    if (data < 0) {
      colorStr = '#f28686';
    } else if (data > 0) {
      colorStr = '#33c126';
    }
    return colorStr;
  }

  onBilledAmountChange(evt, data, isExpense?) {
    const newValue = evt.target.value ? evt.target.value.replace('$', '').replace(',', '') : null;
    if (newValue === null) return;

    data['BilledAmount'] = newValue;
    data['BilledAmountLessDiscount'] = newValue;

    if (isExpense) {
      data['Billable'] = true;
      this.calculateExpenseBilledAmtWuwd(data, true);
    } else this.calculateBilledAmtWuwd(data, true);
    if (!isExpense) {
      this.calcEngagementTimeSalesTax(
        this.searchValueTime?.nativeElement?.value == ''
          ? this.dtchild?._value
          : this.dtchild?.filteredValue
      );
    } else {
      this.calcEngagementExpSalesTax(this.dtchildex?._value);
    }

    this.calculateClientInvoiceAmt();
    this.calculateParentAndChildFooters();
  }

  calculateExpenseBilledAmtWuwd(data, isBilledAmtChanged?) {
    data['WriteUpDown'] = numeral(data['BilledAmount']).value() - numeral(data['StandardAmount']).value()
    const invoiceAmt = this.getExpenseInvoiceAmount(data.ProjectMasterID);
    this.parentLevelOne.forEach((record) => {
      if (data.ProjectMasterID === record.ProjectMasterID) {
        if (isBilledAmtChanged) {
          const initBilledAmt = numeral(data['InitialBilledAmt']).value()
          const currBilledAmt = numeral(data['BilledAmount']).value()
          if (initBilledAmt > currBilledAmt) {
            record.invoiceAmount = invoiceAmt - (initBilledAmt - currBilledAmt);
          } else if (initBilledAmt <= currBilledAmt) {
            record.invoiceAmount = invoiceAmt + (currBilledAmt - initBilledAmt);
          }
          this.calculateParentInvoiceAmt(record);
        }
      }
    });
  }

  getProjects() {
    const parent = this;
    parent.activitiesTypes = [];
    parent.mangoAPISrvc
      .getProjects()
      .subscribe(function (data: any) {
        const filterData = data.filter((record) => record.Inactive == false);
        for (let i = 0; i < filterData.length; i++) {
          parent.activitiesTypes.push({
            label: filterData[i].EngagementName,
            value: filterData[i].ProjectMasterID,
            ProjectMasterID: filterData[i].ProjectMasterID,
            Description: filterData[i].EngagementInvoiceDescription,
            BillingMethod: filterData[i].BillingMethod,
            FlatFeeAmount: filterData[i].FlatFeeAmount,
            EngagementTypeID: filterData[i].EngagementTypeID,
            isTaxable: filterData[i].isTaxable,
            isCalcTaxNoTime: filterData[i].isCalcTaxNoTime
          });
        }
        parent.activitiesTypes.sort(parent.mangoUtils.compareValues('label', 'asc'));
      },
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
      }
    );
  }

  calculateBilledAmtWuwd(data, isBilledAmtChanged?) {
    if (!data['Billable']) {
      data['BilledAmount'] = 0.0;
    } else {
      data['WriteUpDown'] =
        numeral(data['BilledAmount']).value() - numeral(data['StandardAmount']).value();
      if (isBilledAmtChanged) {
        const invoiceAmt = this.getProjectInvoiceAmount(data.ProjectMasterID);
        this.parentLevelOne.forEach((record) => {
          if (data.ProjectMasterID === record.ProjectMasterID) {
            const initBilledAmt = numeral(data['InitialBilledAmt']).value()
            const currBilledAmt = numeral(data['BilledAmount']).value()
            if (initBilledAmt > currBilledAmt) {
              record.invoiceAmount = invoiceAmt - (initBilledAmt - currBilledAmt);
            } else if (initBilledAmt <= currBilledAmt) {
              record.invoiceAmount = invoiceAmt + (currBilledAmt - initBilledAmt);
            }
            this.calculateParentInvoiceAmt(record);
          }
        });
      }
    }
  }


  async syncSelectedItems(): Promise<void>  {
    this.selectedItems = [];
    this.selectedItems = this.parentLevelOne.filter(item => item.isSelected);
  }

  async resetIsBilledFlag(id): Promise <void> {
    await this.syncSelectedItems();
    const selectedParentItem = this.selectedItems.filter((item) => (!item.isChildItem)).map((item) => item.ClientID);
    const selectedChildren = this.parentLevelOne.filter((item) => (item.isChildItem && selectedParentItem.includes(item.ClientID)))
    selectedChildren
      .filter(item => item.ProjectMasterID === id)[0]
      ?.allSelectedTimeSlips.map(item => (item.BilledAmountChanged = false));
  }

  async getRecordChanges(id: number): Promise <number> {
    await this.syncSelectedItems();
    const selectedParentItem = this.selectedItems.filter((item) => (!item.isChildItem)).map((item) => item.ClientID);
    const selectedChildren = this.parentLevelOne.filter((item) => (item.isChildItem && selectedParentItem.includes(item.ClientID)))
    const slipLength = selectedChildren
    .filter((item) => item.ProjectMasterID === id)[0]
    ?.allSelectedTimeSlips?.filter((item) => item.IsColumnChanges || item.BilledAmountChanged).length;
    const expLength = selectedChildren
    .filter((item) => item.ProjectMasterID === id)[0]
    ?.allSelectedExpenseSlips?.filter((item) => item.IsColumnChanges || item.BilledAmountChanged).length;

    return slipLength + expLength;
  }

  calculateParentInvoiceAmt(data: any): void {
    this.parentLevelOne.forEach((record: any) => {
      if (data.ProjectMasterID === record.ProjectMasterID) {
        /* data.PreviousAmount = invoiceAmt
        data.parentInvoiceAmount = invoiceAmt;
        data.parentWUWDAmount = invoiceAmt - data.parentBillableAmount;
        this.recordsTable = record; */
        let newParentInvoiceAmt = 0;
        for (let i = 0; i < record.allSelectedTimeSlips.length; i++) {
          newParentInvoiceAmt += parseFloat(record.allSelectedTimeSlips[i].BilledAmount);
        }
        for (let i = 0; i < record.allSelectedExpenseSlips.length; i++) {
          newParentInvoiceAmt += parseFloat(record.allSelectedExpenseSlips[i].BilledAmount);
        }

        data.parentInvoiceAmount = newParentInvoiceAmt;
        data.parentWUWDAmount = newParentInvoiceAmt - data.parentBillableAmount;
        this.recordsTable = record;
      }
    });
  }

  async calculateClientInvoiceAmt() {
    const selectedParentItem = this.selectedItems.filter((item) => (!item.isChildItem)).map((item) => item.ClientID);
    const origParentItem = this.parentLevelOne.filter((item) => (!item.isChildItem && selectedParentItem.includes(item.ClientID)))
    origParentItem.forEach((parent) => {
      const childItems = this.parentLevelOne.filter((item) => (item.isChildItem && item['ClientID'] == parent.ClientID));
      parent.parentInvoiceAmount = childItems.reduce((a, b) => { return a + +b.parentInvoiceAmount; }, 0);
      parent.parentWUWDAmount = childItems.reduce((a, b) => { return a + +b.parentWUWDAmount; }, 0);
      parent.parentDiscount = childItems.reduce((a, b) => { return a + +b.parentDiscount; }, 0);
      parent.parentBillableAmount = childItems.reduce((a, b) => { return a + +b.parentBillableAmount; }, 0);
    })
  }

  getProjectInvoiceAmount(id: number): number {
    let invoiceAmt = 0.0;
    this.parentLevelOne.forEach(record => {
      if (id === record.ProjectMasterID) {
        record.allSelectedTimeSlips.forEach(slip => {
          invoiceAmt += numeral(slip.BilledAmount).value();
        });
      }
    });
    return invoiceAmt;
  }

  getExpenseInvoiceAmount(id: number): number {
    let invoiceAmt = 0.0;
    this.parentLevelOne.forEach(record => {
      if (id === record.ProjectMasterID) {
        record.allSelectedExpenseSlips.forEach(slip => {
          invoiceAmt += numeral(slip.BilledAmount).value();
        });
      }
    });
    return invoiceAmt;
  }

  async onDiscountChange(data: any): Promise <void> {
    if (await this.getRecordChanges(data.ProjectMasterID) > 0) {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        html: this.translate.instant('billing.invoice_amount_changed'),
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('I_know_continue'),
        cancelButtonText: this.translate.instant('no_cancel')
      }).then(result => {
        if (result.value) {
          this.levelTwoCalculations(data['uuid']);

          this.resetIsBilledFlag(data.ProjectMasterID);
          data['PreviousDiscount'] = 0;
        } else {
          data.parentDiscount = data['PreviousDiscount'];
          data['PreviousDiscount'] = 0;
        }
      });
    } else {
      this.levelTwoCalculations(data['uuid']);
    }
    this.saveData.push(data);
  }

  onFilterFetch(): void {
    this.fetchDataSource();
    this.onCloseFilter();
  }

  onCloseFilter(): void {
    this.showFilterSideBar = false;
  }

  onShowRecords(data): void {
    this.recordsTable = {};
    this.recordsTable = data;
    this.showRecordsSideBar = true;
    this.calcEngagementTimeSalesTax(data?.allTimeSlips);
    this.calcEngagementExpSalesTax(data?.allExpenseSlips);
  }

  onClickBilledAmount() {
    if (this.recordsTable.parentDiscount > 0)
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('Warning'),
        text: this.translate.instant('invoice.discount_in_placed'),
        showConfirmButton: true
      });
  }

  onCloseRecords(): void {
    let dirtyData = 0;
    dirtyData += this.recordsTable?.allTimeSlips?.filter((col) => col?.IsColumnChanges === true)?.length;
    dirtyData += this.recordsTable?.allExpenseSlips?.filter((col) => col?.IsColumnChanges === true)?.length;
    if(this.recordsTable['isDescriptionChanged'] && this.recordsTable['isAutoUpdate']){
      const obj = {
        ProjectMasterID: this.recordsTable['ProjectMasterID'],
        ClientID: this.recordsTable['ClientID'],
        EngagementTypeID: this.recordsTable['EngagementTypeID'],
        EngagementName: this.recordsTable['EngagementName'],
        BillingMethod: this.recordsTable['BillingMethod'],
        EngagementInvoiceDescription: this.recordsTable['EngagementInvoiceDescription']
      };
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.updateProject(obj).subscribe(res => {
        this.recordsTable['isDescriptionChanged'] = false;
        //update EngagementInvoiceDescription after save
        this.selectedItems.map(x => {
          if (x.ProjectMasterID == obj.ProjectMasterID) {
            x.EngagementInvoiceDescription = obj.EngagementInvoiceDescription;
          }
          return x;
        });

        for (let parentIndex = 0; parentIndex < this.selectedItems.length; ++parentIndex) {
          let parentItem = this.selectedItems[parentIndex]; // parent
          let dataItems = this.selectedItems.filter(
            item => item.isChildItem && item['ClientID'] == parentItem.ClientID
          ); // get children of the same client id
          if (dataItems.length > 0 && !parentItem.isChildItem) {
            let customDescription = [];
            for (let childIndex = 0; childIndex < dataItems.length; ++childIndex) {
              if (dataItems[childIndex].isChildItem) {
                dataItems[childIndex]['EngagementInvoiceDescription'] =
                  dataItems[childIndex]['EngagementInvoiceDescription'] ||
                  dataItems[childIndex]['EngagementName'];
                customDescription.push(dataItems[childIndex]['EngagementInvoiceDescription']);
              }
            }
            parentItem['customDescription'] = customDescription.join(' | ');
            parentItem['customDescription'] = this.mangoUtils.replaceCaretTemplateWithMonthAndYear(
              this.replaceShortcuts(parentItem.customDescription),
              this.currentMonth,
              this.currentYear,
              this.currentQuarter
            );
          }
        }

        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify(
          'success',
          this.translate.instant('Success'),
          AppConstants.updateMsg
        );
      });
    }
    if (dirtyData > 0) {
      Swal.fire({
        title: this.translate.instant('warning'),
        html: this.translate.instant('invoicing.unsaved_changes'),
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('Yes'),
        cancelButtonText: this.translate.instant('no_cancel')
      }).then(result => {
        if (result.value) this.showRecordsSideBar = true;
        else this.showRecordsSideBar = false;
      });
    }
  }

  calcEngagementTimeSalesTax(values): void {
    this.engagementLevelTimeTax = 0;
    this.clientProfile = {};

    const clientdata = (this.clientProfile = this.clientList.filter(
      c => c.ClientID === values[0]['ClientID']
    )[0]);
    if (!clientdata) {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Client not found!');
      this.processedCounter++;
      return;
    }
    this.clientProfile = clientdata;
    this.setLaborRates();

    if (
      !this.mangoCompanyData.ActivateLaborRates ||
      !this.salesTax.Labor ||
      numeral(this.salesTax.Labor).value() == 0
    )
      return;

    if (values?.length > 0) {
      let taxableAmtService = 0;
      for (let n = 0; n < values.length; n++) {
        const record = values[n];
        const selectedProject = this.activitiesTypes?.find(
          item => item.ProjectMasterID == record.ProjectMasterID
        );

        if (selectedProject?.isTaxable == null || selectedProject?.isTaxable) {
          record.BilledAmount = record.BilledAmount ? record.BilledAmount : 0;
          if (record.sctaxable == true) {
            taxableAmtService += numeral(record.BilledAmount).value();
          }
        }
      }

      this.engagementLevelTimeTax = this.roundOffDecimals(
        taxableAmtService * (this.salesTax.Labor / 100)
      );
      return;
    } else return;
  }

  calcEngagementExpSalesTax(values): void {
    this.engagementLevelExpTax = 0;
    if (
      !this.mangoCompanyData.ActivateExpenseRates ||
      !this.salesTax.Expense ||
      numeral(this.salesTax.Expense).value() == 0
    )
      return;

    if (values?.length > 0) {
      let taxableAmtExpense = 0;
      for (let n = 0; n < values.length; n++) {
        const record = values[n];

        record.BilledAmount = record.BilledAmount ? record.BilledAmount : 0;
        if (record.ectaxable == true && record.IsTaxable == true) {
          taxableAmtExpense += numeral(record.BilledAmount).value();
        }
      }

      this.engagementLevelExpTax = this.roundOffDecimals(
        taxableAmtExpense * (this.salesTax.Expense / 100)
      );
      return;
    } else return;
  }

  setLaborRates() {
    const parent = this;
    if ((parent.mangoCompanyData.ActivateLaborRates == true || parent.mangoCompanyData.ActivateExpenseRates) && parent.clientProfile.SalesTaxLevel != 'None') {
      if (parent.clientProfile.SalesTaxLevel == 'ClientRate') {
        parent.salesTax.Labor = parent.clientProfile.Tax1ID
          ? numeral(parent.clientProfile.Tax1ID).value()
          : 0;
        parent.salesTax.Expense = parent.clientProfile.Tax2ID
          ? numeral(parent.clientProfile.Tax2ID).value()
          : 0;
      } else if (parent.clientProfile.SalesTaxLevel == 'CompanyLocationRate') {
        const selectedCompanyLocation = this.companyLocations.filter((location) => location['value'] == parent.clientProfile.CompanyMangoLocationID)[0];
        parent.salesTax.Labor = selectedCompanyLocation['laborRate'] ? numeral(selectedCompanyLocation['laborRate']).value() : 0;
        parent.salesTax.Expense = selectedCompanyLocation['expenseRate'] ? numeral(selectedCompanyLocation['expenseRate']).value() : 0;
      } else {
        parent.salesTax.Labor = parent.mangoCompanyData.LaborRate1
          ? numeral(parent.mangoCompanyData.LaborRate1).value()
          : 0;
        parent.salesTax.Expense = parent.mangoCompanyData.ExpenseRate1
          ? numeral(parent.mangoCompanyData.ExpenseRate1).value()
          : 0;
      }
    }
  }

  getCompanyLocations() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyLocations(this.companyId).subscribe(
      (data: any) => {
        this.companyLocations = data.map(location => {
          return {
            value: location.CompanyMangoLocationID,
            label: location.CompanyLocation,
            laborRate: location.TaxRateLaborL,
            expenseRate: location.TaxRateExpenseL
          };
        });
        this.companyLocations.unshift({ label: 'All', value: null });
        this.mangoAPISrvc.showLoader(false);
      },
      err => this.mangoAPISrvc.showLoader(false)
    );
  }

  onResetFilters() {
    this.batchBillingForm.controls['billingPartnerId'].setValue('');
    this.batchBillingForm.controls['InvoiceStartDate'].setValue(new Date());
    this.batchBillingForm.controls['staffId'].setValue('');
    this.batchBillingForm.controls['selectedGroup'].setValue('');
    this.batchBillingForm.controls['selectedGroup'].setValue(null);
    this.batchBillingForm.controls['billingPartnerId'].setValue('');
    this.batchBillingForm.controls['selectedBillingMethod'].setValue(null);
    this.selEngagementType = [];
  }


  OnDeleteProcess(itemData){
    this.recordsTable.allTimeSlips = this.recordsTable.allTimeSlips.filter((val, idx) => val.SlipMasterID !== itemData["SlipMasterID"]);
    this.recordsTable.allSelectedTimeSlips = this.recordsTable.allSelectedTimeSlips.filter((val) => val.SlipMasterID !== itemData["SlipMasterID"]);
    this.recordsTable.allExpenseSlips = this.recordsTable.allExpenseSlips.filter((val, idx) => val.SlipMasterID !== itemData["SlipMasterID"]);
    this.recordsTable.allSelectedExpenseSlips = this.recordsTable.allSelectedExpenseSlips.filter((val) => val.SlipMasterID !== itemData["SlipMasterID"]);
    const selectedParentItem = this.selectedItems.filter((item) => (!item.isChildItem)).map((item) => item.ClientID);
    const origParentItem = this.parentLevelOne.filter((item) => (!item.isChildItem && selectedParentItem.includes(itemData["ClientID"])))
    origParentItem.forEach((parent) => {
      if(parent.ClientID === itemData.ClientID){
        parent.allTimeSlips = this.recordsTable.allTimeSlips;
        parent.allSelectedTimeSlips = this.recordsTable.allSelectedTimeSlips;
        parent.allExpenseSlips = this.recordsTable.allExpenseSlips;
        parent.allSelectedExpenseSlips = this.recordsTable.allSelectedExpenseSlips;
        this.calculateClientInvoiceAmt();
        this.calculateParentAndChildFooters();
        this.levelThreeCalculations();
        this.levelTwoCalculations();
      }
    });
  }

  deleteClientAlert(itemData, index, slipMaster) {
    const dayToday = new Date(new Date().setHours(0,0,0,0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    const monthToLock = new Date(new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (
      this.companySetting.isEnableSystemLocking &&
      dayToday >= lockDay &&
      new Date(itemData.Ddate) <= lastDayOfMonth
    ) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        `Unable to delete record since the month is locked.`
      );
      return;
    }

    itemData['Billed'] = itemData['Billed'] ? itemData['Billed'] : false;
    itemData['MarkSlipsBilled'] = itemData['MarkSlipsBilled'] ? itemData['MarkSlipsBilled'] : false;
    if (itemData['Billed'] && !itemData['MarkSlipsBilled']) {
      this.mangoAPISrvc.notify(
        'error',
        'Error!',
        'This time entry has been billed. Deleting is not allowed.'
      );
      return false;
    } else {
      const parentObj = this;
      Swal.fire({
        title: parentObj.translate.instant('confirmation'),
        text: parentObj.translate.instant('delete_alert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: parentObj.translate.instant('yes_delete'),
        cancelButtonText: parentObj.translate.instant('no_delete')
      }).then(result => {
        if (result.value) {
          parentObj.mangoAPISrvc.showLoader(true);
          parentObj.mangoAPISrvc.deleteTimeSheet(itemData.SlipMasterID).subscribe(function (data) {
            parentObj.mangoAPISrvc.showLoader(false);
            //parentObj.onDeleteSuccess(itemData.SlipMasterID);
            parentObj.mangoAPISrvc.notify(
              'success',
              parentObj.translate.instant('Success'),
              AppConstants.deleteMessage
            );
            //parentObj.fetchDataSource();
            parentObj.OnDeleteProcess(itemData);
          }),
            error => {
              parentObj.mangoAPISrvc.notify(
                'error',
                parentObj.translate.instant('error'),
                AppConstants.deleteErrorMsg
              );
              parentObj.mangoAPISrvc.showLoader(false);
            };
        }
      });
    }
  }

  onDateSelect(event: any, formControlName) {
    if (formControlName == 'InvoiceDate') {
      this.encrDecSrvc.addObject(
        AppConstants.batchLastInvoiceDate,
        this.batchBillingForm.controls[formControlName].value
      );
    } else {
      this.encrDecSrvc.addObject(
        AppConstants.recurringLastDateThru,
        this.batchBillingForm.controls[formControlName].value
      );
    }
  }

  verifySystemLocking(e) {
    if (e == null) return;

    const dayToday = new Date(new Date().setHours(0,0,0,0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    const monthToLock = new Date(new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (
      this.companySetting.isEnableSystemLocking &&
      dayToday >= lockDay &&
      new Date(e) <= lastDayOfMonth
    ) {
      this.mangoAPISrvc.notify(
        'error',
        'Error!',
        'System Locking is enabled on the selected date.'
      );
    }
  }

  validateInvoiceDateTimeout: any;
  validateInvoiceDate() {
    if (typeof this.validateInvoiceDateTimeout != 'undefined') {
      clearTimeout(this.validateInvoiceDateTimeout);
      this.validateInvoiceDateTimeout = undefined;
    }

    this.validateInvoiceDateTimeout = setTimeout(() => {
      const invoiceDate: Date = this.batchBillingForm.controls['InvoiceDate']
        .value as unknown as Date;

      const dateToday = new Date();

      if (this.mangoUtils.dateDiff(invoiceDate, dateToday, 'months') > 2) {
        Swal.fire({
          icon: 'warning',

          title: `${this.translate.instant('Warning')}?`,
          text: `${this.translate.instant('two-month-gap-date-entry-warning')}!`,

          confirmButtonText: 'OK',

          showCancelButton: false,
          allowEscapeKey: true,
          allowEnterKey: true
        });
      }

      clearTimeout(this.validateInvoiceDateTimeout);
      this.validateInvoiceDateTimeout = undefined;
    }, 500);
  }

  onUpdateBilledAmt(data) {
    this.distributeInvoiceAmount(
      { ...this.recordsTable, amount: data.newBilledAmt },
      true,
      false,
      true
    );
    this.calculateParentAndChildFooters(true);
    this.calculateParentInvoiceAmt(this.recordsTable);
    this.calculateClientInvoiceAmt();
    this.calcEngagementTimeSalesTax(
      this.searchValue?.nativeElement?.value == ''
        ? this.dtchild?._value
        : this.dtchild?.filteredValue
    );
  }

  async save() {
    this.mangoAPISrvc.showLoader(true);
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
    const response = await this.mangoAPISrvc.updateSlipMasterbyCompanyIDLevel1(this.saveData);
    if (response["message"] == 'OK') {
      this.mangoAPISrvc.notify('success', 'Success!', 'Customer settings saved successfully.');
    } else {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Error Saving.');
    }
    this.mangoAPISrvc.showLoader(false);
  }
}
