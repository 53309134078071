import { Table } from 'primeng/table';
import { Component, OnInit, Inject, ViewChild, Input, ElementRef,ChangeDetectorRef, AfterViewInit  } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConstants } from '@app/_helpers/api-constants';
import { AuthGuard, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditorComponent, CountService } from '@syncfusion/ej2-angular-richtexteditor';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { SharedComponentsService } from '../shared-components-service';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import moment from "moment";
declare let numeral: any;
import $ from "jquery";
import Swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';

export enum EInvoiceTypes {
  RETAINER_INVOICE = 'Retainer Invoice',
  MANUAL_INVOICE = 'Manual Invoice',
  BATCH_INVOICE = 'Batch Billing',
  RETAINER_PAYMENT = 'Retainer Payment',
  RETAINER = 'Retainer',
  RECURRING = 'Recurring',
}

@Component({
  selector: 'app-invoice',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, CountService],
  templateUrl: './invoice.component.html'
})
export class InvoiceComponent implements OnInit {
  isSortAscending = true;
  displayDate = false;
  @ViewChild('MemoNote') input: ElementRef;
  public tse: any;
  public isEditDialogOpen: boolean = false;
  isSuperAdmin = false
  public invoiceForm: UntypedFormGroup;
  selectedInvoiceTemplate: any;
  public activitiesTypes: any;
  public termsList: any = [];
  public lastRowIndex: number = -1;
  public totalFromSlips: any = {
    TotalServices: 0,
    TotalStaffCost: 0,
    TotalWUWD: 0,
    TotalRate: 0,
    TotalStandardAmt: 0,
  };
  public shouldSaveTimeRecords: boolean = false;
  public totalPayments: number = 0;
  public surchargeAmt: any = 0;
  _selectedColumns: any[];
  public cols: any[];
  public prevInvoiceAmount: any;
  prevDiscount
  public InvoiceAmountBeforeToEdit: any = null;
  public companyLocations: SelectItem[] = [];
  public recordsTable: any = {
    unFilteredTimeSlips: [],
    allTimeSlips: [],
    allExpenseSlips: [],
    timeSlipsWithoutBillingDetails: []
  };
  editForm: boolean = false;
  public clonedData: any = [];
  searchTextStr: any = "";
  filteredItemsSize = -1;
  @ViewChild("dtAllTimeSlips") dtAllTimeSlips: Table;
  @ViewChild("searchValue") searchValue;
  public mangoCompanyData: any;
  public clientProfile: any;
  public salesTax: any = { 'Labor': 0, 'Expense': 0, 'taxableAmtService': 0, 'taxableAmtExpense': 0, 'serviceTax': 0, 'expenseTax': 0 };
  invoiceTemplates: SelectItem[];
  clientID;
  clientName;
  loginCompanyId;
  settingData: any;
  editData: any = [];
  public lineItems: any = [];
  public expensesItems: any = [];
  public isFormValid: boolean = false;
  public isInvoiceDateChanged: boolean = false;
  public isShowMemo: boolean = false;
  public isShowBottonMemo: boolean = false;
  public showRecordsSideBar: boolean = false;
  public isEditInvoiceAmount: boolean = false;
  public dom: Document;
  public activeObj = {
    "InvoiceType": null,
    "DescriptionShort": null,
    "BillNote": null,
    "BillNoteTop": null
  };
  isMemoChanged = false;
  public tools: ToolbarModule = {
    items: ['Bold', 'Italic', 'Underline', 'FontName', 'FontSize', 'FontColor', 'BackgroundColor', 'Formats', 'Alignments', 'Print']
  };

  public companyInvoiceSetting: any = null;
  public rowAmountBeforeToEdit: number = 0;

  @ViewChild('billNote')
  public noteObj: RichTextEditorComponent;

  @ViewChild('topEditor')
  public topObj: RichTextEditorComponent;
  companySetting: any;
  IsExcludeAging: any;
  excludeAgingChanged: boolean;

  propertyEdited: any = [];

  oldDescriptionShort = '';

  constructor(
    private http: HttpClient,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private router: Router,
    public mangoUtils: mangoUtils,
    public sharedSrvc: SharedComponentsService,
    public auth: AuthGuard,
    private _translate: TranslateService,
    private _fb: UntypedFormBuilder,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) dom: Document,
  ) {
    this.mangoAPISrvc.applyLanguage();
    this.dom = dom;
    this.clientName = this.encrDecSrvc.getObject(AppConstants.ClientName);
    this.clientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.loginCompanyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.activitiesTypes = this.encrDecSrvc.activitiesList;
    this._translate.reloadLang(this._translate.currentLang).subscribe(data=>{
      this.invoiceTemplates = [
        { value: '2', label: this._translate.instant('client.narrative') },
        { value: '9', label: this._translate.instant('client.narrative_summary') },
        { value: '10', label: this._translate.instant('client.narrative_detail') },
        { value: '4', label: this._translate.instant('client.narrative_mini_statement') },
        { value: '0', label: this._translate.instant('client.detail') },
        { value: '6', label: this._translate.instant('client.detail_no_rate') },
        { value: '7', label: this._translate.instant('client.detail_no_rate_time') },
        { value: '11', label: this._translate.instant('client.detail_no_rate_time_amount') },
        { value: '12', label: this._translate.instant('client.narrative_simple_no_detail') },
        { value: '13', label: this._translate.instant('client.narrative_simple_with_detail') },
        { value: '14', label: this._translate.instant('client.narrative_simple_no_remit') },
        { value: '15', label: this._translate.instant('client.invoice_detail_summary') },
        { value: '16', label: this._translate.instant('client.invoice_narrative_summary_by_activity') },
      ];

      if (this.loginCompanyId == 1) {
        this.invoiceTemplates.push({ label: ('Laser Software'), value: '8' });
      }
    });
  }

  ngOnInit(): void {
    this.initializeInvoiceForm();
    const settings = this.encrDecSrvc.getObject(AppConstants.systemLocking);
    this.companySetting = settings || {};
  }

  ngAfterViewInit() : void {

  }

  initializeColumns() {
    const allCols = [
      { field: 'Ddate', header: this._translate.instant('date'), rowClass: 'width-7p p-text-center' },
      { field: 'StaffName', header: this._translate.instant('user-title'), rowClass: 'width-10p p-text-left' },
      { field: 'StaffNumber', header: this._translate.instant('User Initials'), rowClass: 'width-7p p-text-left' },
      { field: 'scdescr', header: this._translate.instant('activity'), rowClass: 'width-10p p-text-left' },
      { field: 'ServiceCode', header: this._translate.instant('Code'), rowClass: 'width-6p p-text-left' },
      { field: 'Memo', header: this._translate.instant('description'), rowClass: 'width-15p p-text-left' },
    ]
    let selectedCols = [];
    const defaultCols = [
      { field: 'Ddate', header: this._translate.instant('date'), rowClass: 'width-7p p-text-center' },
      { field: 'StaffName', header: this._translate.instant('user-title'), rowClass: 'width-10p p-text-left' },
      { field: 'scdescr', header: this._translate.instant('activity'), rowClass: 'width-10p p-text-left' },
      { field: 'Memo', header: this._translate.instant('description'), rowClass: 'width-15p p-text-left' },
    ]
    this.cols = [
      ...allCols
    ]

    this.mangoAPISrvc.showLoader(true)
    this.mangoAPISrvc.getUsersSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID)).subscribe((data: any) => {
      if (data.OpenTimeCols?.length > 0) {
        selectedCols = allCols.filter((col) => data.OpenTimeCols.includes(col.field));
        if(data.OpenTimeCols.includes('Ddate')) {
          this.displayDate = true;
        }
      } else {
        selectedCols = [...defaultCols];
      }
      this._selectedColumns = selectedCols;
      this.mangoAPISrvc.showLoader(false)
    }, err => {
      this.mangoAPISrvc.showLoader(false)
      selectedCols = [...defaultCols];
    })
  }


  sortDates() {
    this.recordsTable.allTimeSlips.sort((a, b) => {
      const dateA = new Date(a.Ddate).getTime();
      const dateB = new Date(b.Ddate).getTime();
      return this.isSortAscending ? dateA - dateB : dateB - dateA;
    });

    this.isSortAscending = !this.isSortAscending;
    this.cdr.detectChanges();
  }

  openInVoiceModal(data) {
    data['isEditFlow'] = true;
    this.isEditDialogOpen = true;
  }

  async getTimeRecordWithOutBillingDetail (BillingHeaderID: number) {
    let serviceFunc = this.mangoAPISrvc.getTimeRecordWithoutBD.bind(this)
    if(this.isSuperAdmin) serviceFunc = this.mangoAPISrvc.getTimeRecordWithoutBDSuperAdmin.bind(this)

    const response: any = await serviceFunc(BillingHeaderID);
    if (response[0]?.ClientID) {
      this.recordsTable.timeSlipsWithoutBillingDetails = response
        .filter((item) => item.IsTimeRecord === 'T')
        .map((item) => {
          return {
            ...item,
            Ddate: this.mangoUtils.formatDateString(item.Ddate),
            sortableDdate: moment(this.mangoUtils
              .removeOffset(item.Ddate)).format('YYYY/MM/DD')
          }
        });
    }
  }

  editInvoiceModal(itemData) {
    this.selectedInvoiceTemplate = "";
    this.IsExcludeAging = itemData.IsExcludeAging;
    this.isSuperAdmin = itemData.isSuperAdmin
    let serviceFunc = this.mangoAPISrvc.getTimeRecordsByBHID.bind(this)
    if(this.isSuperAdmin) {
      serviceFunc = this.mangoAPISrvc.getTimeRecordsByBHIDSuperAdmin.bind(this)
      this.loginCompanyId = itemData.CompanyID
    }

    serviceFunc(itemData['BillingHeaderID'])
    .subscribe((result: any) => {
      this.recordsTable.unFilteredTimeSlips = (
        result
        .filter((item) => item.IsTimeRecord == 'T')
        .map((item) => {
          return {
            ...item,
            Ddate: (
              //this.mangoUtils.removeOffset(item.Ddate)
              this.mangoUtils.formatDateString(item.Ddate)
            ),
            sortableDdate: (
              moment(this.mangoUtils.removeOffset(item.Ddate))
              .format('YYYY/MM/DD')
            )
          }
        })
      );

      this.getTimeRecordWithOutBillingDetail(itemData['BillingHeaderID']);

      this.recordsTable.allExpenseSlips = (
        result.filter((item) => item.IsTimeRecord == 'X')
      )
    },

    err => {
      this.mangoAPISrvc.showLoader(false);
      this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
    });

    this.clientID = itemData.ClientID;

    this.loadDefaults();

    this.mangoAPISrvc.showLoader(true);

    setTimeout(() => {
      this.surchargeAmt = (
        itemData['SurChargeAmount']
        ? itemData['SurChargeAmount']
        : 0
      );

      let serviceFunc = this.mangoAPISrvc.getBillingDetailRecords.bind(this)
      if(this.isSuperAdmin) serviceFunc = this.mangoAPISrvc.getBillingDetailRecordsSuperAdmin.bind(this)
      serviceFunc(itemData['BillingHeaderID'])
      .subscribe((data:any) => {
        this.invoiceForm.controls['BillingHeaderID'].setValue(itemData['BillingHeaderID']);
        this.invoiceForm.controls['ClientName'].setValue(itemData['ClientName']);

        let invoiceBal = '$' + numeral(itemData['InvoiceBalance']).format('0,0.00');

        this.invoiceForm.controls['InvoiceNumber'].setValue(itemData['InvoiceNumber']);

        const totalTax = numeral(itemData['TotalTax']).value() + numeral(itemData['TotalTaxExpenses']).value();
        this.invoiceForm.controls['TotalTax'].setValue('$' + numeral(totalTax).format('0,0.00'));

        this.totalPayments = itemData['TotalPayments'] ? numeral(itemData['TotalPayments']).value() : 0;

        this.invoiceForm.controls['Discount'].setValue(`$${numeral(itemData['Discount']).format('0,0.00')}`);

        invoiceBal = '$' + numeral(invoiceBal).format('0,0.00');

        this.invoiceForm.controls['InvoiceAmount']
        .setValue('$' + numeral(itemData['InvoiceAmount']).format('0,0.00'));

        this.invoiceForm.controls['PreviousBalance']
        .setValue('$' + numeral(itemData['PreviousBalance']).format('0,0.00'));

        this.invoiceForm.controls['TotalPayments']
        .setValue('$' + numeral(this.totalPayments).format('0,0.00'));

        this.invoiceForm.controls['InvoiceBalance']
        .setValue('$' + numeral(itemData['InvoiceBalance']).format('0,0.00'));

        itemData[ "InvoiceDate" ] =  new Date(moment( itemData[ "InvoiceDate" ] ).format( "YYYY-MM-DDT00:00:00" ).toString( ));

        this.invoiceForm.controls[ "InvoiceDate" ]
        .setValue( itemData[ "InvoiceDate" ] );

        this.invoiceForm.controls['DescriptionShort'].setValue(itemData['DescriptionShort']);
        this.invoiceForm.controls['BillNoteTop'].setValue(itemData['BillNoteTop']);
        this.invoiceForm.controls['BillNote'].setValue(itemData['BillNote']);

        this.mangoUtils.setPropertyById(this.activitiesTypes, data, 'ServiceCodeID', 'Description', 'ServiceCode');

        let counter = 0;

        this.lineItems = data.map((obj) => {
          obj['prevDiscount'] = obj['Discount'] || 0;
          obj['LineItem'] = obj['LineItem'] ? obj['LineItem'] : (obj['LineItem'] = counter + 1);

          counter++;
          return obj;
        });

        this.activeObj.InvoiceType = itemData[ "InvoiceType" ];

        this.activeObj.DescriptionShort = itemData['DescriptionShort'];
        this.activeObj.BillNoteTop = itemData['BillNoteTop'];
        this.activeObj.BillNote = itemData['BillNote'];
        this.isEditDialogOpen = true;

        if (itemData.InvoiceTemplate != null) {
          if (isNaN(itemData.InvoiceTemplate)) {
            this.selectedInvoiceTemplate = (
              this.invoiceTemplates
              .filter((client) => client['label'] == itemData.InvoiceTemplate)[0]
              .value
            );
          } else {
            this.selectedInvoiceTemplate = (
              this.invoiceTemplates
              .filter((client) => client['value'] == itemData.InvoiceTemplate)[0]
              .value
            );
          }
        } else {
          this.selectedInvoiceTemplate = this.invoiceTemplates[0].value;
        }

        this.invoiceForm.controls['selectedInvoiceTemplate'].setValue(this.selectedInvoiceTemplate);
        this.mangoAPISrvc.showLoader(true);
        let serviceFunc = this.mangoAPISrvc.getSettingData.bind(this)
        if(this.isSuperAdmin) serviceFunc = this.mangoAPISrvc.getSettingDataSuperAdmin.bind(this)
        serviceFunc(itemData['ClientID'])
        .subscribe((data: any) => {
          this.settingData = data;
          this.invoiceForm.controls['customerTermId'].setValue(data.TermsID);
          this.mangoAPISrvc.showLoader(false);
        });

        this.mangoAPISrvc.showLoader(true);

        let serviceFunc2 = this.mangoAPISrvc.getExpenseMasterList.bind(this)
        if(this.isSuperAdmin) serviceFunc2 = this.mangoAPISrvc.getExpenseMasterListSuperAdmin.bind(this)
        serviceFunc2(itemData['BillingHeaderID'])
        .subscribe((data) => {
          this.expensesItems = data;
          this.mangoAPISrvc.showLoader(false);
        });

        this.mangoAPISrvc.showLoader(false);
      });
    }, 1000);
  }

  heightChange(){
    this.input.nativeElement.style.height = 'auto';
    this.input.nativeElement.style.height = `${this.input.nativeElement.scrollHeight}px`;
  }

  getCompanyLocations() {
    this.mangoAPISrvc.showLoader(true);
    let serviceFunc = this.mangoAPISrvc.getCompanyLocations.bind(this)
    if(this.isSuperAdmin) serviceFunc = this.mangoAPISrvc.getCompanyLocationsSuperAdmin.bind(this)
    serviceFunc(this.loginCompanyId).subscribe(
      (data: any) => {
        this.companyLocations = data.map((location) => {
          return {
            value: location.CompanyMangoLocationID,
            label: location.CompanyLocation,
            laborRate: location.TaxRateLaborL,
            expenseRate: location.TaxRateExpenseL,
          }
        });
        this.setLaborRates()
        this.mangoAPISrvc.showLoader(false);
      },
      (err) => this.mangoAPISrvc.showLoader(false)
    );
  }

  loadDefaults() {
    const parent = this;

    this.mangoAPISrvc.showLoader(true);

    let serviceFunc = this.mangoAPISrvc.loadManualInvoice.bind(this)
    if(this.isSuperAdmin) serviceFunc = this.mangoAPISrvc.loadSharedInvoiceSuperAdmin.bind(this)
    serviceFunc(this.loginCompanyId)
    .subscribe((data: any) => {
      this.getTerms(data[0]);
      this.getCompanyInfo(data[1][0]);
      this.getCompanyLocations();

      this.isEditInvoiceAmount  = data[2].isEditInvoiceAmount;
      this.mangoAPISrvc.showLoader(false);
    }),

    (error) => {
      this.mangoAPISrvc
      .notify(
        "error",
        this._translate.instant("error"),
        AppConstants.fetchErrorMsg
      );

      this.mangoAPISrvc.showLoader(false);
    };
  }

  isInvoiceAmountCanEdit() {
    if (this.isEditInvoiceAmount) {
      //only user with Permission can update InvoiceAmount
      if (this.auth.isAllowAccess(34) || this.auth.isSuperAdmin) return true;
      else false;
    } else {
      //everyone can edit
      return true;
    }
  }

  textAreaAdjust(e) {
    e.target.style.height = "0px";
    e.target.style.height = (e.target.scrollHeight + 2)+"px";
  }

  getCompanyInfo(obj) {
    const parent = this;
    this.mangoCompanyData = obj;
  }

  getTerms(data) {
    const _this = this;
    if (_this.termsList.length > 0) {
      return false;
    }
    _this.termsList = [];
    for (let i = 0; i < _this.encrDecSrvc.termsList.length; i++) {
      if (!_this.encrDecSrvc.termsList[i].Inactive) {
        _this.termsList.push({ label: _this.encrDecSrvc.termsList[i].TermsDescription, value: _this.encrDecSrvc.termsList[i].TermsID })
      }
    }
    if (data.TermsID != null) {
      _this.invoiceForm.controls['customerTermId'].setValue(data.TermsID);
    } else {
      if (_this.termsList.length > 0) {
        _this.invoiceForm.controls['customerTermId'].setValue(_this.termsList[0].value);
      }
    }
  }

  saveSetting() {
    let templateValue = this.invoiceForm.controls['selectedInvoiceTemplate'].value;
    if (isNaN(templateValue)) {
      templateValue = this.invoiceTemplates.filter((client) => client['label'] == templateValue)[0].value;
    } else {
      templateValue = this.invoiceTemplates.filter((client) => client['value'] == templateValue)[0].value;
    }
    const parent = this;
    const obj = {
      'invoiceTemplate': templateValue,
      'customerTermId': this.invoiceForm.controls['customerTermId'].value,
      'arCreditLimit': this.settingData.ARCreditLimitAmount,
      'wipCreditLimit': this.settingData.WIPCreditLimitAmount,
      'salesTax1': this.settingData.Tax1ID,
      'activateClientRates': this.settingData.ActivateClientRates,
      'salesTax2': this.settingData.Tax2ID,
      'salesTax3': this.settingData.Tax3ID,
      'markSlipBilled': this.settingData.MarkSlipsBilled,
      'markDepositApplied': this.settingData.MarkDepositApplied,
      'noServiceCharges': this.settingData.NoServiceCharges,
      'FinalizeAction': this.settingData.FinalizeAction
    };
    parent.mangoAPISrvc.showLoader(true);
    let serviceFunc = this.mangoAPISrvc.saveSettingData.bind(this)
    if(this.isSuperAdmin) serviceFunc = this.mangoAPISrvc.saveSettingDataSuperAdmin.bind(this)

    serviceFunc(parent.clientID, obj).subscribe(function (data) {
      parent.mangoAPISrvc.isFormChanged = false;
      // parent.mangoAPISrvc.showLoader(false);
    }, error => {
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  async saveInvoice(billingHeaderObj: any) {
    this.mangoAPISrvc.showLoader(true);

    const billingDetailsList = [];

    this.setForm();

    const invoiceDate = (
      moment( billingHeaderObj[ "InvoiceDate" ] )
      .format( "YYYY-MM-DD" )
      .toString( )
    );

    billingHeaderObj["InvoiceDate"] = invoiceDate;

    const invoiceDateTime = new Date(moment(invoiceDate, "YYYY-MM-DD").format("YYYY-MM-DDT00:00:00").toString());

    const dayToday = new Date(new Date().setHours(0, 0, 0, 0));
    const effectiveLockDay = this.companySetting?.effectiveLockDay ?? 0;
    const monthsPreviousToLock = this.companySetting?.monthsPreviousToLock ?? 0;
    const isEnableSystemLocking = this.companySetting?.isEnableSystemLocking ?? false;
  
    const lockDay = new Date(new Date(dayToday).setDate(effectiveLockDay));
    const monthToLock = new Date(new Date(lockDay).setMonth(lockDay.getMonth() - monthsPreviousToLock)).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (this.companySetting.isEnableSystemLocking && dayToday >= lockDay && invoiceDateTime <= lastDayOfMonth) {
      this.mangoAPISrvc.notify(
        "error",
        "Error!",
        "Unable to update invoice since the month is locked."
      );

      return;
    }

    let totalItemAmount = 0;

    for (let i = 0; i < this.lineItems.length; ++i) {
      const billingDetailRecord = {};

      billingDetailRecord['ClientID'] = billingHeaderObj.ClientID;
      billingDetailRecord['BillingHeaderID'] = billingHeaderObj.BillingHeaderID;
      billingDetailRecord["InvoiceDate"]= invoiceDate;
      billingDetailRecord['Amount'] = this.converToNum(this.lineItems[i].Amount);
      billingDetailRecord['Discount'] = this.converToNum(this.lineItems[i].Discount);
      billingDetailRecord['InvoiceNumber'] = billingHeaderObj.InvoiceNumber;
      billingDetailRecord['Description'] = this.lineItems[i].bddescription;
      billingDetailRecord['ServiceCodeID'] = this.lineItems[i].ServiceCodeID;
      billingDetailRecord['ProjectID'] = this.lineItems[i].ProjectID;
      billingDetailRecord['StaffID'] = this.lineItems[i].StaffID;
      billingDetailRecord['BillingDetailID'] = this.lineItems[i].BillingDetailID;
      billingDetailRecord['LineItem'] = this.lineItems[i].LineItem;
      billingDetailRecord["WriteUpDownTime"] = (
        this.recordsTable
          .unFilteredTimeSlips
          ?.filter((item) => item.IsTimeRecord != "X")
          ?.reduce((a, b) => {
            return a + numeral(b.WriteUpDown).value();
          }, 0)

        || 0
      );

      totalItemAmount += billingDetailRecord['Amount'];

      billingDetailsList.push(billingDetailRecord);
    }

    this.expensesItems.forEach(e => {
      totalItemAmount += this.converToNum(e.BilledAmount);
    });

    this.mangoAPISrvc.showLoader(false);


    this.saveSetting();
    this.changeExcludeAging();

    this.updateBillingDetailsRecords(billingDetailsList)
      .subscribe(data => {
        this.lineItems
          ?.forEach((lineItem) => {
            if(!this.isSuperAdmin) {
              this.mangoAPISrvc
                .sendBudgetAlert({
                  ClientID: this.clientID,
                  ProjectMasterID: lineItem['ProjectID'],
                  CompanyID: this.loginCompanyId,
                  Ddate: invoiceDate
                })
                .subscribe((data) => { }, err => { console.log(err) })
            }
          });

        this.closeDialog();
        // this.mangoAPISrvc.showLoader(false);
        this.sharedSrvc.setInvoiceDialogData(true);
      }, (error) => {
        // this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('error', "Error!", error);
      });

    // updating Billing Header Record
    billingHeaderObj['InvoiceTemplate'] = billingHeaderObj.selectedInvoiceTemplate;
    billingHeaderObj['InvoiceAmount'] = numeral(billingHeaderObj.InvoiceAmount).value();
    billingHeaderObj['Discount'] = numeral(billingHeaderObj.Discount).value();
    billingHeaderObj['PreviousBalance'] = numeral(billingHeaderObj.PreviousBalance).value();
    if(this.isSuperAdmin) {
      billingHeaderObj['TotalPayments'] = numeral(billingHeaderObj.TotalPayments).value();
      billingHeaderObj['InvoiceBalance'] = numeral(billingHeaderObj.InvoiceBalance).value();
    }

    if (this.shouldSaveTimeRecords) {
      // billingHeaderObj["InvoiceBalance"] = (
      //   this.mangoUtils
      //     .roundOffDecimals(
      //       this.mangoUtils.subtractFloat(billingHeaderObj["InvoiceAmount"], this.totalPayments)
      //     )
      // );

      if (this.recordsTable.unFilteredTimeSlips.length == 0) {
        return;
      }
      let totals: any = 0;
      const totalExpenses = this.expensesItems.reduce((acc, item) => acc + this.converToNum(item.BilledAmount), 0);
      const adjustedDiscount = billingHeaderObj["Discount"] > 0 ? billingHeaderObj["Discount"] * -1 : billingHeaderObj["Discount"];
      const totalServices = this.mangoUtils.roundOffDecimals(
        this.mangoUtils.addFloat(
          adjustedDiscount,
          this.totalFromSlips.TotalServices
        )
      );
      totals = this.mangoUtils.addFloat(totalServices, totalExpenses);
      // totals = totals + this.mangoUtils.addFloat(this.salesTax.serviceTax, this.salesTax.expenseTax)
      billingHeaderObj["TotalTax"] = this.mangoUtils.roundOffDecimals(numeral(this.salesTax.serviceTax).value());

      billingHeaderObj["SalesTaxAmount"] = this.salesTax.taxableAmtService
      billingHeaderObj["SalesTaxAmountExpenses"] = this.salesTax.taxableAmtExpense
      billingHeaderObj["TotalStaffCost"] = this.mangoUtils.roundOffDecimals(this.totalFromSlips.TotalStaffCost)
      billingHeaderObj["TotalServices"] = totalServices
      billingHeaderObj["InvoiceAmount"] = totals;

      totals += (this.salesTax.serviceTax + this.salesTax.expenseTax);
      billingHeaderObj["InvoiceBalance"] = this.totalPayments > 0 ? totals - this.totalPayments : totals;

    } else {
      await this.calculateSalesTaxes();
      billingHeaderObj['TotalTax'] = this.mangoUtils.roundOffDecimals(numeral(this.salesTax.serviceTax).value())
      billingHeaderObj['InvoiceBalance'] = numeral(billingHeaderObj.InvoiceBalance).value();
      billingHeaderObj['TotalPayments'] = numeral(billingHeaderObj.TotalPayments).value();
    }

    billingHeaderObj['LastModifiedStaffID'] = this.encrDecSrvc.getObject(AppConstants.staffID);

    this.mangoAPISrvc.showLoader(true);
    let serviceFunc = this.mangoAPISrvc.updateSalesTaxInBillingHeader.bind(this)
    if(this.isSuperAdmin) serviceFunc = this.mangoAPISrvc.updateBillingHeaderSuperAdmin.bind(this);

    (!this.isSuperAdmin ? serviceFunc(
        billingHeaderObj,
        billingHeaderObj["BillingHeaderID"]
      ) : serviceFunc(
        this.loginCompanyId,
        billingHeaderObj["BillingHeaderID"],
        billingHeaderObj,
      )).pipe(finalize(() => {
        this.mangoAPISrvc.showLoader(false);
      }))
      .subscribe((data) => {
        if(data.allowEdit == false) {
          this.mangoAPISrvc.notify("warn", "Warning!", data.message);
          return
        }
        if (this.isInvoiceDateChanged) {
          this.mangoAPISrvc
            .updateInvoiceDateByBillingHeaderID(
              (
                billingHeaderObj["BillingHeaderID"]
              ),

              (
                {
                  CompanyID: this.loginCompanyId,
                  InvoiceDate: (
                    invoiceDate
                  ),

                  InvoiceType: (
                    this.activeObj.InvoiceType
                  )
                }
              )
            )
            .subscribe(
              (data) => {
                this.isInvoiceDateChanged = false

                this.sharedSrvc.setInvoiceDialogData(true);
              },
              (err) => {
                this.isInvoiceDateChanged = false
                this.mangoAPISrvc.notify(
                  "error",
                  "Error!",
                  AppConstants.updateErrorMsg
                )
              }
            );
        }

        if (this.shouldSaveTimeRecords) {
          const rowsToUpdate = [];

          this.recordsTable
            .unFilteredTimeSlips
            .forEach((record) => {
              record.BilledAmount = record.BilledAmount ? this.converToNum(record.BilledAmount) : 0
              rowsToUpdate.push(record)
            });

          setTimeout(() => {
            this.mangoAPISrvc
              .bulkTimeSheetSave(this.loginCompanyId, { rowsToInsert: [], rowsToUpdate, rowsToDelete: [] })
              .subscribe((result) => { });
          }, 500)
        }

        this.mangoAPISrvc
          .notify(
            "success",
            "Updated!",
            AppConstants.updateMsg
          );
      },

        err => {
          this.mangoAPISrvc.notify(
            "error",
            "Error!",
            err
          );
        });

    this.mangoAPISrvc.showLoader(true);

    this.updateBillingExpensiveRecords(this.expensesItems)
      .subscribe(data => {
        this.closeDialog();
        this.mangoAPISrvc.showLoader(false);
        this.sharedSrvc.setInvoiceDialogData(true);
      });

    if (billingDetailsList?.length === 0 && this.expensesItems) {
      this.closeDialog();
    }
  }

  updateBillingExpensiveRecords(selectedItemList) {
    const observableBatch = [];
    const parent = this;
    selectedItemList.forEach((selectedItem, key) => {
      const url = parent.http.put(`${environment.API_URL}/company/timeSheet/${selectedItem['SlipMasterID']}`, selectedItem);
      observableBatch.push(url);
    });
    return forkJoin(observableBatch)
  }

  updateBillingDetailsRecords(selectedItemList) {
    const observableBatch = [];
    const parent = this;

    selectedItemList.forEach((selectedItem, key) => {
      const url = parent.http.put(`${environment.API_URL}/billingDetail/update/${selectedItem['BillingDetailID']}`, selectedItem);
        observableBatch.push(url);
    });
    return forkJoin(observableBatch)
  }

  closeInvoiceDialog(flag) {
    this.surchargeAmt = 0;
    this.invoiceForm.reset();
  }

  onRowChange(event) {
    this.isFormValid = true;
    for (let index = 0; index < this.lineItems.length; index++) {
      const element = this.lineItems[index];
      element['LineItem'] = index + 1;
    }
  }

  initializeInvoiceForm() {
    this.invoiceForm = (
      this._fb.group({
        BillingHeaderID: [''],
        ClientName: [''],
        InvoiceNumber: [''],
        InvoiceAmount: [''],
        PreviousBalance: [''],
        TotalTax: [''],
        TotalPayments: [null],
        InvoiceBalance: [null],
        Discount: [''],
        InvoiceDate: [new Date(), [<any>Validators.required]],
        EngagementName: [''],
        DescriptionShort: [''],
        BillNote: [''],
        BillNoteTop: [''],
        selectedInvoiceTemplate: [''],
        customerTermId: [''],
        CopytoClipBoard: [false]
      })
    );

    this.invoiceForm
    .valueChanges
    .subscribe(data => {
      this.validateForm();
    })
  }

  replaceShortcuts(valueStr, rowData?, type?) {
    if(type) {
      const valueShortCut = this.convertShortcuts(valueStr);
      rowData[type] = valueShortCut;
      this.cdr.detectChanges();
      return;
    }
    if (valueStr) {
      if(valueStr.includes('/')){
        const indiceSlash = valueStr.indexOf('/');
        if (indiceSlash !== -1) {
          const textoBeforeSlash = valueStr.substring(0, indiceSlash);
          const textoAfterSlash = valueStr.substring(indiceSlash + 1);
          const value2 = this.convertShortcuts('/'+ textoAfterSlash);
          this.activeObj['DescriptionShort'] = textoBeforeSlash + value2;
          this.cdr.detectChanges();
        } else {
          console.log(valueStr.trim())
        }

      }else {
        const value2 = this.convertShortcuts(valueStr);
        this.activeObj['DescriptionShort'] = value2;
        this.cdr.detectChanges();
      }

    }
  }

  replaceShortcutsItem(value, desc, type?, test?) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }
    if (type === "Memo") desc["Memo"] = valueArr.join(" ");
    else {
      desc["bddescription"] = valueArr.join(" ");
    }
  }

  setForm(){
    this.invoiceForm.controls['BillNoteTop'].setValue(this.activeObj.BillNoteTop);
    this.invoiceForm.controls['BillNote'].setValue(this.activeObj.BillNote);
    this.invoiceForm.controls['DescriptionShort'].setValue(this.activeObj.DescriptionShort);
  }

  replaceShortcuts2(value, desc?, type?) {
    this.activeObj['DescriptionShort'] = this.convertShortcuts(value);
    this.invoiceForm.controls['DescriptionShort'].setValue(this.activeObj.DescriptionShort);
    this.setRichText()
  }


  convertShortcuts(value) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label.includes(shortcut["ShortCutCode"])) {
          label = this.mangoUtils.replaceAll(label, shortcut["ShortCutCode"], shortcut["Phrase"])
        }
      }
      valueArr[i] = label;
    }
    return valueArr.join(" ");
  }

  validateForm(newValue?: string) {

    let isDescriptionValid = false;
    let isInValidData = false;
    let istouchedData = false;

    if (!this.oldDescriptionShort) {
      this.oldDescriptionShort = this.activeObj['DescriptionShort'];
    }
    if (newValue !== undefined && this.activeObj['DescriptionShort'] !== this.oldDescriptionShort) {
      isDescriptionValid = true;
      this.invoiceForm.get('DescriptionShort').setValue(this.activeObj['DescriptionShort']);
    }

    Object.keys(this.invoiceForm.controls).forEach(key => {
      if (this.invoiceForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.invoiceForm.get(key).dirty) {
        if (key === 'InvoiceDate') {
          this.isInvoiceDateChanged = true;
        }
        istouchedData = true;
      }
    });

    this.isFormValid = !isInValidData && (this.invoiceForm.dirty || this.isMemoChanged || this.excludeAgingChanged || isDescriptionValid);
  }

  copyClipBoard(control: any) {
    const desValue = this.invoiceForm.controls['DescriptionShort'].value;
    const copyValue = this.invoiceForm.controls['CopytoClipBoard'].value;
    if (desValue == null || desValue.trim() == "") {
      control.setValue(false);
      return;
    }
    if (copyValue) {
      //this.renderer.invokeElementMethod(this.input.nativeElement, 'focus');
      //this.renderer.invokeElementMethod(this.input.nativeElement, 'select');
      this.dom.execCommand("copy");
    } else {
      this.dom.execCommand("Unselect");
    }
  }

  closeDialog(): void {
    this.isEditDialogOpen = false;
    this.invoiceForm.reset();
    this.invoiceForm.controls['InvoiceDate'].setValue(new Date());
    this.sharedSrvc.setInvoiceDialogData(false);
    this.sharedSrvc.sharedCompVisibility.invoice = false;
    const url = this.router.url;
    if (url == `/${AppConstants.clientRoutePath}/${AppConstants.invoicesRoutePath}` || url == `${AppConstants.InvoiceReviewUrl}`) {
      this.sharedSrvc.setInvoiceDialogData(true);
    }
  }

  setRichText(){
    this.isMemoChanged = true;
    this.validateForm()
  }

  showTimeRecords(projMasterID) {
    if(!this.isInvoiceAmountCanEdit()){
      Swal.fire({
        title: this._translate.instant('Warning'),
        text:  this._translate.instant('invoice.prevent-edit'),
        icon: 'warning',
        confirmButtonText: 'OK',
      }).then((result) => {});
    }
    else{
      this.initializeColumns()

      this.recordsTable.allTimeSlips = []
      this.recordsTable.allTimeSlips =
        this.recordsTable.unFilteredTimeSlips.filter(
          (slip) => slip.ProjectMasterID == projMasterID
        )

      this.calculateTotalFromSlips()
      this.showRecordsSideBar = true
    }
  }


  showTimeRecordsWithoutBillingDetails() {
    if(!this.isInvoiceAmountCanEdit()){
      Swal.fire({
        title: this._translate.instant('Warning'),
        text:  this._translate.instant('invoice.prevent-edit'),
        icon: 'warning',
        confirmButtonText: 'OK',
      }).then((result) => {});
    }
    else{
      this.initializeColumns()

      this.recordsTable.allTimeSlips = []
      this.recordsTable.allTimeSlips = this.recordsTable.timeSlipsWithoutBillingDetails

      this.calculateTotalFromSlips()
      this.showRecordsSideBar = true
    }
  }

  onCloseRecords() {
    this.recordsTable.allTimeSlips.forEach((item) => {
      if(item['IsRowEditing']) {
        item['IsRowEditing'] = false;
        const cloned = this.clonedData.filter(item => item.SlipMasterID === item.SlipMasterID)[0]
        item.BilledAmount = cloned.BilledAmount

        this.removeItemInClonedArray(item['SlipMasterID'], 'SlipMasterID')
      }
    })

    this.showRecordsSideBar = false
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
  }

  clearSearchFilterTime() {
    this.searchValue.nativeElement.value = this.searchTextStr = "";
    this.filteredItemsSize = -1;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    const arr = val.map(col => col.field)
    this._selectedColumns = this.cols.filter(col => arr.includes(col.field));
  }

  onChangeSelectedCols(event) {
    let columnsToSave = '';
    event.value.map((cols, index) => {
      if (index > 0)
        columnsToSave += `, ${cols.field}`
      else
        columnsToSave += cols.field
    })
    const result = event.value.some(obj => obj.field == 'Ddate');
    if(result) {
      this.displayDate = true;
    }else {
      this.displayDate = false;
    }
    const objToSave = { OpenTimeCols: `{${columnsToSave}}` }
    this.mangoAPISrvc
      .updateUserSelectedColsByUserId(
        this.encrDecSrvc.getObject(AppConstants.staffID),
        objToSave
      )
      .subscribe(
        (data) => { },
        (err) => { }
      );
  }

  onBilledAmountChanged(event, rowData) {
    rowData["WriteUpDown"] = this.mangoUtils.roundOffDecimals(
      this.mangoUtils.subtractFloat(
        rowData["BilledAmount"],
        rowData["StandardAmount"]
      )
    );
    this.processTimeSlips(rowData, 'slips')
  }

  setWriteUpDownColor(data) {
    let colorStr = "#1f0c0c";
    if (data < 0) {
      colorStr = "#f28686";
    } else if (data > 0) {
      colorStr = "#33c126";
    }
    return colorStr;
  }

  onInvoiceAmountChanged(rowData) {
    if (this.prevInvoiceAmount == rowData.Amount && this.prevDiscount == rowData.Discount) {
      return;
    }
    this.prevInvoiceAmount = rowData.Amount;
    this.prevDiscount = rowData.Discount;

    this.processTimeSlips(rowData, "details");
  }

  processTimeSlips(rowData, type) {
    this.shouldSaveTimeRecords = true;

    if(type == 'details') {
      const invoiceAmount = this.lineItems
        .filter((item) => item.ProjectID == rowData.ProjectID)
        .reduce((a, b) => {
          return this.mangoUtils.addFloat(a, numeral(b.Amount).value())
        }, 0)

      const totalDiscount = this.lineItems
        .filter((item) => item.ProjectID == rowData.ProjectID)
        .reduce((a, b) => {
          return this.mangoUtils.addFloat(a, numeral(b.Discount).value())
        }, 0)

      const totalBilledT = this.recordsTable.unFilteredTimeSlips
        .filter((item) => item.ProjectMasterID == rowData.ProjectID)
        .reduce((a, b) => {
          return this.mangoUtils.addFloat(
            a,
            numeral(b["StandardAmount"]).value()
          );
        }, 0);
      this.distributeAmtToSlips(invoiceAmount - totalDiscount, totalBilledT, rowData.ProjectID);
    } else {
      const uniqueArr = this.getUniqueProjectIDAndAmt();

      uniqueArr?.forEach((item) => {
        this.distributeAmtToDetails(item);
      });
    }

    this.calculateSalesTaxes();
    this.calculateTotalFromSlips();

    const invoiceTax = this.mangoUtils.addFloat(
      this.salesTax.serviceTax,
      this.salesTax.expenseTax
    );

    const formObj = this.invoiceForm.value;
    const totalExpensesAmt = this.expensesItems?.reduce((a, b) => {
      return this.mangoUtils.addFloat(a, numeral(b["BilledAmount"]).value());
    }, 0);

    let totalInvoiceAmt = this.lineItems.reduce((a, b) => {
      return this.mangoUtils.addFloat(a, numeral(b["Amount"]).value());
    }, 0);

    totalInvoiceAmt = this.mangoUtils.addFloat(totalExpensesAmt || 0, totalInvoiceAmt || 0)
    totalInvoiceAmt = this.mangoUtils.addFloat(totalInvoiceAmt, formObj["Discount"])

    this.invoiceForm.controls["TotalTax"].setValue(this.salesTax.serviceTax + this.salesTax.expenseTax);
    this.invoiceForm.controls["InvoiceAmount"].setValue(totalInvoiceAmt);

    this.doMoneyFormate("InvoiceAmount");
    this.doMoneyFormate("TotalTax");
    this.isFormValid = true
  }

  calculateTotalFromSlips() {
    this.totalFromSlips = {
      TotalServices: 0,
      TotalStaffCost: 0,
      TotalWUWD: 0,
      TotalRate: 0,
      TotalBilledAmt: 0,
      TotalStandardAmt: 0,
    };
  
    this.lineItems.forEach(item => {
      const amount = parseFloat(item.Amount.replace(/[$,]/g, '')) || 0;
      this.totalFromSlips.TotalServices += amount;
    });
  
    this.recordsTable.unFilteredTimeSlips.filter(
      (slip) => this.lineItems.map(x => x.EngagementTypeID).includes(slip.EngagementTypeID)
    ).forEach(record => {
      this.totalFromSlips.TotalStaffCost = this.mangoUtils.addFloat(this.totalFromSlips.TotalStaffCost, record['StaffCost'] || 0);
    });
  
    this.recordsTable.allTimeSlips.forEach(record => {
      this.totalFromSlips.TotalWUWD = this.mangoUtils.addFloat(this.totalFromSlips.TotalWUWD, record['WriteUpDown'] || 0);
      this.totalFromSlips.TotalBilledAmt = this.mangoUtils.addFloat(this.totalFromSlips.TotalBilledAmt, record['BilledAmount'] || 0);
      this.totalFromSlips.TotalRate = this.mangoUtils.addFloat(this.totalFromSlips.TotalRate, record['BillingRate'] || 0);
      this.totalFromSlips.TotalStandardAmt = this.mangoUtils.addFloat(this.totalFromSlips.TotalStandardAmt, record['StandardAmount'] || 0);
    });
  }
  
  distributeAmtToDetails(item) {
    const sameProject = this.lineItems?.filter((line) => item.ProjectMasterID === line.ProjectID)
    const totalInvoice = sameProject.reduce((a, b) => {
      return a + +numeral(b["Amount"]).value();
    }, 0)

    let deduction = this.mangoUtils.subtractFloat(totalInvoice, item.totalBilledAmt)
    this.lineItems?.map((line) => {
      if ((item.ProjectMasterID === line.ProjectID)) {
        let lineAmount = numeral(line.Amount).value()
        if (deduction === 0) return;

        if (deduction >= lineAmount) {
          deduction = this.mangoUtils.subtractFloat(deduction, lineAmount)
          lineAmount = 0;
        } else {
          lineAmount = this.mangoUtils.subtractFloat(lineAmount, deduction)
          deduction = 0;
        }

        line.Amount = this.mangoUtils.roundOffDecimals(lineAmount)
        line.PreviousAmount = line.Amount
      }
    })
  }

  checkAmountAndPaymentValues(type: any, rowData?: any, column?: any) {
    const totalLineItems = this.lineItems.reduce((sum, item) => {
      return sum + numeral(item.Amount).value();
    }, 0);

    const totalExpenses = this.expensesItems.reduce((acc, item) => acc + this.converToNum(item.BilledAmount), 0);
    this.calculateTotalDiscount()
    const discount = parseFloat(this.invoiceForm.controls['Discount'].value.replace(/\$/g, ''));

    const totalInvoiceAmount = totalLineItems + totalExpenses + discount;

    if (totalInvoiceAmount < this.totalPayments) {
      if(column === 'Amount')
        rowData['Amount'] = this.rowAmountBeforeToEdit;
      else {
        rowData['Discount'] = rowData['prevDiscount'];
        this.calculateTotalDiscount()
      }
      this.mangoAPISrvc.notify("warn", "Warning!", "You cannot change the amount to be less than the total payments.");
    } else {
      this.doMoneyFormate(type, rowData, column);
      if(column === 'Discount')
        rowData['prevDiscount'] = rowData['Discount'];
    }
  }


  doMoneyFormate(type: any, rowData?: any, column?: any) {
    if(column) {
      var myNumeral = numeral(rowData[column])
      if (myNumeral.value() === null) {
        rowData[column] = 0.0
      }
      rowData[column] = "$" + numeral(myNumeral.value()).format("0,0.00")
    } else {
      var myNumeral = numeral(this.invoiceForm.controls[type].value);
      if (myNumeral.value() === null) {
        this.invoiceForm.controls[type].setValue(0.0);
      }
      this.invoiceForm.controls[type].setValue("$" + numeral(myNumeral.value()).format("0,0.00"))
    }
  }

  getUniqueProjectIDAndAmt() {
    const uniqueIDs = [];
    const arrObj = [];

    this.recordsTable.allTimeSlips.forEach((slip) => {
      if(!uniqueIDs.includes(slip.ProjectMasterID)) uniqueIDs.push(slip.ProjectMasterID)

      const projObj = arrObj.filter((item) => item.ProjectMasterID == slip.ProjectMasterID)
      if(projObj.length > 0) {
        projObj[0]['totalBilledAmt'] = this.mangoUtils.addFloat(projObj[0]['totalBilledAmt'], slip.BilledAmount)
      } else {
        arrObj.push({ ProjectMasterID: slip.ProjectMasterID, totalBilledAmt: numeral(slip.BilledAmount).value()})
      }
    })

    return arrObj;
  }

  distributeAmtToSlips(percentageAmount, totalStandardAmt, projectID) {
    let lastBillableSlipID = null;
    let totalBilledAmt = 0;

    this.recordsTable.unFilteredTimeSlips.forEach((slip) => {
      if(slip.ProjectMasterID !== projectID || totalStandardAmt <= 0)
        return;

      const slipStandardAmt = numeral(slip.StandardAmount).value();

      if (slip.Billable) {
        slip.BilledAmount = this.mangoUtils.roundOffDecimals((slipStandardAmt / totalStandardAmt) * percentageAmount);
        slip.WriteUpDown = this.mangoUtils.roundOffDecimals(this.mangoUtils.subtractFloat(slip.BilledAmount, slipStandardAmt))

        totalBilledAmt = this.mangoUtils.roundOffDecimals(this.mangoUtils.addFloat(slip.BilledAmount, totalBilledAmt))
        lastBillableSlipID = slip.SlipMasterID;
      } else {
        slip.WriteUpDown = slip.BilledAmount - slipStandardAmt
      }
    })

    if (totalBilledAmt > percentageAmount) {
      const diff = this.mangoUtils.subtractFloat(totalBilledAmt, percentageAmount)
      this.recordsTable.unFilteredTimeSlips.forEach((slip) => {
        if (slip.SlipMasterID === lastBillableSlipID) {
          slip.BilledAmount = this.mangoUtils.roundOffDecimals(this.mangoUtils.subtractFloat(slip.BilledAmount, diff))
          slip.WriteUpDown = this.mangoUtils.roundOffDecimals(this.mangoUtils.subtractFloat(slip.BilledAmount, slip.StandardAmount))
          return;
        }
      })
    } else if (totalBilledAmt < percentageAmount) {
      const diff = this.mangoUtils.subtractFloat(percentageAmount, totalBilledAmt)
      this.recordsTable.unFilteredTimeSlips.forEach((slip) => {
        if (slip.SlipMasterID === lastBillableSlipID) {
          slip.BilledAmount = this.mangoUtils.roundOffDecimals(this.mangoUtils.addFloat(slip.BilledAmount, diff))
          slip.WriteUpDown = this.mangoUtils.roundOffDecimals(this.mangoUtils.subtractFloat(slip.BilledAmount, slip.StandardAmount))
          return;
        }
      })
    }
  }

  onInvoiceAmountFocus(data) {
    this.prevInvoiceAmount = null;
    this.prevInvoiceAmount = data
  }

  onDiscountFocus(data) {
    this.prevDiscount = null;
    this.prevDiscount = data
  }

  onInvoiceAmountInput(event ) {
    this.InvoiceAmountBeforeToEdit =  event.target.value;
  }

  setLaborRates() {
    let serviceFunc = this.mangoAPISrvc.getClientFullinformation.bind(this)
    if(this.isSuperAdmin) serviceFunc = this.mangoAPISrvc.getClientFullinformationSuperAdmin.bind(this)

    serviceFunc(this.clientID)
      .subscribe((clientdata: any) => {
        this.clientProfile = clientdata;

        if (this.mangoCompanyData.ActivateLaborRates == true && this.clientProfile.SalesTaxLevel != 'None') {
          if (this.clientProfile.SalesTaxLevel == 'ClientRate') {
            this.salesTax.Labor = this.clientProfile.Tax1ID ? numeral(this.clientProfile.Tax1ID).value() : 0;
            this.salesTax.Expense = this.clientProfile.Tax2ID ? numeral(this.clientProfile.Tax2ID).value() : 0;
          } else if (this.clientProfile.SalesTaxLevel == 'CompanyLocationRate') {
            const selectedCompanyLocation = this.companyLocations.filter((location) => location['value'] == this.clientProfile.CompanyMangoLocationID)[0];
            this.salesTax.Labor = selectedCompanyLocation['laborRate'] ? numeral(selectedCompanyLocation['laborRate']).value() : 0;
            this.salesTax.Expense = selectedCompanyLocation['expenseRate'] ? numeral(selectedCompanyLocation['expenseRate']).value() : 0;
          } else {
            this.salesTax.Labor = this.mangoCompanyData.LaborRate1 ? numeral(this.mangoCompanyData.LaborRate1).value() : 0;
            this.salesTax.Expense = this.mangoCompanyData.ExpenseRate1 ? numeral(this.mangoCompanyData.ExpenseRate1).value() : 0;
          }
        }
      }, err => {
        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg)
      })
  }

  async calculateSalesTaxes() {
    this.salesTax.serviceTax = 0;
    this.salesTax.expenseTax = 0;
    this.salesTax.taxableAmtExpense = 0;
    this.salesTax.taxableAmtService = 0;
  
    for (let index = 0; index < this.lineItems.length; index++) {
      const element = this.lineItems[index];
      const amountToUse = numeral(element.Amount).value();
  
      this.salesTax.taxableAmtService += amountToUse;
    }
    
    for (let index = 0; index < this.expensesItems.length; index++) {
      const element = this.expensesItems[index];
      element.BilledAmount = element.BilledAmount ? element.BilledAmount : 0;
      // need to check if user changed taxable at the expense entry level
      if (element.ectaxable !== false && element.IsTaxable !== false) {
        this.salesTax.taxableAmtExpense += numeral(element.BilledAmount).value();
      }
    }
  
    if (this.mangoCompanyData.ActivateLaborRates)
      this.salesTax.serviceTax = this.salesTax.taxableAmtService * (this.salesTax.Labor / 100);
  
    if (this.mangoCompanyData.ActivateExpenseRates)
      this.salesTax.expenseTax = this.salesTax.taxableAmtExpense * (this.salesTax.Expense / 100);
  }
  

  onExpenseRowEdit(rowData){
    rowData['IsRowEditing'] = true;
    this.editForm = rowData['IsRowEditing'];
  }

  onExpenseRowFinishEdit(rowData){
    rowData['IsRowEditing'] = false;
    this.editForm = rowData['IsRowEditing'];
  }

  changeExcludeAging() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    if(parent.isSuperAdmin) {
      parent.mangoAPISrvc
        .updateBillingHeaderSuperAdmin(
          this.loginCompanyId,
          this.invoiceForm.value.BillingHeaderID,
          {
            IsExcludeAging: this.IsExcludeAging,
          }
        )
        .subscribe(
          (data) => {
            parent.mangoAPISrvc.showLoader(false);
          },
          (error) => {
            parent.mangoAPISrvc.showLoader(false);
            parent.mangoAPISrvc.notify("error", this._translate.instant("error"), error);
          }
        );
    } else {
      parent.mangoAPISrvc.updateBillingHeaderObj({
        IsExcludeAging: this.IsExcludeAging
      }, this.invoiceForm.value.BillingHeaderID).subscribe(data => {
        parent.mangoAPISrvc.showLoader(false);
      }, (error) => {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('error', this._translate.instant('error'), error);
      });
    }
  }

  onRowEditInit(rowData, type, property, index) {
    if (this.editData.length < 1) {
      this.rowAmountBeforeToEdit = rowData['Amount'];
      this.prevDiscount = rowData['Discount'];
      const lineItemToFind = rowData['LineItem'];
      const foundItem = this.lineItems.find(item => item.LineItem === lineItemToFind);
      this.rowAmountBeforeToEdit = foundItem ? foundItem.Amount : 0;
      const column = type == 'details' ? 'LineItem' : 'SlipMasterID'
  
      const filtered = this.clonedData.filter((item) => item[column] == rowData[column]);
      if(filtered.length == 0) {
        this.clonedData.push({...rowData});
      }
  
      if(rowData['IsRowEditing'] && this.lastRowIndex == index)
        return;
  
      this.lastRowIndex = index;
      if (property === 'Desc') {
        this.selectCell('desc', index);
      } else if (property === 'Amount') {
        this.selectCell('amt', index);
      } else if (property === 'Discount') {
        this.selectCell('discount', index);
      }
      
      let propertyArr = [...this.propertyEdited, property];
      this.propertyEdited = propertyArr.length > 0 ? [...new Set(propertyArr)] : [];
      this.editData.push({...rowData});
      rowData['IsRowEditing'] = true;
      this.editForm = rowData['IsRowEditing'];
    } else {
      return;
    }
  }

  selectCell(clss, index, xtraEl?) {
    setTimeout(() => {
      let colClass = `.${clss}-${index}`
      colClass += xtraEl ? ` > ${xtraEl}` : ''
      $(colClass).select();
    }, 50);
  }

  onRowEditCancel(rowData, type) {
    rowData['IsRowEditing'] = false;
    this.editForm = rowData['IsRowEditing'];
    const column = type == 'details' ? 'LineItem' : 'SlipMasterID'
    const cloned = this.clonedData.filter(item => item[column] === rowData[column])[0]
    if(type=='details') {
      rowData.Amount = cloned.Amount
      rowData.Discount = cloned.Discount
      rowData.bddescription = cloned.bddescription
    } else {
      rowData.BilledAmount = cloned.BilledAmount
    }
    
    this.deleteEditData(rowData);
    this.removeItemInClonedArray(rowData[column], column)
  }

  deleteEditData(rowData) {
    const indice = this.editData.findIndex(item => item.BillingDetailID === rowData.BillingDetailID);

    if (indice !== -1) {
      this.editData.splice(indice, 1);
    }
  }

  removeItemInClonedArray(id, column) {
    this.clonedData = this.clonedData.filter((item) => item[column] !== id);
  }

  onRowEditSave(rowData, type, rowIndex?) {
    this.isFormValid = true;
    const column = (
      type == 'details'
      ? 'LineItem'
      : 'SlipMasterID'
    );

    rowData['IsRowEditing'] = false;
    this.deleteEditData(rowData);
    this.editForm = rowData['IsRowEditing'];

    if(!(this.propertyEdited.length === 1 && this.propertyEdited.includes('Desc'))) {
      if (type=='details') {
        this.calculateTotalDiscount()
        this.onInvoiceAmountChanged(rowData)
      } else {
        this.onBilledAmountChanged(null, rowData)
      }
      this.removeItemInClonedArray(rowData[column], column)
    } else {
      this.removeItemInClonedArray(rowData[column], column)
    }
  }

  converToNum(str) {
    return str ? numeral(str).value() : 0
  }

  verifySystemLocking(e) {
    if (e == null) return;

    const dayToday = new Date(new Date().setHours(0,0,0,0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    const monthToLock = new Date(new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (this.companySetting.isEnableSystemLocking && dayToday >= lockDay && new Date(e) <= lastDayOfMonth) {
      this.mangoAPISrvc.notify('error', "Error!", "System Locking is enabled on the selected date.");
    }
  }

  handleInvoiceTemplateClick() {
    if (this.activeObj.InvoiceType === EInvoiceTypes.RETAINER_INVOICE) {
      Swal.fire({
        title: this._translate.instant("Warning"),
        text: this._translate.instant("invoice.retainer-invoice-disable-invoice-template"),
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  }

  public get eInvoiceTypes(): typeof EInvoiceTypes {
    return EInvoiceTypes;
  }

  calculateTotalDiscount() {
    const sumUpAllDiscount = this.lineItems.reduce((a, b) => {
      return a + numeral(b.Discount).value();
    }, 0);
    this.invoiceForm.controls["Discount"].setValue(
      `$${numeral(sumUpAllDiscount * -1).format("0,0.00")}`
    );
  }

  preventTimeRecordUpdate(projectMasterId, rowData, ri, event) {
    const lineItemsWithMatchedProjectIds = this.lineItems.filter(
      (item) => item["ProjectID"] == projectMasterId
    );
    const sumupAllDiscount = lineItemsWithMatchedProjectIds.reduce(function (a, b) {
      return a + +numeral(b["Discount"]).value();
    }, 0);
    if (sumupAllDiscount > 0) {
      Swal.fire({
        icon: "warning",
        title: this._translate.instant("Warning"),
        text: this._translate.instant("invoice.discount_in_placed"),
        showConfirmButton: true,
      });
      this.dtAllTimeSlips.cancelRowEdit(rowData)
      rowData['IsColumnChanges'] = false
      rowData['IsRowEditing'] = false
    } else {
      event.target.select()
    }
  }
}