import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AppConstants } from "@app/_helpers/api-constants";
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils } from "@app/_services";
import { debounceTime, finalize, forkJoin, Subject, timer } from "rxjs";
import { environment } from "@environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem, SelectItem } from "primeng/api";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
declare let numeral: any;
import moment from "moment";
import { DomSanitizer } from "@angular/platform-browser";
declare let $: any;
declare let FattJs: any;
import { AutoComplete } from "primeng/autocomplete";


@Component({
  selector: 'app-cash-receipts',
  templateUrl: './cash-receipts.component.html'
})
export class CashReceiptsComponent implements OnInit {
  @ViewChild('firstNameInputFocus') firstNameInputField: ElementRef;
  debouncer: Subject<string> = new Subject();
  private filterSubject = new Subject<string>();
  allClients: any[] = []; 
  public showDebitMemoDialog: boolean = false;
  public clientAutoCompleteDisable: boolean = false;
  public isDebitCard: boolean = false;
  public mycashReceiptsForm: UntypedFormGroup;
  public myAchReceiptsForm: UntypedFormGroup;
  public myPaymentForm: UntypedFormGroup;
  public client: string;
  public selClient: any = null;
  public dialogTitle = "";
  public selInvoiceNo: any = {};
  public selClientGroup: any = {};
  public selectedRowValue: any = null;
  public clientsList: any = [];
  public filteredClients: any = [];
  public filteredGroups: any = [];
  public filteredInvoices: any = [];
  public typeofPayments: SelectItem[];
  public typeofPaymentsSearchByGroup: SelectItem[];
  public cardTypes: SelectItem[];
  public openInvoicesDataSource: any = [];
  public openRetainersDataSource: any = [];
  public copyInvoicesDataSource: any = [];
  public transCodeTypes: any = [];
  public isFormValid: boolean = false;
  public isProcessing: boolean = false;
  public isEditMode: boolean = false;
  public globalValidForm: boolean = true;
  public isNewCpaPay: boolean = false;
  public isPaymentFlowRequired: boolean = false;
  public totalNumberAmt: number = 0;
  public totalNumberPrev: number = 0;
  public totalNumberBalance: number = 0;
  public totalNumberPayments: number = 0;
  public counter: number = 0;
  public dialogWidth: any = '30vw';
  public showTable: boolean = true;
  public isValidTaxAmt: boolean = false;
  public isLexcharge: boolean = false;
  public isCheckRef: boolean = false;
  public isCheckNo: boolean = false;
  public showText: any = 'Hide';
  public selectedValue: any = '';
  public totalNumberPaymentamt: number = 0;
  public totalNumberPaymentUnapplied: number = 0;
  public totalNumberPaymentUnappliedValue: number = 0;
  public isPaymentApplied = false;
  public isRetainerApplied = false;
  public isUnapplied = false;
  public paymentHeaderID: any = '';
  public selectedCustomer: any = '';
  public isFormDisble = false;
  public isDialogFormValid = false;
  public dialogDisplay: boolean = false;
  public showCardoption: boolean = false;
  public showAchCardoption: boolean = false;
  public combinedVisible: boolean;
  public isCpaPay: boolean = false;
  public isSearchByClient: number = 0;
  public paymentProfile: any = null;
  public companyData: any = {};
  public mangoCompanyData: any = {};
  public flagAddNewCard: boolean = false;
  public isIncludeActive: boolean = false;
  public isAutoApply: boolean = false;
  public PaymentNote: any = '';
  public isVisaCard: string = 'true';
  public isBusiness: string = 'false';
  public selectedPaymentDate: any = null;
  public nativeWindow: any;
  public companyIdRef: any;
  public paymentHeaderIdRef = '';
  public searchSrtring = 'Search by Invoice';
  public labelStr = 'Client Name';
  items: MenuItem[];
  public isSaveAndSplitClicked: boolean = false;
  public isClientSelected: boolean = false;
  public showACHDialog: boolean = false;
  public showAchNewCardoption: boolean = true;
  public achProfile: any = {};
  public amountToCharge;
  public loginCompanyId: any;
  public staffID: any;
  intervalid: any;
  public companyName: any;
  public emailCompany: any;
  public url;
  public newcardurl;
  public debitMemoDesc: string = '';
  public allEmailContacts: any = [];
  public groupList: any = [];
  buttonsDeleteList: MenuItem[];

  // STax code
  public showStaxoption: boolean = false;
  public isStaxNewCard: boolean = false;
  public isCCFlow: boolean = false;
  public sTaxProfile: any = null;
  public fattJs: any;
  public staffPermission: any = 0;
  public isSTaxEnabled: boolean = false;
  public transactionData: any = null;
  public userName;
  cashReceiptEmailTemplate = null;
  next = '>>';
  prev = '<<';

  superAdminVisible: number[] = AppConstants.superAdminVisible;
  companySetting: any;

  private currentFocusedIndex = -1;

  batchSize = 100;
  currentBatch = 0;
  @ViewChild('autoComplete', { static: true }) autoComplete!: ElementRef;
  @ViewChild('autoCompleteRef') clientAutoComplete: AutoComplete;
  constructor(
    private router: Router,
    private http: HttpClient,
    private mangoAPISrvc: MangoApiService,
    private _translate: TranslateService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private _fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    public mangoUtils: mangoUtils
  ) {
    this._translate.reloadLang(this._translate.currentLang).subscribe(data => {
      this.breadcrumbService.setItems([
        { label: this._translate.instant('accounting') },
        { label: this._translate.instant('Accounts.cash-receipts'), icon: 'ic-red' }
      ]);

      this.items = [
        {
          label: this._translate.instant('save_and_split'),
          disabled: this.isSearchByClient == 2,
          icon: 'fa fa-columns',
          command: () => {
            this.saveAndSplit();
          }
        }
      ];

      this.buttonsDeleteList = [
        {
          label: 'Search by Client',
          icon: 'fal fa-user-friends',
          command: () => {
            this.switchSearch(0);
          }
        },
        {
          label: 'Search by Invoice No.',
          icon: 'fal fa-file-invoice-dollar',
          command: () => {
            this.switchSearch(1);
          }
        },
        {
          label: 'Search by Group',
          icon: 'fal fa-user-friends',
          command: () => {
            this.switchSearch(2);
          }
        }
      ];
    });

    this.transCodeTypes = [
      { label: 'None', value: null },
      { label: 'Checking', value: 27 },
      { label: 'Savings', value: 37 }
    ];
    this.loginCompanyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.companyIdRef = this.loginCompanyId;
    this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.emailCompany = this.encrDecSrvc.getObject(AppConstants.companyEmail);
    this.companyName = this.encrDecSrvc.getObject(AppConstants.companyName);
    const userPermissionObj = this.encrDecSrvc.getObject(AppConstants.userPermissionObj);
    this.staffPermission = parseInt(userPermissionObj.Permission);
    this.userName = this.encrDecSrvc.getObject(AppConstants.userName);
    const unSaved = sessionStorage.getItem("unSavedCashReceipts");

    if (unSaved) {
      this.mangoAPISrvc.isFormChanged = true;
    } else {
      this.mangoAPISrvc.isFormChanged = false;
    }

    this.nativeWindow = this.mangoAPISrvc.getNativeWindow();

    this.initializeForm();
    this.initializeDialogForm();
    this.getShortCutLabels();
    this.updateForm(true);
    this.getCompanyInfo();
    this.initializeACHForm();
    this.encrDecSrvc.clientList$.subscribe((clients) => {
      if (clients.length > 0) {
        this.fetchClients();
        this.allClients = [...this.clientsList];
        this.filteredClients = this.allClients.slice(0, 100);
      }
    
    });
  }

  onScroll(event: any) {
    const target = event.target;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight + 0.5;

    if (scrollTop + clientHeight >= scrollHeight) {
      if (this.currentBatch * this.batchSize < this.allClients.length) {
        this.loadBatch();
      }
    }
  }

  focusOnItem(targetItem: any) {
    setTimeout(() => {
      const dropdownPanel = document.querySelector('.p-autocomplete-panel');
      if (!dropdownPanel) return;
      const itemElements = dropdownPanel.querySelectorAll('.p-autocomplete-item');
      itemElements.forEach((element: HTMLElement) => {
        if (element.textContent?.trim() === targetItem.ClientName) {
          element.scrollIntoView({ behavior: 'instant', block: 'nearest' }); 
          element.focus(); 
        }
      });
    });
  }
  handleClientKeyPress(event) { 
    if(!this.selClient) {
      this.currentFocusedIndex = -1;
    }   
    const key = event.key || event.code || event.which;
    if (key === 'ArrowDown') {
      if(this.currentFocusedIndex==-1){
        this.currentFocusedIndex=0;
      }else if(this.currentFocusedIndex<this.clientAutoComplete.suggestions.length-1){
        this.currentFocusedIndex++;
      }else if(this.currentFocusedIndex==this.clientAutoComplete.suggestions.length-1){
        this.currentFocusedIndex=0;
      }
    }else if (key === 'ArrowUp') {
      if(this.currentFocusedIndex==0){
        this.currentFocusedIndex=this.clientAutoComplete.suggestions.length-1;
      }else if(this.currentFocusedIndex>0){
        this.currentFocusedIndex--;
      }
    }else if(key === 'Enter'){
      event.preventDefault(); 
      if (this.currentFocusedIndex >= 0) {
        const focusedItem = this.filteredClients[this.currentFocusedIndex];
      
        this.selClient = focusedItem;
        this.handleSelectClick(focusedItem);
      }
 
    }
    else if(key === 'Backspace') {
      return;
    }
    if (['ArrowDown', 'ArrowUp'].includes(key) && this.currentFocusedIndex >= 0) {
      this.selClient = this.clientAutoComplete.suggestions[this.currentFocusedIndex];
      this.focusOnItem(this.selClient);
    }
  }


  loadBatch() {
    const start = this.currentBatch * this.batchSize;
    const end = start + this.batchSize;
    const nextBatch = this.allClients.slice(start, end);
    this.filteredClients = [...this.filteredClients, ...nextBatch];
    this.currentBatch++;
  }

  onDropdownShow() {
    console.log('Click for scroll')
    setTimeout(() => {
      const panel = document.querySelector('.p-autocomplete-panel') as HTMLElement;
      if (panel) {
        panel.addEventListener('scroll', this.onScroll.bind(this));
      }
    }, 0);
  }

  getGroups() {
    this.mangoAPISrvc.showLoader(true);
    const parent = this;
    this.mangoAPISrvc.getGroups().subscribe(
      function (data: any) {
        if (data && data.length > 0) {
          parent.groupList = data
            .filter(item => !item['Inactive'])
            .map(item => {
              item['label'] = item['GroupDescription'];
              item['value'] = item['CustomerGroupCategoryID'];
              return item;
            });
        }
        // parent.encrDecSrvc.addObject(AppConstants.customerType, data);
        parent.mangoAPISrvc.showLoader(false);
      },
      error => {
        this.mangoAPISrvc.notify(
          'error',
          this._translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }
  ngOnInit(): void {
    this.companySetting = this.encrDecSrvc.getObject(AppConstants.systemLocking);
    this.getGroups();
    this.isAutoApply = this.encrDecSrvc.getObject(AppConstants.cashReceiptAutoApply);
    this.filterSubject.pipe(debounceTime(350)).subscribe((query) => {
      this.performFiltering(query);
    });
  }

  updateCombinedVisible() {
    this.combinedVisible = this.showCardoption && this.dialogDisplay;
  }

  setTabIndex(): void {
    setTimeout(() => {
      if (document.getElementById('selClient')?.children[0]?.children[0]) {
        (<HTMLElement>document.getElementById('selClient')?.children[0]?.children[0]).tabIndex = 1;
      }
      if (document.getElementById('selInvoiceNo')?.children[0]?.children[0]) {
        (<HTMLElement>(
          document.getElementById('selInvoiceNo')?.children[0]?.children[0]
        )).tabIndex = 1;
      }
      if (document.getElementById('selClientGroup')?.children[0]?.children[0]) {
        (<HTMLElement>(
          document.getElementById('selClientGroup')?.children[0]?.children[0]
        )).tabIndex = 1;
      }

      if (document.getElementById('paymentDate')?.children[0]?.children[0]) {
        (<HTMLElement>(
          document.getElementById('paymentDate')?.children[0]?.children[0]
        )).tabIndex = 2;
      }

      if (document.getElementById('paymentType')?.children[0].children[0].children[0]) {
        (<HTMLElement>(
          document.getElementById('paymentType')?.children[0].children[0].children[0]
        )).tabIndex = 3;
      }

      if (document.getElementById('paymentAmount')?.children[0]?.children[0]) {
        (<HTMLElement>(
          document.getElementById('paymentAmount')?.children[0]?.children[0]
        )).tabIndex = 4;
      }
    }, 300);
  }

  ngAfterViewInit() {
    this.setTabIndex();

    this.myAchReceiptsForm.valueChanges.subscribe(() => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.myAchReceiptsForm.pristine);
    });

    this.mycashReceiptsForm.valueChanges.subscribe(() => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.mycashReceiptsForm.pristine);
    });

    this.myPaymentForm.valueChanges.subscribe(() => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.myPaymentForm.pristine);
    });
  }

  fetchClients() {
    const list = this.encrDecSrvc.clientList || [];
  
    if (list.length === 0) {
      console.warn('Client List is empty or undefined');
      return;
    }
    const idSet = new Set(this.clientsList.map((client) => client.ClientID));
    
    const newClients = list.filter(
      (item) =>
        !idSet.has(item.ClientID) && 
        item['ContactRecord'] !== true 
    );
  
    this.clientsList.push(...newClients); 
    this.filteredClients = [...this.clientsList];
  }

  filterClients(event: { query: string }) {
    const query = event.query.toLowerCase();;
    if (query === '') { 
      this.filteredClients = this.allClients.slice(0, (this.currentBatch + 1) * this.batchSize);
      return;
    }
    this.filterSubject.next(query);
  }

  performFiltering( query: string) {
    const matchingClients = this.allClients
    .filter((client) => {
      const matchesName = client['ClientName'].toLowerCase().includes(query);
      const matchesNumber = client['ClientNumber']?.toLowerCase().includes(query);
      const isNotContactRecord = client['ContactRecord'] !== true;

      if (!isNotContactRecord) return false;
      if (this.isIncludeActive) {
        return matchesName || matchesNumber;
      } else {
        return (matchesName || matchesNumber) && client['Inactive'] !== true;
      }
    });
    this.filteredClients = matchingClients;
  }

  private filterTimeout1: any = null;
  private filterTimer1: any = timer(1000);
  filterGroups(event) {
    if (this.filterTimeout1) {
      this.filterTimeout1.unsubscribe();
    }
    this.filterTimeout1 = this.filterTimer1.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.groupList.length; i++) {
        const group = this.groupList[i];
        if (group["label"].toLowerCase().indexOf(query.toLowerCase()) > -1) {
          filtered.push(group);
        }
        if (filtered.length > 20) break;
      }
      this.filteredGroups = filtered;
      this.filterTimeout1.unsubscribe();
    });
  }

  getCompanyInfo() {
    const parent = this;
    parent.mangoAPISrvc.getCompanyMangoDetails(this.loginCompanyId).subscribe((response) => {
      parent.mangoCompanyData = response[0];
      parent.mangoAPISrvc
        .performStaxActions({ isFullData: false }, parent.mangoCompanyData.CompanyID, 'lrv')
        .subscribe(
          (response: any) => {
            if (
              response &&
              response.underwriting_status != 'PENDED' &&
              parent.mangoCompanyData.StaxStatus == 'ACTIVE'
            ) {
              parent.getPaymentMethods(true);
              parent.isSTaxEnabled = true;
            } else {
              parent.getPaymentMethods(false);
              if (
                response &&
                response.underwriting_status == 'PENDED' &&
                parent.staffPermission == 1
              ) {
                parent.isSTaxEnabled = false;
                Swal.fire({
                  title: parent._translate.instant('confirmation'),
                  html: `<div>Your MangoPayments enrollment has some underwriting issues that require immediate attention!</div>`,
                  icon: 'warning',
                  showCancelButton: true,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  confirmButtonText: 'Enroll Now',
                  cancelButtonText: 'Cancel'
                }).then(result => {
                  if (result.value) {
                    parent.mangoAPISrvc.isFormChanged = false;
                    parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
                    parent.router.navigate(['accounting/cards-view']);
                  }
                });
              } else {
                parent.isSTaxEnabled = false;
              }
            }
          },
          (err: any) => {
            parent.getPaymentMethods(false);
            parent.mangoAPISrvc.showLoader(false);
            parent.isProcessing = false;
            parent.isSTaxEnabled = false;
          }
        );

      parent.cardTypes = [
        { label: parent._translate.instant('VISA'), value: 'VISA' },
        { label: parent._translate.instant('MASTER CARD'), value: 'MSTR' },
        { label: parent._translate.instant('DISCOVER'), value: 'DISC' },
        { label: parent._translate.instant('AMERICAN EXPRESS'), value: 'AMEX' }
      ];
      // { label: 'ACH - Same Day', value: 'SDACH' },
      //parent.typeofPayments = [{ label: parent._translate.instant(' Check'), value: 'Check' }, { label: parent._translate.instant('Cash'), value: 'Cash' }, { label: parent._translate.instant('Credit Card'), value: 'Credit Card' }, { label: parent._translate.instant('Credit Card - Other'), value: 'Credit Card - Other' }, { label: 'ACH', value: 'ACH' }, { label: parent._translate.instant('EFT'), value: 'EFT' }, { label: parent._translate.instant('Write-Off'), value: 'Write-Off' }, { label: parent._translate.instant('Certified Check'), value: 'Certified Check' }, { label: parent._translate.instant('Money Order'), value: 'Money Order' }, { label: parent._translate.instant('Credit Memo'), value: 'Credit Memo' }, { label: parent._translate.instant('Refund Advance Payment'), value: 'Refund' }];
      // ,{ label: parent._translate.instant('Refund'), value: 'Refund' }

      parent.myAchReceiptsForm.controls['MerchantID'].setValue(
        parent.mangoCompanyData['CCmerchantID']
      );
      parent.myAchReceiptsForm.controls['Login'].setValue(parent.mangoCompanyData['CClogin']);
      parent.myAchReceiptsForm.controls['Password'].setValue(parent.mangoCompanyData['CCpassword']);
    });
  }

  initializeForm() {
    this.mycashReceiptsForm = this._fb.group({
      ClientID: ['', [<any>Validators.required]],
      // CompanyID: [''],
      // RetainerHeaderID: [''],
      CheckRef: ['', [<any>Validators.maxLength(25)]],
      PaymentDate: [new Date(), [<any>Validators.required]],
      PaymentType: ['', [<any>Validators.required]],
      PaymentAmount: ['', [<any>Validators.required, <any>Validators.maxLength(20)]],
      PaymentNote: ['', [<any>Validators.maxLength(500)]],
      PaymentUnapplied: ['']
    });
    this.mycashReceiptsForm.valueChanges.subscribe(data => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.mycashReceiptsForm.pristine);
      this.validateForm();
    });

    this.mycashReceiptsForm.get('PaymentUnapplied').valueChanges.subscribe(selectedValue => {
      if (selectedValue == 0) {
        this.items = [
          {
            label: this._translate.instant('save_and_split'),
            disabled: true,
            icon: 'fa fa-columns'
          }
        ];
      } else {
        this.items = [
          {
            label: this._translate.instant('save_and_split'),
            disabled: false,
            icon: 'fa fa-columns',
            command: () => {
              this.saveAndSplit();
            }
          }
        ];
      }
    });
  }

  getPaymentMethods(isStax) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getPaymentMethods(parent.loginCompanyId).subscribe(function (data: any) {
      const items = [];
      for (let i = 0; i < data.length; i++) {
        if (!isStax) {
          if (data[i].PaymentDescription != 'ACH' && data[i].PaymentDescription != 'Credit Card') {
            items.push({ label: data[i].PaymentDescription, value: data[i].PaymentDescription });
          }
        } else {
          items.push({ label: data[i].PaymentDescription, value: data[i].PaymentDescription });
        }
        //  else {
        //   if (parent.mangoCompanyData.modeOfPayment == 2 && (data[i].PaymentDescription == "ACH" || data[i].PaymentDescription == "Credit Card")) {
        //     items.push({ label: data[i].PaymentDescription, value: data[i].PaymentDescription });
        //   } else if (parent.mangoCompanyData.modeOfPayment == 1 && data[i].PaymentDescription == "ACH") {
        //     items.push({ label: data[i].PaymentDescription, value: data[i].PaymentDescription });
        //   } else if (parent.mangoCompanyData.modeOfPayment == 0 && data[i].PaymentDescription == "Credit Card") {
        //     items.push({ label: data[i].PaymentDescription, value: data[i].PaymentDescription });
        //   } else if (data[i].PaymentDescription != "ACH" && data[i].PaymentDescription != "Credit Card") {
        //     items.push({ label: data[i].PaymentDescription, value: data[i].PaymentDescription });
        //   }
        // }
      }
      parent.typeofPayments = items.sort(function (a, b) {
        return a.CompanyPaymentMethodID - b.CompanyPaymentMethodID;
      });
      parent.typeofPaymentsSearchByGroup = parent.typeofPayments.filter(
        item => !['ACH', 'Credit Card', 'Refund', 'Refund Advance Payment'].includes(item['value'])
      );
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  loadACHData(data) {
    this.isBusiness = 'false';
    this.myAchReceiptsForm.controls['RoutingNumber'].setValue('');
    this.myAchReceiptsForm.controls['AccountNumber'].setValue('');
    this.myAchReceiptsForm.controls['isBusiness'].setValue('false');
    this.myAchReceiptsForm.controls['Amount'].setValue('');
    this.myAchReceiptsForm.controls['FirstName'].setValue('');
    this.myAchReceiptsForm.controls['LastName'].setValue('');
    this.myAchReceiptsForm.controls['EmailAddress'].setValue(data.Email);
    this.myAchReceiptsForm.controls['Address1'].setValue(data.BusStreet1);
    this.myAchReceiptsForm.controls['City'].setValue(data.BusCity);
    this.myAchReceiptsForm.controls['State'].setValue(data.BusState);
    this.myAchReceiptsForm.controls['Zip'].setValue(data.BusZip);
  }

  /*
  initialize the dialog form
  */
  initializeDialogForm() {
    this.myPaymentForm = this._fb.group({
      nameOnCard: ['', [Validators.minLength(1)]],
      cardType: ['VISA'],
      cardno: ['', [Validators.minLength(16)]],
      expDate: ['', [Validators.minLength(5)]],
      securityCode: ['', [Validators.minLength(3)]],
      customerDesc: [''],
      firstName: [''],
      lastName: [''],
      address: [''],
      phoneNum: [''],
      city: [''],
      state: [''],
      zip: [''],
      country: [''],
      email: [''],
      company: [''],
      amount: [''],
      SurChargeAmount: [0],
      withSurcharge: [0],
      fname: [''],
      lname: ['']
    });
    this.myPaymentForm.valueChanges.subscribe(data => {
      this.validateDialogForm();
      this.isValidStaxForm();
    });
  }

  /*
  updateDialogForm form data
  */
  updateDialogForm() {
    this.myPaymentForm.controls['nameOnCard'].setValue('');
    this.myPaymentForm.controls['cardType'].setValue('');
    this.myPaymentForm.controls['cardno'].setValue('');
    this.myPaymentForm.controls['expDate'].setValue('');
    this.myPaymentForm.controls['securityCode'].setValue('');
    this.myPaymentForm.controls['customerDesc'].setValue('');
    this.myPaymentForm.controls['firstName'].setValue('');
    this.myPaymentForm.controls['lastName'].setValue('');
    this.myPaymentForm.controls['address'].setValue('');
    this.myPaymentForm.controls['phoneNum'].setValue('');
    this.myPaymentForm.controls['city'].setValue('');
    this.myPaymentForm.controls['state'].setValue('');
    this.myPaymentForm.controls['zip'].setValue('');
    this.myPaymentForm.controls['country'].setValue('');
    this.myPaymentForm.controls['email'].setValue('');
    this.myPaymentForm.controls['company'].setValue('');
    this.myPaymentForm.controls['amount'].setValue('');
    this.myPaymentForm.controls['SurChargeAmount'].setValue(0);
    this.myPaymentForm.controls['withSurcharge'].setValue(0);
  }

  /**
   * add validators for credit card form
   */
  public requiredcardFields = ['cardno', 'expDate', 'nameOnCard'];
  public cardFields = [
    'securityCode',
    'customerDesc',
    'firstName',
    'cardno',
    'expDate',
    'lastName',
    'address',
    'phoneNum',
    'city',
    'state',
    'zip',
    'country',
    'email',
    'company',
    'nameOnCard',
    'cardType',
    'amount'
  ];

  addCardValidators() {
    const fieldNames = this.cardFields;
    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i];
      if (this.requiredcardFields.indexOf(fieldName) > -1) {
        this.myPaymentForm.controls[fieldName].setValidators([Validators.required]);
      }
      if (fieldName == "company") {
        this.myPaymentForm.controls[fieldName].setValue(this.selectedCustomer["ClientName"]);
      } else if (fieldName == "amount") {
        const amt =
          "$" + numeral(this.mycashReceiptsForm.controls["PaymentAmount"].value).format("0,0.00");
        this.myPaymentForm.controls[fieldName].setValue(amt);
      } else {
        this.myPaymentForm.controls[fieldName].setValue(this.companyData[fieldName]);
      }
      this.myPaymentForm.get(fieldName).updateValueAndValidity();
    }
  }

  validateForm() {
    let isInValidData = false;
    let istouchedData = false;
    this.isCheckNo = false;
    //this.isFormValid = true;
    let validForm = true;
    const formData = this.mycashReceiptsForm.value;
    Object.keys(this.mycashReceiptsForm.controls).forEach((key) => {
      if (this.mycashReceiptsForm.get(key).dirty) {
        istouchedData = true;
      }
      if (this.mycashReceiptsForm.get(key).invalid) {
        if (key == 'ClientID' && this.isSearchByClient == 2) return;
        isInValidData = true;
      }
    });
    if (!isInValidData && numeral(formData.PaymentAmount).value() > 0) {
      validForm = true;
      if (formData.PaymentType == 'Write-Off' && formData.PaymentUnapplied === '') {
        validForm = false;
        this.isUnapplied = true;
      } else {
        validForm = true;
        this.isUnapplied = false;
      }
    } else {
      validForm = false;
    }

    if (!isInValidData && validForm) {
      validForm = true;
      if (formData.PaymentType == 'Check' && formData.CheckRef == '') {
        validForm = false;
        this.isCheckNo = true;
      } else if (formData.PaymentType == 'Credit Card - Other' && formData.PaymentAmount == 0) {
        validForm = false;
      } else {
        validForm = true;
        this.isCheckNo = false;
      }
    } else {
      validForm = false;
    }

    if (!this.globalValidForm) {
      validForm = false;
    }

    // Stax changes @ravi
    if (
      (formData.PaymentType == 'Credit Card' || formData.PaymentType == 'ACH') &&
      this.isSTaxEnabled &&
      !this.isRetainerApplied
    ) {
      validForm = false;
      if (this.transactionData || formData.PaymentAmount == 0) {
        this.isPaymentFlowRequired = true;
      } else {
        this.isPaymentFlowRequired = false;
      }
    }

    //
    if (
      this.isRetainerApplied &&
      this.openInvoicesDataSource.filter(invoices => invoices.selected == true).length == 0
    ) {
      validForm = false;
    }

    this.isFormValid = validForm;
  }

  /*
  Update form data
  */
  updateForm(isFormReset) {
    this.mycashReceiptsForm.controls['ClientID'].setValue('');
    this.mycashReceiptsForm.controls['PaymentUnapplied'].setValue('0.00');
    this.mycashReceiptsForm.controls['PaymentAmount'].setValue('$0.00');
    this.mycashReceiptsForm.controls['CheckRef'].setValue('');
    //this.mycashReceiptsForm.controls['PaymentType'].setValue('');
    this.mycashReceiptsForm.controls['PaymentNote'].setValue('');
    this.openInvoicesDataSource = [];
    this.openRetainersDataSource = [];
    this.isEditMode = false;
    this.isPaymentApplied = false;
    this.isRetainerApplied = false;
    this.selectedValue = null;
    this.selectedRowValue = null;
    this.isCheckRef = false;
    this.isValidTaxAmt = false;
    this.isFormDisble = false;
    this.isUnapplied = false;
    this.paymentHeaderID = '';
    this.selectedCustomer = '';
    this.totalNumberPayments = 0;
    if (isFormReset) {
      this.client = '';
      this.selClient = '';
      this.selInvoiceNo = '';
      this.selClientGroup = null;
      //this.mycashReceiptsForm.controls['PaymentDate'].setValue(new Date());
    }
  }

  closeCreditCardForm() {
    this.dialogDisplay = false;
    this.showACHDialog = false;
    this.dialogWidth = '30vw';
    this.isNewCpaPay = false;
    this.isLexcharge = false;
    this.counter = 0;
    this.updateCombinedVisible();
    //this.paymentProfile = null;
    //  let self = this;
    // self.showCardoption = false;
    // self.paymentProfile = null;
    // self.mycashReceiptsForm.controls['PaymentType'].setValue(null);

    // setTimeout(() => {
    //   self.showCardoption = false;
    // }, 200);
  }

  removeCardValidators() {
    const fieldNames = this.cardFields;
    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i];
      this.myPaymentForm.controls[fieldName].setValidators([]);
      this.myPaymentForm.get(fieldName).updateValueAndValidity();
    }
  }

  /*
  Verifing the form
  */
  validateDialogForm() {
    let isInValidData = false;
    let istouchedData = false;
    Object.keys(this.myPaymentForm.controls).forEach((key) => {
      if (this.myPaymentForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.myPaymentForm.get(key).dirty) {
        istouchedData = true;
      }
    });
    if (!isInValidData && this.myPaymentForm.dirty) {
      this.isDialogFormValid = true;
    } else {
      this.isDialogFormValid = false;
    }
  }

  getShortCutLabels() {
    const parent = this;

    parent.mycashReceiptsForm.valueChanges.subscribe(data => {
      data.PaymentNote = parent.replaceShortcuts(data.PaymentNote);
      parent.mycashReceiptsForm.controls['PaymentNote'].setValue(data.PaymentNote, {
        emitEvent: false
      });
    });
  }

  replaceShortcuts(value) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut['ShortCutCode']) {
          label = shortcut['Phrase'];
        }
      }
      valueArr[i] = label;
    }
    return valueArr.join(' ');
  }

  resetToDefaultValues() {
    setTimeout(() => {
      const formObj = this.mycashReceiptsForm.value;
      if (!this.isSaveAndSplitClicked) {
        this.totalNumberPayments = 0;
        this.mycashReceiptsForm.reset();
        this.updateForm(true);
        this.mangoAPISrvc.isFormChanged = false;
        sessionStorage.removeItem('unSavedCashReceipts');
        this.selectedPaymentDate = null;
      } else {
        this.mycashReceiptsForm.controls['ClientID'].setValue('');
        this.openInvoicesDataSource = [];
        this.openRetainersDataSource = [];
        this.client = '';
        this.selClient = '';
        this.selInvoiceNo = '';
        this.isEditMode = false;
        this.isPaymentApplied = false;
        this.isRetainerApplied = false;
        this.selectedValue = null;
        this.selectedRowValue = null;
        this.isCheckRef = false;
        this.isValidTaxAmt = false;
        this.isFormDisble = false;
        this.isUnapplied = false;
        this.paymentHeaderID = '';
        this.selectedCustomer = '';
        this.totalNumberPayments = 0;
        this.counter = 0;
        this.isNewCpaPay = false;
        this.dialogWidth = '30vw';
      }
      this.mycashReceiptsForm.controls['PaymentDate'].setValue(new Date(formObj['PaymentDate']));
      this.mycashReceiptsForm.controls['PaymentType'].setValue(''); //formObj['PaymentType']
      this.isFormValid = false;
      this.isClientSelected = false;
      this.isPaymentFlowRequired = false;
      this.mangoAPISrvc.showLoader(false);
    }, 3000);
  }

  initializeACHForm() {
    const parent = this;
    parent.myAchReceiptsForm = parent._fb.group({
      MerchantID: [''],
      Login: [''],
      Password: [''],
      RoutingNumber: ['', [<any>Validators.required]],
      AccountNumber: ['', [<any>Validators.required]],
      TransCode: [null, [<any>Validators.required]],
      Amount: ['', [<any>Validators.required]],
      FirstName: ['', [<any>Validators.required]],
      LastName: ['', [<any>Validators.required]],
      EmailAddress: ['', [<any>Validators.required]],
      Address1: ['', [<any>Validators.required]],
      City: ['', [<any>Validators.required]],
      State: ['', [<any>Validators.required]],
      Zip: ['', [<any>Validators.required]],
      Country: ['US'],
      StandardEntryCode: ['WEB'],
      Description: ['ACH'],
      CheckNegativeAccounts: [false],
      TokenizeOnly: [false],
      isBusiness: ['false']
    });

    this.myAchReceiptsForm.valueChanges.subscribe(data => {
      this.isValidStaxForm();
    });
  }

  loadCompanyInfo(data) {
    const self = this;
    self.companyData = {
      address: data['BusStreet1'],
      city: data['BusCity'],
      state: data['BusState'],
      zip: data['BusZip'],
      country: data['Country'],
      phoneNum: data['Office'],
      email: data['Email'],
      company: data['ClientName']
    };
  }

  cardTypeChange() {
    const formData = this.myPaymentForm.value;
    this.isVisaCard = formData.cardType != "AMEX" ? "true" : "false";
  }

  switchSearch(value) {
    if (this.transactionData) {
      return false;
    }
    this.setTabIndex();
    this.isSearchByClient = value;
    // if(this.isSearchByClient > 2 || this.isSearchByClient < 0) this.isSearchByClient = 0;
    this.resetToDefaultValues();
    if (this.isSearchByClient == 0) {
      this.searchSrtring = 'Search by Invoice';
      this.labelStr = 'Client Name';
    } else if (this.isSearchByClient == 1) {
      this.searchSrtring = 'Search by Group';
      this.labelStr = 'Invoice No #';
    } else if (this.isSearchByClient == 2) {
      Swal.fire({
        icon: 'warning',
        title: this._translate.instant('Warning'),
        html: 'ACH, Credit-Card and Refund payment are not allowed in search by group.',
        showConfirmButton: true,
        timer: 5000
      });
      this.searchSrtring = 'Search by Client';
      this.labelStr = 'Client Group';
    }
  }

  processAchCard() {
    const parent = this;
    const formData = parent.myAchReceiptsForm.value;
    formData["LastName"] = formData["FirstName"];
    formData["Amount"] = numeral(formData["Amount"]).value();

    const formObj = this.mycashReceiptsForm.value;
    formObj["PaymentAmount"] = numeral(formObj["PaymentAmount"]).value();

    if (
      parent.achProfile &&
      parent.achProfile.TransType == 'ACH' &&
      parent.achProfile.TransactionReference != null &&
      !parent.showAchNewCardoption
    ) {
      const achPayment = {};
      achPayment["MerchantID"] = parent.mangoCompanyData["CCmerchantID"];
      achPayment["Login"] = parent.mangoCompanyData["CClogin"];
      achPayment["Password"] = parent.mangoCompanyData["CCpassword"];
      achPayment["Amount"] = formObj["PaymentAmount"];
      achPayment["Token"] = parent.achProfile.TransactionReference;
      achPayment["CheckNegativeAccounts"] = false;
      achPayment["StandardEntryCode"] = "WEB";

      // if (formObj.PaymentType == "SDACH") {
      //   achPayment['SameDayACH'] = 'SD';
      // }
      parent.mangoAPISrvc.showLoader(true);
      if (parent.isCpaPay) {
        const typeOfTransaction = formData.PaymentType == "Credit Card" ? "CC" : "ACH";
        // process a payment and enter all card information
        const obj = {};
        obj["uniqueId"] = parent.selectedCustomer.ClientID.toString() + "$" + typeOfTransaction;
        obj["Token"] = achPayment["Token"];

        obj['Amount'] = parseFloat(achPayment['Amount']).toFixed(2);
        obj['LexOperatingMerchantAccountId'] =
          parent.mangoCompanyData['LexOperatingMerchantAccountId'];
        obj['LexAccessToken'] = parent.mangoCompanyData['LexAccessToken'];

        parent.mangoAPISrvc.processLexPaymentsWithToken(obj).subscribe(
          (result: any) => {
            if (parent.achProfile && parent.achProfile.NameOnCard && parent.achProfile.CardNo) {
              const note =
                "Paid By: " +
                parent.achProfile.NameOnCard +
                ', ' +
                'using ACH:  ****' +
                parent.achProfile.CardNo +
                ' (CR)';
              parent.mycashReceiptsForm.controls['PaymentNote'].setValue(note);
            }
            parent.mangoAPISrvc.isFormChanged = true;
            parent.showACHDialog = false;
            parent.isDialogFormValid = false;
            parent.mangoAPISrvc.showLoader(false);
            Swal.fire({
              icon: 'success',
              title: `${parent._translate.instant('Success')}!`,
              showCancelButton: false,
              allowEscapeKey: true,
              allowEnterKey: true,
              confirmButtonText: 'OK',
              text: `${parent._translate.instant('transaction-processed-successfully')}!`
            });
          },
          error => {
            parent.showACHDialog = false;
            parent.isDialogFormValid = false;
            parent.mangoAPISrvc.showLoader(false);
          }
        );
      } else {
        // process a tokenized payment
        parent.mangoAPISrvc.tokenizedPaymentsACHCard(achPayment).subscribe((data: any) => {
          if (data.Status == 'success') {
            parent.showACHDialog = false;
            parent.showAchNewCardoption = false;
            parent.mangoAPISrvc.notify('success', 'Success!', 'Successfully Processed.');
            parent.mangoAPISrvc.showLoader(false);
          }
        });
      }
    } else {
      formData['TokenizeOnly'] = true;
      // if (formObj.PaymentType == "SDACH") {
      //   formData['SameDayACH'] = 'SD';
      // }
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.createACHCard(formData).subscribe(
        (data: any) => {
          if (data.Status == 'success') {
            // reference api used for creating profile for CC transactions
            // TransType = ACH or for Credit Card, TransType = CC  go back and update the Create for Cc
            // if ACH, need to use last 4 for BankAcctNo.
            const cashformObj = parent.mycashReceiptsForm.value;
            const obj = {};
            obj["TransactionReference"] = data.Confirmation;
            obj["TransType"] = "ACH";
            obj["BankAcctNo"] = formData["AccountNumber"].substr(
              formData["AccountNumber"].length - 4,
              formData["AccountNumber"].length
            );
            obj['ClientID'] = cashformObj['ClientID'];
            obj['CompanyID'] = this.companyIdRef;
            obj['active'] = true;
            obj['nameOnCard'] = formData['FirstName'];

            parent.mangoAPISrvc.saveProfileIDinMango(obj).subscribe(
              mangodata => {
                // Call tokenize API

                const achPayment = {};
                achPayment["MerchantID"] = parent.mangoCompanyData["CCmerchantID"];
                achPayment["Login"] = parent.mangoCompanyData["CClogin"];
                achPayment["Password"] = parent.mangoCompanyData["CCpassword"];
                achPayment["Amount"] = formObj["PaymentAmount"];
                achPayment["Token"] = data.Confirmation;
                achPayment["CheckNegativeAccounts"] = false;
                achPayment["StandardEntryCode"] = "WEB";
                // if (formObj.PaymentType == "SDACH") {
                //   achPayment['SameDayACH'] = 'SD';
                // }
                parent.mangoAPISrvc
                  .tokenizedPaymentsACHCard(achPayment)
                  .subscribe((tokendata: any) => {
                    parent.mangoAPISrvc.showLoader(false);
                    if (tokendata.Status == 'success') {
                      parent.showACHDialog = false;
                      parent.showAchNewCardoption = false;
                      parent.mangoAPISrvc.notify('success', 'Success!', 'Successfully Processed.');
                    }
                  });
              },
              error => {
                parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          } else {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          }
        },
        error => {
          parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    }
  }

  onAutoCompleteBlur() {
    this.clientAutoCompleteDisable = true;
    setTimeout(() => (this.clientAutoCompleteDisable = false), 1000);
  }

  onClearClick() {
    this.isSaveAndSplitClicked = false;
    this.resetToDefaultValues();
  }

  saveConfirmationAction(clicked) {
    // this.saveDataByClientGroup();
    // return
    if (clicked) {
      this.isSaveAndSplitClicked = false;
    }
    const formObj = this.mycashReceiptsForm.value;
    const parent = this;
    //parent.isFormValid = false;
    parent.mangoAPISrvc.showLoader(true);
    // setTimeout(() => {
    //   parent.mangoAPISrvc.showLoader(false)
    // }, 1000);
    const selectedInvoices = this.openInvoicesDataSource.filter(
      (invoice) => invoice.selected == true
    );
    if (
      formObj.PaymentType == "Write-Off" &&
      selectedInvoices.some((invoice) => invoice.InvoiceType === "Retainer Invoice")
    ) {
      parent.mangoAPISrvc.showLoader(false);

      Swal.fire({
        icon: "warning",
        title: `${parent._translate.instant("warning")}!`,
        text: parent._translate.instant("cash_receipts.cannot_apply_writeoff_to_retainer_invoice"),
        showConfirmButton: true,
      });
      return false;
    }

    if (
      formObj.PaymentType == "Credit Memo" &&
      (formObj.PaymentNote == "" || formObj.PaymentNote == undefined || formObj.PaymentNote == null)
    ) {
      parent.mangoAPISrvc.showLoader(false);

      Swal.fire({
        icon: "warning",
        title: `${parent._translate.instant("warning")}!`,
        text: parent._translate.instant("credit-memo-requires-memo-description"),
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }

    if (formObj.PaymentType == "Write-Off" && formObj["PaymentUnapplied"] > 0) {
      parent.mangoAPISrvc.showLoader(false);

      Swal.fire({
        icon: "warning",
        title: `${parent._translate.instant("warning")}!`,
        text: parent._translate.instant("write-off_unapplied-warning"),
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }

    if (
      this.isSearchByClient != 2 &&
      (formObj.PaymentType != "Refund" || formObj.PaymentType != "Refund Advance Payment") &&
      parent.openInvoicesDataSource.length > 0
    ) {
      parent.mangoAPISrvc.showLoader(false);
      if (
        parent.openInvoicesDataSource.filter((invoices) => invoices.selected == true).length == 0 &&
        !parent.selectedCustomer["MarkDepositApplied"]
      ) {
        Swal.fire({
          title: parent._translate.instant("confirmation"),
          html: `<div>${parent._translate.instant(
            "there-are-open-invoices"
          )}.</div><div>${parent._translate.instant("is-this-advance-payment")}</div>`,
          icon: "warning",
          showCancelButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: parent._translate.instant("yes_continue"),
          cancelButtonText: "No",
        }).then((result) => {
          parent.mangoAPISrvc.showLoader(true);

          if (result.value) {
            if (this.isSearchByClient != 2) parent.saveData();
            else parent.saveDataByClientGroup();
            if (formObj.PaymentType == "Credit Memo") {
              parent.mangoAPISrvc.showLoader(false);
              Swal.fire({
                title: parent._translate.instant("confirmation"),
                html: `<div>${parent._translate.instant("CashReceipts.print-credit-memo")}?</div>`,
                icon: "warning",
                showCancelButton: true,
                allowEscapeKey: false,
                allowEnterKey: false,
                confirmButtonText: parent._translate.instant("yes_print_credit_memo"),
                cancelButtonText: "No",
              }).then((result) => {
                if (result.value) {
                  const url = new URL(`${encodeURIComponent('/home/tim/Credit Memo.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);
                  url.searchParams.set('ClientID', formObj.ClientID);
                  url.searchParams.set('PaymentHeaderID', parent.paymentHeaderIdRef);

                  const newWindow = parent.nativeWindow.open(url.href);
                  newWindow.location = url.href;
                }
              });
            }
          } else {
            parent.mangoAPISrvc.showLoader(false);
          }
        });
      } else {
        if (this.isSearchByClient != 2) parent.saveData();
        else parent.saveDataByClientGroup();
      }
    } else {
      parent.mangoAPISrvc.showLoader(true);

      if (this.isSearchByClient != 2) parent.saveData();
      else parent.saveDataByClientGroup();
      if (formObj.PaymentType == "Credit Memo") {
        Swal.fire({
          title: parent._translate.instant("confirmation"),
          html: `<div>${parent._translate.instant("CashReceipts.print-credit-memo")}?</div>`,
          icon: "warning",
          showCancelButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: parent._translate.instant("yes_print_credit_memo"),
          cancelButtonText: "No",
        }).then((result) => {
          if (result.value) {
            const url = new URL(`${encodeURIComponent('/home/tim/Credit Memo.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);
            url.searchParams.set('ClientID', formObj.ClientID);
            url.searchParams.set('PaymentHeaderID', parent.paymentHeaderIdRef);

            const newWindow = parent.nativeWindow.open(url.href);
            newWindow.location = url.href;
          }
        });
      }
    }
    setTimeout(() => {
      parent.mangoAPISrvc.showLoader(false);
    }, 1000);

    // parent.mangoAPISrvc.showLoader(false);
  }

  saveDataByClientGroup() {
    const formObj = this.mycashReceiptsForm.value;
    if (formObj["PaymentUnapplied"] > 0 || !this.isPaymentApplied) {
      this.mangoAPISrvc.showLoader(false);

      Swal.fire({
        icon: 'warning',
        title: `${this._translate.instant('warning')}!`,
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: 'OK',
        text: this._translate.instant('Payment Amount must be applied.')
      });
      return;
    }
    const groupByClient = this.openInvoicesDataSource
      .filter((item) => item["selected"])
      .reduce(function (r, a) {
        r[a.ClientID] = r[a.ClientID] || [];
        r[a.ClientID].push(a);
        return r;
      }, Object.create(null));
    for (const [clientId, clientOpenInvoices] of Object.entries(groupByClient)) {
      const client = this.clientsList.find((c) => c["ClientID"] == clientId);
      const clientPaymentsApplied = this.openInvoicesDataSource
        .filter((item) => item["ClientID"] == clientId)
        .reduce((a, b) => a + (numeral(b["PaymentsApplied"]).value() || 0), 0);
      // console.log("----")
      // console.log("client id:", clientId)
      // console.log("client: ", client)
      // console.log("payments applied: ", clientPaymentsApplied)
      // console.log("open invoices:", clientOpenInvoices)
      formObj['PaymentAmount'] = clientPaymentsApplied;
      formObj['ClientID'] = clientId;
      this.saveData(formObj, client, clientOpenInvoices);
    }
  }

  saveData(formObjFromClient?, clientFromGroup?, openInvoiceFromClient?) {
    const paymentFormData = this.myPaymentForm.value;
    const localOpenInvoices = openInvoiceFromClient || this.openInvoicesDataSource;
    const localClient = clientFromGroup || this.selectedCustomer;

    const parent = this;
    const formObj = formObjFromClient || this.mycashReceiptsForm.value;
    this.isPaymentFlowRequired = false;
    parent.encrDecSrvc.addObject(AppConstants.cashReceiptAutoApply, parent.isAutoApply);
    parent.encrDecSrvc.addObject(AppConstants.cashReceiptPaymentType, formObj['PaymentType']);

    formObj["PaymentAmount"] = numeral(formObj["PaymentAmount"]).value();
    const frmPaymentAmount = numeral(formObj["PaymentAmount"]).value();
    formObj["PaymentUnapplied"] = numeral(formObj["PaymentUnapplied"]).value();
    parent.isProcessing = false;

    localClient['MarkDepositApplied'] = localClient['MarkDepositApplied']
      ? localClient['MarkDepositApplied']
      : false;
    // var localTime = moment.utc(formObj["PaymentDate"]).toDate();
    // formObj["PaymentDate"] = moment(localTime).format("YYYY-MM-DD HH:mm:ss");

    const selectedData = localOpenInvoices.filter(
      invoice => invoice.InvoiceType === 'Retainer Invoice' && invoice.selected == true
    );
    if (selectedData.length > 0 && formObj.PaymentUnapplied > 0) {
      parent.mangoAPISrvc.showLoader(false);

      Swal.fire({
        icon: 'warning',
        title: `${parent._translate.instant('warning')}!`,
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: 'OK',
        text: parent._translate.instant('cash-receipts.Retainer-with-unapplied-warning')
      });
      return false;
    }
    // Handle Refund of Advance Payment
    if (formObj.PaymentType == 'Refund' || formObj.PaymentType == 'Refund Advance Payment') {
      // Update Selected Payment Header Record from table
      parent.selectedRowValue.PaymentUnapplied = numeral(
        parent.selectedRowValue.PaymentUnapplied
      ).value();
      formObj.PaymentUnapplied = parent.selectedRowValue.PaymentUnapplied - formObj.PaymentAmount;
      formObj['PaymentAmount'] = numeral(parent.selectedRowValue.PaymentAmount).value();
      formObj['LastModifiedStaffID'] = parent.encrDecSrvc.getObject(AppConstants.staffID);
      formObj['SurChargeAmount'] = paymentFormData['SurChargeAmount']
        ? paymentFormData['SurChargeAmount']
        : 0;
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc
        .updatePaymentHeader(formObj, parent.selectedRowValue.PaymentHeaderID)
        .subscribe(
          data => {
            // get the billing Header record by BillingHeaderID in the PaymentHeader
            parent.mangoAPISrvc
              .getBillingHeaderById(parent.selectedRowValue.BillingHeaderID)
              .subscribe(
                (itemData: any) => {
                  // Update the BillingHeader record to update InvoiceBalance = 0

                  itemData.TotalPayments =
                    numeral(itemData.TotalPayments).value() + frmPaymentAmount;
                  itemData.InvoiceBalance =
                    numeral(itemData.InvoiceAmount).value() + itemData.TotalPayments;
                  itemData.LastModifiedStaffID = parent.encrDecSrvc.getObject(AppConstants.staffID);
                  itemData['SurChargeAmount'] = paymentFormData['SurChargeAmount']
                    ? paymentFormData['SurChargeAmount']
                    : 0;
                  // Tim - Need to add an If PaymentType=Refund then InvoiceBalance = 0
                  parent.mangoAPISrvc
                    .updateBillingHeader(itemData, itemData.BillingHeaderID)
                    .subscribe(
                      tempdata => {
                        // Create a Payment Detail Record with reference to BillingHeaderID and PaymentHeaderID
                        formObj.PaymentNote = formObj.PaymentNote ? formObj.PaymentNote : "";
                        const PaymentDetailEntity = {};
                        PaymentDetailEntity["PaymentHeaderID"] =
                          parent.selectedRowValue.PaymentHeaderID;
                        PaymentDetailEntity['BillingHeaderID'] =
                          parent.selectedRowValue.BillingHeaderID;
                        PaymentDetailEntity['PaymentDate'] = formObj['PaymentDate'];
                        PaymentDetailEntity['InvoiceNumber'] =
                          parent.selectedRowValue.InvoiceNumber;
                        PaymentDetailEntity['InvoiceDate'] = formObj['PaymentDate'];
                        PaymentDetailEntity['PaymentType'] = formObj.PaymentType;
                        PaymentDetailEntity['PaymentNote'] =
                          'Refund Processed. ' + formObj.PaymentNote;
                        PaymentDetailEntity['AppliedAmount'] = frmPaymentAmount;
                        PaymentDetailEntity['InitialAmount'] = frmPaymentAmount;
                        PaymentDetailEntity['PaymentAmount'] = frmPaymentAmount;
                        PaymentDetailEntity['ClientID'] = formObj['ClientID'];
                        parent.mangoAPISrvc.showLoader(false);

                        // create payment details
                        parent.mangoAPISrvc.createPaymentDetails(PaymentDetailEntity).subscribe(
                          data => {
                            parent.mangoAPISrvc.showLoader(false);
                            parent.mangoAPISrvc.notify(
                              'success',
                              'Success!',
                              AppConstants.createMsg
                            );
                            parent.resetToDefaultValues();
                          },
                          error => {
                            parent.mangoAPISrvc.notify(
                              'error',
                              'Error!',
                              AppConstants.updateErrorMsg
                            );
                            parent.mangoAPISrvc.showLoader(false);
                          }
                        );
                      },
                      error => {
                        parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
                        parent.mangoAPISrvc.showLoader(false);
                      }
                    );
                },
                error => {
                  parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
                  parent.mangoAPISrvc.showLoader(false);
                }
              );
          },
          error => {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          }
        );
    }
    // Advance Payment Only.  No Payment Applied and No Retainer Applied - Create a Retainer
    else if (
      !this.isPaymentApplied &&
      !this.isRetainerApplied &&
      !localClient['MarkDepositApplied'] &&
      !this.isSaveAndSplitClicked
    ) {
      // Build new record in billing header
      const newHeader = {};
      newHeader["ClientID"] = formObj["ClientID"];
      newHeader["InvoiceDate"] = formObj["PaymentDate"];
      newHeader["InvoiceAmount"] = formObj["PaymentUnapplied"] * -1;
      newHeader["TotalServices"] = newHeader["InvoiceAmount"];
      newHeader["InvoiceBalance"] = newHeader["InvoiceAmount"];
      newHeader["PaymentsApplied"] = 0;
      newHeader["TotalStaffCost"] = 0;
      newHeader["TotalPayments"] = 0;
      newHeader["InvoicePosted"] = true;

      newHeader['CreatedbyStaffID'] = this.encrDecSrvc.getObject(AppConstants.staffID);
      newHeader['BillingPartnerID'] = localClient['BillingPartnerID'];
      newHeader['OriginatingPartnerID'] = localClient['OriginatingPartnerID'];
      newHeader['StaffAssignedID'] = localClient['StaffAssignedID'];
      newHeader['GroupDescriptionID'] = localClient['GroupDescriptionID'];
      newHeader['GroupDescriptionIDArray'] = localClient['GroupDescriptionIDArray'];
      newHeader['SurChargeAmount'] = paymentFormData['SurChargeAmount']
        ? paymentFormData['SurChargeAmount']
        : 0;

      if (formObj.PaymentType == 'Credit Memo') {
        newHeader['InvoiceType'] = 'Credit Memo';
        newHeader['DescriptionShort'] = 'Credit Memo';
      } else {
        newHeader['InvoiceType'] = 'Retainer';
        newHeader['DescriptionShort'] =
          'Advance Payment' + (formObj['PaymentNote'] ? ` - ${formObj['PaymentNote']}` : '');
        const selectedData = this.openInvoicesDataSource.filter(
          invoice => invoice.selected == true
        );
        for (let i = 0; i < selectedData.length; i++) {
          if ((i = selectedData.length - 1)) {
            newHeader['DescriptionShort'] += selectedData[i].DescriptionShort;
          } else {
            newHeader['DescriptionShort'] += selectedData[i].DescriptionShort + ' | ';
          }
        }
        //newHeader['DescriptionShort'] = "Advance Payment - "+ formObj;
      }
      parent.mangoAPISrvc.showLoader(true);
      // No Payment Applied and No Retainer Applied - Create advance Payment
      // Create billing Header
      parent.mangoAPISrvc.createBillingHeader(newHeader).subscribe(
        (data: any) => {
          // Build new paymentHeader record
          const billingData = data.data;
          // formObj['BillingHeaderID'] = data.data.BillingHeaderID;
          formObj['PaymentAmount'] = formObj['PaymentUnapplied'];
          formObj['SelectedForDepositToBank'] = false;
          // Need to mark PaymentHeader if Credit Memo so it does not show up for deposit
          if (formObj.PaymentType == 'Credit Memo') {
            formObj['SelectedForDepositToBank'] = true;
          }
          let note;
          formObj['StaxID'] = parent.transactionData ? parent.transactionData.id : null;
          if (parent.transactionData) {
            if (parent.transactionData.payment_method) {
              note = `Paid : ${parent.transactionData.payment_method.nickname}`;
            } else if (
              this.transactionData.child_transactions &&
              this.transactionData.child_transactions.length > 0
            ) {
              note = `Paid : ${parent.transactionData.child_transactions[0].payment_method.nickname}`;
            }
            formObj.PaymentNote =
              (newHeader['PaymentType'] == 'Credit Card' ? note + ' (CR)' : note + ') (CR)') +
              (formObj['PaymentNote'] ? `; ${formObj['PaymentNote']}` : '');
            if (formObj.PaymentType == 'Credit Card' && parent.isDebitCard) {
              newHeader['PaymentType'] = 'Debit card';
            }
          }

          formObj['CreatedbyStaffID'] = parent.encrDecSrvc.getObject(AppConstants.staffID);
          formObj['SurChargeAmount'] = paymentFormData['SurChargeAmount']
            ? paymentFormData['SurChargeAmount']
            : 0;

          // Create PaymentHeader
          parent.mangoAPISrvc.createPaymentHeader(formObj).subscribe(
            (data: any) => {
              //Create PaymentDetail
              const PaymentDetailEntity = {};
              PaymentDetailEntity["PaymentHeaderID"] = data.data.PaymentHeaderID;
              PaymentDetailEntity["BillingHeaderID"] = billingData.BillingHeaderID;
              PaymentDetailEntity["ParentHeaderID"] = null;
              PaymentDetailEntity["InvoiceNumber"] = billingData.InvoiceNumber;
              PaymentDetailEntity["InvoiceDate"] = billingData.InvoiceDate;
              PaymentDetailEntity["PaymentType"] = data.data.PaymentType;
              PaymentDetailEntity["PaymentDate"] = formObj["PaymentDate"];
              PaymentDetailEntity["isAdvancePayment"] = true;
              PaymentDetailEntity["PaymentNote"] =
                "Advance Payment" + (formObj["PaymentNote"] ? ` - ${formObj["PaymentNote"]}` : "");
              PaymentDetailEntity["ClientID"] = formObj["ClientID"];
              PaymentDetailEntity["CheckRef"] = formObj["CheckRef"];

              if (numeral(data.data.PaymentAmount).value() != 0) {
                PaymentDetailEntity['AppliedAmount'] = numeral(data.data.PaymentAmount).value();
                PaymentDetailEntity['InitialAmount'] = numeral(data.data.PaymentAmount).value();
                PaymentDetailEntity['PaymentAmount'] = numeral(data.data.PaymentAmount).value();
              }
              parent.mangoAPISrvc.showLoader(false);

              // create payment details
              parent.mangoAPISrvc.createPaymentDetails(PaymentDetailEntity).subscribe(
                data => {
                  setTimeout(() => {
                    parent.transactionData = null;
                    parent.paymentHeaderIdRef = data['data']['PaymentHeaderID'];
                    parent.sendMailToCustmor(
                      formObj['PaymentAmount'],
                      data['data']['PaymentHeaderID'],
                      localClient,
                      formObj['PaymentNote']
                    );
                    parent.sendToCompany(
                      formObj,
                      localClient,
                      this.openInvoicesDataSource.filter(invoice => invoice.selected == true)
                    );
                    parent.resetToDefaultValues();
                    parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
                  }, 500);
                },
                error => {
                  parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
                  parent.mangoAPISrvc.showLoader(false);
                }
              );
            },
            error => {
              parent.mangoAPISrvc.notify('error', 'Error!', error);
              parent.mangoAPISrvc.showLoader(false);
            }
          );
        },
        error => {
          parent.mangoAPISrvc.notify('error', 'Error!', error);
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else if (!parent.isPaymentApplied && parent.isRetainerApplied) {
      parent.mangoAPISrvc.notify('error', 'Error!', 'Nothing has been applied to open invoices');
      // Handle an Open Retainer Applied to an Open Invoice
    } else if (parent.isPaymentApplied && parent.isRetainerApplied) {
      formObj["NSFtrue"] = false;
      // var localTime = moment.utc(parent.selectedPaymentDate).toDate();
      // formObj["PaymentDate"] = moment(localTime).format("YYYY-MM-DD HH:mm:ss");
      formObj["LastModifiedStaffID"] = parent.encrDecSrvc.getObject(AppConstants.staffID);
      formObj["SurChargeAmount"] = paymentFormData["SurChargeAmount"]
        ? paymentFormData["SurChargeAmount"]
        : 0;

      parent.mangoAPISrvc.showLoader(true);
      const selectedInvoices = this.openInvoicesDataSource.filter((x) => x.selected == true);
      parent.mangoAPISrvc
        .handleOpenRetainer(formObj, parent.paymentHeaderID, selectedInvoices)
        .subscribe(
          (newdata: any) => {
            setTimeout(() => {
              parent.resetToDefaultValues();
              parent.mangoAPISrvc.showLoader(false);
              parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
            }, 500);
          },
          err => {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.createErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          }
        );

      // // updating the paymentHeader table
      // parent.mangoAPISrvc.updatePaymentHeader(formObj, parent.paymentHeaderID).subscribe((itemdata) => {
      //   //  to get the billing Header id from paymentHeaderID
      //   parent.mangoAPISrvc.getPaymentHeaderById(parent.paymentHeaderID).subscribe((newdata: any) => {
      //     if (newdata.BillingHeaderID) {
      //       // get the billing Header record by BillingHeaderID in the PaymentHeader
      //       parent.mangoAPISrvc.getBillingHeaderById(newdata.BillingHeaderID).subscribe((data: any) => {
      //         //  Need to see if there is still a credit balance -
      //         if (formObj['PaymentUnapplied'] != 0) {
      //           data.InvoiceAmount = numeral(formObj['PaymentUnapplied']).value() * -1
      //           data.InvoiceBalance = numeral(data.InvoiceAmount).value();
      //           data.LastModifiedStaffID = parent.encrDecSrvc.getObject(AppConstants.staffID);
      //           data["SurChargeAmount"] = paymentFormData['SurChargeAmount'] ? paymentFormData['SurChargeAmount'] : 0;

      //           // Tim - Need to add an If PaymentType=Refund subscribe InvoiceBalance = 0
      //           parent.mangoAPISrvc.updateBillingHeader(data, data.BillingHeaderID).subscribe((data) => {
      //             setTimeout(() => {
      //               parent.resetToDefaultValues();
      //               parent.mangoAPISrvc.showLoader(false);
      //             }, 500);
      //           })
      //         } else {
      //           // No credit balance so delete the Advance Payment BillingHeader record
      //           // delete row from billing header table
      //           parent.mangoAPISrvc.deleteBillingHeader(data.BillingHeaderID).subscribe((data) => {
      //             //this.notificationsService.notify('success', this._translate.instant(data.message));
      //             parent.mangoAPISrvc.showLoader(false);
      //           }, error => {
      //             parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      //             parent.mangoAPISrvc.showLoader(false);
      //           })
      //         }
      //       }, error => {
      //         parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      //         parent.mangoAPISrvc.showLoader(false);
      //       })
      //     } else {
      //       //get the adv payment billingheader ID from the PaymentDetails
      //       parent.mangoAPISrvc.getPaymentDetailByHeaderID(parent.paymentHeaderID).subscribe((resPDetails: any) => {
      //         if (resPDetails?.length > 0) {
      //           let totalUnapplied = formObj['PaymentAmount'] - formObj['PaymentUnapplied']

      //           resPDetails.forEach((pDetail) => {
      //             if (pDetail.InvoiceType == 'Retainer Payment' || pDetail.InvoiceType == 'Retainer') {
      //               totalUnapplied = this.mangoUtils.roundOffDecimals(totalUnapplied)
      //               if (totalUnapplied > 0) {
      //                 const advBalance = Math.abs(numeral(pDetail.AppliedAmount).value())

      //                 if (totalUnapplied > advBalance) {
      //                   pDetail['amount'] = 0;
      //                   totalUnapplied = this.mangoUtils.subtractFloat(totalUnapplied, advBalance)
      //                 } else {
      //                   pDetail['amount'] = this.mangoUtils.subtractFloat(advBalance, totalUnapplied)
      //                   totalUnapplied = 0
      //                 }

      //                 parent.mangoAPISrvc.getBillingHeaderById(pDetail.BillingHeaderID).subscribe((advPaymentData: any) => {

      //                   advPaymentData.InvoiceAmount = Math.abs(pDetail['amount']) * -1;
      //                   advPaymentData.InvoiceBalance = numeral(advPaymentData.InvoiceAmount).value();
      //                   advPaymentData["SurChargeAmount"] = paymentFormData['SurChargeAmount'] ? paymentFormData['SurChargeAmount'] : 0;

      //                   if (advPaymentData.InvoiceBalance == 0) {
      //                     //delete payment detail
      //                     parent.mangoAPISrvc.deletePaymentDetails(pDetail.PaymentDetailID).subscribe((res) => {
      //                       parent.mangoAPISrvc.deleteBillingHeader(advPaymentData.BillingHeaderID).subscribe((res) => {

      //                       }, error => {
      //                         parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
      //                         parent.mangoAPISrvc.showLoader(false);
      //                       })
      //                     }, error => {
      //                       parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
      //                       parent.mangoAPISrvc.showLoader(false);
      //                     })
      //                   } else {
      //                     const paymentDetailObj = {
      //                       AppliedAmount: Math.abs(advPaymentData.InvoiceBalance),
      //                       PaymentAmount: Math.abs(advPaymentData.InvoiceBalance)
      //                     }
      //                     advPaymentData.LastModifiedStaffID = parent.encrDecSrvc.getObject(AppConstants.staffID);
      //                     parent.mangoAPISrvc.updatePaymentDetail(paymentDetailObj, pDetail.PaymentDetailID).subscribe((res) => {
      //                       parent.mangoAPISrvc.updateBillingHeader(advPaymentData, advPaymentData.BillingHeaderID).subscribe((data) => {

      //                       }, error => {
      //                         parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      //                         parent.mangoAPISrvc.showLoader(false);
      //                       })
      //                     }, error => {
      //                       parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      //                       parent.mangoAPISrvc.showLoader(false);
      //                     })
      //                   }
      //                 }, error => {
      //                   parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      //                   parent.mangoAPISrvc.showLoader(false);
      //                 })
      //               }
      //             }
      //           })
      //         }
      //       }, error => {
      //         parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      //         parent.mangoAPISrvc.showLoader(false);
      //       })
      //     }

      //     // Update billing Header
      //     // this.mangoAPISrvc.updateBillingHeader(data,data.BillingHeaderID).then((data) => {
      //     //  this.notificationsService.notify('success', this._translate.instant(data.message));
      //     for (let i = 0; i < this.openInvoicesDataSource.length; ++i) {
      //       let itemData = this.openInvoicesDataSource[i];

      //       // Payment Details are not required for an Retainer Invoice Receipt
      //       if (itemData.InvoiceType == "Retainer Invoice" && itemData.selected) {
      //         let billingHeaderRequest = {};
      //         billingHeaderRequest['ClientID'] = formObj['ClientID'];
      //         billingHeaderRequest['CompanyID'] = this.loginCompanyId;
      //         billingHeaderRequest['InvoiceDate'] = new Date();
      //         billingHeaderRequest['DescriptionShort'] = "Advance Payment" + (formObj['PaymentNote'] ? ` - ${formObj['PaymentNote']}` : '');
      //         billingHeaderRequest['InvoiceAmount'] = numeral(itemData.TotalPayments).value() * -1;
      //         billingHeaderRequest['TotalServices'] = billingHeaderRequest['InvoiceAmount'];
      //         billingHeaderRequest['InvoiceBalance'] = billingHeaderRequest['InvoiceAmount'];
      //         billingHeaderRequest['PaymentsApplied'] = 0;
      //         billingHeaderRequest['TotalStaffCost'] = 0;
      //         billingHeaderRequest['TotalPayments'] = 0;
      //         billingHeaderRequest['InvoicePosted'] = true;
      //         billingHeaderRequest['NSFtrue'] = false;
      //         billingHeaderRequest["SurChargeAmount"] = paymentFormData['SurChargeAmount'] ? paymentFormData['SurChargeAmount'] : 0;
      //         // If line is RI, then create negative BH for the payment received.  If 0 balance, delete BH
      //         if (numeral(itemData.InvoiceBalance).value() <= 0) {
      //           this.mangoAPISrvc.deleteBillingHeader(itemData.BillingHeaderID).subscribe((item) => {

      //           });
      //         } else {
      //           let cloneObj = Object.assign({}, billingHeaderRequest);
      //           cloneObj['AppliedCheckEdit'] = 0;
      //           cloneObj['PaymentsApplied'] = 0;
      //           cloneObj['DescriptionShort'] = itemData.DescriptionShort;
      //           cloneObj['InvoiceAmount'] = numeral(itemData.InvoiceAmount).value();
      //           cloneObj['InvoiceBalance'] = numeral(itemData.InvoiceBalance).value();
      //           cloneObj['PaymentAmount'] = numeral(itemData.PaymentAmount).value();
      //           cloneObj['PaymentUnapplied'] = numeral(itemData.PaymentUnapplied).value();
      //           cloneObj['TotalPayments'] = numeral(itemData.TotalPayments).value();
      //           cloneObj['LastModifiedStaffID'] = parent.encrDecSrvc.getObject(AppConstants.staffID);
      //           this.mangoAPISrvc.updateBillingHeader(cloneObj, itemData.BillingHeaderID).subscribe((item) => {

      //           });
      //         }
      //         billingHeaderRequest['InvoiceType'] = 'Retainer Payment';
      //         billingHeaderRequest['CreatedbyStaffID'] = this.encrDecSrvc.getObject(AppConstants.staffID);
      //         this.mangoAPISrvc.createBillingHeader(billingHeaderRequest).subscribe((item: any) => {
      //           formObj.PaymentNote = formObj.PaymentNote ? formObj.PaymentNote : "";
      //           let PaymentDetailEntity = {};
      //           PaymentDetailEntity['PaymentHeaderID'] = newdata.PaymentHeaderID;
      //           PaymentDetailEntity['BillingHeaderID'] = item.data.BillingHeaderID; //new invoice header (advance payment)
      //           PaymentDetailEntity['ParentHeaderID'] = numeral(itemData.InvoiceBalance).value() > 0 ? itemData.BillingHeaderID : null;  //if there still a balance put a retainer header;
      //           PaymentDetailEntity['PaymentDate'] = newdata.PaymentDate;
      //           PaymentDetailEntity['InvoiceNumber'] = itemData.InvoiceNumber;
      //           PaymentDetailEntity['InvoiceDate'] = newdata.PaymentDate;
      //           PaymentDetailEntity['PaymentType'] = newdata.PaymentType;
      //           PaymentDetailEntity['isAdvancePayment'] = true;
      //           PaymentDetailEntity['PaymentNote'] = "Advance Payment" + (formObj['PaymentNote'] ? ` - ${formObj['PaymentNote']}` : '');
      //           PaymentDetailEntity['AppliedAmount'] = numeral(itemData.PaymentsApplied).value();
      //           PaymentDetailEntity['InitialAmount'] = numeral(itemData.PaymentsApplied).value();
      //           PaymentDetailEntity['PaymentAmount'] = numeral(itemData.PaymentsApplied).value();
      //           PaymentDetailEntity['ClientID'] = formObj['ClientID'];
      //           parent.mangoAPISrvc.showLoader(false);

      //           // create payment details
      //           parent.mangoAPISrvc.createPaymentDetails(PaymentDetailEntity).subscribe((data) => {
      //             setTimeout(() => {
      //               parent.resetToDefaultValues();
      //               parent.mangoAPISrvc.showLoader(false);
      //               parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
      //             }, 500);
      //           }, error => {
      //             parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      //             parent.mangoAPISrvc.showLoader(false);
      //           })

      //         });
      //       } else {
      //         // let retainerData = this.openRetainersDataSource;  fix paymentdate, checkref
      //         if (itemData.selected) {
      //           // insert payment detail record
      //           let PaymentDetailEntity = {};
      //           PaymentDetailEntity['PaymentHeaderID'] = this.paymentHeaderID;
      //           PaymentDetailEntity['BillingHeaderID'] = itemData.BillingHeaderID;
      //           const localTime = moment.utc(this.selectedPaymentDate).toDate();
      //           PaymentDetailEntity['PaymentDate'] = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
      //           PaymentDetailEntity['CheckRef'] = formObj['CheckRef']
      //           PaymentDetailEntity['InvoiceNumber'] = itemData.InvoiceNumber;
      //           PaymentDetailEntity['InvoiceDate'] = itemData.InvoiceDate;
      //           PaymentDetailEntity['PaymentType'] = formObj.PaymentType;
      //           PaymentDetailEntity['PaymentNote'] = formObj.PaymentNote;
      //           PaymentDetailEntity['ClientID'] = formObj['ClientID'];

      //           if (PaymentDetailEntity['PaymentType'] == 'Write-Off') {
      //             PaymentDetailEntity['WriteOffAmount'] = numeral(itemData.PaymentsApplied).value();
      //           } else {
      //             PaymentDetailEntity['AppliedAmount'] = numeral(itemData.PaymentsApplied).value();
      //             PaymentDetailEntity['InitialAmount'] = numeral(itemData.PaymentsApplied).value();
      //           }
      //           // if (PaymentDetailEntity['PaymentType'] == 'Credit Card' && this.paymentProfile) {
      //           //   PaymentDetailEntity['PaymentNote'] = "Paid By: " + this.paymentProfile.NameOnCard + ", " + "using Credit Card:  ****" + this.paymentProfile.CardNo;
      //           // }
      //           // create payment details
      //           this.mangoAPISrvc.createPaymentDetails(PaymentDetailEntity).subscribe((data) => {
      //             //this.resetToDefaultValues();
      //             //this.notificationsService.notify('success', this._translate.instant(data.message));
      //           }, error => {
      //             parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      //             parent.mangoAPISrvc.showLoader(false);
      //           })
      //           itemData.AppliedCheckEdit = 0;
      //           itemData.PaymentsApplied = 0;
      //           itemData['InvoiceBalance'] = itemData['InvoiceBalance'] ? itemData['InvoiceBalance'] : 0;
      //           itemData['InvoiceAmount'] = itemData['InvoiceAmount'] ? itemData['InvoiceAmount'] : 0;
      //           itemData['InvoiceAmount'] = numeral(itemData['InvoiceAmount']).value();
      //           itemData['InvoiceBalance'] = numeral(itemData['InvoiceBalance']).value()
      //           itemData['LastModifiedStaffID'] = parent.encrDecSrvc.getObject(AppConstants.staffID);
      //           // updating Billing Header
      //           this.mangoAPISrvc.updateBillingHeader(itemData, itemData.BillingHeaderID).subscribe((data) => {
      //             //this.resetToDefaultValues();
      //             setTimeout(() => {
      //               this.resetToDefaultValues();
      //               parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
      //             }, 500);
      //           }, error => {
      //             parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      //             parent.mangoAPISrvc.showLoader(false);
      //           })
      //         }
      //       }
      //     }
      //   }, error => {
      //     parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      //     parent.mangoAPISrvc.showLoader(false);
      //   })
      // }, error => {
      //   parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
      //   parent.mangoAPISrvc.showLoader(false);
      // });

      //Handle a Payment Applied from a payment NOT a retainer.  Handles PaymentUnapplied=0 and PaymentUnapplied>0
    } else if (
      (this.isPaymentApplied && !this.isRetainerApplied) ||
      localClient['MarkDepositApplied']
    ) {
      if (
        this.isSaveAndSplitClicked ||
        formObj['PaymentUnapplied'] == 0 ||
        localClient['MarkDepositApplied']
      ) {
        parent.processAdvOrDebitMemo(formObj, true, localClient, localOpenInvoices);
      } else {
        parent.debitMemoDesc = '';
        Swal.fire({
          title: parent._translate.instant('confirmation'),
          html: `${parent._translate.instant('cash.there_is_an_advance_payment')}$${numeral(
            parent.mycashReceiptsForm.get('PaymentUnapplied').value
          ).format('0,0.00')}. ${parent._translate.instant('cash.debit_memo_or_adv_payment')}`,
          icon: 'warning',
          showCancelButton: false,
          showDenyButton: true,
          width: '38em',
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: parent._translate.instant('advance_payment'),
          denyButtonText: parent._translate.instant('debit_memo')
          // cancelButtonText: this._translate.instant("no_cancel"),
        }).then(result => {
          if (result.isConfirmed) {
            parent.processAdvOrDebitMemo(formObj, true, localClient, localOpenInvoices);
          } else if (result.isDenied) {
            parent.showDebitMemoDialog = true;
            // parent.processAdvOrDebitMemo(formObj, false);
          }
        });
      }
    }
  }

  processAdvOrDebitMemo(formObj, isAdvPayment: boolean, localClient, localOpenInvoices): void {
    const parent = this;
    const paymentFormData = parent.myPaymentForm.value;
    parent.mangoAPISrvc.showLoader(true);
    // BillingHeader needs to be created first if there is an unapplied balance.  Create Advance Payment.
    if (formObj['PaymentUnapplied'] > 0 && !localClient['MarkDepositApplied']) {
      let newBillingHeaderID = null;
      //Build BillingHeader
      //if(!this.isSaveAndSplitClicked){

      // Create BillingHeader for the Advance Payment
      if (this.isSaveAndSplitClicked) {
        // create new record in paymentHeader
        var newHeader = {};
        newHeader['ClientID'] = formObj['ClientID'];
        newHeader['PaymentDate'] = formObj['PaymentDate'];
        newHeader['PaymentType'] = formObj['PaymentType'];
        newHeader['PaymentNote'] = formObj['PaymentNote'];
        // if (newHeader['PaymentType'] == 'Credit Card' && this.paymentProfile) {
        //   newHeader['PaymentNote'] = "Paid By: " + this.paymentProfile.NameOnCard + ", " + "using Credit Card:  ****" + this.paymentProfile.CardNo;
        // }
        newHeader['PaymentUnapplied'] = formObj['PaymentUnapplied'];
        newHeader['CheckRef'] = formObj['CheckRef'];

        // handle customer mark deposited applied
        if (!localClient['MarkDepositApplied']) {
          newHeader['PaymentUnapplied'] = formObj.PaymentUnapplied;
        } else {
          newHeader['PaymentUnapplied'] = 0;
        }
        newHeader['BillingPartnerID'] = localClient['BillingPartnerID'];
        newHeader['OriginatingPartnerID'] = localClient['OriginatingPartnerID'];
        newHeader['StaffAssignedID'] = localClient['StaffAssignedID'];
        newHeader['GroupDescriptionID'] = localClient['GroupDescriptionID'];
        newHeader['ClientTypeID'] = localClient['ClientTypeID'];
        newHeader['SelectedForDepositToBank'] = false;
        newHeader['NSFtrue'] = false;

        // Handle a paymentType Write-off
        if (formObj.PaymentType == 'Write-Off') {
          newHeader['WriteOff'] = true;
          newHeader['WriteOffAmount'] = numeral(formObj['PaymentAmount']).value();
          newHeader['PaymentAmount'] = 0;
          newHeader['SelectedForDepositToBank'] = true;
        } else {
          //newHeader['PaymentAmount'] = numeral(formObj['PaymentAmount']).value();
          if (this.isSaveAndSplitClicked) {
            newHeader['PaymentAmount'] = numeral(this.totalNumberPayments).value();
            newHeader['PaymentUnapplied'] = 0;
          } else {
            newHeader['PaymentAmount'] = numeral(formObj['PaymentAmount']).value();
          }
          if (formObj['PaymentUnapplied'] > 0) {
            this.mangoAPISrvc.isFormChanged = true;
          } else {
            this.mangoAPISrvc.isFormChanged = false;
          }
        }
        if (formObj.PaymentType == 'Credit Memo') {
          newHeader['SelectedForDepositToBank'] = true;
        }
        newHeader['StaxID'] = parent.transactionData ? parent.transactionData.id : null;
        var note;
        if (parent.transactionData) {
          if (parent.transactionData.payment_method) {
            note = `Paid : ${parent.transactionData.payment_method.nickname}`;
          } else if (
            parent.transactionData.child_transactions &&
            parent.transactionData.child_transactions.length > 0
          ) {
            note = `Paid : ${parent.transactionData.child_transactions[0].payment_method.nickname}`;
          }
          newHeader['PaymentNote'] =
            (newHeader['PaymentType'] == 'Credit Card' ? note + ' (CR)' : note + ') (CR)') +
            (formObj['PaymentNote'] ? `; ${formObj['PaymentNote']}` : '');
          if (formObj.PaymentType == 'Credit Card' && parent.isDebitCard) {
            newHeader['PaymentType'] = 'Debit card';
          }
        }
        newHeader['CreatedbyStaffID'] = parent.encrDecSrvc.getObject(AppConstants.staffID);
        newHeader['SurChargeAmount'] = paymentFormData['SurChargeAmount']
          ? paymentFormData['SurChargeAmount']
          : 0;

        this.mangoAPISrvc.createPaymentHeader(newHeader).subscribe(
          (data: any) => {
            parent.transactionData = null;
            parent.mangoAPISrvc.showLoader(false);
            this.sendMailToCustmor(
              formObj['PaymentAmount'],
              data['data']['PaymentHeaderID'],
              localClient,
              formObj['PaymentNote']
            );
            this.sendToCompany(
              formObj,
              localClient,
              localOpenInvoices.filter(item => item['selected'] == true)
            );
            // itarate billing header to Update billing Header and Create payment details Records
            let counterResults = 0;
            const selectedListLen = this.openInvoicesDataSource.filter(
              (item) => item["selected"] == true
            ).length;
            for (let i = 0; i < this.openInvoicesDataSource.length; ++i) {
              const itemData = this.openInvoicesDataSource[i];

              // Payment Details are not required for an Retainer Invoice Receipt
              if (itemData.InvoiceType == 'Retainer Invoice' && itemData.selected) {
                counterResults++;
                const billingHeaderRequest = {};
                billingHeaderRequest["ClientID"] = formObj["ClientID"];
                billingHeaderRequest["CompanyID"] = this.loginCompanyId;
                billingHeaderRequest["InvoiceDate"] = new Date();
                billingHeaderRequest["DescriptionShort"] =
                  "Advance Payment" +
                  (formObj["PaymentNote"] ? ` - ${formObj["PaymentNote"]}` : "");
                billingHeaderRequest["InvoiceAmount"] =
                  numeral(itemData.TotalPayments).value() * -1;
                billingHeaderRequest['TotalServices'] = billingHeaderRequest['InvoiceAmount'];
                billingHeaderRequest['InvoiceBalance'] = billingHeaderRequest['InvoiceAmount'];
                billingHeaderRequest['PaymentsApplied'] = 0;
                billingHeaderRequest['TotalStaffCost'] = 0;
                billingHeaderRequest['TotalPayments'] = 0;
                billingHeaderRequest['InvoicePosted'] = true;
                billingHeaderRequest['NSFtrue'] = false;
                billingHeaderRequest['SurChargeAmount'] = paymentFormData['SurChargeAmount']
                  ? paymentFormData['SurChargeAmount']
                  : 0;
                // If line is RI, then create negative BH for the payment received.  If 0 balance, delete BH
                if (numeral(itemData.InvoiceBalance).value() <= 0) {
                  this.mangoAPISrvc
                    .deleteBillingHeader(itemData.BillingHeaderID)
                    .subscribe(item => {});
                } else {
                  const cloneObj = Object.assign({}, billingHeaderRequest);
                  cloneObj["AppliedCheckEdit"] = 0;
                  cloneObj["PaymentsApplied"] = 0;
                  cloneObj["DescriptionShort"] = itemData.DescriptionShort;
                  cloneObj["InvoiceAmount"] = numeral(itemData.InvoiceAmount).value();
                  cloneObj["InvoiceBalance"] = numeral(itemData.InvoiceBalance).value();
                  cloneObj["PaymentAmount"] = numeral(itemData.PaymentAmount).value();
                  cloneObj["PaymentUnapplied"] = numeral(itemData.PaymentUnapplied).value();
                  cloneObj["TotalPayments"] = numeral(itemData.TotalPayments).value();
                  cloneObj["LastModifiedStaffID"] = parent.encrDecSrvc.getObject(
                    AppConstants.staffID
                  );
                  this.mangoAPISrvc
                    .updateBillingHeader(cloneObj, itemData.BillingHeaderID)
                    .subscribe(item => {});
                }
                billingHeaderRequest['CreatedbyStaffID'] = this.encrDecSrvc.getObject(
                  AppConstants.staffID
                );
                this.mangoAPISrvc.createBillingHeader(billingHeaderRequest).subscribe(item => {
                  if (counterResults == selectedListLen) {
                    setTimeout(() => {
                      parent.resetToDefaultValues();
                      parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
                    }, 500);
                  }
                });
              } else {
                if (itemData.selected) {
                  counterResults++;
                  const PaymentDetailsEntity = {};
                  PaymentDetailsEntity["BillingHeaderID"] = itemData.BillingHeaderID;
                  PaymentDetailsEntity["PaymentHeaderID"] = data.data.PaymentHeaderID;
                  PaymentDetailsEntity["ClientID"] = data.data.ClientID;
                  PaymentDetailsEntity["CheckRef"] = data.data.CheckRef;
                  PaymentDetailsEntity["PaymentNote"] = data.data.PaymentNote;
                  PaymentDetailsEntity["PaymentDate"] = formObj["PaymentDate"];
                  PaymentDetailsEntity["PaymentType"] = data.data.PaymentType;
                  PaymentDetailsEntity["InvoiceNumber"] = itemData.InvoiceNumber;
                  PaymentDetailsEntity["InvoiceDate"] = itemData.InvoiceDate;

                  //
                  if (formObj.PaymentType == 'Write-Off') {
                    PaymentDetailsEntity['WriteOffAmount'] = numeral(
                      itemData.PaymentsApplied
                    ).value();
                  } else {
                    PaymentDetailsEntity['AppliedAmount'] = numeral(
                      itemData.PaymentsApplied
                    ).value();
                    PaymentDetailsEntity['InitialAmount'] = numeral(
                      itemData.PaymentsApplied
                    ).value();
                  }
                  // if (PaymentDetailsEntity['PaymentType'] == 'Credit Card' && this.paymentProfile) {
                  //   PaymentDetailsEntity['PaymentNote'] = "Paid By: " + this.paymentProfile.NameOnCard + ", " + "using Credit Card:  ****" + this.paymentProfile.CardNo;
                  // }

                  // create payment details
                  this.mangoAPISrvc.createPaymentDetails(PaymentDetailsEntity).subscribe(
                    (data: any) => {
                      if (counterResults == selectedListLen) {
                        parent.resetToDefaultValues();
                        parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
                      }
                    },
                    error => {
                      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.createErrorMsg);
                      parent.mangoAPISrvc.showLoader(false);
                    }
                  );
                  itemData.AppliedCheckEdit = 0;
                  itemData.PaymentsApplied = 0;
                  itemData.InvoiceAmount = numeral(itemData.InvoiceAmount).value();
                  itemData.InvoiceBalance = numeral(itemData.InvoiceBalance).value();
                  itemData.PaymentAmount = numeral(itemData.PaymentAmount).value();
                  itemData.PaymentUnapplied = numeral(itemData.PaymentUnapplied).value();
                  itemData.TotalPayments = numeral(itemData.TotalPayments).value();

                  const parent = this;

                  itemData.LastModifiedStaffID = parent.encrDecSrvc.getObject(AppConstants.staffID);
                  // updating BillingHeader for each selected Open Invoice
                  parent.mangoAPISrvc
                    .updateBillingHeader(itemData, itemData.BillingHeaderID)
                    .subscribe(
                      data => {
                        // if(counterResults == selectedListLen){
                        //   parent.resetToDefaultValues();
                        //   parent.notificationsService.notify('success', parent._translate.instant(data.message));
                        // }
                      },
                      error => {
                        parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
                        parent.mangoAPISrvc.showLoader(false);
                      }
                    );
                }
              }
            }
          },
          error => {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          }
        );
      } else {
        var newHeader = {};
        newHeader['ClientID'] = formObj['ClientID'];
        newHeader['InvoiceDate'] = formObj['PaymentDate'];
        newHeader['DescriptionShort'] = isAdvPayment
          ? 'Advance Payment' + (formObj['PaymentNote'] ? ` - ${formObj['PaymentNote']}` : '')
          : parent.debitMemoDesc;
        const PmtUnapplied = numeral(formObj["PaymentUnapplied"]).value();
        newHeader["InvoiceAmount"] = isAdvPayment ? PmtUnapplied * -1 : PmtUnapplied;
        newHeader["TotalServices"] = newHeader["InvoiceAmount"];
        newHeader["TotalPayments"] = 0;
        if (isAdvPayment) newHeader["InvoiceBalance"] = newHeader["InvoiceAmount"];
        else newHeader["TotalPayments"] = newHeader["InvoiceAmount"];
        newHeader["PaymentsApplied"] = 0;
        newHeader["TotalStaffCost"] = 0;
        newHeader["InvoicePosted"] = true;
        newHeader["InvoiceType"] = isAdvPayment ? "Retainer" : "Debit Memo";
        newHeader["NSFtrue"] = false;
        newHeader["CreatedbyStaffID"] = this.encrDecSrvc.getObject(AppConstants.staffID);
        newHeader["SurChargeAmount"] = paymentFormData["SurChargeAmount"]
          ? paymentFormData["SurChargeAmount"]
          : 0;
        parent.mangoAPISrvc.createBillingHeader(newHeader).subscribe(
          (item: any) => {
            newBillingHeaderID = item.data.BillingHeaderID;
            // create new record in paymentHeader
            const newHeader = {};
            newHeader["ClientID"] = formObj["ClientID"];
            newHeader["PaymentDate"] = formObj["PaymentDate"];
            newHeader["PaymentType"] = formObj["PaymentType"];
            newHeader["PaymentNote"] = formObj["PaymentNote"];
            // if (newHeader['PaymentType'] == 'Credit Card' && this.paymentProfile) {
            //   newHeader['PaymentNote'] = "Paid By: " + this.paymentProfile.NameOnCard + ", " + "using Credit Card:  ****" + this.paymentProfile.CardNo;
            // }
            newHeader['PaymentUnapplied'] = formObj['PaymentUnapplied'];
            newHeader['CheckRef'] = formObj['CheckRef'];

            // handle customer mark deposited applied
            if (!localClient['MarkDepositApplied']) {
              newHeader['PaymentUnapplied'] = formObj.PaymentUnapplied;
            } else {
              newHeader['PaymentUnapplied'] = 0;
            }
            newHeader['PaymentUnapplied'] = isAdvPayment ? newHeader['PaymentUnapplied'] : 0;
            newHeader['BillingPartnerID'] = localClient['BillingPartnerID'];
            newHeader['OriginatingPartnerID'] = localClient['OriginatingPartnerID'];
            newHeader['StaffAssignedID'] = localClient['StaffAssignedID'];
            newHeader['GroupDescriptionID'] = localClient['GroupDescriptionID'];
            newHeader['ClientTypeID'] = localClient['ClientTypeID'];
            newHeader['SelectedForDepositToBank'] = false;
            newHeader['NSFtrue'] = false;

            // Handle a paymentType Write-off
            if (formObj.PaymentType == 'Write-Off') {
              newHeader['WriteOff'] = true;
              newHeader['WriteOffAmount'] = numeral(formObj['PaymentAmount']).value();
              newHeader['PaymentAmount'] = 0;
              newHeader['SelectedForDepositToBank'] = true;
            } else {
              //newHeader['PaymentAmount'] = numeral(formObj['PaymentAmount']).value();
              if (this.isSaveAndSplitClicked) {
                newHeader['PaymentAmount'] = numeral(this.totalNumberPayments).value();
                newHeader['PaymentUnapplied'] = 0;
              } else {
                if(isAdvPayment||this.debitMemoDesc?.length>0){
                  newHeader["PaymentAmount"] = numeral(newHeader["PaymentUnapplied"]).value()+numeral(this.totalNumberPayments).value();
                }else{
                  newHeader["PaymentAmount"] = numeral(formObj["PaymentAmount"]).value();
                }
              }
              if (formObj['PaymentUnapplied'] > 0) {
                this.mangoAPISrvc.isFormChanged = true;
              } else {
                this.mangoAPISrvc.isFormChanged = false;
              }
            }
            if (formObj.PaymentType == 'Credit Memo') {
              newHeader['SelectedForDepositToBank'] = true;
            }
            // insert paymentHeader record (This is needed only if Advance Payment Done PaymentUnapplied > 0)
            if (newBillingHeaderID) {
              newHeader['BillingHeaderID'] = newBillingHeaderID;
              newHeader['RetainerHeaderID'] = isAdvPayment ? newBillingHeaderID : null;
            }
            newHeader["StaxID"] = parent.transactionData ? parent.transactionData.id : null;
            let note;
            if (parent.transactionData) {
              if (parent.transactionData.payment_method) {
                note = `Paid : ${parent.transactionData.payment_method.nickname}`;
              } else if (
                parent.transactionData.child_transactions &&
                parent.transactionData.child_transactions.length > 0
              ) {
                note = `Paid : ${parent.transactionData.child_transactions[0].payment_method.nickname}`;
              }
              newHeader['PaymentNote'] =
                (newHeader['PaymentType'] == 'Credit Card' ? note + ' (CR)' : note + ') (CR)') +
                (formObj['PaymentNote'] ? `; ${formObj['PaymentNote']}` : '');
              if (formObj.PaymentType == 'Credit Card' && parent.isDebitCard) {
                newHeader['PaymentType'] = 'Debit card';
              }
            }

            newHeader['CreatedbyStaffID'] = parent.encrDecSrvc.getObject(AppConstants.staffID);
            newHeader['SurChargeAmount'] = paymentFormData['SurChargeAmount']
              ? paymentFormData['SurChargeAmount']
              : 0;

            parent.mangoAPISrvc.createPaymentHeader(newHeader).subscribe(
              (data: any) => {
                parent.transactionData = null;
                parent.mangoAPISrvc.showLoader(false);
                parent.sendMailToCustmor(
                  formObj['PaymentAmount'],
                  data['data']['PaymentHeaderID'],
                  localClient,
                  formObj['PaymentNote']
                );
                parent.sendToCompany(
                  formObj,
                  localClient,
                  localOpenInvoices.filter(item => item['selected'] == true)
                );
                // itarate billing header to Update billing Header and Create payment details Records
                let counterResults = 0;
                const selectedListLen = parent.openInvoicesDataSource.filter(
                  (item) => item["selected"] == true
                ).length;
                let paymentDetailsForDB = {};
                let paymentDetailsForAP = {};
                for (let i = 0; i < parent.openInvoicesDataSource.length; ++i) {
                  const itemData = parent.openInvoicesDataSource[i];
                  if (itemData.InvoiceType == "Retainer Invoice" && itemData.selected) {
                    counterResults++;
                    const billingHeaderRequest = {};
                    billingHeaderRequest["ClientID"] = formObj["ClientID"];
                    billingHeaderRequest["CompanyID"] = parent.loginCompanyId;
                    billingHeaderRequest["InvoiceDate"] = new Date();
                    billingHeaderRequest["DescriptionShort"] =
                      "Advance Payment" +
                      (formObj["PaymentNote"] ? ` - ${formObj["PaymentNote"]}` : "");
                    billingHeaderRequest["InvoiceAmount"] =
                      numeral(itemData.TotalPayments).value() * -1;
                    billingHeaderRequest['TotalServices'] = billingHeaderRequest['InvoiceAmount'];
                    billingHeaderRequest['InvoiceBalance'] = billingHeaderRequest['InvoiceAmount'];
                    billingHeaderRequest['PaymentsApplied'] = 0;
                    billingHeaderRequest['TotalStaffCost'] = 0;
                    billingHeaderRequest['TotalPayments'] = 0;
                    billingHeaderRequest['InvoicePosted'] = true;
                    billingHeaderRequest['NSFtrue'] = false;
                    // If line is RI, then create negative BH for the payment received.  If 0 balance, delete BH
                    if (numeral(itemData.InvoiceBalance).value() <= 0) {
                      this.mangoAPISrvc
                        .deleteBillingHeader(itemData.BillingHeaderID)
                        .subscribe(item => {});
                    } else {
                      const cloneObj = Object.assign({}, billingHeaderRequest);
                      cloneObj["AppliedCheckEdit"] = 0;
                      cloneObj["PaymentsApplied"] = 0;
                      cloneObj["DescriptionShort"] = itemData.DescriptionShort;
                      cloneObj["InvoiceAmount"] = numeral(itemData.InvoiceAmount).value();
                      cloneObj["InvoiceBalance"] = numeral(itemData.InvoiceBalance).value();
                      cloneObj["PaymentAmount"] = numeral(itemData.PaymentAmount).value();
                      cloneObj["PaymentUnapplied"] = numeral(itemData.PaymentUnapplied).value();
                      cloneObj["TotalPayments"] = numeral(itemData.TotalPayments).value();
                      cloneObj["LastModifiedStaffID"] = this.encrDecSrvc.getObject(
                        AppConstants.staffID
                      );
                      this.mangoAPISrvc
                        .updateBillingHeader(cloneObj, itemData.BillingHeaderID)
                        .subscribe(item => {});
                    }
                    billingHeaderRequest['CreatedbyStaffID'] = this.encrDecSrvc.getObject(
                      AppConstants.staffID
                    );
                    billingHeaderRequest['SurChargeAmount'] = paymentFormData['SurChargeAmount']
                      ? paymentFormData['SurChargeAmount']
                      : 0;
                    this.mangoAPISrvc.createBillingHeader(billingHeaderRequest).subscribe(item => {
                      if (counterResults == selectedListLen) {
                        setTimeout(() => {
                          parent.resetToDefaultValues();
                          parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
                        }, 500);
                      }
                    });
                  } else {
                    if (itemData.selected) {
                      counterResults++;
                      const PaymentDetailsEntity = {};
                      PaymentDetailsEntity["BillingHeaderID"] = itemData.BillingHeaderID;
                      PaymentDetailsEntity["PaymentHeaderID"] = data.data.PaymentHeaderID;
                      PaymentDetailsEntity["ClientID"] = data.data.ClientID;
                      PaymentDetailsEntity["CheckRef"] = data.data.CheckRef;
                      PaymentDetailsEntity["PaymentNote"] = data.data.PaymentNote;
                      PaymentDetailsEntity["PaymentDate"] = formObj["PaymentDate"];
                      PaymentDetailsEntity["PaymentType"] = data.data.PaymentType;
                      PaymentDetailsEntity["InvoiceNumber"] = itemData.InvoiceNumber;
                      PaymentDetailsEntity["InvoiceDate"] = itemData.InvoiceDate;
                      //
                      if (formObj.PaymentType == 'Write-Off') {
                        PaymentDetailsEntity['WriteOffAmount'] = numeral(
                          itemData.PaymentsApplied
                        ).value();
                      } else {
                        PaymentDetailsEntity['AppliedAmount'] = numeral(
                          itemData.PaymentsApplied
                        ).value();
                        PaymentDetailsEntity['InitialAmount'] = numeral(
                          itemData.PaymentsApplied
                        ).value();
                      }
                      // if (PaymentDetailsEntity['PaymentType'] == 'Credit Card' && this.paymentProfile) {
                      //   PaymentDetailsEntity['PaymentNote'] = "Paid By: " + this.paymentProfile.NameOnCard + ", " + "using Credit Card:  ****" + this.paymentProfile.CardNo;
                      // }

                      // create payment details
                      this.mangoAPISrvc.createPaymentDetails(PaymentDetailsEntity).subscribe(
                        data => {
                          if (counterResults == selectedListLen) {
                            parent.resetToDefaultValues();
                            parent.mangoAPISrvc.notify(
                              'success',
                              'Success!',
                              AppConstants.createMsg
                            );
                          }
                        },
                        error => {
                          parent.mangoAPISrvc.notify(
                            'error',
                            'Error!',
                            AppConstants.createErrorMsg
                          );
                          parent.mangoAPISrvc.showLoader(false);
                        }
                      );

                      //create payment details for debit memo
                      if (!isAdvPayment) {
                        paymentDetailsForDB = {
                          ...PaymentDetailsEntity,
                          BillingHeaderID: newBillingHeaderID
                        };
                      } else {
                        paymentDetailsForAP = {
                          ...PaymentDetailsEntity,
                          BillingHeaderID: newBillingHeaderID,
                          AppliedAmount: numeral(data.data.PaymentUnapplied).value(),
                          InitialAmount: numeral(data.data.PaymentUnapplied).value()
                        };
                      }

                      itemData.AppliedCheckEdit = 0;
                      itemData.PaymentsApplied = 0;
                      itemData.InvoiceAmount = numeral(itemData.InvoiceAmount).value();
                      itemData.InvoiceBalance = numeral(itemData.InvoiceBalance).value();
                      itemData.PaymentAmount = numeral(itemData.PaymentAmount).value();
                      itemData.PaymentUnapplied = numeral(itemData.PaymentUnapplied).value();
                      itemData.TotalPayments = numeral(itemData.TotalPayments).value();
                      itemData.LastModifiedStaffID = parent.encrDecSrvc.getObject(
                        AppConstants.staffID
                      );
                      // updating BillingHeader for each selected Open Invoice
                      parent.mangoAPISrvc
                        .updateBillingHeader(itemData, itemData.BillingHeaderID)
                        .subscribe(
                          data => {
                            // if(counterResults == selectedListLen){
                            //   parent.resetToDefaultValues();
                            //   parent.notificationsService.notify('success', parent._translate.instant(data.message));
                            // }
                          },
                          error => {
                            parent.mangoAPISrvc.notify(
                              'error',
                              'Error!',
                              AppConstants.updateErrorMsg
                            );
                            parent.mangoAPISrvc.showLoader(false);
                          }
                        );
                    }
                  }
                }
                //create payment details for debit memo
                if (!isAdvPayment) {
                  paymentDetailsForDB['PaymentNote'] = 'Debit Memo';
                  paymentDetailsForDB['CheckRef'] = 'DM';
                  paymentDetailsForDB['InvoiceNumber'] = null;
                  paymentDetailsForDB['AppliedAmount'] = numeral(
                    formObj['PaymentUnapplied']
                  ).value();
                  paymentDetailsForDB['InitialAmount'] = numeral(
                    formObj['PaymentUnapplied']
                  ).value();
                  paymentDetailsForDB['ClientID'] = formObj['ClientID'];

                  this.mangoAPISrvc.createPaymentDetails(paymentDetailsForDB).subscribe(
                    data => {
                      if (counterResults == selectedListLen) {
                        parent.resetToDefaultValues();
                        parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
                      }
                    },
                    error => {
                      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
                      parent.mangoAPISrvc.showLoader(false);
                    }
                  );
                } else {
                  paymentDetailsForAP['PaymentNote'] = data.data.PaymentNote;
                  paymentDetailsForAP['CheckRef'] = data.data.CheckRef;
                  paymentDetailsForAP['InvoiceNumber'] = null;
                  paymentDetailsForAP['ClientID'] = formObj['ClientID'];
                  this.mangoAPISrvc.createPaymentDetails(paymentDetailsForAP).subscribe(
                    data => {
                      if (counterResults == selectedListLen) {
                        parent.resetToDefaultValues();
                        parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
                      }
                    },
                    error => {
                      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
                      parent.mangoAPISrvc.showLoader(false);
                    }
                  );
                }
              },
              error => {
                parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
                parent.mangoAPISrvc.showLoader(false);
              }
            );
            //}
          },
          error => {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          }
        );
      }
    } else if (localClient['MarkDepositApplied'] == true && formObj['PaymentUnapplied'] > 0) {
      // create new record in paymentHeader
      var newHeader = {};
      newHeader['ClientID'] = formObj['ClientID'];
      newHeader['PaymentDate'] = formObj['PaymentDate'];
      newHeader['PaymentType'] = formObj['PaymentType'];
      newHeader['PaymentNote'] = formObj['PaymentNote'];
      // if(newHeader['PaymentType'] == 'Credit Card' && this.paymentProfile){
      //   newHeader['PaymentNote'] = "Paid By: " + this.paymentProfile.NameOnCard + ", " + "using Credit Card:  ****" + this.paymentProfile.CardNo;
      // }

      newHeader['CheckRef'] = formObj['CheckRef'];

      // handle customer mark deposited applied

      newHeader['PaymentUnapplied'] = 0;

      newHeader['BillingPartnerID'] = localClient['BillingPartnerID'];
      newHeader['OriginatingPartnerID'] = localClient['OriginatingPartnerID'];
      newHeader['StaffAssignedID'] = localClient['StaffAssignedID'];
      newHeader['GroupDescriptionID'] = localClient['GroupDescriptionID'];
      newHeader['GroupDescriptionIDArray'] = localClient['GroupDescriptionIDArray'];
      newHeader['ClientTypeID'] = localClient['ClientTypeID'];
      newHeader['NSFtrue'] = false;

      // Handle a paymentType Write-off

      newHeader['SelectedForDepositToBank'] = false;
      // This amount comes from the footer table
      newHeader['PaymentAmount'] = numeral(formObj['PaymentAmount']).value();
      if(this.isSaveAndSplitClicked){
        newHeader['PaymentAmount'] = numeral(this.totalNumberPayments).value();
      }
      newHeader['StaxID'] = this.transactionData ? this.transactionData.id : null;
      var note;
      if (this.transactionData) {
        if (this.transactionData.payment_method) {
          note = `Paid : ${this.transactionData.payment_method.nickname}`;
        } else if (
          this.transactionData.child_transactions &&
          this.transactionData.child_transactions.length > 0
        ) {
          note = `Paid : ${this.transactionData.child_transactions[0].payment_method.nickname}`;
        }
        newHeader['PaymentNote'] =
          (newHeader['PaymentType'] == 'Credit Card' ? note + ' (CR)' : note + ') (CR)') +
          (formObj['PaymentNote'] ? `; ${formObj['PaymentNote']}` : '');
        if (formObj.PaymentType == 'Credit Card' && this.isDebitCard) {
          newHeader['PaymentType'] = 'Debit card';
        }
      }

      newHeader['CreatedbyStaffID'] = this.encrDecSrvc.getObject(AppConstants.staffID);
      newHeader['SurChargeAmount'] = paymentFormData['SurChargeAmount']
        ? paymentFormData['SurChargeAmount']
        : 0;
      // insert paymentHeader record (This is needed only if Advance Payment Done PaymentUnapplied > 0)
      //newHeader['BillingHeaderID'] = item.data.BillingHeaderID;
      const parent = this;
      parent.mangoAPISrvc.createPaymentHeader(newHeader).subscribe((data) => {
        parent.resetToDefaultValues();
      });
    }
    // No Advance Payment.  create new record in paymentHeader
    else if (formObj['PaymentUnapplied'] == 0) {
      var newHeader = {};
      newHeader['ClientID'] = formObj['ClientID'];
      newHeader['PaymentDate'] = formObj['PaymentDate'];
      newHeader['PaymentType'] = formObj['PaymentType'];
      newHeader['PaymentNote'] = formObj['PaymentNote'];
      // if (newHeader['PaymentType'] == 'Credit Card' && this.paymentProfile) {
      //   newHeader['PaymentNote'] = "Paid By: " + this.paymentProfile.NameOnCard + ", " + "using Credit Card:  ****" + this.paymentProfile.CardNo;
      // }
      newHeader['PaymentUnapplied'] = formObj['PaymentUnapplied'];
      newHeader['CheckRef'] = formObj['CheckRef'];

      // handle customer mark deposited applied
      if (!parent.selectedCustomer['MarkDepositApplied']) {
        newHeader['PaymentUnapplied'] = formObj.PaymentUnapplied;
      } else {
        newHeader['PaymentUnapplied'] = 0;
      }
      //let selectedListLen = this.openInvoicesDataSource.filter((item) => item['selected'] == true).length;
      for (let i = 0; i < localOpenInvoices.length; ++i) {
        const itemData = localOpenInvoices[i];
        if (itemData.InvoiceType == "Retainer Invoice") {
          newHeader["PaymentUnapplied"] += numeral(itemData.PaymentsApplied).value();
        }
      }

      newHeader['BillingPartnerID'] = parent.selectedCustomer['BillingPartnerID'];
      newHeader['OriginatingPartnerID'] = parent.selectedCustomer['OriginatingPartnerID'];
      newHeader['StaffAssignedID'] = parent.selectedCustomer['StaffAssignedID'];
      newHeader['GroupDescriptionID'] = parent.selectedCustomer['GroupDescriptionID'];
      newHeader['GroupDescriptionIDArray'] = parent.selectedCustomer['GroupDescriptionIDArray'];
      newHeader['ClientTypeID'] = parent.selectedCustomer['ClientTypeID'];
      newHeader['SelectedForDepositToBank'] = false;
      newHeader['NSFtrue'] = false;

      // Handle a paymentType Write-off
      if (formObj.PaymentType == 'Write-Off') {
        newHeader['WriteOff'] = true;
        newHeader['WriteOffAmount'] = numeral(formObj['PaymentAmount']).value();
        newHeader['PaymentAmount'] = 0;
        newHeader['SelectedForDepositToBank'] = true;
      } else {
        if (parent.isSaveAndSplitClicked) {
          newHeader['PaymentAmount'] = numeral(parent.totalNumberPayments).value();
        } else {
          newHeader['PaymentAmount'] = numeral(formObj['PaymentAmount']).value();
        }
      }

      if (formObj.PaymentType == 'Credit Memo') {
        newHeader['SelectedForDepositToBank'] = true;
      }
      newHeader['StaxID'] = parent.transactionData ? parent.transactionData.id : null;
      var note;
      if (parent.transactionData) {
        if (parent.transactionData.payment_method) {
          note = `Paid : ${parent.transactionData.payment_method.nickname}`;
        } else if (
          parent.transactionData.child_transactions &&
          parent.transactionData.child_transactions.length > 0
        ) {
          note = `Paid : ${parent.transactionData.child_transactions[0].payment_method.nickname}`;
        }
        newHeader['PaymentNote'] =
          (newHeader['PaymentType'] == 'Credit Card' ? note + ' (CR)' : note + ') (CR)') +
          (formObj['PaymentNote'] ? `; ${formObj['PaymentNote']}` : '');
        if (formObj.PaymentType == 'Credit Card' && parent.isDebitCard) {
          newHeader['PaymentType'] = 'Debit card';
        }
      }

      newHeader['CreatedbyStaffID'] = parent.encrDecSrvc.getObject(AppConstants.staffID);
      newHeader['SurChargeAmount'] = paymentFormData['SurChargeAmount']
        ? paymentFormData['SurChargeAmount']
        : 0;
      // insert paymentHeader record (This is needed only if Advance Payment Done PaymentUnapplied > 0)
      //  newHeader['BillingHeaderID'] = item.data.BillingHeaderID;
      parent.mangoAPISrvc.createPaymentHeader(newHeader).subscribe(
        (data: any) => {
          parent.transactionData = null;
          parent.sendMailToCustmor(
            newHeader['PaymentAmount'],
            data['data']['PaymentHeaderID'],
            localClient,
            newHeader['PaymentNote']
          );
          parent.sendToCompany(
            newHeader,
            localClient,
            localOpenInvoices.filter(item => item['selected'] == true)
          );
          const paymentHeaderObj = data["data"];
          // itarate billing header to Update billing Header and Create payment details Records
          for (let i = 0; i < localOpenInvoices.length; ++i) {
            const itemData = localOpenInvoices[i];

            if (itemData.InvoiceType == "Retainer Invoice" && itemData.selected) {
              const billingHeaderRequest = {};
              billingHeaderRequest["ClientID"] = formObj["ClientID"];
              billingHeaderRequest["CompanyID"] = parent.loginCompanyId;
              billingHeaderRequest["InvoiceDate"] = itemData["InvoiceDate"];
              billingHeaderRequest["DescriptionShort"] =
                "Advance Payment" + (formObj["PaymentNote"] ? ` - ${formObj["PaymentNote"]}` : "");
              billingHeaderRequest["InvoiceType"] = "Retainer Payment";
              billingHeaderRequest["InvoiceAmount"] =
                numeral(itemData.PaymentsApplied).value() * -1;
              billingHeaderRequest['InvoiceBalance'] = billingHeaderRequest['InvoiceAmount'];
              billingHeaderRequest['PaymentsApplied'] = 0;
              billingHeaderRequest['TotalStaffCost'] = 0;
              billingHeaderRequest['TotalPayments'] = 0;
              billingHeaderRequest['InvoicePosted'] = true;
              billingHeaderRequest['NSFtrue'] = false;
              // If line is RI, then create negative BH for the payment received.  If 0 balance, delete BH
              if (numeral(itemData.InvoiceBalance).value() <= 0) {
                parent.mangoAPISrvc
                  .deleteBillingHeader(itemData.BillingHeaderID)
                  .subscribe(response => {});
              } else {
                const cloneObj = Object.assign({}, billingHeaderRequest);
                cloneObj["AppliedCheckEdit"] = 0;
                cloneObj["PaymentsApplied"] = 0;
                cloneObj["DescriptionShort"] = itemData.DescriptionShort;
                cloneObj["InvoiceType"] = "Retainer Invoice";
                cloneObj["InvoiceAmount"] = numeral(itemData.InvoiceAmount).value();
                cloneObj["InvoiceBalance"] = numeral(itemData.InvoiceBalance).value();
                cloneObj["PaymentAmount"] = numeral(itemData.PaymentsApplied).value();
                cloneObj["PaymentUnapplied"] = numeral(itemData.PaymentUnapplied).value();
                cloneObj["TotalPayments"] = numeral(itemData.TotalPayments).value();
                cloneObj["LastModifiedStaffID"] = parent.encrDecSrvc.getObject(
                  AppConstants.staffID
                );
                parent.mangoAPISrvc
                  .updateBillingHeader(cloneObj, itemData.BillingHeaderID)
                  .subscribe(item => {});
              }

              billingHeaderRequest['CreatedbyStaffID'] = this.encrDecSrvc.getObject(
                AppConstants.staffID
              );
              billingHeaderRequest['SurChargeAmount'] = paymentFormData['SurChargeAmount']
                ? paymentFormData['SurChargeAmount']
                : 0;
              parent.mangoAPISrvc.createBillingHeader(billingHeaderRequest).subscribe(item => {
                paymentHeaderObj['BillingHeaderID'] = item['data']['BillingHeaderID'];
                paymentHeaderObj['RetainerHeaderID'] = itemData.BillingHeaderID;

                // Need to create PaymentDetail record for the Retainer for association to the BH record
                const PaymentDetailsEntity = {};
                PaymentDetailsEntity["BillingHeaderID"] = paymentHeaderObj["BillingHeaderID"];
                PaymentDetailsEntity["PaymentHeaderID"] = paymentHeaderObj["PaymentHeaderID"];
                if (numeral(itemData.InvoiceBalance).value() != 0) {
                  PaymentDetailsEntity['ParentHeaderID'] = itemData.BillingHeaderID;
                }
                PaymentDetailsEntity['ClientID'] = formObj['ClientID'];
                PaymentDetailsEntity['CheckRef'] = formObj['CheckRef'];
                PaymentDetailsEntity['PaymentNote'] = formObj['PaymentNote'];
                PaymentDetailsEntity['PaymentDate'] = formObj['PaymentDate'];
                PaymentDetailsEntity['PaymentType'] = formObj['PaymentType'];
                PaymentDetailsEntity['InvoiceNumber'] = itemData.InvoiceNumber;
                PaymentDetailsEntity['InvoiceDate'] = itemData['InvoiceDate'];
                PaymentDetailsEntity['isAdvancePayment'] = true;
                PaymentDetailsEntity['AppliedAmount'] = numeral(itemData.PaymentsApplied).value();
                PaymentDetailsEntity['InitialAmount'] = numeral(itemData.PaymentsApplied).value();

                parent.mangoAPISrvc.createPaymentDetails(PaymentDetailsEntity).subscribe(item => {
                  setTimeout(() => {
                    parent.resetToDefaultValues();
                    parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
                  }, 500);
                });

                // this.mangoAPISrvc.updatePaymentHeader(paymentHeaderObj, paymentHeaderObj['PaymentHeaderID']).then((item) => {
                //   setTimeout(() => {
                //     parent.resetToDefaultValues();
                //     parent.notificationsService.notify('success', parent._translate.instant(data.message));
                //   }, 500);
                // });
              });
            } else {
              if (itemData.selected) {
                const PaymentDetailsEntity = {};
                PaymentDetailsEntity["BillingHeaderID"] = itemData.BillingHeaderID;
                PaymentDetailsEntity["PaymentHeaderID"] = data.data.PaymentHeaderID;
                PaymentDetailsEntity["ClientID"] = data.data.ClientID;
                PaymentDetailsEntity["CheckRef"] = data.data.CheckRef;
                PaymentDetailsEntity["PaymentNote"] = data.data.PaymentNote;
                PaymentDetailsEntity["PaymentDate"] = formObj["PaymentDate"];
                PaymentDetailsEntity["PaymentType"] = data.data.PaymentType;
                PaymentDetailsEntity["InvoiceNumber"] = itemData.InvoiceNumber;
                PaymentDetailsEntity["InvoiceDate"] = itemData.InvoiceDate;
                //
                if (formObj.PaymentType == 'Write-Off') {
                  PaymentDetailsEntity['WriteOffAmount'] = numeral(
                    itemData.PaymentsApplied
                  ).value();
                } else {
                  PaymentDetailsEntity['AppliedAmount'] = numeral(itemData.PaymentsApplied).value();
                  PaymentDetailsEntity['InitialAmount'] = numeral(itemData.PaymentsApplied).value();
                }
                // if (PaymentDetailsEntity['PaymentType'] == 'Credit Card' && this.paymentProfile) {
                //   PaymentDetailsEntity['PaymentNote'] = "Paid By: " + this.paymentProfile.NameOnCard + ", " + "using Credit Card:  ****" + this.paymentProfile.CardNo;
                // }

                // create payment details
                this.mangoAPISrvc.createPaymentDetails(PaymentDetailsEntity).subscribe(
                  data => {
                    // if(i == parent.openInvoicesDataSource.length -1){
                    //   this.resetToDefaultValues();
                    // }
                  },
                  error => {
                    parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
                    parent.mangoAPISrvc.showLoader(false);
                  }
                );
                itemData.AppliedCheckEdit = 0;
                itemData.PaymentsApplied = 0;
                itemData.InvoiceAmount = numeral(itemData.InvoiceAmount).value();
                itemData.InvoiceBalance = numeral(itemData.InvoiceBalance).value();
                itemData.PaymentAmount = numeral(itemData.PaymentAmount).value();
                itemData.PaymentUnapplied = numeral(itemData.PaymentUnapplied).value();
                itemData.TotalPayments = numeral(itemData.TotalPayments).value();

                const parent = this;
                // updating BillingHeader for each selected Open Invoice
                itemData['LastModifiedStaffID'] = parent.encrDecSrvc.getObject(
                  AppConstants.staffID
                );
                itemData['SurChargeAmount'] = paymentFormData['SurChargeAmount']
                  ? paymentFormData['SurChargeAmount']
                  : 0;
                parent.mangoAPISrvc
                  .updateBillingHeader(itemData, itemData.BillingHeaderID)
                  .subscribe(
                    data => {
                      //if(i == parent.openInvoicesDataSource.length -1){
                      parent.resetToDefaultValues();
                      parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
                      //}
                    },
                    error => {
                      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
                      parent.mangoAPISrvc.showLoader(false);
                    }
                  );
              }
            }
          }
        },
        error => {
          parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      this.resetToDefaultValues();
    }
  }

  sendToCompany(data, client, selectedOpenInvoices?) {
    if (this.mangoCompanyData['MangoPaymentNotification']?.length > 0) {
      this.mangoAPISrvc.getListOfUsers().subscribe((response: any) => {
        if (response?.length > 0) {
          const toArr = [];
          response.forEach((staff) => {
            if (
              this.mangoCompanyData['MangoPaymentNotification'].includes(staff.StaffID.toString())
            ) {
              if (staff.Email) {
                toArr.push({ email: staff.Email, name: staff.StaffName });
              }
            }
          });
          this.sendMailtoCompany(data, toArr, client, selectedOpenInvoices);
        }
      });
    }
  }

  sendMailtoCompany(data, toArr, clientN, selectedOpenInvoices) {
    const parent = this;
    const curUser = this.encrDecSrvc.getObject(AppConstants.userName);
    const companysendInBlueObj = {
      //sender: { name: "Mango Billing", email: environment.EMAIL_RETURN_SENDER },
      sender: { name: "Mango Billing", email: environment.EMAIL_RETURN_SENDER },
      to: [{ email: "", name: "" }],
      replyTo: { email: environment.EMAIL_RETURN_SENDER },
      templateId: 17,
      params: {}
    };
    const toObj = { email: "", name: "" };
    if (toArr.length == 0) {
      toObj['email'] = parent.mangoCompanyData['EmailCompany'];
      toObj['name'] = parent.mangoCompanyData['CompanyName'];
      toArr.push(toObj);
    }

    const amt = "$" + numeral(data["PaymentAmount"]).format("0,0.00");
    companysendInBlueObj.params["INVOICES_LENGTH"] = selectedOpenInvoices.length;
    companysendInBlueObj.params["PAYMENT_TYPE"] = data["PaymentType"];
    companysendInBlueObj.params["PAYMENTNOTE"] = data["PaymentNote"];
    if (selectedOpenInvoices && selectedOpenInvoices.length > 0) {
      const tempInvoices = JSON.parse(JSON.stringify(selectedOpenInvoices));
      tempInvoices.forEach((invoice) => {
        invoice["InvoiceAmount"] = "$" + numeral(invoice["InvoiceAmount"]).format("0,0.00");
      });
      companysendInBlueObj.params['INVOICES'] = tempInvoices;
    }
    //companysendInBlueObj.to = [{ email: parent.mangoCompanyData['EmailCompany'], name: parent.mangoCompanyData['CompanyName'] }];
    //companysendInBlueObj.bcc = toArr;
    companysendInBlueObj.to = toArr;
    companysendInBlueObj.params['COMPANYNAME'] = clientN['ClientName'];
    companysendInBlueObj.params['PAYMENTAMOUNT'] = '$' + numeral(amt).format('0,0.00');
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.sendSMTPEmail(companysendInBlueObj).subscribe(
      data => {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', 'Notification', 'Email sent successfully');
      },
      err => {
        // parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  sendMailToCustmor(amt, paymentHeaderId, localClient, paymentNote) {
    const paymentType = this.mycashReceiptsForm.get("PaymentType").value;
    if (
      !localClient ||
      this.mangoCompanyData['EmailPaymentMango'] == false ||
      this.mangoCompanyData['EmailPaymentMango'] == null ||
      ['Write-Off', 'Credit Memo'].includes(paymentType)
    ) {
      return false;
    }
    const sendInBlueObj = {};
    let toObj = { email: "", name: "" };
    const toArr = [];
    const tags = [];

    tags.push(localClient['ClientID'].toString());
    tags.push('none');
    tags.push(paymentHeaderId.toString());
    tags.push(this.loginCompanyId.toString());
    tags.push(localClient['ClientName']);

    localClient['ContactPerson'] = localClient['ContactPerson'] ? localClient['ContactPerson'] : '';

    const clientContacts = this.allEmailContacts
      .filter((item) => item["ClientID"] == localClient["ClientID"])
      .filter((item) => item.isEmailPaymentNotification);
    for (let index = 0; index < clientContacts.length; index++) {
      const element = clientContacts[index];
      if (element['Email'] == null) continue;
      toObj = { email: '', name: '' };
      toObj['name'] = element['ContactName'] ? element['ContactName'] : element['Email'];
      toObj['email'] = element['Email'];
      toArr.push(toObj);
    }

    const senderObj = { name: "", email: "" };
    senderObj["name"] = "Mango Billing";
    senderObj["email"] = environment.EMAIL_RETURN_SENDER;

    sendInBlueObj["replyTo"] = { email: environment.EMAIL_RETURN_SENDER };
    sendInBlueObj["subject"] = "Payment Received";
    sendInBlueObj["tags"] = tags;
    sendInBlueObj["sender"] = senderObj;
    sendInBlueObj["sender.name"] = this.companyName;
    sendInBlueObj["sender.email"] = environment.EMAIL_RETURN_SENDER;
    sendInBlueObj["to"] = this.mangoUtils.deduplicateEmail(toArr);
    amt = "$" + numeral(amt).format("0,0.00");

    const customer = localClient;

    this.mangoAPISrvc
      .getEmailTemplateByTypeAndCompany('cashReceipt', this.companyIdRef)
      .subscribe(result => {
        if (sendInBlueObj['to'].length > 0) {
          if (!result['EmailTemplateID']) {
            this.cashReceiptEmailTemplate = this.mangoUtils.getDefaultTemplate(
              'cashReceipt',
              this.loginCompanyId
            ); // default
          } else {
            this.cashReceiptEmailTemplate = result; // DB
          }
          let htmlContent = this.mangoUtils.getCashReceiptEmailTemplate();
          sendInBlueObj['subject'] = this.cashReceiptEmailTemplate['EmailSubject'];

          const formData = this.myPaymentForm.value;
          if (this.mangoCompanyData.isSurchargeEnabled && formData.SurChargeAmount > 0) {
            this.cashReceiptEmailTemplate[
              'htmlBody'
            ] += `</br></br><div style="text-align: center;font-size: 17px;color: #cb0000;">Transaction subject to a surcharge in the amount of <strong>$${formData.SurChargeAmount}</strong>.</div>`;
          }
          htmlContent = this.mangoUtils.replaceAll(
            htmlContent,
            '%InjectTemplate%',
            this.cashReceiptEmailTemplate['htmlBody']
          );
          htmlContent = this.mangoUtils.replaceAll(
            htmlContent,
            '%ContactName%',
            customer['ContactPerson']
          );
          htmlContent = this.mangoUtils.replaceAll(htmlContent, '%YourFirmName%', this.companyName);
          htmlContent = this.mangoUtils.replaceAll(
            htmlContent,
            '%ClientName%',
            customer['ClientName']
          );
          htmlContent = this.mangoUtils.replaceAll(htmlContent, '%PaymentMemo%', paymentNote ?? '');
          htmlContent = this.mangoUtils.replaceAll(
            htmlContent,
            '%YourFirmName%',
            customer['ClientName']
          );
          htmlContent = this.mangoUtils.replaceAll(htmlContent, '%Amount%', amt);
          htmlContent = this.mangoUtils.replaceAll(htmlContent, '%Email%', customer['Email']);
          sendInBlueObj['htmlContent'] = htmlContent;
          sendInBlueObj['subject'] = this.mangoUtils.replaceAll(
            sendInBlueObj['subject'],
            '%ClientName%',
            customer['ClientName']
          );
          this.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe(data => {
            this.mangoAPISrvc.notify(
              'success',
              this._translate.instant('notification'),
              this._translate.instant('email-sent-successfully')
            );
          });
        }
      });
  }

  filteredInvoicesFun(e) {
    if (!isNaN(parseInt(e.query))) {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getDataByInvoiceNo(e.query).subscribe((clientdata: any) => {
        this.filteredInvoices = clientdata;
        this.mangoAPISrvc.showLoader(false);
      });
    } else {
      this.selInvoiceNo = '';
      Swal.fire({
        icon: 'warning',
        title: `${this._translate.instant('warning')}!`,
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: 'OK',
        text: this._translate.instant('CashReceipts.invoice_number_cannot_be_string')
      });
    }
  }

  handleInvoiceSelectClick(event) {
    // this.selClient = event;
    // this.client = event['ClientName'];
    this.isClientSelected = true;
    this.handleSelectClick(event);
  }

  handleGroupSelect(event) {
    // this.selClient = event;
    // this.client = event['ClientName'];
    this.isClientSelected = true;
    const clientIds = this.clientsList
      .filter(
        item =>
          !item['Inactive'] &&
          item['GroupDescriptionIDArray'] &&
          item['GroupDescriptionIDArray'].includes(event.value)
      )
      .map(item => item['ClientID']);
    event['ClientID'] = clientIds.join(',');
    this.handleSelectClick(event, true);
  }
  /*
     fetching the client full information

   */
  fetchClientInformation(id) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getClientFullinformation(id).subscribe(clientdata => {
      this.selectedCustomer = clientdata;
      this.loadCompanyInfo(clientdata);
      this.loadACHData(clientdata);
      this.mangoAPISrvc.showLoader(false);
    });
  }

  handleSelectClick(event, isGroup?) {
    this.clientAutoCompleteDisable = true;
    const parent = this;
    parent.isClientSelected = true;
    parent.isCheckRef = false;
    const formObj = parent.mycashReceiptsForm.value;
    if (!parent.isSaveAndSplitClicked) {
      parent.updateForm(false);
    }
    parent.mycashReceiptsForm.controls['PaymentDate'].setValue(formObj['PaymentDate']);
    parent.mycashReceiptsForm.controls['PaymentType'].setValue(
      parent.encrDecSrvc.getObject(AppConstants.cashReceiptPaymentType)
    );
    const paymentType = parent.encrDecSrvc.getObject(AppConstants.cashReceiptPaymentType);
    if (paymentType == "Check") {
      parent.isCheckRef = true;
    }
    if (!isGroup) {
      parent.selectedCustomer = event;
      parent.client = event.ClientName;
      parent.myAchReceiptsForm.controls['FirstName'].setValue(event.ClientName.substring(0, 19));
      parent.mycashReceiptsForm.controls['ClientID'].setValue(event.ClientID);
      parent.fetchClientInformation(event.ClientID);
      parent.selClient = event;
    }

    parent.paymentProfile = null;
    parent.isDialogFormValid = false;
    parent.isFormValid = false;
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Authorization", parent.encrDecSrvc.getObject("token"));
    const observableBatch = [];
    parent.getEmailcontactsList(event.ClientID);
    const urlOne = parent.http.get(
      `${environment.API_URL}/accounting/getOpenInvoicesById/[${event.ClientID}]`,
      { headers: headers, withCredentials: true }
    );
    const urlTwo = parent.http.get(
      `${environment.API_URL}/accounting/getOpenRetainersById/[${event.ClientID}]`,
      { headers: headers, withCredentials: true }
    );
    observableBatch.push(urlOne);
    observableBatch.push(urlTwo);
    forkJoin(observableBatch).subscribe(
      (data: any) => {
        this.openInvoicesDataSource = data[0].map(function (obj) {
          obj['selected'] = false;
          obj.LateFeeAmount = obj.LateFeeAmount ? numeral(obj.LateFeeAmount).value() : 0;
          obj.InvoiceBalance = obj.InvoiceBalance ? numeral(obj.InvoiceBalance).value() : 0;
          obj.InvoiceAmount = obj.InvoiceAmount ? numeral(obj.InvoiceAmount).value() : 0;
          obj.TotalTax = obj.TotalTax ? numeral(obj.TotalTax).value() : 0;
          obj.TotalTaxExpenses = obj.TotalTaxExpenses ? numeral(obj.TotalTaxExpenses).value() : 0;
          return obj;
        });
        this.openRetainersDataSource = data[1].map(function (obj) {
          obj.PaymentUnapplied = obj.PaymentUnapplied.replace('$', '');
          return obj;
        });

        this.updateInvoiceTotals();
        this.updateMoneyFormat();
        this.copyInvoicesDataSource = this.mangoUtils.deepCloneObject(this.openInvoicesDataSource);
        this.clientAutoCompleteDisable = false;
        this.validateForm();
      },
      err => console.error(err)
    );
    this.isAutoApply=false;
  }

  getEmailcontactsList(clientId) {
    const parent = this;
    parent.allEmailContacts = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getEmailcontactsByClientID(clientId).subscribe((response: any) => {
      parent.allEmailContacts = response;
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  paymentsAppliedOnChange(item) {
    const parent = this;
    parent.isFormDisble = false;
    const formObj = parent.mycashReceiptsForm.value;
    if (formObj.PaymentType == "Check" && !parent.isRetainerApplied) {
      parent.isCheckRef = true;
    }

    let unappliedAmt = numeral(formObj.PaymentUnapplied).value();
    let invoiceBal = numeral(item.InvoiceBalance).value();
    let totalPayments = numeral(item.TotalPayments).value();
    let paymentsapplied = numeral(item.PaymentsApplied).value();
    parent.isPaymentApplied = false;
    item.isEditing = false;
    if (paymentsapplied <= 0) {
      item['selected'] = false;
      if (item.PrevPaymentsApplied > 0) {
        const valueStr = unappliedAmt + numeral(item.PrevPaymentsApplied).value();
        parent.mycashReceiptsForm.controls["PaymentUnapplied"].setValue(valueStr);
        item.InvoiceBalance = numeral((invoiceBal + item.PrevPaymentsApplied).toFixed(2)).format(
          '0,0.00'
        );
        item.TotalPayments = numeral((totalPayments - item.PrevPaymentsApplied).toFixed(2)).format(
          '0,0.00'
        );
        item.TotalPayments = numeral(item.TotalPayments).value();
        item.InvoiceBalance = numeral(item.InvoiceBalance).value();
        parent.updateInvoiceTotals();
      }
      item.PaymentsApplied = 0;
      item.PrevPaymentsApplied = 0;
      return;
    }
    if (item.PaymentsApplied == item.PrevPaymentsApplied) {
      return;
    }
    if (
      item.PaymentsApplied &&
      item.PrevPaymentsApplied &&
      item.PaymentsApplied != item.PrevPaymentsApplied
    ) {
      unappliedAmt = unappliedAmt + item.PrevPaymentsApplied;
      totalPayments = totalPayments - item.PrevPaymentsApplied; //0
      invoiceBal = item.InvoiceAmount - totalPayments;
      if (unappliedAmt > formObj.PaymentAmount) unappliedAmt = formObj.PaymentAmount;
    }
    item['selected'] = true;
    // if unapplied balance is zero
    if (item.selected && unappliedAmt === 0) {
      parent.mangoAPISrvc.notify(
        'error',
        'Error!',
        parent._translate.instant('The Unapplied Balance is Zero. No Payment can be Applied.')
      );
      item.selected = false;
    } else {
      const computedBalace = invoiceBal - paymentsapplied;
      if (computedBalace < 0) {
        paymentsapplied = invoiceBal;
      }
      item.PaymentsApplied = paymentsapplied;
      item.PrevPaymentsApplied = paymentsapplied;
      const valueStr = unappliedAmt - numeral(item.PaymentsApplied).value();
      parent.mycashReceiptsForm.controls["PaymentUnapplied"].setValue(valueStr);
      item.InvoiceBalance = numeral((invoiceBal - paymentsapplied).toFixed(2)).format("0,0.00");
      item.TotalPayments = numeral((totalPayments + paymentsapplied).toFixed(2)).format("0,0.00");
      item.TotalPayments = numeral(item.TotalPayments).value();
      item.InvoiceBalance = numeral(item.InvoiceBalance).value();

      parent.updateInvoiceTotals();
    }
    if (parent.openInvoicesDataSource.filter(invoices => invoices.selected == true).length > 0) {
      parent.isPaymentApplied = true;
    }

    if (
      item.InvoiceType == 'Retainer Invoice' &&
      parent.isPaymentApplied &&
      formObj.PaymentNote == null
    ) {
      parent.mycashReceiptsForm.controls['PaymentNote'].setValue(item.DescriptionShort);
    }

    if (parent.isPaymentApplied) {
      parent.isCheckRef = false;
      parent.isFormDisble = true;
    }
  }

  /* redirecting to Mail */
  calculateData(item) {
    const parent = this;
    parent.isFormDisble = false;
    const formObj = parent.mycashReceiptsForm.value;
    if (formObj.PaymentType == "Check" && !parent.isRetainerApplied) {
      parent.isCheckRef = true;
    }

    const unappliedAmt = numeral(formObj.PaymentUnapplied).value();
    const invoiceBal = numeral(item.InvoiceBalance).value();
    const totalPayments = numeral(item.TotalPayments).value();
    const paymentsapplied = numeral(item.PaymentsApplied).value();
    parent.isPaymentApplied = false;
    // if unapplied balance is zero
    if (item.selected && unappliedAmt === 0) {
      parent.mangoAPISrvc.notify(
        'error',
        'Error!',
        parent._translate.instant('The Unapplied Balance is Zero. No Payment can be Applied.')
      );
      item.selected = false;
    } else {
      if (item.selected) {
        const computedBalace = invoiceBal - unappliedAmt;
        if (computedBalace >= 0) {
          item.PaymentsApplied = unappliedAmt;
          item.PrevPaymentsApplied = unappliedAmt;
          const valueStr = (unappliedAmt - numeral(item.PaymentsApplied).value()).toFixed(2);
          parent.mycashReceiptsForm.controls["PaymentUnapplied"].setValue(valueStr);
          item.InvoiceBalance = numeral((invoiceBal - unappliedAmt).toFixed(2)).format("0,0.00");
          item.TotalPayments = numeral((totalPayments + unappliedAmt).toFixed(2)).format("0,0.00");
        } else {
          item.PaymentsApplied = invoiceBal;
          item.PrevPaymentsApplied = invoiceBal;
          const valueStr = Math.abs(numeral(computedBalace).value()).toFixed(2);
          parent.mycashReceiptsForm.controls["PaymentUnapplied"].setValue(valueStr);
          item.InvoiceBalance = numeral().format("0,0.00");
          item.TotalPayments = numeral((totalPayments + invoiceBal).toFixed(2)).format("0,0.00");
        }
      } else {
        item.PaymentsApplied = 0;
        item.PrevPaymentsApplied = 0;
        const valueStr = (unappliedAmt + paymentsapplied).toFixed(2);
        parent.mycashReceiptsForm.get("PaymentUnapplied").setValue(valueStr);
        item.InvoiceBalance = numeral((invoiceBal + paymentsapplied).toFixed(2)).format("0,0.00");
        item.TotalPayments = numeral((totalPayments - paymentsapplied).toFixed(2)).format("0,0.00");
      }
      item.TotalPayments = numeral(item.TotalPayments).value();
      item.InvoiceBalance = numeral(item.InvoiceBalance).value();

      parent.updateInvoiceTotals();
    }
    if (parent.openInvoicesDataSource.filter(invoices => invoices.selected == true).length > 0) {
      parent.isPaymentApplied = true;
    }

    if (item.InvoiceType == 'Retainer Invoice' && item.selected == false) {
      //remove retainer invoice
      let val = parent.mycashReceiptsForm.controls["PaymentNote"].value?.trim() ?? "";
      const regex = / *\(Retainer Invoice #[^)]*\) */i;
      if (regex.test(val)) {
        val = val
          .replaceAll(new RegExp(regex, 'g'), '')
          .replaceAll(item.DescriptionShort, '')
          .trim()
          .slice(0, -1);
        parent.mycashReceiptsForm.controls['PaymentNote'].setValue(val);
      }
    } else {
      if (
        item.InvoiceType == 'Retainer Invoice' &&
        parent.isPaymentApplied &&
        formObj.PaymentNote == null
      ) {
        parent.mycashReceiptsForm.controls['PaymentNote'].setValue(
          item.DescriptionShort + ` (Retainer Invoice #${item.InvoiceNumber})`
        );
      } else if (
        item.InvoiceType == 'Retainer Invoice' &&
        parent.isPaymentApplied &&
        formObj.PaymentNote !== null
      ) {
        let val = parent.mycashReceiptsForm.controls["PaymentNote"].value.trim() ?? "";
        const regex = / *\(Retainer Invoice #[^)]*\) */i;
        if (regex.test(val)) {
          val = val
            .replaceAll(new RegExp(regex, 'g'), '')
            .replaceAll(';', '')
            .replaceAll(item.DescriptionShort, '');
        }
        parent.mycashReceiptsForm.controls['PaymentNote'].setValue(
          [val, item.DescriptionShort].filter(Boolean).join('; ') +
            ` (Retainer Invoice #${item.InvoiceNumber})`
        );
      }
    }

    if (parent.isPaymentApplied) {
      parent.isCheckRef = false;
      parent.isFormDisble = true;
    }
  }

  autoApply() {
    if (this.isClientSelected && this.openInvoicesDataSource.length > 0) {
      if (this.isAutoApply) {
        if(parseFloat(this.mycashReceiptsForm.value["PaymentUnapplied"]) == 0){          
          this.mangoAPISrvc.notify(
            "error",
            "Error!",
            this._translate.instant("The Unapplied Balance is Zero. No Payment can be Applied.")
          );
          setTimeout(() => {
            this.isAutoApply = false;
          }, 250);
          return;
        }
        this.openInvoicesDataSource.forEach(item => {
          item.selected = false;
          this.calculateData(item);
        });
        this.openInvoicesDataSource.forEach((item) => {
          const formObj = this.mycashReceiptsForm.value;
          const unappliedAmt = parseFloat(formObj["PaymentUnapplied"]);
          if (unappliedAmt > 0) {
            item.selected = true;
            this.calculateData(item);
          }
        });
      } else {
        this.openInvoicesDataSource.forEach(item => {
          item.selected = false;
          this.calculateData(item);
        });
      }
    }
  }

  updateMoneyFormat() {
    this.openInvoicesDataSource = this.openInvoicesDataSource.map(function (obj) {
      obj['selected'] = false;
      obj['InvoiceAmount'] = numeral(obj['InvoiceAmount']).value();
      obj['TotalPayments'] = numeral(obj['TotalPayments']).value();
      obj['InvoiceBalance'] = obj['InvoiceBalance'];
      obj["PaymentsApplied"] = parseFloat(numeral(obj["PaymentsApplied"] ? obj["PaymentsApplied"] : "0").format("0.00"));
      obj['PaymentAmount'] = numeral(obj['PaymentAmount']).format('0,0.00');
      obj["PaymentUnapplied"] = parseFloat(numeral(obj["PaymentUnapplied"] ? obj["PaymentUnapplied"] : "0").format("0.00")); 
      return obj;
    });
    this.openRetainersDataSource = this.openRetainersDataSource.map(function (obj) {
      obj['PaymentAmount'] = numeral(obj['PaymentAmount']).format('0,0.00');
      obj['PaymentUnapplied'] = numeral(obj['PaymentUnapplied']).format('0,0.00');
      return obj;
    });
  }

  /*
 updating invoice total
 */
  updateInvoiceTotals() {
    const totalsAmt = this.openInvoicesDataSource.reduce(function (a, b) {
      return numeral(a).value() + numeral(b.InvoiceAmount).value();
    }, 0);
    this.totalNumberAmt = numeral(totalsAmt).format('0,0.00');

    const totalsPrev = this.openInvoicesDataSource.reduce(function (a, b) {
      return numeral(a).value() + numeral(b.TotalPayments).value();
    }, 0);
    this.totalNumberPrev = numeral(totalsPrev).format('0,0.00');

    const totalsBal = this.openInvoicesDataSource.reduce(function (a, b) {
      return numeral(a).value() + numeral(b.InvoiceBalance).value();
    }, 0);
    this.totalNumberBalance = numeral(totalsBal).format('0,0.00');

    const totalsPay = this.openInvoicesDataSource.reduce(function (a, b) {
      return numeral(a).value() + numeral(b.PaymentsApplied).value();
    }, 0);
    this.totalNumberPayments = numeral(totalsPay).format('0,0.00');

    const totalsPayAmt = this.openRetainersDataSource.reduce(function (a, b) {
      return numeral(a).value() + numeral(b.PaymentAmount).value();
    }, 0);
    this.totalNumberPaymentamt = numeral(totalsPayAmt).format('0,0.00');

    const totalsPayUn = this.openRetainersDataSource.reduce(function (a, b) {
      return numeral(a).value() + numeral(b.PaymentUnapplied).value();
    }, 0);
    this.totalNumberPaymentUnappliedValue = numeral(totalsPayUn).value();
    this.totalNumberPaymentUnapplied = numeral(totalsPayUn).format('0,0.00');
  }
  /*
  clear radio button selection
  */
  clearSelection() {
    if (!this.isRetainerApplied) {
      return false;
    }
    this.selectedValue = null;
    this.selectedRowValue = null;
    this.selectedPaymentDate = null;
    this.openInvoicesDataSource = [];
    this.mycashReceiptsForm.controls['PaymentUnapplied'].setValue('0.00');
    this.mycashReceiptsForm.controls['PaymentAmount'].setValue('0.00');
    this.mycashReceiptsForm.controls['CheckRef'].setValue('');
    this.mycashReceiptsForm.controls['PaymentType'].setValue('');
    this.mycashReceiptsForm.controls['PaymentNote'].setValue('');
    this.isPaymentApplied = false;
    this.isRetainerApplied = false;
    this.isFormDisble = false;
    this.isCheckRef = false;
    this.showCardoption = false;
    this.showAchCardoption = false;
    this.paymentHeaderID = '';
    //this.selectedCustomer = "";
    this.openInvoicesDataSource = this.mangoUtils.deepCloneObject(this.copyInvoicesDataSource);
    this.updateInvoiceTotals();
    this.updateCombinedVisible();
  }

  /*
 show seleted Retainer Item
 */
  showRowData(item) {
    const parent = this;
    parent.isRetainerApplied = true;
    parent.selectedRowValue = item;
    parent.globalValidForm = true;
    if (
      parent.mycashReceiptsForm.get('PaymentType').value != 'Refund' ||
      parent.mycashReceiptsForm.get('PaymentType').value != 'Refund Advance Payment'
    ) {
      parent.showCardoption = false;
      parent.showAchCardoption = false;
      parent.updateCombinedVisible();
      const paymentUnapplied = numeral(item["PaymentUnapplied"]).value();
      const paymentamt = numeral(item["PaymentAmount"]).value();
      const paymentNote = [
        (parent.mycashReceiptsForm.get("PaymentNote").value ?? "")
          .replaceAll("Advance Payment - " + item["PaymentNote"], "")
          .replaceAll(";", "")
          .trim(),
        item['PaymentNote'] ? 'Advance Payment - ' + item['PaymentNote'] : ''
      ]
        .filter(Boolean)
        .join('; ');
      parent.mycashReceiptsForm.controls['PaymentUnapplied'].setValue(paymentUnapplied);
      parent.mycashReceiptsForm.controls['PaymentAmount'].setValue(paymentamt);
      parent.mycashReceiptsForm.controls['CheckRef'].setValue(item['CheckRef']);
      parent.mycashReceiptsForm.controls['PaymentType'].setValue(item['PaymentType']);
      parent.mycashReceiptsForm.controls['PaymentNote'].setValue(paymentNote);
      parent.openInvoicesDataSource = [];
      //parent.isFormValid = true;
      parent.isEditMode = true;
      parent.isFormDisble = true;
      parent.isCheckRef = false;
      parent.paymentHeaderID = item['PaymentHeaderID'];
      parent.selectedPaymentDate = item['PaymentDate'];
      parent.openInvoicesDataSource = parent.mangoUtils.deepCloneObject(
        parent.copyInvoicesDataSource
      );
      if (parent.mycashReceiptsForm.controls['PaymentType']['value'].trim() == 'Credit Card') {
        if (!parent.selectedCustomer || !parent.selectedCustomer.ClientID) {
          return;
        }
        if (parent.mangoCompanyData['CCmerchantID']) {
          parent.showCardoption = true;
          parent.updateCombinedVisible();
        }
      }
    } else if (
      parent.mycashReceiptsForm.get('PaymentType').value == 'Refund' ||
      parent.mycashReceiptsForm.get('PaymentType').value == 'Refund Advance Payment'
    ) {
      item.PaymentUnapplied = numeral(item.PaymentUnapplied).value();
      const calcMat =
        item.PaymentUnapplied -
        numeral(parent.mycashReceiptsForm.controls['PaymentAmount']['value']).value();
      if (calcMat < 0) {
        Swal.fire({
          icon: 'warning',
          title: `${parent._translate.instant('warning')}!`,
          showCancelButton: false,
          allowEscapeKey: true,
          allowEnterKey: true,
          confirmButtonText: 'OK',
          text: parent._translate.instant('CashReceipts.the-refund-amount')
        });
        parent.globalValidForm = false;
        item.PaymentUnapplied = numeral(item.PaymentUnapplied).format('0,0.00');
        this.mycashReceiptsForm.controls['PaymentUnapplied'].setValue(
          parent.mycashReceiptsForm.controls['PaymentAmount']['value']
        );
      } else {
        parent.mycashReceiptsForm.controls['PaymentUnapplied'].setValue(0.0);
        item.PaymentUnapplied = numeral(item.PaymentUnapplied).format('0,0.00');
      }
    } else {
      if (item['isRetainerApplied']) {
        return false;
      }
      item.PaymentUnapplied = numeral(item.PaymentUnapplied).value();
      const calcMat =
        item.PaymentUnapplied -
        numeral(parent.mycashReceiptsForm.controls['PaymentAmount']['value']).value();
      parent.mycashReceiptsForm.controls['PaymentUnapplied'].setValue(calcMat);
      if (calcMat < 0) {
        Swal.fire({
          icon: 'warning',
          title: `${parent._translate.instant('warning')}!`,
          showCancelButton: false,
          allowEscapeKey: true,
          allowEnterKey: true,
          confirmButtonText: 'OK',
          text: parent._translate.instant('CashReceipts.the-refund-amount')
        });
        item.PaymentUnapplied = numeral(item.PaymentUnapplied).format('0,0.00');

        // this.mycashReceiptsForm.controls['PaymentUnapplied'].setValue(0);
        //item.PaymentUnapplied = (calcMat*-1);
      } else {
        // item.PaymentUnapplied -= this.mycashReceiptsForm.controls['PaymentAmount'].value;
      }
      // item.PaymentUnapplied = numeral(item.PaymentUnapplied).format('0,0.00');
    }

    item['isRetainerApplied'] = true;

    if (parent.openInvoicesDataSource.filter(invoices => invoices.selected == true).length > 0) {
      parent.isPaymentApplied = true;
    }

    parent.updateInvoiceTotals();
  }

  processACHData() {
    const self = this;
    self.amountToCharge = numeral(self.mycashReceiptsForm.get("PaymentAmount").value).value();
    self.myAchReceiptsForm.controls["Amount"].setValue(
      "$" + numeral(self.amountToCharge).format("0,0.00")
    );
    if (
      !self.selectedCustomer.BusStreet1 ||
      !self.selectedCustomer.BusZip ||
      !self.selectedCustomer.Email
    ) {
      Swal.fire({
        title: self._translate.instant('confirmation'),
        html: `<div>${self._translate.instant(
          'CashReceipts.client-zip-email-required'
        )}</div> <div> ${self._translate.instant(
          'CashReceipts.to-process-ach-transaction'
        )}.</div> <div>${self._translate.instant('CashReceipts.update-client-record-now')}</div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${self._translate.instant('Go-to-Client')}!`,
        cancelButtonText: self._translate.instant('no_cancel')
      }).then(result => {
        if (result.value) {
          self.encrDecSrvc.addObject(AppConstants.selectedClientRecord, self.selectedCustomer);
          self.encrDecSrvc.addObject(AppConstants.clientID, self.selectedCustomer['ClientID']);
          self.encrDecSrvc.addObject(AppConstants.ClientName, self.selectedCustomer['ClientName']);
          self.mangoAPISrvc.showLoader(true);
          self.mangoAPISrvc
            .getAllDataCounts(self.selectedCustomer['ClientID'])
            .subscribe(function (data) {
              self.encrDecSrvc.addObject(AppConstants.allDataCountsForClient, data);
              self.router
                .navigate([AppConstants.clientRoutePath + '/' + AppConstants.viewRoutePath])
                .then(res => self.mangoAPISrvc.fireClientView(true));
              self.mangoAPISrvc.showLoader(false);
            });
        }
      });
      return false;
    }

    const amt = "$" + numeral(self.myAchReceiptsForm.controls["Amount"].value).format("0,0.00");
    self.myAchReceiptsForm.controls["Amount"].setValue(amt);
    self.myAchReceiptsForm.controls["FirstName"].setValue(
      self.selClient.ClientName.substring(0, 19)
    );
    self.myAchReceiptsForm.controls['LastName'].setValue(
      self.selClient.ClientName.substring(0, 19)
    );
    self.showACHDialog = true;
    self.showAchCardoption = false;
    self.mangoAPISrvc
      .getPaymentProfile(self.selectedCustomer.ClientID, 'ACH')
      .subscribe((result: any) => {
        if (result == null || result.length == 0) {
          self.showAchNewCardoption = true;
        } else {
          if (result.TransType == 'ACH') {
            self.showAchNewCardoption = false;
            self.achProfile = result;
            self.isBusiness = result.isBusiness == 'true' ? 'true' : 'false';
          }
        }
      });
  }

  changePaymentType(control: any) {
    this.isCheckRef = false;
    this.isCheckNo = false;
    this.isUnapplied = false;
    this.showCardoption = false;
    this.showAchCardoption = false;
    this.isCpaPay = false;
    this.isPaymentFlowRequired = false;
    this.updateCombinedVisible();
    if (this.transactionData) {
      this.isPaymentFlowRequired = true;
    }
    this.mycashReceiptsForm.controls['CheckRef'].setValue('');
    if (control['value'] == 'Check') {
      this.isCheckRef = true;
      this.isFormValid = false;
      this.isCheckNo = true;
    } else if (control['value'] == 'Write-Off') {
      this.isUnapplied = true;
    } else if (control["value"] == "ACH") {
      const self = this;
      self.achProfile = null;
      // if (self.mangoCompanyData['LexOperatingMerchantAccountId'] && self.mangoCompanyData['LexStatusMessage'] == 'Open') {
      //   self.isCpaPay = true;
      //   self.showAchCardoption = true;
      // } else if (self.mangoCompanyData['CCmerchantID']) {
      //   self.showAchCardoption = true;
      //   return false;
      // } else
      if (!self.mangoCompanyData.StaxStatus) {
        Swal.fire({
          title: self._translate.instant('confirmation'),
          html: `<div>Your MangoPayments enrollment is not done.Do you want to enroll?</div>`,
          icon: 'warning',
          showCancelButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: 'Enroll Now',
          cancelButtonText: 'Cancel'
        }).then(result => {
          if (result.value) {
            self.mangoAPISrvc.isFormChanged = false;
            self.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
            self.router.navigate(['accounting/cards-view']);
          } else {
            self.mycashReceiptsForm.controls['PaymentType'].setValue('');
          }
        });
        return false;
      }
      if (!self.selectedCustomer.ClientID) {
        return false;
      }
      self.mangoAPISrvc
        .getPaymentProfile(self.selectedCustomer.ClientID, 'ACH')
        .subscribe((result: any) => {
          if (result == null || result.length == 0) {
            self.showAchNewCardoption = true;
          } else {
            if (result.TransType == 'ACH') {
              self.showAchNewCardoption = false;
              self.achProfile = result;
              self.isBusiness = result.isBusiness == 'true' ? 'true' : 'false';
            }
          }
        });
    } else if (control["value"] == "Credit Card") {
      const self = this;
      if (!self.selectedCustomer || !self.selectedCustomer.ClientID) {
        return;
      }
      // if (self.mangoCompanyData['LexOperatingMerchantAccountId'] && self.mangoCompanyData['LexStatusMessage'] == 'Open') {
      //   self.isCpaPay = true;
      //   self.showCardoption = true;
      // } else if (self.mangoCompanyData['CCmerchantID']) {
      //   self.showCardoption = true;
      // } else
      if (!self.mangoCompanyData.StaxStatus) {
        Swal.fire({
          title: self._translate.instant('confirmation'),
          html: `<div>Your MangoPayments enrollment is not done.Do you want to enroll?</div>`,
          icon: 'warning',
          showCancelButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: 'Enroll Now',
          cancelButtonText: 'Cancel'
        }).then(result => {
          if (result.value) {
            self.mangoAPISrvc.isFormChanged = false;
            self.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
            self.router.navigate(['accounting/cards-view']);
          } else {
            self.isDebitCard = false;
            self.myPaymentForm.controls['SurChargeAmount'].setValue(0);
            self.myPaymentForm.controls['withSurcharge'].setValue(0);
            self.mycashReceiptsForm.controls['PaymentType'].setValue('');
          }
        });
        return false;
      }
    }

    if (
      this.openRetainersDataSource.length == 0 &&
      (this.mycashReceiptsForm.get('PaymentType').value == 'Refund' ||
        this.mycashReceiptsForm.get('PaymentType').value == 'Refund Advance Payment')
    ) {
      Swal.fire({
        icon: 'warning',
        title: `${this._translate.instant('warning')}!`,
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: 'OK',
        text: `${this._translate.instant('no-advance-payments')}!`
      });
    }
  }

  showHideTable() {
    if (this.showTable) {
      this.showText = 'Show';
    } else {
      this.showText = 'Hide';
    }
    this.showTable = !this.showTable;
  }

  changeUSMoney(control: any, control2: any) {
    this.isValidTaxAmt = false;
    this.isFormValid = true;
    const enteredValue = control["value"];
    if (enteredValue == "" || enteredValue === null) {
      control.setValue("$0.00");
      control2.setValue(0.0);
      this.isValidTaxAmt = true;
      this.isFormValid = false;
      return false;
    } else {
      const val = numeral(numeral(enteredValue).value()).format("$0,0.00");
      control.setValue(val);
      control2.setValue(numeral(enteredValue).value());
    }
    //control.setValue(numeral().format('0,0.00'));
    //if(!this.isEditMode){

    // @ravi commented : 11/July/2022 : unapplied amount issue

    // let total = (parseFloat(enteredValue.replace("$", "")) - numeral(this.totalNumberPayments).value());
    // if (total > 0) {
    //   control2.setValue(total);
    // } else {
    //   control2.setValue(0.00);
    // }
    this.selectedValue = null;
    //}

    if (this.isAutoApply) {
      this.autoApply();
    }
  }

  processData() {
    const self = this;
    self.amountToCharge = numeral(this.mycashReceiptsForm.get("PaymentAmount").value).value();
    self.myPaymentForm.controls["amount"].setValue(
      "$" + numeral(self.amountToCharge).format("0,0.00")
    );
    if (!self.showCardoption) {
      return false;
    }

    if (
      !self.selectedCustomer.BusStreet1 ||
      !self.selectedCustomer.BusZip ||
      !self.selectedCustomer.Email
    ) {
      Swal.fire({
        title: self._translate.instant('confirmation'),
        html: `<div>${self._translate.instant(
          'CashReceipts.client-zip-email-required'
        )}</div> <div> ${self._translate.instant(
          'CashReceipts.to-process-ach-transaction'
        )}.</div> <div>${self._translate.instant('CashReceipts.update-client-record-now')}</div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${self._translate.instant('Go-to-Client')}!`,
        cancelButtonText: self._translate.instant('no_cancel')
      }).then(result => {
        if (result.value) {
          self.router.navigate([`/clients/` + self.selectedCustomer.ClientID + `/edit`]);
        }
      });
      return false;
    }

    self.paymentProfile = null;
    self.flagAddNewCard = false;
    self.mangoAPISrvc
      .getPaymentProfile(self.selectedCustomer.ClientID, 'CC')
      .subscribe((result: any) => {
        self.updateDialogForm();
        if (result == null || result.length == 0) {
          self.addCardValidators();
          self.isDialogFormValid = false;
          self.dialogDisplay = true;
        } else {
          self.removeCardValidators();
          self.paymentProfile = result;
          self.isDialogFormValid = true;
          self.dialogDisplay = true;
        }
        self.updateCombinedVisible();
      });
  }

  addNewCard() {
    const self = this;
    if (!self.showCardoption && !self.isCpaPay) {
      return false;
    }

    if (self.isCpaPay) {
      self.isNewCpaPay = true;
      self.dialogWidth = "50vw";
      const formData = self.mycashReceiptsForm.value;
      self.counter = 0;
      self.newcardurl = '';
      self.mangoAPISrvc.showLoader(true);
      self.mangoAPISrvc
        .processLexPayments(
          self.mangoCompanyData,
          self.selectedCustomer,
          formData,
          self.loginCompanyId
        )
        .subscribe(
          (data: any) => {
            self.counter = 0;
            self.newcardurl = self.sanitizer.bypassSecurityTrustResourceUrl(data.formUrl);
            self.mangoAPISrvc.showLoader(false);
            setTimeout(() => {
              self.counter = 0;
            }, 200);
          },
          (data: any) => {
            self.mangoAPISrvc.showLoader(false);
          }
        );
    } else {
      self.dialogWidth = '30vw';
      self.updateDialogForm();
      self.addCardValidators();
      self.isDialogFormValid = false;
      self.flagAddNewCard = true;
    }
  }

  singularCCTokenize() {
    const parent = this;
    const formData = this.myPaymentForm.value;
    const cashFormData = this.mycashReceiptsForm.value;
    const CCPayment = {};
    CCPayment["MerchantID"] = this.mangoCompanyData["CCmerchantID"];
    CCPayment["Login"] = this.mangoCompanyData["CClogin"];
    CCPayment["Password"] = this.mangoCompanyData["CCpassword"];
    CCPayment["CardType"] =
      formData["cardType"] == "" || formData["cardType"] == null ? "VISA" : formData["cardType"];
    CCPayment["CardNumber"] = formData["cardno"].replace(/\-/g, "");
    CCPayment["CVV"] = formData["securityCode"].replace(/\-/g, "");
    CCPayment["ExpDate"] = formData["expDate"].replace(/\//g, "");
    CCPayment["Amount"] = "0";
    CCPayment["FirstName"] = formData["nameOnCard"];
    CCPayment["LastName"] = formData["nameOnCard"];
    CCPayment["EmailAddress"] = parent.companyData["email"];
    CCPayment["Address1"] = parent.companyData["address"];
    CCPayment["City"] = parent.companyData["city"];
    CCPayment["State"] = parent.companyData["state"];
    CCPayment["Country"] = parent.companyData["country"];
    CCPayment["Zip"] = parent.companyData["zip"].replace(/\-/g, "");

    const sbpMangoDbProfile = {};
    sbpMangoDbProfile["ClientID"] = cashFormData["ClientID"];
    sbpMangoDbProfile["expiryDate"] = formData["expDate"];
    sbpMangoDbProfile["nameOnCard"] = formData["nameOnCard"];
    sbpMangoDbProfile["active"] = true;
    parent.mangoAPISrvc.showLoader(true);

    // deleting old card information
    parent.mangoAPISrvc.deleteProfile(cashFormData['ClientID']).subscribe(
      obj => {
        // saving new profile
        parent.mangoAPISrvc.createCustomerCC(CCPayment).subscribe(
          (data: any) => {
            if (data.Status == 'success') {
              sbpMangoDbProfile['TransactionReference'] = data['Confirmation'];
              sbpMangoDbProfile['TransType'] = 'CC';
              sbpMangoDbProfile['CardNo'] = CCPayment['CardNumber'].substr(
                CCPayment['CardNumber'].length - 4,
                CCPayment['CardNumber'].length
              );
              // create Custmor Cr Card Profile in mango DB
              parent.mangoAPISrvc.saveProfileIDinMango(sbpMangoDbProfile).subscribe(
                carddata => {
                  parent.dialogDisplay = false;
                  parent.isDialogFormValid = false;
                  parent.updateCombinedVisible();
                  sbpMangoDbProfile["CustomerCardID"] = carddata["id"];
                  sbpMangoDbProfile["ExpiryDate"] = sbpMangoDbProfile["expiryDate"];
                  sbpMangoDbProfile["NameOnCard"] = sbpMangoDbProfile["nameOnCard"];
                  parent.singularProcessPayment(sbpMangoDbProfile, true);
                  parent.mangoAPISrvc.showLoader(false);
                },
                error => {
                  parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
                  parent.mangoAPISrvc.showLoader(false);
                }
              );
            } else {
              parent.mangoAPISrvc.notify('error', 'Error!', data.Message);
              parent.mangoAPISrvc.showLoader(false);
            }
          },
          error => {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
            parent.mangoAPISrvc.showLoader(false);
          }
        );
      },
      error => {
        parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  singularProcessPayment(obj, isCreateTime) {
    const parent = this;
    const authProfile = {};
    const cashFormData = this.mycashReceiptsForm.value;

    authProfile['MerchantID'] = this.mangoCompanyData['CCmerchantID'];
    authProfile['Login'] = this.mangoCompanyData['CClogin'];
    authProfile['Password'] = this.mangoCompanyData['CCpassword'];
    authProfile['Amount'] = numeral(cashFormData.PaymentAmount).value();
    authProfile['StandardEntryCode'] = 'SEC';
    authProfile['AuthOnly'] = false;
    authProfile['CheckNegativeAccounts'] = false;
    authProfile['Token'] = obj['TransactionReference'];
    obj['CustomerCardID'] = obj['CustomerCardID']
      ? obj['CustomerCardID']
      : parent.paymentProfile['CustomerCardID'];
    parent.mangoAPISrvc.showLoader(true);

    if (parent.isCpaPay) {
      const typeOfTransaction = cashFormData.PaymentType == "Credit Card" ? "CC" : "ACH";
      parent.counter = 0;
      const obj = {};
      obj["uniqueId"] = parent.selectedCustomer.ClientID.toString() + "$" + typeOfTransaction;
      obj["Token"] = authProfile["Token"];
      obj["Amount"] = parseFloat(authProfile["Amount"]).toFixed(2);
      obj["LexOperatingMerchantAccountId"] =
        parent.mangoCompanyData["LexOperatingMerchantAccountId"];
      obj["LexAccessToken"] = parent.mangoCompanyData["LexAccessToken"];

      if (
        parent.paymentProfile &&
        parent.paymentProfile.NameOnCard &&
        parent.paymentProfile.CardNo
      ) {
        const note =
          "Paid By: " +
          parent.paymentProfile.NameOnCard +
          ', ' +
          'using Credit Card:  ****' +
          parent.paymentProfile.CardNo +
          ' (CR)';
        parent.mycashReceiptsForm.controls['PaymentNote'].setValue(note);
      }

      parent.mangoAPISrvc.processLexPaymentsWithToken(obj).subscribe(
        (result: any) => {
          parent.mangoAPISrvc.isFormChanged = true;
          parent.dialogDisplay = false;
          parent.isDialogFormValid = false;
          this.updateCombinedVisible();
          parent.mangoAPISrvc.showLoader(false);
          parent.counter = 0;

          Swal.fire({
            icon: 'success',
            title: `${parent._translate.instant('Success')}!`,
            showCancelButton: false,
            allowEscapeKey: true,
            allowEnterKey: true,
            confirmButtonText: 'OK',
            text: `${parent._translate.instant('transaction-processed-successfully')}!`
          });
        },
        error => {
          parent.counter = 0;
          parent.dialogDisplay = false;
          parent.isDialogFormValid = false;
          parent.updateCombinedVisible();
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      parent.mangoAPISrvc.tokenizedPaymentsACHCard(authProfile).subscribe(
        (result: any) => {
          parent.mangoAPISrvc.showLoader(false);
          if (result.Status == 'success') {
            obj['TransactionReference'] = result['Confirmation'];

            parent.mangoAPISrvc.updateProfileIDinMango(obj).subscribe(
              carddata => {},
              error => {
                parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
                parent.mangoAPISrvc.showLoader(false);
              }
            );
            if (
              parent.paymentProfile &&
              parent.paymentProfile.NameOnCard &&
              parent.paymentProfile.CardNo
            ) {
              const note =
                "Paid By: " +
                parent.paymentProfile.NameOnCard +
                ', ' +
                'using Credit Card:  ****' +
                parent.paymentProfile.CardNo +
                ' (CR)';
              parent.mycashReceiptsForm.controls['PaymentNote'].setValue(note);
            }
            Swal.fire({
              icon: 'success',
              title: `${parent._translate.instant('Success')}!`,
              showCancelButton: false,
              allowEscapeKey: true,
              allowEnterKey: true,
              confirmButtonText: 'OK',
              text: parent._translate.instant('cc-processed-successfully')
            });
          } else {
            if (isCreateTime) {
              parent.mangoAPISrvc.deleteProfile(obj['ClientID']).subscribe(
                obj => {
                  Swal.fire({
                    icon: 'error',
                    title: `Hmmm. ${parent._translate.instant('problem-with-cc')}`,
                    showCancelButton: false,
                    allowEscapeKey: true,
                    allowEnterKey: true,
                    confirmButtonText: 'OK',
                    text: parent._translate.instant('cc-processed-failed')
                  });
                  parent.mangoAPISrvc.showLoader(false);
                },
                error => {
                  parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
                  parent.mangoAPISrvc.showLoader(false);
                }
              );
            } else {
              Swal.fire({
                icon: 'error',
                title: `Hmmm. ${parent._translate.instant('problem-with-cc')}`,
                showCancelButton: false,
                allowEscapeKey: true,
                allowEnterKey: true,
                confirmButtonText: 'OK',
                text: parent._translate.instant('cc-processed-failed')
              });
              parent.mangoAPISrvc.showLoader(false);
            }
          }

          parent.mangoAPISrvc.isFormChanged = true;
          sessionStorage.setItem('unSavedCashReceipts', 'true');
          parent.dialogDisplay = false;
          parent.isDialogFormValid = false;
          parent.updateCombinedVisible();
        },
        error => {
          Swal.fire({
            icon: 'error',
            title: `Hmmm. ${parent._translate.instant('problem-with-cc')}`,
            showCancelButton: false,
            allowEscapeKey: true,
            allowEnterKey: true,
            confirmButtonText: 'OK',
            text: parent._translate.instant('cc-processed-failed')
          });
          parent.dialogDisplay = false;
          parent.isDialogFormValid = false;
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    }
  }

  /* Saving Users Education data */
  savePaymentData() {
    if (!this.showCardoption || !this.isDialogFormValid) {
      return;
    }
    if (!this.paymentProfile) {
      this.createCustomerProfile();
    } else if (this.flagAddNewCard) {
      this.createCustomerPaymentProfile();
    } else {
      this.chargeCustomerProfile();
    }
  }

  chargeCustomerProfile(self?: any) {
    if (!self) {
      self = this;
    }
    const formData = self.myPaymentForm.value;
    formData.InvoiceNumber = Math.floor(Math.random() * 9999) + 1;
    const mycashReceiptsFormData = self.mycashReceiptsForm.value;
    const postData = {
      ClientID: self.selectedCustomer.ClientID,
      CustomerProfileID: self.paymentProfile.ProfileID,
      CustomerPaymentProfileID: self.paymentProfile.PaymentProfileID,
      InvoiceNumber: formData.InvoiceNumber,
      InvoiceDesc: mycashReceiptsFormData.PaymentNote,
      PaymentAmount: numeral(mycashReceiptsFormData.PaymentAmount).value(),

      Items: [
        {
          ItemID: formData.InvoiceNumber,
          Name: formData.InvoiceNumber,
          Description: mycashReceiptsFormData.PaymentNote,
          Quantity: 1,
          UnitPrice: Number(mycashReceiptsFormData.PaymentAmount)
        }
      ]
    };

    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.chargePaymentProfile(postData).then(result => {
      if (result.messages && result.messages.resultCode == 'Ok') {
        self.mangoAPISrvc.isFormChanged = true;
        sessionStorage.setItem('unSavedCashReceipts', 'true');
        self.showCardoption = false;
        self.updateCombinedVisible();
        self.mangoAPISrvc.notify(
          'success',
          'Success!',
          this._translate.instant('Credit Card Payment processed successfully.')
        );
      } else {
        self.mangoAPISrvc.notify(
          'error',
          'Error!',
          this._translate.instant('Credit Card Payment failed to process.')
        );
      }
      self.mangoAPISrvc.showLoader(false);
      self.dialogDisplay = false;
      self.isDialogFormValid = false;
      self.updateCombinedVisible();
    });
  }

  createCustomerProfile() {
    const self = this;
    if (!self.selectedCustomer.ClientID || self.paymentProfile) {
      return false;
    }

    const formData = self.myPaymentForm.value;

    const postData = {
      ClientID: self.selectedCustomer.ClientID,
      NameOnCard: formData.nameOnCard,
      CardNo: formData.cardno.replace(/\-/g, ''),
      ExpiryDate: formData.expDate,
      Customer: {
        Email: formData.email,
        FirstName: formData.firstName,
        LastName: formData.lastName,
        Company: formData.company,
        Address: formData.address,
        City: formData.city,
        State: formData.state,
        Zip: formData.zip,
        Country: formData.country,
        PhoneNum: formData.phoneNum,
        Description: formData.customerDesc
      },
      CustomerProfileID: '',
      CustomerPaymentProfileID: '',
      InvoiceNumber: '',
      InvoiceDesc: '',
      Items: [{ ItemID: '', Name: '', Description: '', Quantity: 1, UnitPrice: 0 }]
    };
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.saveCustomerProfile(postData).subscribe((result: any) => {
      if (result.messages && result.messages.resultCode == 'Ok') {
        self.mangoAPISrvc
          .getPaymentProfile(self.selectedCustomer.ClientID, 'CC')
          .subscribe(result => {
            if (result == null) {
              return;
            }
            self.paymentProfile = result;
            self.mangoAPISrvc.showLoader(false);
          });
      }
    });
  }

  createCustomerPaymentProfile() {
    const self = this;
    if (!self.selectedCustomer.ClientID || !self.paymentProfile) {
      return false;
    }

    const formData = self.myPaymentForm.value;
    //this.PaymentNote = "Paid By: " + formData.nameOnCard + ", " + "using Credit Card:  ****" + formData.cardno.replace(/\-/g, "");
    //this.mycashReceiptsForm.controls['PaymentNote'].setValue(this.PaymentNote);
    const postData = {
      ClientID: self.selectedCustomer.ClientID,
      NameOnCard: formData.nameOnCard,
      CardNo: formData.cardno.replace(/\-/g, ''),
      ExpiryDate: formData.expDate,
      Customer: {
        Email: formData.email,
        FirstName: formData.firstName,
        LastName: formData.lastName,
        Company: formData.company,
        Address: formData.address,
        City: formData.city,
        State: formData.state,
        Zip: formData.zip,
        Country: formData.country,
        PhoneNum: formData.phoneNum,
        Description: formData.customerDesc
      },
      CustomerProfileID: self.paymentProfile.ProfileID,
      CustomerPaymentProfileID: '',
      InvoiceNumber: '',
      InvoiceDesc: '',
      Items: [{ ItemID: '', Name: '', Description: '', Quantity: 1, UnitPrice: 0 }]
    };
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.savePaymentProfile(postData).subscribe((result: any) => {
      if (result.messages && result.messages.resultCode == 'Ok') {
        self.mangoAPISrvc
          .getPaymentProfile(self.selectedCustomer.ClientID, 'CC')
          .subscribe(result => {
            if (result == null) {
              return;
            }
            self.paymentProfile = result;
            self.flagAddNewCard = false;
            self.mangoAPISrvc.showLoader(false);
          });
      }
    });
  }

  saveAndSplit() {
    console.log(numeral(this.totalNumberPayments).value());
    const formObj = this.mycashReceiptsForm.value;
    this.isSaveAndSplitClicked = true;
    if (numeral(formObj['PaymentUnapplied']).value() == 0) {
      Swal.fire({
        icon: 'warning',
        title: this._translate.instant('split-payment-notification'),
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: 'OK',
        text: this._translate.instant('CashReceipts.split-payment-cant-be-done')
      });
    } else if (
      formObj['PaymentType'] == 'Refund' ||
      formObj['PaymentType'] == 'Refund Advance Payment'
    ) {
      Swal.fire({
        icon: 'warning',
        title: this._translate.instant('split-payment-notification'),
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: 'OK',
        text: this._translate.instant('CashReceipts.split-payment-not-allowed-refund')
      });
    } else if (numeral(this.totalNumberPayments).value() > 0) {
      this.saveConfirmationAction(false);
    } else {
      Swal.fire({
        icon: 'warning',
        title: this._translate.instant('split-payment-notification'),
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: 'OK',
        text: this._translate.instant('CashReceipts.amounts-must-be-applied')
      });
    }
    // this.onClearClick();
  }

  processLexcharge() {
    // need to authorize company with Lex everytime before processing a transaction
    // check to see if token has expired.  No need to refresh if within the same day.
    const parent = this;
    parent.counter = 0;
    parent.mangoCompanyData.LexLastAuthDate = parent.mangoCompanyData.LexLastAuthDate
      ? parent.mangoCompanyData.LexLastAuthDate
      : moment().add(1, "days");
    const todayDate = moment().format("MM-DD-YYYY");
    const dbDate = moment(parent.mangoCompanyData.LexLastAuthDate).format("MM-DD-YYYY");
    if (todayDate == dbDate) {
      parent.loadLexPaymentScreen();
    } else {
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.processLexAuth(parent.mangoCompanyData.LexRefreshToken).subscribe(
        (data: any) => {
          if (data.access_token) {
            const obj = {};
            obj["LexAccessToken"] = data.access_token;
            obj["LexRefreshToken"] = data.refresh_token;
            obj["LexLastAuthDate"] = new Date();
            obj["CompanyID"] = parent.loginCompanyId;
            parent.mangoCompanyData.LexLastAuthDate = obj["LexLastAuthDate"];
            parent.mangoCompanyData.LexAccessToken = data.access_token;
            parent.mangoCompanyData.LexRefreshToken = data.refresh_token;
            parent.updateLexObjject(obj);
          } else {
            parent.loadLexPaymentScreen();
          }
          parent.mangoAPISrvc.showLoader(false);
        },
        (data: any) => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    }
  }

  updateLexObjject(obj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateLexObjject(obj).subscribe(
      (data: any) => {
        parent.loadLexPaymentScreen();
        parent.mangoAPISrvc.showLoader(false);
      },
      (data: any) => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  loadLexPaymentScreen() {
    const parent = this;
    parent.dialogWidth = "30vw";
    const formData = parent.mycashReceiptsForm.value;
    parent.dialogTitle = "Process " + formData.PaymentType;
    const typeOfTransaction = formData.PaymentType == "Credit Card" ? "CC" : "ACH";
    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc
      .getPaymentProfile(parent.selectedCustomer.ClientID, typeOfTransaction)
      .subscribe((result: any) => {
        if (result == null || result.length == 0 || result['cardStatus'] == false) {
          parent.url = '';
          parent.mangoAPISrvc
            .processLexPayments(
              parent.mangoCompanyData,
              parent.selectedCustomer,
              formData,
              parent.loginCompanyId
            )
            .subscribe(
              (data: any) => {
                parent.counter = 0;
                parent.url = parent.sanitizer.bypassSecurityTrustResourceUrl(data.formUrl);
                parent.mangoAPISrvc.showLoader(false);
                parent.isLexcharge = true;
                parent.counter = 0;
                parent.isNewCpaPay = false;
                setTimeout(() => {
                  parent.counter = 0;
                }, 200);
              },
              (data: any) => {
                parent.mangoAPISrvc.showLoader(false);
              }
            );
        } else {
          parent.isDialogFormValid = true;
          parent.amountToCharge = numeral(
            parent.mycashReceiptsForm.get('PaymentAmount').value
          ).value();
          parent.mangoAPISrvc.showLoader(false);
          parent.showAchNewCardoption = false;
          parent.flagAddNewCard = false;

          if (typeOfTransaction == 'ACH') {
            parent.showACHDialog = true;
            parent.achProfile = result;
          } else {
            parent.showCardoption = true;
            parent.dialogDisplay = true;
            parent.paymentProfile = result;
            parent.updateCombinedVisible();
          }
        }
      });
  }

  onLoad() {
    const parent = this;
    parent.counter++;
    if (parent.counter > 1 && parent.selectedCustomer.ClientID) {
      const formData = parent.mycashReceiptsForm.value;
      const typeOfTransaction = formData.PaymentType == "Credit Card" ? "CC" : "ACH";
      parent.mangoAPISrvc.showLoader(true);
      setTimeout(() => {
        parent.mangoAPISrvc
          .getPaymentProfile(parent.selectedCustomer.ClientID, typeOfTransaction)
          .subscribe((result: any) => {
            parent.mangoAPISrvc.showLoader(false);
            parent.counter = 0;
            parent.isLexcharge = false;
            parent.closeCreditCardForm();
            if (result == null || result.length == 0 || result['cardStatus'] == false) {
              Swal.fire({
                icon: 'error',
                title: `Hmmm. ${parent._translate.instant('problem-with-cc')}`,
                showCancelButton: false,
                allowEscapeKey: true,
                allowEnterKey: true,
                confirmButtonText: 'OK',
                text: parent._translate.instant('cc-processed-failed')
              });
            } else {
              if (result && result.NameOnCard && result.CardNo) {
                const note = `Paid By: ${result.NameOnCard} , using ${formData.PaymentType}:  **** ${result.CardNo} (CR)`;
                parent.mycashReceiptsForm.controls["PaymentNote"].setValue(note);
              }
              Swal.fire({
                icon: 'success',
                title: `${parent._translate.instant('Success')}!`,
                showCancelButton: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                confirmButtonText: 'OK',
                text: `${formData.PaymentType} ${parent._translate.instant(
                  'processed-successfully'
                )}!`
              });
            }
          });
      }, 100);
    }
  }

  // STax code
  closeStaxForm() {
    this.isProcessing = false;
    this.isStaxNewCard = false;
    this.showStaxoption = false;
    this.isCCFlow = false;
  }

  addSTaxNewCard() {
    const parent = this;
    let formData = null;
    let extraDetails = null;
    if (parent.isProcessing) {
      return false;
    }
    parent.isProcessing = true;
    parent.myAchReceiptsForm.controls['isBusiness'].setValue(parent.isBusiness);
    let phNo = parent.selClient.Mobile ? parent.selClient.Mobile : parent.selClient.Office;
    phNo = phNo ? phNo.replace(/[{(___)-}]/g, '').trim() : '555-555-5555';
    phNo = phNo ? phNo : '555-555-5555';
    parent.mangoAPISrvc.showLoader(true);
    if (this.isCCFlow) {
      formData = parent.myPaymentForm.value;
      // this.fattJs.showCardForm().then(handler => {
      // handler.setTestPan(formData['cardno'].replace(/\-/g, ""));
      // handler.setTestCvv(formData.securityCode);
      extraDetails = {
        total: numeral(formData.amount).value(),
        firstname: formData.fname,
        lastname: formData.lname,
        email: parent.selClient.Email,
        month: parseInt(formData.expDate.split('/')[0]),
        year: parseInt(formData.expDate.split('/')[1]),
        phone: phNo,
        address_1: parent.selClient.BusStreet1,
        address_2: parent.selClient.BusStreet2 ? parent.selClient.BusStreet2 : '',
        address_city: parent.selClient.BusCity,
        address_state: parent.selClient.BusState,
        address_zip: parent.selClient.BusZip,
        method: 'card',
        validate: false,
        send_receipt: false,
        meta: {
          otherField1:
            'from Cash Receipts | User : ' +
            this.userName +
            ' | ClientName :' +
            this.selectedCustomer['ClientName']
        }
      };
      // call pay api
      parent.fattJs
        .pay(extraDetails)
        .then(result => {
          if (result.id) {
            parent.getStaxToken(result);
          }
        })
        .catch(err => {
          parent.mangoAPISrvc.showLoader(false);
          parent.isProcessing = false;
          let msg = err['message'] ? err['message'] : null;
          if (!msg) {
            if (err.payment_attempt_message) {
              msg = err.payment_attempt_message;
            } else if (err.child_transactions && err.child_transactions.length > 0) {
              msg = err.child_transactions[0].message;
            } else if (err.error_description) {
              msg = err.error_description;
            } else {
              try {
                msg =
                  typeof err === 'object'
                    ? Object.keys(err)
                        .map(k => err[k].join(' '))
                        .join(' ')
                    : JSON.stringify(err);
              } catch (error) {}
            }
          }
          Swal.fire({
            icon: 'error',
            title: parent._translate.instant('Transaction Not Approved'),
            text: msg,
            showConfirmButton: false,
            timer: 7000
          });
        });
    } else {
      formData = parent.myAchReceiptsForm.value;
      extraDetails = {
        total: numeral(formData.Amount).value(),
        firstname: formData.FirstName,
        lastname: formData.LastName,
        person_name: formData.FirstName + ' ' + formData.LastName,
        email: parent.selClient.Email,
        phone: phNo,
        address_1: parent.selClient.BusStreet1,
        address_2: parent.selClient.BusStreet2 ? parent.selClient.BusStreet2 : '',
        address_city: parent.selClient.BusCity,
        address_state: parent.selClient.BusState,
        address_zip: parent.selClient.BusZip,
        method: 'bank',
        validate: false,
        url: 'https://omni.fattmerchant.com/#/bill/',
        bank_type: formData.TransCode == 27 ? 'checking' : 'savings',
        bank_account: formData.AccountNumber,
        bank_routing: formData.RoutingNumber,
        bank_holder_type: formData.isBusiness == 'true' ? 'business' : 'personal',
        send_receipt: false,
        meta: {
          otherField1:
            'from Cash Receipts | User : ' +
            this.userName +
            ' | ClientName :' +
            this.selectedCustomer['ClientName']
        }
      };

      parent.fattJs
        .pay(extraDetails)
        .then(result => {
          if (result.id) {
            parent.getStaxToken(result);
          }
        })
        .catch(err => {
          parent.mangoAPISrvc.showLoader(false);
          parent.isProcessing = false;
          let msg = err['message'] ? err['message'] : null;
          if (!msg) {
            if (err.payment_attempt_message) {
              msg = err.payment_attempt_message;
            } else if (err.child_transactions && err.child_transactions.length > 0) {
              msg = err.child_transactions[0].message;
            } else if (err.error_description) {
              msg = err.error_description;
            } else {
              try {
                msg =
                  typeof err === 'object'
                    ? Object.keys(err)
                        .map(k => err[k].join(' '))
                        .join(' ')
                    : JSON.stringify(err);
              } catch (error) {}
            }
          }
          Swal.fire({
            icon: 'error',
            title: parent._translate.instant('Transaction Not Approved'),
            text: msg,
            showConfirmButton: false,
            timer: 7000
          });
        });
    }
  }

  getStaxToken(result) {
    const cardObj = {};
    const parent = this;
    let formData = null;
    let extraDetails = null;
    let phNo = parent.selClient.Mobile ? parent.selClient.Mobile : parent.selClient.Office;
    phNo = phNo ? phNo.replace(/[{(___)-}]/g, '').trim() : '555-555-5555';
    phNo = phNo ? phNo : '555-555-5555';
    parent.transactionData = result;
    parent.isProcessing = false;
    if (this.isCCFlow) {
      formData = parent.myPaymentForm.value;
      extraDetails = {
        total: numeral(formData.amount).value(),
        firstname: formData.fname,
        lastname: formData.lname,
        email: parent.selectedCustomer.Email,
        month: parseInt(formData.expDate.split('/')[0]),
        year: parseInt(formData.expDate.split('/')[1]),
        phone: phNo,
        address_1: parent.selectedCustomer.BusStreet1,
        address_2: parent.selClient.BusStreet2 ? parent.selClient.BusStreet2 : '',
        address_city: parent.selectedCustomer.BusCity,
        address_state: parent.selectedCustomer.BusState,
        address_zip: parent.selectedCustomer.BusZip,
        method: 'card',
        validate: false,
        send_receipt: false
      };
    } else {
      parent.myAchReceiptsForm.controls['isBusiness'].setValue(parent.isBusiness);
      formData = parent.myAchReceiptsForm.value;
      extraDetails = {
        total: numeral(formData.Amount).value(),
        firstname: formData.FirstName,
        lastname: formData.LastName,
        person_name: formData.FirstName + ' ' + formData.LastName,
        phone: phNo,
        address_1: parent.selectedCustomer.BusStreet1,
        address_2: parent.selClient.BusStreet2 ? parent.selClient.BusStreet2 : '',
        address_city: parent.selectedCustomer.BusCity,
        address_state: parent.selectedCustomer.BusState,
        address_zip: parent.selectedCustomer.BusZip,
        bank_account: formData.AccountNumber,
        bank_routing: formData.RoutingNumber,
        bank_type: formData.TransCode == 27 ? 'checking' : 'savings',
        bank_holder_type: formData.isBusiness == 'true' ? 'business' : 'personal',
        method: 'bank',
        validate: false,
        send_receipt: false,
        url: 'https://omni.fattmerchant.com/#/bill/'
      };
    }

    this.fattJs
      .tokenize(extraDetails)
      .then(result => {
        if (result) {
          // saving into Mango DB
          cardObj['CompanyID'] = parent.selectedCustomer.CompanyID;
          cardObj['ClientID'] = parent.selectedCustomer.ClientID;
          cardObj['CardNo'] = result.card_last_four;
          cardObj['ExpiryDate'] = result.card_exp;
          cardObj['NameOnCard'] = result.person_name;
          cardObj['TransType'] = parent.isCCFlow ? 'CC' : 'ACH';
          cardObj['FirstName'] = formData.fname;
          cardObj['LastName'] = formData.lname;
          cardObj['uid'] = result.id;
          cardObj['scid'] = result.customer_id;
          cardObj['CardType'] = parent.isCCFlow ? result['card_type'] : result['bank_type'];
          if (!parent.isCCFlow) {
            cardObj['BankAcctNo'] = formData['AccountNumber'].substr(
              formData['AccountNumber'].length - 4,
              formData['AccountNumber'].length
            );
            cardObj['isBusiness'] = formData.isBusiness == 'true' ? true : false;
          }
          parent.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
          parent.isPaymentFlowRequired = true;
          if (parent.sTaxProfile && parent.sTaxProfile.length == 0) {
            parent.mangoAPISrvc.createCreditCardDetails(cardObj).subscribe(
              (resultRes: any) => {
                parent.sTaxProfile['CustomerCardID'] = resultRes.id;
                parent.mangoAPISrvc.notify('success', 'Success!', 'Transaction Approved.');
                parent.saveData();
                parent.mangoAPISrvc.showLoader(false);
                parent.showStaxoption = false;
                // Swal.fire({
                //   icon: "success",
                //   title: parent._translate.instant("Success"),
                //   html:
                //     parent._translate.instant("Transaction Approved."),
                //   showConfirmButton: false,
                //   timer: 5000,
                // });
              },
              (data: any) => {
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          } else {
            parent.mangoAPISrvc
              .updateCreditCardDetails(parent.sTaxProfile.CustomerCardID, cardObj)
              .subscribe(
                (resultRes: any) => {
                  parent.mangoAPISrvc.notify('success', 'Success!', 'Transaction Approved.');
                  parent.saveData();
                  parent.mangoAPISrvc.showLoader(false);
                  parent.showStaxoption = false;

                  // Swal.fire({
                  //   icon: "success",
                  //   title: parent._translate.instant("Success"),
                  //   html:
                  //     parent._translate.instant("Transaction Approved."),
                  //   showConfirmButton: false,
                  //   timer: 5000,
                  // });
                },
                (data: any) => {
                  parent.mangoAPISrvc.showLoader(false);
                }
              );
          }
        }
      })
      .catch(err => {
        parent.mangoAPISrvc.showLoader(false);
        parent.isProcessing = false;
        let msg = err['message'] ? err['message'] : null;
        if (!msg) {
          if (err.payment_attempt_message) {
            msg = err.payment_attempt_message;
          } else if (err.child_transactions && err.child_transactions.length > 0) {
            msg = err.child_transactions[0].message;
          } else if (err.error_description) {
            msg = err.error_description;
          } else {
            try {
              msg =
                typeof err === 'object'
                  ? Object.keys(err)
                      .map(k => err[k].join(' '))
                      .join(' ')
                  : JSON.stringify(err);
            } catch (error) {}
          }
        }
        Swal.fire({
          icon: 'error',
          title: parent._translate.instant('Transaction Not Approved'),
          text: msg,
          showConfirmButton: false,
          timer: 7000
        });
      });
  }

  processSTaxUsingToken() {
    const parent = this;
    const formData = parent.myPaymentForm.value;
    const amount = numeral(formData.amount).value();
    if (amount == 0) {
      Swal.fire({
        icon: 'error',
        title: 'The amount must be at least .01',
        showConfirmButton: false,
        timer: 3000
      });
      return false;
    } else if (parent.isProcessing) {
      return false;
    }
    parent.isProcessing = true;
    parent.mangoAPISrvc.showLoader(true);

    const cashformData = parent.mycashReceiptsForm.value;
    const cardObj = {
      method: parent.isCCFlow ? "card" : "bank",
      CustomerCardID: this.sTaxProfile.CustomerCardID,
      send_receipt: false,
      meta: {
        tax: 0,
        shippingAmount: 0,
        customerCode: this.selectedCustomer['ClientID'],
        otherField1:
          'from Cash Receipts | User : ' +
          this.userName +
          ' | ClientName :' +
          this.selectedCustomer['ClientName'] +
          ' | Memo : ' +
          cashformData.PaymentNote,
        subtotal: numeral(amount).value()
      },
      total: numeral(amount).value()
    };
    parent.mangoAPISrvc
      .performStaxActions(cardObj, parent.mangoCompanyData.CompanyID, 'c')
      .subscribe(
        (result: any) => {
          parent.transactionData = result;
          parent.saveData();
          parent.showStaxoption = false;
          parent.isPaymentFlowRequired = true;
          parent.mangoAPISrvc.showLoader(false);
          parent.isSaveAndSplitClicked = false;
          parent.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
          const obj = {};
          obj["uid"] = parent.transactionData ? parent.transactionData.receiptID : null;
          obj["isBusiness"] = !parent.isCCFlow && parent.isBusiness == "true" ? true : false;
          parent.mangoAPISrvc
            .updateCreditCardDetails(parent.sTaxProfile.CustomerCardID, obj)
            .subscribe((resultRes: any) => {});
          parent.mangoAPISrvc.notify('success', 'Success!', 'Transaction Approved.');
          // Swal.fire({
          //   icon: "success",
          //   title: parent._translate.instant("Success"),
          //   html:
          //     parent._translate.instant("Transaction Approved."),
          //   showConfirmButton: false,
          //   timer: 5000,
          // });
        },
        (err: any) => {
          parent.mangoAPISrvc.showLoader(false);
          parent.isProcessing = false;
          let msg = err['message'] ? err['message'] : err;
          if (!msg) {
            if (err.payment_attempt_message) {
              msg = err.payment_attempt_message;
            } else if (err.child_transactions && err.child_transactions.length > 0) {
              msg = err.child_transactions[0].message;
            } else if (err.error_description) {
              msg = err.error_description;
            } else {
              try {
                msg =
                  typeof err === 'object'
                    ? Object.keys(err)
                        .map(k => err[k].join(' '))
                        .join(' ')
                    : JSON.stringify(err);
              } catch (error) {}
            }
          }
          Swal.fire({
            icon: 'error',
            title: parent._translate.instant('Transaction Not Approved'),
            text: msg,
            showConfirmButton: false,
            timer: 7000
          });
        }
      );
  }

  genarateIFrames() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    setTimeout(() => {
      self.mangoAPISrvc.showLoader(false);
      //var form = document.querySelector('form');
      // self should get from company table
      self.fattJs = new FattJs(self.mangoCompanyData.spkuid, {
        number: {
          id: 'fattjs-number',
          placeholder: '0000 0000 0000 0000',
          format: 'prettyFormat',
          style:
            'width: 95%; height:90%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%;'
        },
        cvv: {
          id: 'fattjs-cvv',
          placeholder: '000',
          style:
            'width: 30%; height:90%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%;'
        }
      });

      self.fattJs
        .showCardForm()
        .then(handler => {})
        .catch(err => {});

      self.fattJs.on('card_form_complete', message => {});

      self.fattJs.on('card_form_incomplete', message => {});
    }, 500);
  }

  async getSurchargeAmount(merchantId, Amount, companyId, eventType) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .performStaxActions({ ccid: merchantId, amount: Amount }, companyId, eventType)
          .subscribe(function (data) {
            data = data['bin_type'] ? data : null;
            resolve(data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
  async getCardType(customerCardID, companyId, eventType) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .performStaxActions({ ccid: customerCardID }, companyId, eventType)
          .subscribe(function (data) {
            resolve(data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  showStaxdialog() {
    const self = this;
    const cashFormData = this.mycashReceiptsForm.value;
    self.transactionData = null;
    this.isProcessing = false;
    this.isCCFlow = cashFormData.PaymentType == "Credit Card" ? true : false;
    const type = cashFormData.PaymentType == "Credit Card" ? "CC" : "ACH";
    const cardMsg = cashFormData.PaymentUnapplied > 0 ? "Advance" : "Applied";
    self.myPaymentForm.controls["SurChargeAmount"].setValue(0);
    self.myPaymentForm.controls["withSurcharge"].setValue(0);
    self.isDebitCard = false;
    Swal.fire({
      title: self._translate.instant('confirmation'),
      html: `<div>Your transaction will be auto saved after submitting the electronic payment.  You are processing an ${cardMsg} Payment.</div>`,
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.value) {
        self.mangoAPISrvc.showLoader(true);
        self.mangoAPISrvc
          .getPaymentProfile(self.selectedCustomer.ClientID, type)
          .subscribe(async (result: any) => {
            self.mangoAPISrvc.showLoader(false);
            self.sTaxProfile = result;
            self.isBusiness = result['isBusiness'] == true ? 'true' : 'false';
            self.isStaxNewCard = self.sTaxProfile && self.sTaxProfile['scid'] ? false : true;
            self.isProcessing = self.isStaxNewCard;
            self.amountToCharge = numeral(
              self.mycashReceiptsForm.get('PaymentAmount').value
            ).value();
            self.myPaymentForm.controls['amount'].setValue(
              '$' + numeral(self.amountToCharge).format('0,0.00')
            );
            self.myAchReceiptsForm.controls['Amount'].setValue(
              '$' + numeral(self.amountToCharge).format('0,0.00')
            );
            self.myPaymentForm.controls['zip'].setValue(self.selectedCustomer.BusZip);
            self.myPaymentForm.controls['state'].setValue(self.selectedCustomer.BusState);
            self.myPaymentForm.controls['city'].setValue(self.selectedCustomer.BusCity);
            self.myPaymentForm.controls['address'].setValue(self.selectedCustomer.BusStreet1);
            if (self.sTaxProfile && self.sTaxProfile.CustomerCardID) {
              const cardTypeObj = await self.getCardType(
                self.sTaxProfile.CustomerCardID,
                self.mangoCompanyData.CompanyID,
                'ct'
              );
              self.isDebitCard = cardTypeObj['bin_type'] == 'DEBIT' ? true : false;
              if (!self.sTaxProfile['CardType']) {
                self.sTaxProfile['CardType'] = cardTypeObj['bank_type']
                  ? cardTypeObj['bank_type']
                  : null;
                self.mangoAPISrvc
                  .updateCreditCardDetails(self.sTaxProfile.CustomerCardID, self.sTaxProfile)
                  .subscribe((resultRes: any) => {});
              }
            }

            if (
              !self.isStaxNewCard &&
              self.sTaxProfile &&
              self.mangoCompanyData.isSurchargeEnabled
            ) {
              const surchargeObj = await self.getSurchargeAmount(
                self.sTaxProfile.CustomerCardID,
                self.amountToCharge,
                self.mangoCompanyData.CompanyID,
                'sc'
              );
              if (surchargeObj) {
                self.myPaymentForm.controls['SurChargeAmount'].setValue(
                  surchargeObj['surcharge_amount']
                );
                self.myPaymentForm.controls['withSurcharge'].setValue(
                  surchargeObj['total_with_surcharge_amount']
                );
                self.isDebitCard = surchargeObj['bin_type'] == 'DEBIT' ? true : false;
              }
            } else {
              self.myPaymentForm.controls['SurChargeAmount'].setValue(0);
              self.myPaymentForm.controls['withSurcharge'].setValue(0);
              self.isDebitCard = false;
            }

            // Credit card flow
            if (self.isCCFlow) {
              this.myPaymentForm.controls['nameOnCard'].setValue('');
              this.myPaymentForm.controls['cardType'].setValue('');
              this.myPaymentForm.controls['cardno'].setValue('');
              this.myPaymentForm.controls['expDate'].setValue('');
              this.myPaymentForm.controls['securityCode'].setValue('');
              this.myPaymentForm.controls['fname'].setValue('');
              this.myPaymentForm.controls['lname'].setValue('');
              this.showStaxoption = true;
              this.genarateIFrames();
              // if (!this.selectedCustomer.BusStreet1 || !this.selectedCustomer.BusZip || !this.selectedCustomer.BusCity || !this.selectedCustomer.BusState) {

              //   Swal.fire({
              //     title: self._translate.instant('confirmation'),
              //     html: `<div>${self._translate.instant('CashReceipts.client-zip-email-required')}</div> <div> ${self._translate.instant('CashReceipts.to-process-ach-transaction')}.</div> <div>${self._translate.instant('CashReceipts.update-client-record-now')}</div>`,
              //     icon: 'warning',
              //     showCancelButton: true,
              //     confirmButtonText: `${self._translate.instant('Go-to-Client')}!`,
              //     cancelButtonText: self._translate.instant('no_cancel')
              //   }).then((result) => {
              //     if (result.value) {
              //       self.encrDecSrvc.addObject(AppConstants.selectedClientRecord, self.selectedCustomer);
              //       self.encrDecSrvc.addObject(AppConstants.clientID, self.selectedCustomer['ClientID']);
              //       self.encrDecSrvc.addObject(AppConstants.ClientName, self.selectedCustomer['ClientName']);
              //       self.mangoAPISrvc.showLoader(true);
              //       self.mangoAPISrvc.getAllDataCounts(self.selectedCustomer['ClientID']).subscribe(function (data) {
              //         self.encrDecSrvc.addObject(AppConstants.allDataCountsForClient, data);
              //         self.router.navigate([
              //           AppConstants.clientRoutePath + "/" + AppConstants.viewRoutePath,
              //         ]).then((res) => self.mangoAPISrvc.fireClientView(true));
              //         self.mangoAPISrvc.showLoader(false);
              //         //self.mangoAPISrvc.fireClientView(true);
              //         self.mangoAPISrvc.showLoader(false);
              //       });
              //     }
              //   });
              //   return false;
              // } else {
              //   this.showStaxoption = true;
              //   this.genarateIFrames();
              // }
            } else {
              this.showStaxoption = false;
              this.fattJs = new FattJs(self.mangoCompanyData.spkuid, {});
              if (self.isStaxNewCard) {
                this.myAchReceiptsForm.controls['Address1'].setValue('');
                this.myAchReceiptsForm.controls['City'].setValue('');
                this.myAchReceiptsForm.controls['State'].setValue('');
                this.myAchReceiptsForm.controls['Zip'].setValue('');
              }
              if (
                !this.selectedCustomer.BusStreet1 ||
                !this.selectedCustomer.BusZip ||
                !this.selectedCustomer.BusCity ||
                !this.selectedCustomer.BusState
              ) {
                Swal.fire({
                  title: self._translate.instant('confirmation'),
                  html: `<div>${self._translate.instant(
                    'CashReceipts.client-zip-email-required'
                  )}</div> <div> ${self._translate.instant(
                    'CashReceipts.to-process-ach-transaction'
                  )}.</div> <div>${self._translate.instant(
                    'CashReceipts.update-client-record-now'
                  )}</div>`,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: `${self._translate.instant('Go-to-Client')}!`,
                  cancelButtonText: self._translate.instant('no_cancel')
                }).then(result => {
                  if (result.value) {
                    self.encrDecSrvc.addObject(
                      AppConstants.selectedClientRecord,
                      self.selectedCustomer
                    );
                    self.encrDecSrvc.addObject(
                      AppConstants.clientID,
                      self.selectedCustomer['ClientID']
                    );
                    self.encrDecSrvc.addObject(
                      AppConstants.ClientName,
                      self.selectedCustomer['ClientName']
                    );
                    self.mangoAPISrvc.showLoader(true);
                    self.mangoAPISrvc
                      .getAllDataCounts(self.selectedCustomer['ClientID'])
                      .subscribe(function (data) {
                        self.encrDecSrvc.addObject(AppConstants.allDataCountsForClient, data);
                        self.router
                          .navigate([
                            AppConstants.clientRoutePath + '/' + AppConstants.viewRoutePath
                          ])
                          .then(res => self.mangoAPISrvc.fireClientView(true));
                        self.mangoAPISrvc.showLoader(false);
                        //self.mangoAPISrvc.fireClientView(true);
                        self.mangoAPISrvc.showLoader(false);
                      });
                  }
                });
                return false;
              } else {
                this.showStaxoption = true;
                this.fattJs = new FattJs(self.mangoCompanyData.spkuid, {});
              }
            }
          });
      }
    });
  }

  focusCard(index, element) {
    const iframeRef = $("iframe")[index];
    $(iframeRef).focus();
    $(iframeRef).contents().find(`#${element}`).focus();
  }

  onCloseDebitMemoDialog(data) {
    this.showDebitMemoDialog = false;
  }

  onSaveDebitMemoDialog(data) {
    const formObj = this.mycashReceiptsForm.value;
    this.debitMemoDesc = data?.description;
    this.showDebitMemoDialog = false;
    this.processAdvOrDebitMemo(formObj, false, this.selectedCustomer, this.openInvoicesDataSource);
  }

  onCloseApplyAdvanceDialog(data) {
    this.resetToDefaultValues();
  }

  async calculateSurcharge() {
    const processAmt = numeral(this.myPaymentForm.get("amount").value).value();
    if (
      this.sTaxProfile &&
      this.mangoCompanyData.isSurchargeEnabled &&
      processAmt &&
      processAmt > 0
    ) {
      this.isDebitCard = false;
      this.mangoAPISrvc.showLoader(true);
      const surchargeObj = await this.getSurchargeAmount(
        this.sTaxProfile.CustomerCardID,
        processAmt,
        this.mangoCompanyData.CompanyID,
        'sc'
      );
      if (surchargeObj) {
        this.myPaymentForm.controls['SurChargeAmount'].setValue(surchargeObj['surcharge_amount']);
        this.myPaymentForm.controls['withSurcharge'].setValue(
          surchargeObj['total_with_surcharge_amount']
        );
        this.isDebitCard = surchargeObj['bin_type'] == 'DEBIT' ? true : false;
      }
      this.mangoAPISrvc.showLoader(false);
    }
  }

  async authorizeSTaxNewCard() {
    const parent = this;
    let formData = null;
    let extraDetails = null;
    let cards = [];
    const cardObj = {};
    let phNo = parent.selClient.Mobile ? parent.selClient.Mobile : parent.selClient.Office;
    phNo = phNo ? phNo.replace(/[{(___)-}]/g, '').trim() : '';
    phNo = phNo ? phNo : '';
    parent.mangoAPISrvc.showLoader(true);
    if (this.isCCFlow) {
      formData = parent.myPaymentForm.value;
    } else {
      formData = parent.myAchReceiptsForm.value;
    }
    this.mangoAPISrvc.getPaymentMethodInfo(parent.selClient.ClientID).subscribe(
      async (data: any) => {
        cards = data;
        let customerId = '';
        const existingCard = cards.filter(item =>
          item.TransType == parent.isCCFlow ? 'CC' : 'ACH'
        );
        if (existingCard && existingCard.length > 0) {
          customerId = existingCard && existingCard.length > 0 ? existingCard[0]?.scid : '';
        } else {
          const customerObj = {
            firstname: parent.isCCFlow ? formData.fname : formData.FirstName,
            lastname: parent.isCCFlow ? formData.lname : formData.LastName,
            phone: phNo,
            email: parent.selClient.Email,
            address_1: parent.isCCFlow ? formData.address : parent.selClient.BusStreet1,
            address_city: parent.isCCFlow ? formData.city : parent.selClient.BusCity,
            address_state: parent.isCCFlow ? formData.state : parent.selClient.BusState,
            address_zip: parent.isCCFlow ? formData.zip : parent.selClient.BusZip
          };
          if (!phNo) delete customerObj.phone;
          if (!parent.selClient.Email) delete customerObj.email;
          try {
            const responseCust = await parent.createStaxCustomer(customerObj);
            customerId = responseCust['id'];
          } catch (errCust) {
            parent.mangoAPISrvc.showLoader(false);
            parent.isDialogFormValid = true;
            let msg = '';
            if (!msg) {
              let errCount = 0;
              if (errCust.address_zip && errCust.address_zip.length > 0) {
                msg += 'Phone #';
                errCount++;
              }
              if (errCust.address_1 && errCust.address_1.length > 0) {
                msg += errCount > 0 ? ', Address 1' : 'Address 1';
                errCount++;
              }
              if (errCust.address_2 && errCust.address_2.length > 0) {
                msg += errCount > 0 ? ', Address 2' : 'Address 2';
                errCount++;
              }
              if (errCust.address_city && errCust.address_city.length > 0) {
                msg += errCount > 0 ? ', city' : 'city';
                errCount++;
              }
              if (errCust.address_state && errCust.address_state.length > 0) {
                msg += errCount > 0 ? ', state' : 'state';
                errCount++;
              }
              if (errCust.address_zip && errCust.address_zip.length > 0) {
                msg += errCount > 0 ? ', and zip' : 'zip';
                errCount++;
              }
              if (errCount > 0) msg += ' are invalid!';
              else msg += ' is invalid!';
            }
            Swal.fire({
              icon: 'error',
              title: parent._translate.instant('Transaction Not Approved'),
              text: msg,
              showConfirmButton: false,
              timer: 7000
            });
            return;
          }
        }
        if (this.isCCFlow) {
          formData = parent.myPaymentForm.value;
          extraDetails = {
            total: numeral(formData.amount).value(),
            firstname: formData.fname,
            lastname: formData.lname,
            email: parent.selClient.Email,
            month: formData.expDate ? parseInt(formData.expDate.split('/')[0]) : null,
            year: formData.expDate ? parseInt(formData.expDate.split('/')[1]) : null,
            phone: phNo,
            address_1: formData.address,
            address_city: formData.city,
            address_state: formData.state,
            address_zip: formData.zip,
            method: 'card',
            validate: false,
            send_receipt: false
          };
        } else {
          parent.myAchReceiptsForm.controls['isBusiness'].setValue(parent.isBusiness);
          formData = parent.myAchReceiptsForm.value;
          extraDetails = {
            total: numeral(formData.Amount).value(),
            firstname: formData.FirstName,
            lastname: formData.LastName,
            person_name: formData.FirstName + ' ' + formData.LastName,
            phone: phNo,
            address_1: parent.selClient.BusStreet1,
            address_2: parent.selClient.BusStreet2 ? parent.selClient.BusStreet2 : '',
            address_city: parent.selClient.BusCity,
            address_state: parent.selClient.BusState,
            address_zip: parent.selClient.BusZip,
            bank_account: formData.AccountNumber,
            bank_routing: formData.RoutingNumber,
            bank_type: formData.TransCode == 27 ? 'checking' : 'savings',
            bank_holder_type: formData.isBusiness == 'true' ? 'business' : 'personal',
            method: 'bank',
            validate: false,
            send_receipt: false,
            url: 'https://omni.fattmerchant.com/#/bill/'
          };
        }

        // call pay api
        this.fattJs
          .tokenize(extraDetails)
          .then(result => {
            if (result.id) {
              cardObj['CompanyID'] = parent.selClient.CompanyID;
              cardObj['ClientID'] = parent.selClient.ClientID;
              cardObj['CardNo'] = result.card_last_four;
              cardObj['ExpiryDate'] = result.card_exp;
              cardObj['NameOnCard'] = result.person_name;
              cardObj['TransType'] = parent.isCCFlow ? 'CC' : 'ACH';
              cardObj['FirstName'] = extraDetails.firstname;
              cardObj['LastName'] = extraDetails.lastname;
              cardObj['scid'] = result.customer_id;
              cardObj['uid'] = result.id;
              cardObj['CardType'] = parent.isCCFlow ? result['card_type'] : result['bank_type'];
              parent.isDebitCard = result['bin_type'] == 'DEBIT' ? true : false;
              parent.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
              if (!parent.isCCFlow) {
                cardObj['BankAcctNo'] = formData['AccountNumber'].substr(
                  formData['AccountNumber'].length - 4,
                  formData['AccountNumber'].length
                );
                cardObj['isBusiness'] = formData.isBusiness == 'true' ? true : false;
              }

              if (parent.sTaxProfile == null || parent.sTaxProfile.length == 0) {
                parent.mangoAPISrvc.createCreditCardDetails(cardObj).subscribe(
                  async (resultRes: any) => {
                    parent.sTaxProfile = cardObj;
                    parent.sTaxProfile['CustomerCardID'] = resultRes.id;
                    parent.sTaxProfile = parent.sTaxProfile;
                    parent.mangoAPISrvc.showLoader(false);
                    parent.isStaxNewCard = false;
                    parent.isProcessing = false;
                    await parent.calculateSurcharge();
                  },
                  (data: any) => {
                    parent.mangoAPISrvc.showLoader(false);
                  }
                );
              } else {
                parent.mangoAPISrvc
                  .updateCreditCardDetails(parent.sTaxProfile.CustomerCardID, cardObj)
                  .subscribe(
                    async (resultRes: any) => {
                      parent.sTaxProfile['StaxToken'] = cardObj['StaxToken'];
                      parent.sTaxProfile['NameOnCard'] = cardObj['NameOnCard'];
                      parent.sTaxProfile['CardNo'] = cardObj['CardNo'];
                      parent.sTaxProfile['CardType'] = cardObj['CardType'];
                      //parent.sTaxProfile['StaxCustomerID'] = cardObj['StaxCustomerID'];
                      //parent.sTaxProfile['StaxPaymentMethodID'] = cardObj['StaxToken'];
                      parent.sTaxProfile['isBusiness'] = cardObj['isBusiness'];
                      parent.sTaxProfile = parent.sTaxProfile;
                      parent.mangoAPISrvc.showLoader(false);
                      parent.isStaxNewCard = false;
                      parent.isProcessing = false;
                      await parent.calculateSurcharge();
                    },
                    (data: any) => {
                      parent.mangoAPISrvc.showLoader(false);
                    }
                  );
              }
            }
          })
          .catch(err => {
            parent.mangoAPISrvc.showLoader(false);
            parent.isDialogFormValid = true;
            let msg = err['message'] ? err['message'] : null;
            if (!msg) {
              if (err.payment_attempt_message) {
                msg = err.payment_attempt_message;
              } else if (err.child_transactions && err.child_transactions.length > 0) {
                msg = err.child_transactions[0].message;
              } else if (err.error_description) {
                msg = err.error_description;
              } else {
                try {
                  msg =
                    typeof err === 'object'
                      ? Object.keys(err)
                          .map(k => err[k].join(' '))
                          .join(' ')
                      : JSON.stringify(err);
                } catch (error) {}
              }
            }
            Swal.fire({
              icon: 'error',
              title: parent._translate.instant('Transaction Not Approved'),
              text: msg,
              showConfirmButton: false,
              timer: 7000
            });
          });
      },
      err => {
        parent.mangoAPISrvc.notify('error', 'Error!', 'Error occurred while adding new card');
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  async createStaxCustomer(customerObj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    return new Promise(function (resolve, reject) {
      parent.mangoAPISrvc
        .performStaxActions(
          {
            data: customerObj
          },
          parent.loginCompanyId,
          'cc'
        )
        .subscribe(
          result => {
            parent.mangoAPISrvc.showLoader(false);
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  addNewCreditCard() {
    this.isStaxNewCard = true;
    this.isProcessing = true;
    this.isDebitCard = false;
    this.genarateIFrames();
    let address: string = "";
    const city: string = this.companyData.company.BusCity ? this.companyData.company.BusCity : "";
    const state: string = this.companyData.company.BusState ? this.companyData.company.BusState : "";
    const country: string = this.companyData.company.BusCountry
      ? this.companyData.company.BusCountry
      : "";
    const zip: string = this.companyData.company.BusZip ? this.companyData.company.BusZip : "";
    if (this.companyData.company.BusStreet1 != null) address = this.companyData.company.BusStreet1;
    else if (this.companyData.company.BusStreet2 != null)
      address = this.companyData.company.BusStreet2;
    else address = '';
    setTimeout(() => {
      this.myPaymentForm.controls['Address1'].setValue(address);
      this.myPaymentForm.controls['Address2'].setValue('');
      this.myPaymentForm.controls['City'].setValue(city);
      this.myPaymentForm.controls['State'].setValue(state);
      this.myPaymentForm.controls['Zip'].setValue(zip);
      this.myPaymentForm.controls['Country'].setValue(country);
      this.myPaymentForm.controls['FirstName'].setValue('');
      this.myPaymentForm?.controls['LastName']?.setValue('');
      this.myPaymentForm?.controls['expDate']?.setValue('');
      this.myPaymentForm?.controls['nameOnCard']?.setValue('');
      this.myPaymentForm?.controls['fname']?.setValue('');
      this.myPaymentForm?.controls['lname']?.setValue('');

      this.firstNameInputField.nativeElement.focus();
    }, 500);

    if (this.mangoCompanyData.isSurchargeEnabled && this.sTaxProfile) {
      this.sTaxProfile['StaxPaymentMethodID'] = null;
      this.myPaymentForm.controls['SurChargeAmount'].setValue(0);
      this.myPaymentForm.controls['withSurcharge'].setValue(0);
    }
  }

  addNewBankCard() {
    this.isStaxNewCard = true;
    this.isProcessing = true;
    this.myAchReceiptsForm.controls['RoutingNumber'].setValue('');
    this.myAchReceiptsForm.controls['AccountNumber'].setValue('');
    this.myAchReceiptsForm.controls['FirstName'].setValue('');
    this.myAchReceiptsForm.controls['LastName'].setValue('');
    this.myAchReceiptsForm.controls['Address1'].setValue(this.selectedCustomer.BusStreet1);
    this.myAchReceiptsForm.controls['City'].setValue(this.selectedCustomer.BusCity);
    this.myAchReceiptsForm.controls['State'].setValue(this.selectedCustomer.BusState);
    this.myAchReceiptsForm.controls['Zip'].setValue(this.selectedCustomer.BusZip);
  }

  handleClickCheck() {
    if (!this.isCheckRef && !this.isPaymentApplied) {
      Swal.fire({
        icon: 'warning',
        title: this._translate.instant('Warning'),
        html: 'Please select Check as type of payment first!',
        showConfirmButton: true
      });
    }
  }

  isValidStaxForm() {
    const paymentFormData = this.myPaymentForm.value;
    const achFormData = this.myAchReceiptsForm.value;
    this.isProcessing = false;
    if (
      this.isCCFlow &&
      this.isStaxNewCard &&
      (!paymentFormData.fname ||
        !paymentFormData.lname ||
        !paymentFormData.address ||
        !paymentFormData.city ||
        !paymentFormData.state ||
        !paymentFormData.zip ||
        !paymentFormData.expDate)
    ) {
      this.isProcessing = true;
    } else if (
      !this.isCCFlow &&
      this.isStaxNewCard &&
      (!achFormData.RoutingNumber ||
        !achFormData.AccountNumber ||
        !achFormData.TransCode ||
        !achFormData.FirstName ||
        !achFormData.LastName)
    ) {
      this.isProcessing = true;
    }
  }

  verifySystemLocking(e) {
    if (e == null) return;

    const dayToday = new Date(new Date().setHours(0, 0, 0, 0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    const monthToLock = new Date(
      new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)
    ).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (
      this.companySetting.isEnableSystemLocking &&
      dayToday >= lockDay &&
      new Date(e) <= lastDayOfMonth
    ) {
      this.mangoAPISrvc.notify(
        'error',
        'Error!',
        'System Locking is enabled on the selected date.'
      );
    }
  }

  validatePaymentDateTimeout: any;
  validatePaymentDate() {
    if (typeof this.validatePaymentDateTimeout != 'undefined') {
      clearTimeout(this.validatePaymentDateTimeout);
      this.validatePaymentDateTimeout = undefined;
    }

    this.validatePaymentDateTimeout = setTimeout(() => {
      const paymentDate = this.mycashReceiptsForm.controls['PaymentDate'].value;

      const dateToday = new Date();

      if (this.mangoUtils.dateDiff(paymentDate, dateToday, 'months') > 2) {
        Swal.fire({
          icon: 'warning',

          title: `${this._translate.instant('Warning')}?`,
          text: `${this._translate.instant('two-month-gap-date-entry-warning')}!`,

          confirmButtonText: 'OK',

          showCancelButton: false,
          allowEscapeKey: true,
          allowEnterKey: true
        });
      }

      clearTimeout(this.validatePaymentDateTimeout);
      this.validatePaymentDateTimeout = undefined;
    }, 500);
  }
}
