import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { AppConstants } from '@app/_helpers/api-constants';
import { AuthGuard, EncrDecrService, MangoApiService, mangoUtils } from '@app/_services';
import { SharedComponentsService } from '../shared-components-service';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare let numeral: any;
import moment from 'moment';
import * as AWS from 'aws-sdk';
import Swal from 'sweetalert2';
import { timer, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { columnSelectionComplete } from '@syncfusion/ej2-grids';

import { AutoComplete } from 'primeng/autocomplete';
import { AWSBucketMask } from '@app/_helpers/aws-bucket-mask';

@Component({
  selector: 'app-expense-entry',
  templateUrl: './expense-entry.component.html'
})
export class ExpenseEntryComponent implements OnInit {
  tsList;
  dateList;
  activeDate;
  ClientName: any;
  clientID;
  formattedAmount: string = '';
  staffID;
  totlcost;
  totalAmtT;
  message;
  staffName: any;
  applyMarkup: boolean = false;
  public showAttachedFiles: boolean = false;
  isShowMemo = false;
  autoApproved;
  idForApprove;
  totlWeekcost;
  totalAmtWeekT;
  counterList;
  selectedDate;
  costOverRide;
  checkAllExpense;
  expenseRatesObj: any = {};
  expenseSheetList;
  editableExpenseId;
  isMemoChanged: boolean = false;
  selectedDateIndex;
  public awsBucket: any;
  intervalId: any = null;
  isEditNote: boolean = false;
  intervalid: any;
  defaultExpObj = { "ExpenseMasterID": null }

  user = 'staff';
  startDate = moment().startOf('isoWeek').toDate();
  endDate = moment().endOf('isoWeek').toDate();

  isEditEE: any;

  tse: any = { isDisable: false };
  clientList: any = [];
  filteredClients: any = [];
  staffList: any = [];
  activityList: any = [];
  projectsList: any = [];
  filesStack: any = [];
  taxableAmount: number = 0;
  selClient: any = null;
  UserLevel;
  @ViewChild("fileUpload") fileUpload: any;
  @Input() displayExpenseEntryModal: boolean = false;
  @Output() closeExpenseEntryModal = new EventEmitter<any>();
  @ViewChild('expenseEntryMainForm') expenseEntryMainForm;
  public companyId;
  public resourceId;
  public userName;
  files: File[] = [];
  fileNames = []
  companyGlobalSetting: any = {};
  public companyLocationObj: any = null;
  settingsData: any = {}
  companyID: any;
  isSelectAndNew: boolean = false;
  isSelectAndDuplicate: boolean = false;
  selectedDdate: any;

  @ViewChild('autoComplete') autoComplete: AutoComplete;
  private currentFocusedIndex = -1;

  constructor(
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    public mangoUtils: mangoUtils,
    public sharedSrvc: SharedComponentsService,
    private auth: AuthGuard,
    private translate: TranslateService) {
    this.mangoAPISrvc.applyLanguage();
    this.sharedSrvc.engagementDialogData
    .subscribe((data) => {
      if (this.selClient && this.selClient['ClientID']) {
        this.getProjects(this.selClient['ClientID']);
      }
    })
    this.sharedSrvc.activityDialogData.subscribe((data) => {
      if (data) {
        this.getExpenseGroups();
      }
    });
  }

  getCompanyLocation() {
    if(!this.settingsData.CompanyMangoLocationID || this.companyLocationObj != null){
      return;
    }
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyLocation(this.settingsData.CompanyMangoLocationID).subscribe(
      (data: any) => {
        if(data && data.length > 0){
          this.companyLocationObj = data[0];
        }
        this.mangoAPISrvc.showLoader(false);
      },
      (err) => this.mangoAPISrvc.showLoader(false)
    );
  }

  getCompanyGlobalSetting() {
    this.companyGlobalSetting = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    if (this.companyGlobalSetting) {
      this.companyGlobalSetting.isWorkLocationRequired = this.companyGlobalSetting.isWorkLocationRequired ? this.companyGlobalSetting.isWorkLocationRequired : false;
      this.companyGlobalSetting.isExpenseMemoRequired = this.companyGlobalSetting.isExpenseMemoRequired ? this.companyGlobalSetting.isExpenseMemoRequired : false;
      if (this && this.companyGlobalSetting && this.companyGlobalSetting.isExpenseMemoRequired) {
        this.setRequired()
      }
    }
  }

  setRequired() {
    if (this.expenseEntryMainForm && this.expenseEntryMainForm.form && this.expenseEntryMainForm.form.controls && this.expenseEntryMainForm.form.controls.description) {
      this.expenseEntryMainForm.form.controls.description.setValidators([Validators.required])
      this.expenseEntryMainForm.form.controls.description.updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.resourceId = this.encrDecSrvc.getObject(AppConstants.resourceID);
    this.userName = this.encrDecSrvc.getObject(AppConstants.userName);
    this.UserLevel = this.encrDecSrvc.getObject(AppConstants.staffPermission);
    this.staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.setAWSOBject();
    this.getAllDataList();
  }

  getSettingData() {
    const parent = this;
    if(!this.selClient.ClientID){
      return;
    }
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getSettingData(this.selClient.ClientID).subscribe(function (data: any) {
      parent.settingsData = data;
      parent.getCompanyLocation();
      parent.mangoAPISrvc.showLoader(false);
    })
  }

  focusOnItem(targetItem: any) {
    setTimeout(() => {
      const dropdownPanel = document.querySelector('.p-autocomplete-panel');
      if (!dropdownPanel) return;
      const itemElements = dropdownPanel.querySelectorAll('.p-autocomplete-item');
      itemElements.forEach((element: HTMLElement) => {
        if (element.textContent?.trim() === targetItem.ClientName) {
          element.scrollIntoView({ behavior: 'instant', block: 'nearest' }); 
          element.focus(); 
        }
      });
    });
  }
  handleClientKeyPress(event) {    
    const key = event.key || event.code || event.which;
    if (key === 'ArrowDown') {
      if(this.currentFocusedIndex==-1){
        this.currentFocusedIndex=0;
      }else if(this.currentFocusedIndex<this.autoComplete.suggestions.length-1){
        this.currentFocusedIndex++;
      }else if(this.currentFocusedIndex==this.autoComplete.suggestions.length-1){
        this.currentFocusedIndex=0;
      }
    }else if (key === 'ArrowUp') {
      if(this.currentFocusedIndex==0){
        this.currentFocusedIndex=this.autoComplete.suggestions.length-1;
      }else if(this.currentFocusedIndex>0){
        this.currentFocusedIndex--;
      }
    }else if(key === 'Enter'){
      this.handleSelectClick(this.selClient);
    }
    else if(key === 'Backspace') {
      return;
    }
    if(this.currentFocusedIndex>=0){
      this.selClient=this.autoComplete.suggestions[this.currentFocusedIndex];
      this.focusOnItem(this.selClient);
    }
  }

  editEEModal(obj) {
    this.filesStack = [];
    this.fileNames = [];
    this.mangoAPISrvc.showLoader(true);
    this.getProjects(obj.ClientID);
    this.getAllDataList();
    setTimeout(() => {
          if (obj.Billed == true) {
            this.mangoAPISrvc.notify('error', this.translate.instant('error'), "Cannot edit billed Expense Entry.");
            return;
          }

          this.isEditEE = true;
          this.selClient = {
            ClientID: obj.ClientID,
            ClientName: obj.ClientName
          };
          this.getSettingData();
          this.applyMarkup = false;
          this.costOverRide = false;
          this.editableExpenseId = obj.SlipMasterID;

          this.tse = obj;
          this.tse['isDisable'] = this.tse['isDisable'] ? this.tse['isDisable'] : false;

          this.tse.Ddate = (
            new Date( this.tse.Ddate )
          );

          //console.log(this.tse.Ddate);
          if (this.tse.Markup)
            this.applyMarkup = true;

          if (this.tse.Tax)
            this.tse.Tax = numeral(this.tse.Tax).value();
          if (this.tse.StandardAmount)
            this.tse.StandardAmount = numeral(this.tse.StandardAmount).value();
          // this.tse.Ddate = moment.utc(this.tse.Ddate).toDate();
          this.staffID = this.tse.StaffID;
          this.tse.isExpenseMemoRequired = this.companyGlobalSetting.isExpenseMemoRequired ? this.companyGlobalSetting.isExpenseMemoRequired : false;
          this.tse.ExpenseApproved = obj.Approved;
          this.calculateExpense();
          if (this.tse.Billed) {
            this.displayExpenseEntryModal = false;
          } else {
            this.displayExpenseEntryModal = true;
          }
        this.mangoAPISrvc.showLoader(false);
      }, 1000);
  }

  restoreDate() {
    if(this.isSelectAndDuplicate){
      this.tse.Ddate = new Date(this.selectedDdate);
      this.isSelectAndDuplicate = false;
    }
  }

  saveAclose(prm) {
    const _this = this;
    this.tse['GroupDescriptionIDArray'] = this.tse['GroupDescriptionIDArray']?.length > 0 ? this.tse['GroupDescriptionIDArray'] : null;

    _this.staffID = _this.tse.StaffID;

    _this.tse.Ddate = (
      moment( new Date( _this.tse.Ddate ) )
      .format( "YYYY-MM-DD" )
    );

    _this.tse['ExpenseCodeID'] = _this.tse.ExpenseCodeID ? _this.tse.ExpenseCodeID : null;
    _this.tse['ExpenseCode'] = _this.tse.ExpenseCode ? _this.tse.ExpenseCode : null;
    _this.tse['Cost'] = _this.tse['Cost'] ? _this.tse['Cost'] : 0;
    _this.tse['Units'] = _this.tse['Units'] ? _this.tse['Units'] : 0;
    _this.tse['Markup'] = _this.tse['Markup'] ? _this.tse['Markup'] : 0;
    _this.tse['Tax'] = _this.tse['Tax'] ? _this.tse['Tax'] : 0;
    _this.tse['Reimbursed'] = _this.tse['Reimbursed'] ? _this.tse['Reimbursed'] : false;
    _this.tse['Reimburseable'] = _this.tse['Reimburseable'] ? _this.tse['Reimburseable'] : false;
    _this.tse['IsTimeRecord'] = 'X';
    _this.tse['Description'] = _this.tse['Description'] ? _this.tse['Description'] : null;
    _this.tse['BillingRate'] = _this.tse['BillingRate'] ? _this.tse['BillingRate'] : null;
    _this.tse['TotalTime'] = _this.tse['TotalTime'] ? _this.tse['TotalTime'] : null;
    _this.tse['StaffPayRate'] = _this.tse['StaffPayRate'] ? _this.tse['StaffPayRate'] : 0;
    _this.tse['ElaspedTime'] = _this.tse['ElaspedTime'] ? _this.tse['ElaspedTime'] : null;
    _this.tse['GroupDescriptionIDArray'] = _this.tse.GroupDescriptionIDArray;
    _this.tse['SelectedAmount'] = _this.tse['SelectedAmount'] ? _this.tse['SelectedAmount'] : 0;
    _this.tse['Billed'] = _this.tse['Billed'] ? _this.tse['Billed'] : false;
    _this.tse['Memo'] = _this.tse['Memo'];
    _this.tse['Approved'] = _this.tse.ExpenseApproved;
    _this.tse['CompanyID'] = _this.companyId;
    _this.tse['BilledAmount'] = _this.tse['Billable'] ? _this.tse['StandardAmount'] : 0;
    _this.tse['WriteUpDown'] = _this.tse['Billable'] ? 0 : _this.mangoUtils.subtractFloat(_this.tse['BilledAmount'], _this.tse['StandardAmount']);

    _this.tse['EngagementTypeID'] = _this.projectsList.filter((item) => item.value == _this.tse.ProjectMasterID)[0]['EngagementTypeID'];
    _this.calculateSalesTax();
    _this.tse.StaffID = _this.tse.StaffID.staffID ? _this.tse.StaffID.staffID : _this.tse.StaffID;
    _this.mangoAPISrvc.showLoader(true);
    if (!_this.isEditEE) {
      _this.mangoAPISrvc.createTimeSheet(_this.tse).subscribe({
        next: (data: any) => {
       _this.mangoAPISrvc.sendBudgetAlert({
           ClientID: _this.tse['ClientID'],
           ProjectMasterID: _this.tse['ProjectMasterID'],
           CompanyID: _this.companyId,
           Ddate: moment(_this.tse.Ddate).format('YYYY-MM-DD')
           }).subscribe({
             next: (data) => {},
             error: (err) => { console.log(err) }
           })
         if (_this.filesStack.length > 0) {
           _this.createDMSParent(prm, data.id);
         } else {
           _this.saveResultSuccess(prm);
         }
        _this.mangoAPISrvc.showLoader(false);
        _this.mangoAPISrvc.notify('success', _this.translate.instant('Created'), AppConstants.createMsg);
      },
      error: (error) => {     
        _this.mangoAPISrvc.showLoader(false);
        _this.mangoAPISrvc.notify('error', _this.translate.instant('error'), AppConstants.createErrorMsg);
      },
       complete: () => {
         _this.restoreDate();
       }
     });
    }
    else {
      _this.mangoAPISrvc.updateTimeSheet(_this.tse.SlipMasterID, _this.tse).subscribe(function (data) {
        _this.mangoAPISrvc.sendBudgetAlert({
          ClientID: _this.tse['ClientID'],
          ProjectMasterID: _this.tse['ProjectMasterID'],
          CompanyID: _this.companyId,
          Ddate: moment(_this.tse.Ddate).format('YYYY-MM-DD')
        }).subscribe((data) => {}, err => { console.log(err) })
        _this.isEditEE = false;
        if (_this.filesStack.length > 0) {
          _this.createDMSParent(prm, _this.tse.SlipMasterID);
        } else {
          _this.saveResultSuccess(prm);
        }
        _this.mangoAPISrvc.showLoader(false);
       _this.mangoAPISrvc.notify('success', _this.translate.instant('updated'), AppConstants.updateMsg);
     }, error => {
       _this.mangoAPISrvc.showLoader(false);
       _this.mangoAPISrvc.notify('error', _this.translate.instant('error'), AppConstants.updateErrorMsg);
     });
    }
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientList.length; i++) {
        const client = this.clientList[i];
        if (client.ClientName.toLowerCase().indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] == false) {
          filtered.push(client);
        } else if (client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] != true) {
          filtered.push(client);
        }

        if (filtered.length > 20)
          break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    })
  }

  fetchClients() {
    const list = this.encrDecSrvc.clientList;
    if (this.clientList.length == 0 || this.clientList.length !== list.length) {
      this.clientList = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item['ContactRecord'] != true) {
          this.clientList.push(item);
          // this.filteredClients.push(item);
        }
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  getAllDataList() {
    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
    // this.clientList = this.encrDecSrvc.clientList;
    // this.filteredClients = this.encrDecSrvc.clientList;
    this.getTimeAndExpenses();
    this.getAllStaffList();
    this.getExpenseGroups();
    this.getCompanyGlobalSetting();
  }

  deleteFile(obj) {
    const params = { Bucket: environment.AWS_BUCKET_NAME, Key: 'documents/' + obj.UniqueName  };
    const self = this;
    self.awsBucket.deleteObject(
      params,

      function (err, data) {
        if (err) {
          return;
        }
        self.mangoAPISrvc.deleteDMSParentFolder(obj.ExpenseMasterID).subscribe((objItem) => {
          const index = self.fileNames.indexOf(obj);
          self.fileNames.splice(index, 1);
          self.mangoAPISrvc.notify('success', self.translate.instant('Success'), AppConstants.deleteMessage);
        });
      },

      self.mangoAPISrvc
    );
  }

  getTimeAndExpenses() {
    const parent = this;
    parent.expenseRatesObj = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    parent.autoApproved = parent.expenseRatesObj.AutoApproveExpenses;
  }

  getAllStaffList() {
    const parent = this;
    if (this.staffList.length > 0) {
      return false;
    }
    const tempList = this.encrDecSrvc.getObject(AppConstants.staffList);
    for (let i = 0; i < tempList.length; i++) {
      if(tempList[i].Inactive)
        continue;

      parent.staffList.push({ label: tempList[i].StaffName, value: tempList[i].StaffID });
    }
    parent.tse.StaffID = this.staffID;
  }

  getExpenseGroups() {
    const _this = this;
    const data = this.encrDecSrvc.getObject(AppConstants.expenses);
    if (_this.activityList.length > 0 && _this.activityList.length == data.length) {
      return false;
    }
    _this.activityList = [{ label: "", value: null, dataItem: {} }];
    for (let i = 0; i < data.length; i++) {
      if (data[i].Inactive == false) {
        _this.activityList.push({ label: data[i].ExpenseCode + " - " + data[i].Description, value: data[i].ExpenseCodeID, dataItem: data[i] })
      }
    }
    _this.activityList.sort(_this.mangoUtils.sortList);
    _this.activityList.shift();
    // _this.activityList[0].label = "Select Expenses";
  }

  transformcost(event) {
    const values = numeral(this.tse.Cost).value();
    event.target.value = values ? values.toFixed(2) : '0.00';
  }

  transformpercent(event) {
    const values = numeral(this.tse.Markup).value();
    event.target.value = values ? values : '0';
  }

  getProjects(clientId) {
    const parent = this;

    parent.projectsList = [];

    if (clientId && !isNaN(clientId)) {
      parent.mangoAPISrvc.showLoader(true);

      parent.mangoAPISrvc.getProjectsByClientId(clientId)
      .subscribe(function (data: any) {
        const filterData = (
          data.filter(
            (note) => (note.Inactive == false)
          )
        );

        for (let i = 0; i < filterData.length; i++) {
          parent.projectsList.push(
            {
              label: filterData[i].EngagementName,
              value: filterData[i].ProjectMasterID,
              EngagementTypeID: filterData[i].EngagementTypeID,
              isBillable: filterData[i].isBillable,
            }
          )
        }

        if (parent.projectsList.length == 1) {
          parent.tse["EngagementName"] = parent.projectsList[0].label;
          parent.tse["ProjectMasterID"] = parent.projectsList[0].value;
          parent.tse["EngagementTypeID"] = parent.projectsList[0].EngagementTypeID;
          parent.tse["isBillable"] = parent.projectsList[0].isBillable;

          parent.selClient = (
            parent.selClient
            ? parent.selClient
            : null
          );

          /*
          parent.selClient['MarkSlipsBilled'] = (
            parent.selClient.MarkSlipsBilled
            ? parent.selClient['MarkSlipsBilled']
            : false
          );
          */

          if(parent.selClient){
            parent.selClient['Billable'] = (
              parent.selClient['Billable']
              ? parent.selClient['Billable']
              : false
            );
          }

          const tempEng = (
            parent.projectsList.filter(
              (proj) => (parent.tse["ProjectMasterID"] == proj.value)
            )[0]
          );

          tempEng['isBillable'] = (
            tempEng['isBillable']
            ? tempEng['isBillable']
            : false
          );

          /*
          if (
              parent.selClient['MarkSlipsBilled'] == true
            || parent.selClient['Billable'] == false
            || tempEng.isBillable == false
          ) {
            parent.tse.Billable = false
          }
          */
        }
        parent.projectsList = [].concat(parent.projectsList); // trick to update the view

        parent.mangoAPISrvc.showLoader(false);
      }, error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );

        parent.mangoAPISrvc.showLoader(false);
      });
    }
  }

  setAWSOBject() {
    /*;
      @note:
        Disable usage of aws credential, transfer flow to the backend.
      @note;

      @disable-aws-credential
    */
    /*;
    AWS.config.region = environment.AWS_REGION;
    this.awsBucket = new AWS.S3({ params: { Bucket: environment.AWS_BUCKET_NAME } });
    */
    this.awsBucket = (
      AWSBucketMask
    );
  }

  bulkDelete(): void {
    this.fileNames = [];
    if (this.filesStack.length > 0) {
      const self = this;
      const params = { Bucket: environment.AWS_BUCKET_NAME, Delete: { Objects: [] } };
      for (let index = 0; index < self.filesStack.length; index++) {
        const element = self.filesStack[index]['UniqueName'];
        const obj = {};
        obj['Key'] = 'documents/' + element;
        params.Delete.Objects.push(obj);
      }
      self.awsBucket.deleteObjects(
        params,

        function (err, data) {
          self.filesStack = [];
          if (err) console.log(err, err.stack); // an error occurred
          else console.log(data);           // successful response
        },

        self.mangoAPISrvc
      );
    }
  }

  addDMSParentFolder(obj, prm, isLastRecord?: any) {
    const parent = this;
    parent.mangoAPISrvc.addDMSParentFolder(obj).subscribe((response) => {
      //parent.fileNames.push(response.data)
      if (isLastRecord) {
        parent.filesStack = [];
        parent.fileNames = [];
        parent.saveResultSuccess(prm);
        parent.mangoAPISrvc.notify('success', parent.translate.instant('Uploaded'), "Successfully Uploaded.");
      }
    });
  }

  saveResultSuccess(prm) {
    //this.tse.Ddate = this.addOffset(this.tse.Ddate);
    // console.log(this.tse.Ddate);
    this.filesStack = [];
    this.fileNames = [];
    if (prm == 'c') {
      this.closeEEModal();
    }
    else if (prm == 'n') {
      this.isSelectAndNew = true;
      this.openEEModal();
    }
    else if (prm == 'd') {
      // this.selectActivity({ value: null });
      // this.tse.Units = 1;
      // this.tse.StandardAmount = 0;
      // this.tse.Cost = 0.00;
      // this.tse.Billed = false;
      // this.tse.Billable = false;
      // this.tse.Reimburseable = false;
      this.isSelectAndDuplicate = true;
      this.selectedDdate = this.tse.Ddate;
      this.tse.ExpenseApproved = this.autoApproved;
    }
  }

  closeEEModal() {
    this.closeExpenseEntryForm();
  }

  closeExpenseEntryForm(doNotRefresh?) {
    const selDate = this.tse.Ddate;
    const url = this.router.url;
    const isFormValid = this.expenseEntryMainForm.valid && (this.expenseEntryMainForm.dirty || this.isMemoChanged) && !this.showAttachedFiles
    if (isFormValid && (url == "/billing-invoicing/manualInvoice" || url == "/client/timeslips" || url == "/engagements/expenses" || url == "/time-expense/list")) {
      this.sharedSrvc.setExpenseDialogData(this.selClient);
    }
    this.isMemoChanged = false;
    if(!doNotRefresh)
      this.closeExpenseEntryModal.emit(selDate);

    this.tse = { isDisable: false };
    this.expenseEntryMainForm.reset();
    this.displayExpenseEntryModal = false;
    this.showAttachedFiles = false;
    this.bulkDelete();
    this.sharedSrvc.sharedCompVisibility.expenseEntry = false;
  }

  openEEModal(selDate?: any) {
    if (selDate && selDate.selectedDate) {
      selDate["selectedDate"] = selDate.selectedDate;
    }
    this.filesStack = [];
    this.fileNames = [];
    this.isEditEE = false;
    this.applyMarkup = false;
    this.costOverRide = false;
    const selectedDdate = this.tse.Ddate;
    this.tse = {};
    this.tse['isDisable'] = this.tse['isDisable'] ? this.tse['isDisable'] : false;
    this.tse['IsTaxable'] = this.companyGlobalSetting.ActivateExpenseRates;
    this.tse.isExpenseMemoRequired = this.companyGlobalSetting.isExpenseMemoRequired ? this.companyGlobalSetting.isExpenseMemoRequired : false;
    this.tse.StandardAmount = 0;
    this.tse.Units = 1;
    this.tse.Cost = 0.00;
    this.tse.StaffID = this.staffID;
    this.getAllDataList();
    this.handleSelectClick({});
    if(selDate){
      this.getProjects(selDate['ClientID']);
    }
    setTimeout(() => {
        if (this.activityList.length > 0) {
          this.tse.ExpenseCodeID = this.activityList[0].value;
          this.tse.ExpenseCode = this.activityList[0].label;
          //this.selectActivity({ value: this.tse.ExpenseCodeID });
        }
        const staffName = this.userName;
        const srchStaff = this.staffList.filter((item) => {
          return item.label == staffName;
        });
        if (srchStaff.length) {
          const staff = srchStaff[0];
          this.staffID = staff.value;
          this.staffName = staff.label;
          this.tse.StaffID = staff.value;
        }        
        if (selDate && selDate['ClientName']) {
          this.selClient = {
            ClientID: selDate.ClientID,
            ClientName: selDate.ClientName
          };
        }
        if(this.isSelectAndNew&&!selDate&&selectedDdate&&this.selClient){
          selDate = { selectedDate:selectedDdate, ClientID:this.selClient.ClientID, ClientName:this.selClient.ClientName };
          this.isSelectAndNew = false;
        }
        if (selDate && selDate['ClientID'] == null) {
          this.tse.Ddate = new Date(selDate["selectedDate"]);
        } else {
          this.tse.Ddate = new Date();
        }
        if (selDate) {
          this.tse.ProjectMasterID = selDate['ProjectMasterID'];
          this.tse.ClientID = selDate['ClientID'];
        }

        this.tse.ExpenseApproved = this.autoApproved;
        this.tse.Billed = false;
        this.tse.Billable = false;
        this.selectActivity({ value: this.tse.ExpenseCodeID });
        this.tse.Reimburseable = false;
          this.displayExpenseEntryModal = true;
    }, 1500);
  }

  handleSelectClick(obj) {
    if(obj?.BlockTimeExpenseEntry){
      this.selClient = this.tse?.ClientID ? {
        ClientID: this.tse.ClientID,
        ClientName: this.tse.ClientName,
      } : null;
      Swal.fire({
        icon: 'warning',
        title: `${this.translate.instant('Warning')}`,
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true,
        confirmButtonText: 'OK',
        text: `${this.translate.instant('block-expense-entry')}!`,
      });
    }
    else{
      if(!obj?.ClientID) return
      this.selClient = obj;
      this.tse.ClientID = obj.ClientID;
      this.tse.ClientName = obj.ClientName;
      this.tse.GroupDescriptionIDArray = obj.GroupDescriptionIDArray;
      this.getSettingData();
      this.getProjects(obj.ClientID);
    }

  }

  handleClientDropdownClick(event) {
    this.filteredClients = [];
    //mimic remote call
    setTimeout(() => {
      this.filteredClients = this.clientList;
    }, 1)
  }

  calculateExpense() {
    const enteredValue = numeral(this.tse.Units).value();
    const enteredCostValue = numeral(this.tse.Cost).value();

    if (enteredValue === null) {
      this.tse.Units = 0.00;
      return;
    } else {
      this.tse.Units = numeral(enteredValue).format('0,0.00');
    }

    this.tse.StandardAmount = enteredValue * enteredCostValue;

    // if (this.applyMarkup && this.tse.Markup && (this.tse.Markup > 0)) {
    //   this.tse.StandardAmount *= this.tse.Markup;
    // }

    // if (this.expenseRatesObj.ActivateExpenseRates && this.tse.Tax && (this.tse.Tax > 0)) {
    //   this.taxableAmount = ((this.tse.StandardAmount * this.tse.Tax) / 100);
    //   this.tse.StandardAmount += this.taxableAmount;
    // }
    this.tse.StandardAmount = this.tse.StandardAmount.toFixed(2);
  }

  calculateSalesTax() {
    const enteredValue = numeral(this.tse.Units).value();
    const enteredCostValue = numeral(this.tse.Cost).value();
    // See if Company has Sales Tax Activated
    const eRates = this.companyGlobalSetting.ActivateExpenseRates;
    let actualTaxRate = 0;
    if(!this.tse.IsTaxable){
      this.tse.Tax = 0;
      return;
    }
    if(JSON.stringify(this.settingsData) === '{}'){
      this.tse.Tax = this.tse.Tax? this.tse.Tax : 0;
      return;
    }
    if (eRates) {
      // 1st Check - Where to get labor rates by checking SalesTaxLevel Values, FirmRate, CompanyLocationRate, ClientRate
      if (this.settingsData && this.settingsData.SalesTaxLevel == 'ClientRate') {
        actualTaxRate = this.settingsData.Tax1ID;
      } else if (this.settingsData && this.settingsData.SalesTaxLevel == 'CompanyLocationRate') {
        actualTaxRate = this.companyLocationObj.TaxRateExpenseL;
      } else {
        actualTaxRate = this.companyGlobalSetting.ExpenseRate1;
      }
      console.log("actualTaxRate...");
      console.log(actualTaxRate)
      this.tse.Tax = numeral(enteredValue * enteredCostValue).value() * (numeral(actualTaxRate).value()/100);
      this.tse.Tax = this.tse.Tax.toFixed(2);
    }
  }

  replaceShortcuts(value, isMemo) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }
      valueArr[i] = label;
    }
    if (isMemo) {
      this.tse.Memo = valueArr.join(" ");
    } else {
      this.isMemoChanged = true
      this.tse.PrivateMemo = valueArr.join(" ");
    }

  }

  removeOffset(date: Date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = date.getTime() - (offset * 60 * 1000);
    return new Date(time);
  }

  addOffset(date: Date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = date.getTime() + (offset * 60 * 1000);
    return new Date(time);
  }

  findChoices(searchText: string) {
    return this['viewContainerRef']['parentInjector']['view']['component']['rawshortcutLabels'].filter(item =>
      item.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  getChoiceLabel(choice: string) {
    return `${choice} `;
  }
  showFiles() {
    this.showAttachedFiles = !this.showAttachedFiles;
  }

  async onSelect(event) {
    if ((this.selClient && !this.selClient.ClientID)) {
      Swal.fire({
        icon: 'warning',
        title: `${this.translate.instant('warning')}!`,
        text: `${this.translate.instant('all_fields_required')}`,
        showConfirmButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Ok',
        timer: 2000
      })
      return false;
    }
    const parent = this;
    const listOfAcceptedFiles = ['pdf', 'txt', 'xls', 'xlsx', 'doc', 'docx', 'csv', 'jpg', 'jpeg', 'png', 'tif', 'rtf'].toString();
    const filePos = event.files[0].name.lastIndexOf('.');
    const fileType = event.files[0].name.substring(filePos + 1, event.files[0].name.length);
    const fileSize = event.files[0].size / 1048576; //size in mb
    this.expenseEntryMainForm.control.markAsDirty()
    if (event.files && listOfAcceptedFiles.indexOf(fileType) > -1 && fileSize <= 30) {
      parent.files.push(...event.files);
      if (parent.tse['SlipMasterID']) {
        for (let index = 0; index < parent.files.length; index++) {
          const file = parent.files[index];
          setTimeout(() => {
            parent.uploadFile(file);
            parent.showAttachedFiles = false;
          }, 200);
        }
      } else {
        parent.tse['GroupDescriptionIDArray'] = parent.tse['GroupDescriptionIDArray']?.length > 0 ? parent.tse['GroupDescriptionIDArray'] : null;
        parent.tse.StaffID = parent.tse.StaffID.staffID ? parent.tse.StaffID.staffID : parent.tse.StaffID;
        parent.mangoAPISrvc.createTimeSheet(parent.tse).subscribe(function (data: any) {
          parent.isEditEE = true;
          parent.tse['SlipMasterID'] = data.id;
          for (let index = 0; index < parent.files.length; index++) {
            const file = parent.files[index];
            setTimeout(() => {
              parent.uploadFile(file);
            }, 200);
          }
        }, error => {
          parent.fileUpload.clear()
          parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.updateErrorMsg);
          parent.mangoAPISrvc.showLoader(false);
        });
      }

    } else {
      Swal.fire({
        icon: 'warning',
        title: `${this.translate.instant('warning')}!`,
        text: `${this.translate.instant('accepted-files')}`,
        showConfirmButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Ok',
        timer: 2000
      })
      parent.fileUpload.clear()
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  async uploadFile(fileObj) {
    const self = this;
    //let defaultObj = { "FName": "", "UniqueName": "", "Size": "", "FileType": "" };
    self.filesStack = [];
    const UniqueName = this.mangoUtils.generateUUID() + '_' + fileObj.name;
    const filePos = fileObj.name.lastIndexOf('.');
    const fileType = fileObj.name.substring(filePos + 1, fileObj.name.length);
    const params = { Bucket: 'mangobillings3', Key: 'documents/' + UniqueName, Body: fileObj };
    self.mangoAPISrvc.showLoader(true);
    self.awsBucket.upload(
      params,

      function (err, data) {
        if (err) {
          self.fileUpload.clear()
          self.mangoAPISrvc.showLoader(false)
          console.log("error while saving file on s3 server", err);
          return;
        }
        self.onRemove(fileObj);
        setTimeout(() => {
          const cloneObj = {};
          cloneObj['FName'] = fileObj.name;
          cloneObj['UniqueName'] = UniqueName;
          cloneObj['Size'] = self.mangoUtils.formatBytes(fileObj.size, 0);
          cloneObj['ModifyOn'] = new Date();
          cloneObj['FileType'] = fileType;
          cloneObj['ShowInPortal'] = false;
          cloneObj['IsExpense'] = true;
          cloneObj['ExpenseMasterID'] = self.tse['SlipMasterID'];
          cloneObj['ClientID'] = self.selClient.ClientID;
          cloneObj['CompanyID'] = self.companyId;
          cloneObj['CreatedOn'] = new Date();
          cloneObj['ModifyBy'] = self.userName;
          cloneObj['isLastRecord'] = self.files.length == 0 ? true : false;
          self.filesStack.push(cloneObj);
          self.fileNames.push(cloneObj);
          self.mangoAPISrvc.showLoader(false);
          self.fileUpload.clear()
        }, 200);

      },

      self.mangoAPISrvc
    );
  }

  selectActivity(event) {
    //let opt = event.value;
    if (!event.value) {
      return false;
    }
    const opt = this.activityList.filter((item) => { return item.value == event.value; })[0].dataItem;
    this.tse.Tax = null;
    this.tse.Markup = opt.Markup;
    this.tse.Description = opt.Description;
    this.tse.Memo = this.isEditEE ? this.tse.Memo : opt.Description;
    this.tse.Billable = opt.Billable;
    this.tse.Cost = numeral(opt.Cost).value();
    this.tse.Cost = this.tse.Cost ? this.tse.Cost.toFixed(2) : '0.00';
    this.tse.ExpenseCodeID = opt.ExpenseCodeID;
    this.tse.ExpenseCode = opt.ExpenseCode;
    this.tse.IsTaxable = opt.TaxMap1;
    this.tse.Memo = this.mangoUtils.replaceCaretTemplate(this.tse.Memo)
    //this.calculateSalesTax();
    if (this.expenseRatesObj.ActivateExpenseRates) {
      if (opt.TaxMap1)
        this.tse.Tax = this.expenseRatesObj.ExpenseRate1.toString();
      else if (opt.TaxMap2)
        this.tse.Tax = this.expenseRatesObj.ExpenseRate2.toString();
    }
    this.calculateExpense();
  }

  approveExpenseRecord() {
    for (let i = 0; i < this.expenseSheetList.length; i++) {
      if (this.expenseSheetList[i].check) {
        this.idForApprove.push(this.expenseSheetList[i].ExpenseMasterID);
      }
    }
    const obj = { 'expenseMasterIdArr': this.idForApprove };
    const _this = this;
    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc.approveExpenseRecord(obj).subscribe(function (data: any) {
      _this.mangoAPISrvc.showLoader(false);
      _this.mangoAPISrvc.notify('success', _this.translate.instant('Success'), data.message);
    }, error => {
      _this.mangoAPISrvc.showLoader(false);
      _this.mangoAPISrvc.notify('error', _this.translate.instant('error'), AppConstants.updateErrorMsg);
    });
  }

  createDMSParent(prm, slipmasterId) {
    if (slipmasterId && this.filesStack.length > 0) {
      for (let index = 0; index < this.filesStack.length; index++) {
        const element = this.filesStack[index];
        element['ExpenseMasterID'] = slipmasterId;
        const isLastRecord = (this.filesStack.length - 1 == index) ? true : false;
        this.addDMSParentFolder(element, prm, isLastRecord);
      }
    }
  }

  // adding new engagent
  addEngagementPopUp() {
    const parent = this;

    if(!parent.isAllowCreateEngagement) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        showConfirmButton: true,
        text: parent.translate.instant('client.engagement.permission')
      });
      return
    }

    const obj = {};
    obj['isClientFlow'] = true;
    obj['isEditFlow'] = true;
    obj['ClientID'] = this.selClient['ClientID'];
    obj['ClientName'] = this.selClient['ClientName'];
    obj['AssignedTo'] = this.staffID;
    parent.sharedSrvc.openEngagementDialog(obj);
  }

  openAddExpenses() {
    const obj = {
      TaxMap1: false,
      TaxMap2: false,
      TaxMap3: false,
      Billable: true,
      Inactive: false,
      Description: "",
      ExpenseCode: "",
      Cost: "",
      Markup: "0"
    };
    // this.displayAddExpenseModal = true;
    this.sharedSrvc.openExpensesDialog({isEditFlow: false, expense: obj})
  }

  validateExpenseDateTimeout: any;
  validateExpenseDate( ){
    if(
        (
              typeof
              this.validateExpenseDateTimeout
          !=  "undefined"
        )
    ){
      clearTimeout( this.validateExpenseDateTimeout );
      this.validateExpenseDateTimeout = undefined;
    }

    this.validateExpenseDateTimeout = (
      setTimeout( ( ) => {
        const expenseDate = (
          this.tse.Ddate
        );

        const dateToday = (
          new Date( )
        );

        if (this.mangoUtils.dateDiff(expenseDate, dateToday, "months") > 2) {
          Swal.fire(
            {
              "icon": 'warning',

              "title": (
                `${ this.translate.instant( 'Warning' ) }?`
              ),
              "text": (
                `${ this.translate.instant( 'two-month-gap-date-entry-warning' ) }!`
              ),

              "confirmButtonText": 'OK',

              "showCancelButton": false,
              "allowEscapeKey": true,
              "allowEnterKey": true,
            }
          );
        }

        clearTimeout( this.validateExpenseDateTimeout );
        this.validateExpenseDateTimeout = undefined;
      }, 500 )
    );
  }

  get isAllowCreateEngagement() {
    return this.auth.isAllowAccess(38)
  }
}
